import {
    CreateReportInput,
    Recurring_Frequency,
    Report_Category,
    useCreateReportMutation,
    useGetCompanyInfoFromDomainQuery,
    useInitiateReportMutation,
    Whistleblower_Relation,
} from 'src/generated/graphql';
import format from 'date-fns/format';
import set from 'date-fns/set';
import paths from '@shared/paths';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import useStore from '@store/useStore';
export interface IFormData {
    reportNumber: string;
    category: Report_Category;
    otherCategory?: string;
    relation?: Whistleblower_Relation;
    relationOther?: string;
    personsInvolved: {
        name: string;
        surname: string;
        relation: string;
    }[];
    message: string;
    files: File[];
    date?: Date;
    time?: Date;
    anonymous?: boolean;
    recurring?: string;
    recurringFrequency?: Recurring_Frequency;
    whistleblower?: {
        name: string;
        surname: string;
        email?: string;
        phone?: string;
    };
    password: string;
    confirmPassword: string;
    hcaptchaToken: string;
}

type RouteState = {
    reportNumber?: string;
    companyName?: string;
    companyLogo?: string;
};

export default () => {
    const useLocationState = useLocation().state as RouteState;
    const [_createReportResult, createReport] = useCreateReportMutation();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [companyName, setCompanyName] = useState('');
    const [companyLogo, setCompanyLogo] = useState('');
    const [result] = useGetCompanyInfoFromDomainQuery({
        pause:
            (useLocationState?.companyName &&
                useLocationState.companyName?.length > 0 &&
                useLocationState?.companyLogo &&
                useLocationState.companyLogo?.length > 0) ||
            (companyName.length > 0 && companyLogo.length > 0),
    });
    const [reportNo, setReportNo] = useState('');
    const [_, initiateReport] = useInitiateReportMutation();
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        if (useLocationState?.companyName && useLocationState.companyName?.length > 0) {
            setCompanyName(useLocationState.companyName);
        } else {
            if (result.error) {
                setLoader(false);
            }
            if (result.data?.getCompanyInfoFromDomain.shortName) {
                setLoader(false);
                setCompanyName(result.data.getCompanyInfoFromDomain.shortName);
            }
        }
        if (useLocationState?.companyLogo && useLocationState.companyLogo?.length > 0) {
            setCompanyLogo(useLocationState.companyLogo);
        } else {
            if (result.data?.getCompanyInfoFromDomain?.logoUrl) {
                setCompanyLogo(result.data.getCompanyInfoFromDomain.logoUrl);
            }
        }
    }, [result]);

    useEffect(() => {
        async function fetchReportNumber() {
            if (!useLocationState.reportNumber) {
                const result = await initiateReport();
                if (result.data?.initiateReport.reportNumber) {
                    setReportNo(result.data.initiateReport.reportNumber);
                }
            } else {
                setReportNo(useLocationState.reportNumber);
            }
        }
        fetchReportNumber();
    }, []);

    const mapFormData = (data: IFormData): CreateReportInput => {
        const { files } = useStore.getState();
        return {
            reportNumber: data.reportNumber || reportNo,
            password: data.password,
            category: data.category,
            otherCategory:
                data.category === Report_Category.Other &&
                data.otherCategory &&
                data.otherCategory.length > 0
                    ? data.otherCategory
                    : undefined,
            message: data.message,
            occurringDetails: {
                firstTimeOccurredAt:
                    data.date &&
                    data.time &&
                    set(new Date(data.date), {
                        hours: +format(new Date(data.time), 'H'),
                        minutes: +format(new Date(data.time), 'm'),
                    }),
                recurring: data?.recurring === 'Yes' ? true : false,
                recurringFrequency: data.recurringFrequency,
            },
            personsInvolved: data.personsInvolved,
            whistleblower: data.whistleblower
                ? {
                      ...data.whistleblower,
                      email: data.whistleblower.email || undefined,
                      phone: data.whistleblower.phone || undefined,
                  }
                : undefined,
            whistleblowerRelation: data.relation && {
                relation: data.relation,
                other: data.relationOther,
            },
            hcaptchaToken: data.hcaptchaToken,
            attachments: files.map(({ fileId }) => fileId),
        };
    };

    const onSubmit = async (data: IFormData) => {
        const { files } = useStore.getState();

        if (data.anonymous) {
            delete data.whistleblower;
        }
        const result = await createReport({ input: mapFormData(data) });
        if (result.data?.createReport.reportNumber) {
            navigate(paths.reportSubmitted, {
                state: {
                    reportNumber: result.data.createReport.reportNumber,
                    companyName,
                    createdAt: new Date(),
                    attachments: files?.length > 0 ? files.map((file) => file.name) : [],
                    message: data.message,
                    whistleblowerRelation: {
                        relation: data.relation,
                        other: data.relationOther,
                    },
                    category: data.category,
                    otherCategory: data.otherCategory,
                    whistleblower: data.whistleblower && {
                        name: data.whistleblower.name,
                        surname: data.whistleblower.surname,
                        phone: data.whistleblower.phone,
                        email: data.whistleblower.email,
                    },
                },
            });
        }
    };
    return {
        onSubmit,
        step,
        setStep,
        companyName,
        companyLogo,
        reportNo,
        loader,
        stateReportNumber: useLocationState.reportNumber,
    };
};
