export const CloseIcon = () => {
    return (
        <svg
            width={24}
            height={24}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <path
                    id="prefix__a"
                    d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z"
                />
            </defs>
            <g fill="none" fillRule="evenodd" opacity={0.32}>
                <path d="M0 0h24v24H0z" />
                <path d="M0 0h24v24H0z" />
                <g transform="translate(5 5)">
                    <mask id="prefix__b" fill="#fff">
                        <use xlinkHref="#prefix__a" />
                    </mask>
                    <use fill="#000" fillRule="nonzero" xlinkHref="#prefix__a" />
                    <g mask="url(#prefix__b)" fill="#000">
                        <path d="M-5 19h24V-5H-5z" />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default CloseIcon;
