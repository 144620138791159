import { makeStyles } from '@material-ui/core/styles';
import defaultTheme from '@shared/theme/default.theme';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
    link: {
        margin: '16px 0 44px',
        fontSize: '14px',
        lineHeight: 1.43,
        textAlign: 'center',
        '& a': {
            color: defaultTheme.colors.input_text_color,
            textDecoration: 'none',
            '&:hover': {
                color: defaultTheme.colors.hover,
            },
        },
    },
}));

export const LinkWrapper = styled.div``;
