import { FormattedMessage } from 'react-intl';
import { IReportNumberProps } from './ReportNumber.d';
import { ReportNumberWrapper, Report, ReportText } from './ReportNumber.styled';

const ReportNumber = ({ reportNumber }: IReportNumberProps) => {
    return (
        <ReportNumberWrapper>
            <ReportText>
                <FormattedMessage id="reportNumber.reportNumber" />
            </ReportText>
            <Report>{reportNumber}</Report>
        </ReportNumberWrapper>
    );
};

export default ReportNumber;
