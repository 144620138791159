export interface IFile {
    size: number;
    frontId: string;
    mimeType: string;
    name: string;
    url: string;
    file: any;
    fileId: string;
}

export interface IFileProgress {
    frontId: string;
    progress: number;
}

export interface IFilesUploader {
    files: IFile[];
    onDelete: (frontId: string) => Promise<void>;
    handleChange: (e: any) => Promise<false | undefined>;
    fileProgresses: IFileProgress[];
    error: string;
}

export enum ACCEPTED_MIME_TYPE {
    IMAGE = 'image',
    VIDEO = 'video',
    AUDIO = 'audio',
    DOC = 'application/msword',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    PDF = 'application/pdf',
    PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    XLS = 'application/vnd.ms-excel',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ZIP = 'application/zip',
    '7Z' = 'application/x-7z-compressed',
    CSV = 'text/csv',
    TXT = 'text/plain',
}
