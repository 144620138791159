import { IReportNumberProps } from './ReportNumber.d';
import { ReportNumberWrapper, Report } from './ReportNumber.styled';

const ReportNumber = ({ reportNumber }: IReportNumberProps) => {
    return (
        <ReportNumberWrapper>
            <Report>{reportNumber}</Report>
        </ReportNumberWrapper>
    );
};

export default ReportNumber;
