import { gql, useMutation } from 'urql';

import { InitiateReportMutation } from 'src/generated/graphql';

const INITIATE_REPORT = gql`
    mutation InitiateReport {
        initiateReport {
            reportNumber
        }
    }
`;

export default () => useMutation<InitiateReportMutation>(INITIATE_REPORT);
