import { Title, Button, Paragraph } from '@components/_universal';
import { TITLE_TYPE } from '@components/_universal/Title/Title.d';
import { Dispatch, SetStateAction } from 'react';
import { Modal } from '@material-ui/core/';
import { ModalBody, CloseButton } from '@components/ReportDetails/Modal/Modal.styled';
import CloseIcon from '@components/_universal/CloseIcon/CloseIcon';
import useAddUserModal from './useAddUserModal';
import { TextInput } from '@components/_form-elements/Inputs';
import { emailRegExp } from '@utils/regexps';
import Alert from '@material-ui/lab/Alert';
import { FormattedMessage } from 'react-intl';

export interface IAddUserModalElement {
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    openModal: boolean;
    inviteEmployee: (email: string) => Promise<void>;
}

const AddUserModal = ({ setOpenModal, openModal, inviteEmployee }: IAddUserModalElement) => {
    const {
        register,
        handleSubmit,
        addUser,
        errors,
        error,
        intl,
        inviteInProgress,
        success,
        setSuccess,
    } = useAddUserModal(inviteEmployee);
    return (
        <Modal
            open={openModal}
            onClose={() => {
                setOpenModal(!openModal);
                setSuccess(false);
            }}
        >
            <ModalBody>
                <CloseButton
                    onClick={() => {
                        setOpenModal(!openModal);
                        setSuccess(false);
                    }}
                >
                    <CloseIcon />
                </CloseButton>
                {!success ? (
                    <>
                        <Title center type={TITLE_TYPE.H1} id="usersPage.modal.title" />
                        <form>
                            <TextInput
                                error={Boolean(errors.email)}
                                helperText={errors.email ? errors.email.message : undefined}
                                label={intl.formatMessage({
                                    id: 'usersPage.emailInputLabel',
                                })}
                                type="email"
                                {...register('email', {
                                    required: intl.formatMessage({
                                        id: 'usersPage.validation.emptyEmail',
                                    }),
                                    pattern: {
                                        value: emailRegExp,
                                        message: intl.formatMessage({
                                            id: 'usersPage.validation.wrongEmail',
                                        }),
                                    },
                                })}
                            />
                            <Button
                                type="submit"
                                size="full"
                                variant="primary"
                                onSubmit={handleSubmit(addUser)}
                                onClick={handleSubmit(addUser)}
                                disabled={inviteInProgress}
                            >
                                <FormattedMessage id="usersPage.submitButton" />
                            </Button>
                        </form>
                    </>
                ) : (
                    <>
                        <Title center type={TITLE_TYPE.H1} id="usersPage.modal.titleSuccess" />
                        <Paragraph fontSize="16px" marginBottom="30px">
                            <FormattedMessage id="usersPage.modal.successDesc" />
                        </Paragraph>
                        <Button
                            type="submit"
                            size="full"
                            variant="primary"
                            onClick={() => {
                                setOpenModal(!openModal);
                                setSuccess(false);
                            }}
                        >
                            <FormattedMessage id="usersPage.continueButton" />
                        </Button>
                    </>
                )}
                {error.length > 0 && (
                    <>
                        <br />
                        <Alert severity="error">{error}</Alert>
                        <br />
                    </>
                )}
            </ModalBody>
        </Modal>
    );
};

export default AddUserModal;
