import { DefaultLayout } from '@components/Layouts';
import { Card, Img, Paragraph, Title } from '@components/_universal';
import Divider from '@components/_universal/Divider/Divider';
import Highlight from '@components/_universal/Highlight/Highlight';
import img from '@assets/images/new-message-cuate.svg';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';

type LocationState = {
    email?: string;
};

const ResetPasswordNotify = () => {
    const { email = '' } = useLocation().state as LocationState;
    return (
        <DefaultLayout>
            <Card>
                <Img src={img} alt="Otwórz link do resetu hasła" />
                <Title center id="resetPasswordNotify.title" />
                <Highlight>
                    <FormattedMessage id="resetPasswordNotify.emailSent" /> <strong>{email}</strong>
                </Highlight>
                <Paragraph center marginTop="20px" fontSize="15px" marginBottom="40px">
                    <FormattedMessage id="resetPasswordNotify.openEmailParagraph" />
                </Paragraph>
                <Divider />
                <Paragraph
                    center
                    color="input_text_color"
                    marginTop="40px"
                    fontSize="15px"
                    marginBottom="20px"
                >
                    <FormattedMessage id="resetPasswordNotify.checkSpam" />
                </Paragraph>
            </Card>
        </DefaultLayout>
    );
};

export default ResetPasswordNotify;
