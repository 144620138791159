import { gql, useMutation } from 'urql';
import {
    ConfirmResetPasswordMutation,
    MutationConfirmResetPasswordArgs,
} from 'src/generated/graphql';

const CONFIRM_RESET_PASSWORD_MUTATION = gql`
    mutation ConfirmResetPassword($input: ConfirmResetPasswordInput!) {
        confirmResetPassword(input: $input) {
            success
        }
    }
`;

export default () =>
    useMutation<ConfirmResetPasswordMutation, MutationConfirmResetPasswordArgs>(
        CONFIRM_RESET_PASSWORD_MUTATION,
    );
