import styled from 'styled-components';

export const ReportDetailsBarWrapper = styled.div`
    width: calc(100% + 32px);
    margin-left: -16px;
    background: #eee;
    margin-top: 20px;
    border-top: 1px solid ${(props) => props.theme.colors.border};
    padding: 10px 16px 50px;
    position: relative;

    ${(props) => props.theme.media.md} {
        width: calc(100% + 64px);
        padding: 0 32px 50px;
        margin-left: -32px;
    }

    .signalist {
        margin-top: 24px;
        font-size: 14px;
        color: ${(props) => props.theme.colors.input_text_color};

        span {
            margin-left: 10px;
        }
    }
`;
