import { Paragraph } from '@components/_universal';
import { IPickedFileProps } from './PickedFile.d';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {
    LeftSide,
    PickedFileContainer,
    DeleteFileButton,
    ProgressHolder,
} from './PickedFile.styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage } from 'react-intl';

const PickedFile = ({ fileName, onDelete, progress, frontId, inputRef }: IPickedFileProps) => {
    return (
        <PickedFileContainer>
            <LeftSide>
                <AttachFileIcon></AttachFileIcon>
                <Paragraph fontSize={'14px'} fontWeight={'semiBold'} marginLeft={'8px'}>
                    {fileName}
                </Paragraph>
            </LeftSide>
            {progress === 100 ? (
                <DeleteFileButton
                    onClick={() => {
                        onDelete(frontId);
                        if (inputRef.current && (inputRef.current as any).value) {
                            (inputRef.current as any).value = null;
                        }
                    }}
                >
                    <Paragraph color={'darkBlue'} fontSize={'15px'} fontWeight={'bold'}>
                        <FormattedMessage id="pickedFile.delete" />
                    </Paragraph>
                </DeleteFileButton>
            ) : (
                <ProgressHolder>
                    <CircularProgress size={25} thickness={6} variant="determinate" value={100} />
                    <CircularProgress
                        size={25}
                        thickness={6}
                        variant="determinate"
                        value={progress}
                    />
                </ProgressHolder>
            )}
        </PickedFileContainer>
    );
};

export default PickedFile;
