import { MessageWrapper, File } from './Message.styled';
import { AttachFile } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

interface IMessageProps {
    response?: boolean;
    fileName?: string;
    onClick?: () => void;
}

const Message: React.FC<IMessageProps> = (props) => {
    return (
        <MessageWrapper response={props.response}>
            {props.fileName ? (
                <File>
                    <AttachFile />
                    <span>{props.fileName}</span>
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            if (props.onClick) {
                                props.onClick();
                            }
                        }}
                    >
                        <FormattedMessage id="checkReportPage.showAttachmentLink" />
                    </a>
                </File>
            ) : (
                props.children
            )}
        </MessageWrapper>
    );
};

export default Message;
