import { withStyles } from '@material-ui/core/styles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import defaultTheme from '@shared/theme/default.theme';

export const CustomCheckbox = withStyles({
    root: {
        color: defaultTheme.colors.border,
        '&$checked': {
            color: defaultTheme.colors.primary,
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const useStyles = makeStyles(() => ({
    label: {
        fontFamily: defaultTheme.fontFamily,
        color: defaultTheme.colors.text,
        fontSize: '12px',
    },
}));

export const StyledCheckbox = withStyles(({ palette }) => ({
    root: {
        margin: -9,
        marginRight: 3,
        flex: '0 0 auto',
    },
}))((props: CheckboxProps) => <Checkbox color="default" {...props} />);
