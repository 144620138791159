import { TextInput, PasswordInput } from '@components/_form-elements/Inputs';
import { DefaultLayout } from '@components/Layouts';
import { Card, Button, Title, Toast } from '@components/_universal';
import { Link } from 'react-router-dom';
import { ForgottenLink } from '../Components/ForgottenLink/ForgottenLink';
import { Text } from '../Components/Text/Text';
import useLoginPage from './useLoginPage';
import { emailRegExp } from '@utils/regexps';
import Alert from '@material-ui/lab/Alert';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import paths from '@shared/paths';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';

type LocationState = {
    showToast?: boolean;
};

const LoginPage = () => {
    const { showEmailError, errors, error, register, handleSubmit, showPasswordError, signin } =
        useLoginPage();
    const intl = useIntl();
    const showToast = (useLocation().state as LocationState)?.showToast;
    return (
        <DefaultLayout>
            <Card>
                <Title center id="loginPage.loginTitle" />
                <form>
                    <TextInput
                        required
                        error={Boolean(errors.email) || showEmailError}
                        helperText={errors.email ? errors.email.message : undefined}
                        label={intl.formatMessage({
                            id: 'loginPage.emailInputLabel',
                        })}
                        type="email"
                        {...register('email', {
                            required: intl.formatMessage({
                                id: 'loginPage.validation.emptyEmail',
                            }),
                            pattern: {
                                value: emailRegExp,
                                message: intl.formatMessage({
                                    id: 'loginPage.validation.wrongEmail',
                                }),
                            },
                        })}
                    />
                    <PasswordInput
                        label={intl.formatMessage({
                            id: 'loginPage.passwordInputLabel',
                        })}
                        error={Boolean(errors.password) || showPasswordError}
                        helperText={errors.password ? errors.password.message : undefined}
                        {...register('password', {
                            required: intl.formatMessage({
                                id: 'loginPage.validation.emptyPassword',
                            }),
                            minLength: 1,
                        })}
                    />
                    <Button
                        type="submit"
                        onSubmit={handleSubmit(signin)}
                        onClick={handleSubmit(signin)}
                        size="full"
                        variant="primary"
                        icon={<AccountCircleIcon />}
                    >
                        <FormattedMessage id="loginPage.loginButton" />
                    </Button>
                </form>
                <ForgottenLink link={paths.resetPassword}>
                    <FormattedMessage id="loginPage.forgottenLink" />
                </ForgottenLink>
                <Text>
                    <FormattedMessage id="loginPage.dontHaveAccount" />
                    <Link to={paths.registration}>
                        <FormattedMessage id="loginPage.createAccount" />
                    </Link>
                </Text>
                {error.length > 0 && <Alert severity="error">{error}</Alert>}
            </Card>
            {showToast && (
                <Toast
                    position={{ vertical: 'top', horizontal: 'center' }}
                    text={
                        error.length === 0
                            ? intl.formatMessage({ id: 'registrationCompletePage.toastText' })
                            : error
                    }
                    autoHide={6000}
                    isSuccess={error.length === 0}
                />
            )}
        </DefaultLayout>
    );
};

export default LoginPage;
