import styled from 'styled-components';

export const StepperWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: #fff;
    z-index: 2;
    display: flex;

    &:after {
        content: '';
        width: 5px;
        height: 100%;
        position: absolute;
        z-index: 3;
        left: 0;
    }
`;

export const Step = styled.div<{ steps: number; last?: boolean; active?: boolean }>`
    width: calc(100% / ${(props) => props.steps});
    height: 100%;
    margin-right: 4px;
    transition: 1000ms all ease;
    background: ${(props) =>
        props.active ? props.theme.colors.primary : props.theme.colors.border};
    ${(props) => props.last && 'margin-right: 0;'};
`;
