import { gql, useMutation } from 'urql';
import {
    CreateCompanyAsMaintainerMutation,
    MutationCreateCompanyAsMaintainerArgs,
} from 'src/generated/graphql';

const CREATE_COMPANY_AS_MAINTAINER_MUTATION = gql`
    mutation CreateCompanyAsMaintainer($input: CreateCompanyAsMaintainerInput!) {
        createCompanyAsMaintainer(input: $input) {
            _id
        }
    }
`;

export default () =>
    useMutation<CreateCompanyAsMaintainerMutation, MutationCreateCompanyAsMaintainerArgs>(
        CREATE_COMPANY_AS_MAINTAINER_MUTATION,
    );
