import { gql, useMutation } from 'urql';
import { CreateReportMutation, MutationCreateReportArgs } from 'src/generated/graphql';

const CREATE_REPORT_MUTATION = gql`
    mutation CreateReport($input: CreateReportInput!) {
        createReport(input: $input) {
            reportNumber
        }
    }
`;

export default () =>
    useMutation<CreateReportMutation, MutationCreateReportArgs>(CREATE_REPORT_MUTATION);
