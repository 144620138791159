import React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import Arrow from '@assets/images/icon-icon-arrow-down.svg';
import { useStyles } from './LanguageSwitcher.styled';
import useLanguageSwitcher from './useLanguageSwitcher';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import useStore from '@store/useStore';

const LanguageSwitcher: React.FC = () => {
    const { handleClick, handleClose, open, anchorEl } = useLanguageSwitcher();
    const classes = useStyles();

    const { language, setLanguage } = useStore();

    return (
        <div>
            <Button
                id="langSwitcher"
                aria-controls="langSwitcher"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className={classes.root}
            >
                {language} <img src={Arrow} alt="" />
            </Button>
            <Menu
                id="langSwitcher"
                aria-labelledby="langSwitcher"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem
                    onClick={() => {
                        setLanguage('pl');
                        LocalStorageManager.setValue('language', 'pl');
                        handleClose();
                    }}
                >
                    Polski
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setLanguage('en');
                        LocalStorageManager.setValue('language', 'en');
                        handleClose();
                    }}
                >
                    English
                </MenuItem>
            </Menu>
        </div>
    );
};

export default LanguageSwitcher;
