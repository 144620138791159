import styled from 'styled-components';
import ButtonBase from '@material-ui/core/ButtonBase';

export const StyledButton = styled(ButtonBase)`
    && {
        position: relative;
        text-transform: none;
        text-decoration: none;
        font-weight: 600;
        border: 2px solid transparent;
        line-height: 24px;
    }

    & .icon {
        position: relative;
        z-index: 1;
    }

    &:disabled {
        opacity: 0.56;
    }

    & .blend {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 2px;
        margin: -2px;
        box-sizing: content-box;
        background: transparent;
        pointer-events: none;
        transition: all 100ms cubic-bezier(0, 0, 0.2, 1);
    }
    & .label {
        position: relative;
    }

    &:hover {
        & .blend {
            background: ${(props) => props.theme.colors.buttonHover};
        }
    }
    &:hover:active {
        & .blend {
            background: ${(props) => props.theme.colors.buttonHover};
        }
    }
    &&:focus {
        text-decoration: none;
    }
    &:not(:hover):focus-visible {
        & .blend {
            padding: 8px;
            margin: -8px;
            background: black;
        }
    }

    &.size-small {
        height: 40px;
        border-radius: 6px;
        padding: 0 10px;
        font-size: 14px;

        & .blend {
            border-radius: 6px;
        }
        & .icon {
            margin: 3px -4px -3px;

            & + .label {
                margin-left: 8px;
            }
        }
    }
    &.size-medium {
        height: 48px;
        border-radius: 8px;
        padding: 0 12px;
        font-size: 15px;

        & .blend {
            border-radius: 8px;
        }
        & .icon {
            margin: 3px -2px -3px;

            & + .label {
                margin-left: 8px;
            }
        }
    }
    &.size-large {
        height: 56px;
        border-radius: 12px;
        padding: 0 14px;
        font-size: 16px;

        & .blend {
            border-radius: 12px;
        }
        & .icon {
            margin: 3px 0px -3px;

            & + .label {
                margin-left: 8px;
            }
        }
    }

    &.size-full {
        height: 48px;
        border-radius: 12px;
        padding: 0 14px;
        font-size: 16px;
        width: 100%;

        & .blend {
            border-radius: 12px;
        }
        & .icon {
            margin: 3px 0px -3px;

            & + .label {
                margin-left: 8px;
            }
        }
    }

    &.variant-primary {
        background-color: ${(props) => props.theme.colors.primary};
        color: white;
    }

    &.variant-secondary {
        background-color: ${(props) => props.theme.colors.secondary};
        color: white;
    }

    &.variant-outlined {
        border-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.primary};

        &.textVariant-neutral {
            color: ${(props) => props.theme.colors.primary};
        }
        &:hover {
            .blend {
                background: ${(props) => props.theme.colors.border};
            }
        }
    }

    &.variant-text {
        color: ${(props) => props.theme.colors.primary};

        &.textVariant-neutral {
            color: ${(props) => props.theme.colors.primary};
        }

        &:hover {
            /* color: ${(props) => props.theme.colors.secondary}; */
            .blend {
                background: rgba(0, 0, 0, 0.04);
            }
        }
    }

    &.no-margin {
        margin-top: 0;
    }

    &.black {
        font-weight: ${(props) => props.theme.fontWeight.black};
    }

    &.absolute {
        position: absolute;
    }

    &.marginTop20 {
        margin-top: 20px;
    }

    &.marginTop60 {
        margin-top: 60px;
    }

    &.bold {
        font-weight: bold;
    }
`;
