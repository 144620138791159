import { IPersonProps } from './Person.d';
import { PersonWrapper, Title, PositionedButton } from './Person.styled';
import { TextInput } from '@components/_form-elements/Inputs';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

const Person = ({ index, data, remove, register, watch, setValue }: IPersonProps) => {
    const values = watch();
    const intl = useIntl();
    return (
        <PersonWrapper>
            <Title>
                <PersonOutlineIcon />
                <span>
                    <FormattedMessage id="person.personNumber" /> {index + 1}
                </span>
            </Title>
            <TextInput
                label={intl.formatMessage({ id: 'person.nameLabel' })}
                defaultValue={data.name}
                InputProps={{
                    endAdornment: values?.personsInvolved[index]?.name !== '' && (
                        <IconButton
                            onClick={() => {
                                setValue(`personsInvolved.${index}.name`, '');
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    ),
                }}
                {...register(`personsInvolved.${index}.name`)}
            />

            <TextInput
                label={intl.formatMessage({ id: 'person.surnameLabel' })}
                defaultValue={data.surname}
                InputProps={{
                    endAdornment: values.personsInvolved[index]?.surname !== '' && (
                        <IconButton
                            onClick={() => {
                                setValue(`personsInvolved.${index}.surname`, '');
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    ),
                }}
                {...register(`personsInvolved.${index}.surname`)}
            />

            <TextInput
                label={intl.formatMessage({ id: 'person.relationLabel' })}
                defaultValue={data.relation}
                InputProps={{
                    endAdornment: values.personsInvolved[index]?.relation !== '' && (
                        <IconButton
                            onClick={() => {
                                setValue(`personsInvolved.${index}.relation`, '');
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    ),
                }}
                {...register(`personsInvolved.${index}.relation`)}
            />

            {index > 0 && (
                <PositionedButton
                    size="medium"
                    className="black absolute"
                    variant="text"
                    onClick={() => remove(index)}
                >
                    <FormattedMessage id="person.delete" />
                </PositionedButton>
            )}
        </PersonWrapper>
    );
};

export default Person;
