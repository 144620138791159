import { Text } from './LogoText.styled';
import { ILogoTextProps } from './LogoText.d';

const LogoText = ({ children, fontSize, cutLength }: ILogoTextProps) => {
    return (
        <Text fontSize={fontSize} cutLength={cutLength}>
            {children}
        </Text>
    );
};

export default LogoText;
