import FormControlLabel from '@material-ui/core/FormControlLabel';
import { StyledRadio, StyledLabel } from './Radio.styled';
import { IRadioProps } from './Radio.d';

export default function Radio({ label, value, checked, inputRef }: IRadioProps) {
    return (
        <FormControlLabel
            value={value}
            checked={checked}
            control={<StyledRadio />}
            label={<StyledLabel>{label}</StyledLabel>}
            inputRef={inputRef}
        />
    );
}
