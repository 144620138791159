import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { IModalForm, IModalProps } from './Modal.d';

export default function useModal(sendReply: IModalProps['sendReply']) {
    const [error, setError] = useState('');
    const [status, setStatus] = useState(false);
    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    } = useForm<IModalForm>({ mode: 'all' });
    const intl = useIntl();

    const sendResponse = async ({ responseValue }: { responseValue: string }) => {
        try {
            setValue('responseValue', '');
            await sendReply(responseValue);
            setStatus(true);
        } catch (err) {
            setError(intl.formatMessage({ id: 'validation.unknownError' }));
        }
    };

    return {
        error,
        errors,
        handleSubmit,
        sendResponse,
        register,
        control,
        status,
        setStatus,
        watch,
    };
}
