import * as React from 'react';
import { IconProps } from './types';

export const Close = ({ fill, sizeY }: IconProps) => (
    <svg
        width="1em"
        height={sizeY ? sizeY : '1em'}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.7278 12.0072L16.8377 7.91986C17.0347 7.72481 17.0347 7.40906 16.8377 7.21451C16.6411 7.01946 16.3219 7.01946 16.1253 7.21451L12.019 11.2984L7.87469 7.15364C7.67815 6.9566 7.35892 6.9566 7.16237 7.15364C6.96583 7.35117 6.96583 7.67091 7.16237 7.86794L11.3037 12.0097L7.14741 16.143C6.95086 16.3381 6.95086 16.6538 7.14741 16.8484C7.34395 17.0434 7.66318 17.0434 7.85972 16.8484L12.0125 12.7186L16.1403 16.8469C16.3368 17.0439 16.6561 17.0439 16.8526 16.8469C17.0491 16.6493 17.0491 16.3296 16.8526 16.1326L12.7278 12.0072Z"
            fill={(fill && fill[0]) || '#333333'}
        />
    </svg>
);

export const AccountUnknown = ({ fill, sizeY }: IconProps) => (
    <svg
        width="56"
        height="56"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M45.5 24.5V28H42v-3.5h3.5m-4.667-11.667h-3.5v-1.166a7 7 0 0 1 14 0c0 2.263-1.166 4.386-3.01 5.623l-.7.443c-1.33.934-2.123 2.357-2.123 3.967v.467H42V21.7a8.24 8.24 0 0 1 3.71-6.883l.677-.444a3.232 3.232 0 0 0 1.446-2.706 3.5 3.5 0 1 0-7 0v1.166M18.667 21c6.23 0 18.666 3.127 18.666 9.333v7H0v-7C0 24.127 12.437 21 18.667 21m0-21a9.333 9.333 0 1 1 0 18.667 9.333 9.333 0 0 1 0-18.667m0 25.433c-6.93 0-14.234 3.407-14.234 4.9V32.9H32.9v-2.567c0-1.493-7.303-4.9-14.233-4.9m0-21a4.9 4.9 0 1 0 0 9.8 4.9 4.9 0 0 0 0-9.8z"
                id="a"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h56v56H0z" />
            <g transform="translate(4.667 9.333)">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <use fill="#000" fillRule="nonzero" xlinkHref="#a" />
                <g mask="url(#b)" fill="#000">
                    <path d="M-4.667 46.667h56v-56h-56z" />
                </g>
            </g>
        </g>
    </svg>
);

export const Anonymous = ({ fill, sizeY }: IconProps) => (
    <svg
        width="56"
        height="56"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="M35.14 25.671c-4.34 0-7.98 3.104-8.913 7.234-2.217-.957-4.247-.7-5.787-.024-.957-4.153-4.597-7.21-8.913-7.21-5.064 0-9.194 4.177-9.194 9.334 0 5.156 4.13 9.333 9.194 9.333 4.806 0 8.726-3.78 9.1-8.587.793-.56 2.87-1.61 5.413.047.42 4.783 4.293 8.54 9.1 8.54 5.063 0 9.193-4.177 9.193-9.333 0-5.157-4.13-9.334-9.193-9.334M11.527 41.678c-3.64 0-6.557-2.987-6.557-6.673 0-3.687 2.94-6.674 6.557-6.674 3.64 0 6.556 2.987 6.556 6.674 0 3.686-2.916 6.673-6.556 6.673m23.613 0c-3.64 0-6.557-2.987-6.557-6.673 0-3.687 2.917-6.674 6.557-6.674 3.64 0 6.58 2.987 6.58 6.674 0 3.686-2.963 6.673-6.58 6.673m11.527-21.84H0v3.5h46.667v-3.5M31.57 1.475A2.509 2.509 0 0 0 28.513.12l-5.18 1.727L18.13.121l-.117-.023a2.509 2.509 0 0 0-3.01 1.493l-5.67 14.747h28L31.64 1.591l-.07-.116z"
                id="a"
                fill={(fill && fill[0]) || '#333333'}
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h56v56H0z" />
            <g transform="translate(4.667 4.667)">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <use fill="#000" fillRule="nonzero" xlinkHref="#a" />
                <g mask="url(#b)" fill="#6600EA">
                    <path d="M-4.667 51.333h56v-56h-56z" />
                </g>
            </g>
        </g>
    </svg>
);

export const AccountCheck = ({ fill, sizeY }: IconProps) => (
    <svg
        width="56"
        height="56"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <path
                d="m42.233 19.833 3.267 3.29L30.263 38.5l-8.096-8.167 3.266-3.29 4.83 4.854 11.97-12.064M18.667 0a9.333 9.333 0 1 1 0 18.667 9.333 9.333 0 0 1 0-18.667m0 4.667a4.667 4.667 0 1 0 0 9.333 4.667 4.667 0 0 0 0-9.333m0 16.333c1.586 0 3.5.21 5.623.607l-3.897 3.896-1.726-.07c-6.93 0-14.234 3.407-14.234 4.9V32.9H18.9l4.433 4.433H0v-7C0 24.127 12.437 21 18.667 21z"
                id="a"
                fill="#F75900"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h56v56H0z" />
            <g transform="translate(4.667 9.333)">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <use fill="#000" fillRule="nonzero" xlinkHref="#a" />
                <g mask="url(#b)" fill="#F75900">
                    <path d="M-4.667 46.667h56v-56h-56z" />
                </g>
            </g>
        </g>
    </svg>
);
