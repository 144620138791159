import { DefaultLayout } from '@components/Layouts';
import { Highlight, Paragraph } from '@components/_universal';
import FormWrapper from '@domains/ReportDomain/Components/FormWrapper/FormWrapper';
import { DevTool } from '@hookform/devtools';
import { IReportStepProps } from '../ReportStepOne/ReportStepOne.d';
import StepTitle from '@domains/ReportDomain/Components/StepTitle/StepTitle';
import useReportStepSix, { SHOW_INFO } from './useReportStepSix';
import { Buttons, ShareInfoButton, SvgContainer } from './ReportStepSix.styled';
import { palette } from '@shared/theme/config';
import { AccountCheck, AccountUnknown, Anonymous } from '@shared/svgs/Icons';
import { TextInput } from '@components/_form-elements/Inputs';
import { emailRegExp } from '@utils/regexps';
import { FormattedMessage, useIntl } from 'react-intl';

const ReportStepSix = ({
    activeStep,
    setStep,
    lastStep,
    companyName,
    companyLogo,
}: IReportStepProps) => {
    const { control, register, showInfo, setShowInfo, errors, setValue, values } =
        useReportStepSix();
    const intl = useIntl();
    const anonymousParagraphText = intl.formatMessage({
        id: 'reportForm.stepSix.anonymousParagraph',
    });
    const notAnonymousParagraphText = intl.formatMessage({
        id: 'reportForm.stepSix.notAnonymousParagraph',
    });
    return (
        <DefaultLayout
            alignItemsCenter
            setFullHeight
            companyName={companyName}
            companyLogo={companyLogo}
        >
            {showInfo !== SHOW_INFO.SHOW && (
                <FormWrapper
                    activeStep={activeStep}
                    setStep={setStep}
                    lastStep={lastStep}
                    disableNextBtn={showInfo === SHOW_INFO.NOTHING}
                    nextBtnText={intl.formatMessage({ id: 'reportForm.nextStepButton' })}
                >
                    <StepTitle number={6}>
                        <FormattedMessage id="reportForm.stepSix.title" />
                    </StepTitle>
                    <Buttons>
                        <ShareInfoButton
                            active={values?.anonymous}
                            {...register('anonymous')}
                            onClick={(e) => {
                                e.preventDefault();
                                if (showInfo === SHOW_INFO.NOTHING && !values?.anonymous) {
                                    setValue('anonymous', true);
                                    setShowInfo(SHOW_INFO.HIDE);
                                } else {
                                    setValue('anonymous', false);
                                    setShowInfo(SHOW_INFO.NOTHING);
                                }
                            }}
                        >
                            <FormattedMessage id="reportForm.stepSix.notShareButton" />
                        </ShareInfoButton>

                        <ShareInfoButton
                            onClick={(e) => {
                                e.preventDefault();
                                setValue('anonymous', false);
                                setShowInfo(SHOW_INFO.SHOW);
                            }}
                        >
                            <FormattedMessage id="reportForm.stepSix.shareButton" />
                        </ShareInfoButton>
                    </Buttons>
                    <SvgContainer biggerMargin>
                        {showInfo === SHOW_INFO.NOTHING && !values?.anonymous ? (
                            <AccountUnknown />
                        ) : (
                            <Anonymous fill={[palette.violet]} />
                        )}
                    </SvgContainer>
                    <Paragraph marginTop="20px" fontSize="15px" center>
                        {values?.anonymous ? anonymousParagraphText : notAnonymousParagraphText}
                    </Paragraph>

                    {showInfo === SHOW_INFO.NOTHING && !values?.anonymous && (
                        <Highlight variant="violet">
                            <FormattedMessage id="reportForm.stepSix.highlight" />
                        </Highlight>
                    )}
                </FormWrapper>
            )}

            {showInfo === SHOW_INFO.SHOW && (
                <FormWrapper
                    activeStep={activeStep}
                    setStep={setStep}
                    lastStep={lastStep}
                    nextBtnText={intl.formatMessage({ id: 'reportForm.nextStepButton' })}
                    disableNextBtn={
                        values?.whistleblower?.name === undefined ||
                        values?.whistleblower?.name?.length < 2 ||
                        values?.whistleblower?.name === '' ||
                        values?.whistleblower?.surname?.length < 2 ||
                        values?.whistleblower?.surname === '' ||
                        values?.whistleblower?.surname === undefined
                            ? true
                            : Boolean(errors?.whistleblower)
                    }
                >
                    <SvgContainer>
                        <AccountCheck />
                    </SvgContainer>
                    <Paragraph marginTop="20px" marginBottom="20px" fontSize="15px" center>
                        <FormattedMessage id="reportForm.stepSix.sharingDataParagraph" />
                    </Paragraph>
                    <TextInput
                        required
                        error={Boolean(errors?.whistleblower)}
                        helperText={
                            errors?.whistleblower?.name
                                ? errors.whistleblower?.name.message
                                : undefined
                        }
                        label={intl.formatMessage({ id: 'reportForm.stepSix.nameLabel' })}
                        {...register('whistleblower.name', {
                            required: intl.formatMessage({
                                id: 'reportForm.stepSix.validation.emptyName',
                            }),
                            minLength: {
                                value: 3,
                                message: intl.formatMessage(
                                    {
                                        id: 'reportForm.stepSix.validation.nameMinLength',
                                    },
                                    { chars: 3 },
                                ),
                            },
                        })}
                    />
                    <TextInput
                        required
                        error={Boolean(errors?.whistleblower?.surname)}
                        helperText={
                            errors?.whistleblower?.surname
                                ? errors?.whistleblower?.surname.message
                                : undefined
                        }
                        label={intl.formatMessage({ id: 'reportForm.stepSix.surnameLabel' })}
                        {...register('whistleblower.surname', {
                            required: intl.formatMessage({
                                id: 'reportForm.stepSix.validation.emptySurname',
                            }),
                            minLength: {
                                value: 3,
                                message: intl.formatMessage(
                                    {
                                        id: 'reportForm.stepSix.validation.surnameMinLength',
                                    },
                                    { chars: 3 },
                                ),
                            },
                        })}
                    />
                    <TextInput
                        error={Boolean(errors?.whistleblower?.email)}
                        helperText={
                            errors?.whistleblower?.email
                                ? errors?.whistleblower?.email?.message
                                : undefined
                        }
                        label={intl.formatMessage({ id: 'reportForm.stepSix.emailInputLabel' })}
                        type="email"
                        {...register('whistleblower.email', {
                            pattern: {
                                value: emailRegExp,
                                message: intl.formatMessage({
                                    id: 'reportForm.stepSix.validation.wrongEmail',
                                }),
                            },
                        })}
                    />
                    <TextInput
                        error={Boolean(errors?.whistleblower?.phone)}
                        helperText={
                            errors?.whistleblower?.phone
                                ? errors?.whistleblower?.phone?.message
                                : undefined
                        }
                        label={intl.formatMessage({ id: 'reportForm.stepSix.phone' })}
                        type="phone"
                        {...register('whistleblower.phone')}
                    />
                </FormWrapper>
            )}
            <DevTool control={control} placement="top-right" />
        </DefaultLayout>
    );
};

export default ReportStepSix;
