import { Modal } from '@material-ui/core/';
import { Title, Button } from '@components/_universal';
import { IModalProps } from './Modal.d';
import { ModalBody, CloseButton, TextInputStyled, ButtonsWrapper } from './Modal.styled';
import { TITLE_TYPE } from '@components/_universal/Title/Title.d';
import useModal from './useModal';
import Alert from '@material-ui/lab/Alert';
import { useMediaQuery } from '@material-ui/core';
import defaultTheme from '@shared/theme/default.theme';
import CloseIcon from '@components/_universal/CloseIcon/CloseIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalStatus from '../ModalStatus/ModalStatus';

export const BasicModal = ({ openModal, setOpenModal, sendReply }: IModalProps) => {
    const isTablet = useMediaQuery(defaultTheme.media.md, { noSsr: true });
    const { errors, error, handleSubmit, register, sendResponse, status, setStatus, watch } =
        useModal(sendReply);
    const intl = useIntl();
    const modalTitleText = intl.formatMessage({
        id: 'reportDetailsPage.modal.messageSentTitle',
    });
    const modalButtonText = intl.formatMessage({
        id: 'reportDetailsPage.modal.messageSentClose',
    });

    return (
        <div>
            <Modal open={openModal} onClose={() => setOpenModal(!openModal)}>
                <ModalBody>
                    {!status ? (
                        <>
                            <CloseButton onClick={() => setOpenModal(!openModal)}>
                                <CloseIcon />
                            </CloseButton>
                            <Title center type={TITLE_TYPE.H1} id="reportDetailsPage.modal.title" />
                            <TextInputStyled
                                multiline
                                id="responseValue"
                                error={Boolean(errors.responseValue)}
                                helperText={
                                    errors.responseValue ? errors?.responseValue.message : undefined
                                }
                                {...register('responseValue', {
                                    required: intl.formatMessage({
                                        id: 'reportDetailsPage.modal.validation.emptyMessage',
                                    }),
                                    minLength: {
                                        value: 20,
                                        message: intl.formatMessage(
                                            {
                                                id: 'reportDetailsPage.modal.validation.message.minLength',
                                            },
                                            {
                                                chars: 20,
                                            },
                                        ),
                                    },
                                    maxLength: {
                                        value: 2800,
                                        message: intl.formatMessage(
                                            {
                                                id: 'reportDetailsPage.modal.validation.message.maxLength',
                                            },
                                            { chars: 2800 },
                                        ),
                                    },
                                })}
                                rows={10}
                                label={intl.formatMessage({
                                    id: 'reportDetailsPage.modal.messageLabel',
                                })}
                            />
                            <ButtonsWrapper>
                                <Button
                                    size={isTablet ? 'small' : 'full'}
                                    onClick={() => setOpenModal(!openModal)}
                                >
                                    <FormattedMessage id="reportDetailsPage.modal.cancelMessageButton" />
                                </Button>
                                <span></span>
                                <Button
                                    size={isTablet ? 'small' : 'full'}
                                    variant="primary"
                                    onClick={handleSubmit(sendResponse)}
                                    disabled={watch('responseValue')?.length >= 20 ? false : true}
                                >
                                    <FormattedMessage id="reportDetailsPage.modal.sendMessageButton" />
                                </Button>
                            </ButtonsWrapper>
                            {error.length > 0 ? <Alert severity="error">{error}</Alert> : <></>}
                        </>
                    ) : (
                        <ModalStatus
                            setOpenModal={setOpenModal}
                            openModal={openModal}
                            setStatus={setStatus}
                            titleText={modalTitleText}
                            closeButtonText={modalButtonText}
                        />
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default BasicModal;
