import { IFile, IFileProgress } from '@components/_universal/FilesUploader/FilesUploader.d';
import getUserLanguage from '@utils/getUserLanguage';
import create from 'zustand';
import { Get_Reports_For_Dashboard_Status_Filter } from '../generated/graphql';

interface ICounts {
    newCount: number;
    inProgressCount: number;
    closedCount: number;
}

interface IStore {
    language: string;
    files: IFile[];
    counts: ICounts;
    fileProgresses: IFileProgress[];
    activeStatus: Get_Reports_For_Dashboard_Status_Filter;
    setLanguage: (language: string) => void;
    setFiles: (files: IFile[]) => void;
    setFileProgresses: (progress: IFileProgress[]) => void;
    setActiveStatus: (activeStatus: Get_Reports_For_Dashboard_Status_Filter) => void;
    setCounts: (counts: ICounts) => void;
}

const useStore = create<IStore>((set) => ({
    language: getUserLanguage(),
    files: [],
    fileProgresses: [],
    counts: {
        newCount: 0,
        inProgressCount: 0,
        closedCount: 0,
    },
    activeStatus: Get_Reports_For_Dashboard_Status_Filter.New,
    setLanguage: (language) =>
        set((state) => ({
            ...state,
            language,
        })),
    setFiles: (files) =>
        set((state) => ({
            ...state,
            files,
        })),
    setFileProgresses: (progresses) =>
        set((state) => ({
            ...state,
            progresses,
        })),
    setActiveStatus: (activeStatus) =>
        set((state) => ({
            ...state,
            activeStatus,
        })),
    setCounts: (counts) =>
        set((state) => ({
            ...state,
            counts,
        })),
}));

export default useStore;
