import { BurgerWrapper } from './Burger.styles';

interface IBurgerProps {
    active: boolean;
}

const Burger = ({ active }: IBurgerProps) => {
    return (
        <BurgerWrapper active={active}>
            <span></span>
            <span></span>
            <span></span>
        </BurgerWrapper>
    );
};

export default Burger;
