import { gql, useQuery } from 'urql';
import { Query } from 'src/generated/graphql';

const GET_REPORT_DETAILS_AS_COMPANY_USER = gql`
    query GetReportDetailsAsCompanyUser($input: GetReportDetailsAsCompanyUserInput!) {
        getReportDetailsAsCompanyUser(input: $input) {
            createdAt
            category
            otherCategory
            reportNumber
            status
            createdAt
            whistleblower {
                name
                surname
                phone
                email
            }
            whistleblowerRelation {
                relation
                other
            }
            personsInvolved {
                name
                surname
                relation
            }
            messages {
                sendBy {
                    name
                    surname
                }
                sendAt
                content
                attachments {
                    size
                    ext
                    _id
                }
            }
            attachments {
                size
                ext
                _id
            }
            occurringDetails {
                recurring
                recurringFrequency
                firstTimeOccurredAt
            }
        }
    }
`;

export default () =>
    useQuery<Query['getReportDetailsAsCompanyUser']>({ query: GET_REPORT_DETAILS_AS_COMPANY_USER });
