import { useFieldArray, useFormContext } from 'react-hook-form';
import { IPerson } from './ReportStepThree.d';

export default () => {
    const { control, register, setValue, watch, getValues } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'personsInvolved',
    });

    const checkIsOnePersonAdded = ({ name, relation, surname }: IPerson) => {
        return name?.length > 0 || relation?.length > 0 || surname?.length > 0;
    };

    return {
        control,
        setValue,
        register,
        watch,
        fields,
        append,
        remove,
        shouldShowNextBtn: checkIsOnePersonAdded(getValues().personsInvolved[0]),
    };
};
