import { Title, Button } from '@components/_universal';
import { Status } from './ModalStatus.styled';
import { TITLE_TYPE } from '@components/_universal/Title/Title.d';
import { CloseButton } from '../Modal/Modal.styled';
import CloseIcon from '@components/_universal/CloseIcon/CloseIcon';
import { Dispatch, SetStateAction } from 'react';
import CheckIcon from '@assets/images/check.svg';

interface IStatusElement {
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    openModal: boolean;
    setStatus: Dispatch<SetStateAction<boolean>>;
    titleText: string;
    closeButtonText: string;
}

const StatusElement = ({
    setOpenModal,
    openModal,
    setStatus,
    titleText,
    closeButtonText,
}: IStatusElement) => {
    return (
        <>
            <CloseButton
                onClick={() => {
                    setOpenModal(!openModal);
                    setStatus(false);
                }}
            >
                <CloseIcon />
            </CloseButton>
            <Status>
                <img src={CheckIcon} alt="CheckIcon" />
                <Title center type={TITLE_TYPE.H1} content={titleText} />
                <Button
                    variant="primary"
                    size="full"
                    onClick={() => {
                        setOpenModal(!openModal);
                        setStatus(false);
                    }}
                >
                    {closeButtonText}
                </Button>
            </Status>
        </>
    );
};

export default StatusElement;
