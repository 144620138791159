export const palette = {
    dark: '#000',
    black: '#343434',
    violet: '#7400F3',
    violetHover: '#5f00d8',
    lightergrey: '#f4f4f4',
    lightgrey: '#c2c2c2',
    darkgrey: '#353535',
    green: '#2AABA0',
    disabled: '#e5d9f3',
    red: '#FF2C2C',
    white: '#FFFFFF',
    grey: '#707070',
    orange: '#f75900',
    pink: '#ea0043',
    lightPrimary: '#f3f3ff',
    darkBlue: '#6600ea',
    lightOrange: '#ffeedf',
};

const device = {
    xs: '320px',
    sm: '375px',
    md: '768px',
    lg: '1024px',
    xl: '1440px',
};

export const global = {
    fontSizes: {
        xs: 10,
        s: 12,
        m: 14,
        mm: 15,
        l: 16,
        xl: 18,
        xlxl: 20,
        xxl: 24,
        max: 36,
    },
    fontWeight: {
        light: 300,
        regular: 400,
        semiBold: 500,
        middleBold: 600,
        bold: 700,
        black: 900,
    },
    borderRadius: {
        checkbox: 2,
        default: 6,
        switch: 15,
        button: 50,
    },
    transitions: {
        default: 0.5,
    },
    fontFamily: 'Poppins',
    media: {
        xs: `@media (min-width: ${device.xs})`,
        sm: `@media (min-width: ${device.sm})`,
        md: `@media (min-width: ${device.md})`,
        lg: `@media (min-width: ${device.lg})`,
        xl: `@media (min-width: ${device.xl})`,
    },
};
