import styled from 'styled-components';

export const Status = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 50px 0;

    h1 {
        width: 100%;
        margin: 30px 0 50px;
    }
`;
