import { DefaultLayout } from '@components/Layouts';
import { Img, Card, Title } from '@components/_universal';
import img from '@assets/images/check.svg';
import Highlight from '@components/_universal/Highlight/Highlight';
import ReportNumber from '../Components/ReportNumber/ReportNumber';
import ReportFooter from '../Components/ReportFooter/ReportFooter';
import { useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';

import {
    Report_Category,
    WhistleblowerRelation,
    Whistleblower_Relation,
} from '../../../generated/graphql';
import useGetCompanyInfoFromDomain from '@utils/hooks/useGetCompanyInfoFromDomain';

const ReportSubmitted = () => {
    const {
        reportNumber = '',
        companyName = '',
        companyLogo = '',
        createdAt = new Date(),
        message = '',
        attachments = [],
        whistleblower = undefined,
        whistleblowerRelation = {
            other: '',
            relation: Whistleblower_Relation.Other,
        },
        category = '',
        otherCategory = undefined,
    } = useLocation().state as {
        companyName: string;
        companyLogo: string;
        createdAt: Date;
        reportNumber: string;
        reportUrl: string;
        attachments: string[];
        message: string;
        whistleblower?: {
            name: string;
            surname: string;
            email: string;
            phone: string;
        };
        whistleblowerRelation: WhistleblowerRelation;
        category: Report_Category | string;
        otherCategory?: string;
    };
    return (
        <DefaultLayout
            companyName={companyName}
            companyLogo={
                companyLogo !== '' ? companyLogo : useGetCompanyInfoFromDomain().companyLogo
            }
        >
            <Card wide>
                <Img src={img} noPadding />
                <Title center id="reportSubmittedPage.title" />
                <Highlight marginTop="56px" variant="lightOrange">
                    <FormattedMessage id="reportSubmittedPage.highlightText" />
                </Highlight>
                <ReportNumber reportNumber={reportNumber} />
                <ReportFooter
                    domain={document.location.origin}
                    reportNumber={reportNumber}
                    createdAt={createdAt}
                    reportUrl={`${document.location.origin}/report`}
                    attachments={attachments}
                    message={message}
                    whistleblower={whistleblower}
                    whistleblowerRelation={whistleblowerRelation}
                    category={category}
                    otherCategory={otherCategory}
                />
            </Card>
        </DefaultLayout>
    );
};

export default ReportSubmitted;
