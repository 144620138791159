import { Language_Codes, useResendConfirmAccountEmailMutation } from 'src/generated/graphql';
import { useState } from 'react';
import getUserLanguage from '@utils/getUserLanguage';

export default function useRegistrationActivation() {
    const [emailSent, setEmailSent] = useState(false);
    const [_, resendConfirmAccountEmail] = useResendConfirmAccountEmailMutation();

    const resendEmail = async (email: string) => {
        try {
            await resendConfirmAccountEmail({
                input: {
                    email,
                    language: getUserLanguage() as Language_Codes,
                },
            });
            setEmailSent(true);
        } catch (err) {
            console.log(err);
        }
    };
    return {
        resendEmail,
        emailSent,
    };
}
