import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import { CombinedError, createClient, Operation } from 'urql';
import { makeOperation } from '@urql/core';
import { API_URL } from '@shared/constants';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';
import { AuthConfig, authExchange } from '@urql/exchange-auth';
import { RefreshSessionTokenDocument } from 'src/generated/graphql';
import paths from '@shared/paths';
import { useNavigate } from 'react-router';

interface IAuthState {
    sessionToken: string;
    refreshToken: string;
}

const getAuth: AuthConfig<IAuthState>['getAuth'] = async ({ authState, mutate }) => {
    if (!authState) {
        const sessionToken = LocalStorageManager.getValue('sessionToken');
        const refreshToken = LocalStorageManager.getValue('refreshToken');
        if (sessionToken) {
            return { sessionToken, refreshToken };
        }
        return null;
    }
    const result = await mutate(RefreshSessionTokenDocument, {
        input: { token: authState.refreshToken },
    });
    if (result?.data?.refreshSessionToken) {
        const { sessionToken, refreshToken } = result?.data?.refreshSessionToken;
        LocalStorageManager.setValue('sessionToken', sessionToken);
        LocalStorageManager.setValue('refreshToken', refreshToken);
        return {
            sessionToken,
            refreshToken,
        };
    }

    LocalStorageManager.clear();
    const navigate = useNavigate();
    navigate(paths.dashboard);
    return null;
};

const addAuthToOperation = ({
    authState,
    operation,
}: {
    authState: IAuthState;
    operation: Operation;
}) => {
    if (!authState || !authState.sessionToken) {
        return operation;
    }

    const fetchOptions =
        typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {};

    return makeOperation(operation.kind, operation, {
        ...operation.context,
        fetchOptions: {
            ...fetchOptions,
            headers: {
                ...fetchOptions.headers,
                Authorization: `Bearer ${authState.sessionToken}`,
            },
        },
    });
};

const didAuthError = ({ error }: { error: CombinedError }) => {
    return error.graphQLErrors.some((e) => e.extensions?.code === 'UNAUTHENTICATED');
};

const client = createClient({
    url: API_URL,
    // exchanges: [multipartFetchExchange],
    exchanges: [
        authExchange({
            getAuth,
            addAuthToOperation,
            didAuthError,
        }),
        multipartFetchExchange,
    ],
});

export default client;
