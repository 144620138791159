import { ReportInfoRowWrapper } from './ReportInfoRow.styled';

interface IReportInfoRow {
    className?: string;
}

export const ReportInfoRow: React.FC<IReportInfoRow> = (props) => {
    return (
        <ReportInfoRowWrapper className={props.className}>{props.children}</ReportInfoRowWrapper>
    );
};

export default ReportInfoRow;
