import { CardWrapper } from './Card.styled';

interface ICardProps {
    children?: React.ReactNode;
    wide?: boolean;
    shadow?: boolean;
}

const Card = ({ children, wide, shadow }: ICardProps) => {
    return (
        <CardWrapper wide={wide} shadow={shadow}>
            {children}
        </CardWrapper>
    );
};

export default Card;
