import styled from 'styled-components';

export const Form = styled.div`
    position: relative;
    flex: 0 0 auto;
    width: 100%;
    border-radius: 24px;
    background: #fff;
    height: auto;

    ${(props) => props.theme.media.md} {
        width: 556px;
        max-height: calc(100vh - 164px);
        overflow-y: scroll;
        padding: 20px 0 0;
        box-shadow: 0 0 30px 0 rgba(139, 151, 159, 0.2);
    }
`;

export const SpaceFiller = styled.div`
    width: 100%;
    height: 50px;
    background: none;
`;

export const Wrapper = styled.div`
    min-height: calc(100vh - 264px);
    ${(props) => props.theme.media.md} {
        padding: 0 20px;
        min-height: 338px;
    }
`;
