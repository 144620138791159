import styled from 'styled-components';

export const DetailsTitleWithReportWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 24px;
    margin-bottom: 20px;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    flex-wrap: wrap;
    width: 100%;

    ${(props) => props.theme.media.md} {
        flex-wrap: nowrap;
    }

    > div {
        display: flex;
        align-items: center;

        > span {
            font-size: 24px;
            font-weight: ${(props) => props.theme.fontWeight.semiBold};
            line-height: 1.5;
            color: ${(props) => props.theme.colors.text};
            margin: 0 10px 0 0;
        }
    }
`;

export const Date = styled.div`
    font-size: 18px;
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
    line-height: 1.33;
    letter-spacing: 0.24px;
    text-align: right;
    color: ${(props) => props.theme.colors.text};
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;

    ${(props) => props.theme.media.md} {
        margin-left: 15px;
        margin-top: 0;
        margin-bottom: 0;
        width: auto;
    }
`;
