import styled from 'styled-components';
import defaultTheme from '@shared/theme/default.theme';
import closeIcon from '@assets/images/icon-close.svg';
import closeIconError from '@assets/images/icon-close-error.svg';
import closeIconTick from '@assets/images/icon-tick.svg';
import { PASSWORD_STATUS } from './PasswordError.d';

export const Error = styled.div<{ status: PASSWORD_STATUS | (() => PASSWORD_STATUS) }>`
    color: ${defaultTheme.colors.text};
    background: url('${closeIcon}') 0 50% no-repeat;
    padding: 0 10px 0 24px;
    background-size: 20px;
    margin-top: 3px;
    font-size: 12px;
    font-weight: ${defaultTheme.fontWeight.semiBold};
    ${(props) =>
        props.status === PASSWORD_STATUS.ERROR &&
        `
        color: ${defaultTheme.colors.error};
        background: url('${closeIconError}') 0 50% no-repeat;
        background-size: 20px;
      `}
    ${(props) =>
        props.status === PASSWORD_STATUS.OK &&
        `
          color: ${defaultTheme.colors.green};
          background: url('${closeIconTick}') 0 50% no-repeat;
          background-size: 20px;
        `}
`;
