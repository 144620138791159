import { DefaultLayout } from '@components/Layouts';
import { Highlight, Paragraph } from '@components/_universal';

import FormWrapper from '@domains/ReportDomain/Components/FormWrapper/FormWrapper';
import { DevTool } from '@hookform/devtools';
import { IReportStepProps } from '../ReportStepOne/ReportStepOne.d';
import StepTitle from '@domains/ReportDomain/Components/StepTitle/StepTitle';
import useReportStepSeven from './useReportStepSeven';
import { PasswordInput } from '@components/_form-elements/Inputs';
import { passwordRegExp } from '@utils/regexps';
import { PasswordContainer } from './ReportStepSeven.styled';
import { FormattedMessage, useIntl } from 'react-intl';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { resolveEnv } from '../../../../utils/helpers';
import getUserLanguage from '../../../../utils/getUserLanguage';

const ReportStepSeven = ({
    activeStep,
    setStep,
    lastStep,
    onNextClick,
    companyName,
    companyLogo,
}: IReportStepProps) => {
    const { control, register, errors, watch, setValue, getValues } = useReportStepSeven();
    const intl = useIntl();
    return (
        <DefaultLayout
            alignItemsCenter
            setFullHeight
            companyName={companyName}
            companyLogo={companyLogo}
        >
            <FormWrapper
                activeStep={activeStep}
                setStep={setStep}
                lastStep={lastStep}
                nextBtnText={intl.formatMessage({ id: 'reportForm.stepSeven.sendReportButton' })}
                disableNextBtn={
                    !!(
                        errors.password ||
                        errors.confirmPassword ||
                        !getValues('hcaptchaToken') ||
                        getValues('hcaptchaToken').length === 0
                    )
                }
                onNextClick={onNextClick}
            >
                <StepTitle number={7}>
                    <FormattedMessage id="reportForm.stepSeven.title" />
                </StepTitle>
                <Highlight variant="lightOrange">
                    <FormattedMessage id="reportForm.stepSeven.highlight" />
                </Highlight>
                <Paragraph marginTop="20px" fontSize="15px">
                    <FormattedMessage id="reportForm.stepSeven.passwordParagraph" />
                </Paragraph>
                <PasswordContainer>
                    <PasswordInput
                        label={intl.formatMessage({ id: 'reportForm.stepSeven.passwordLabel' })}
                        error={errors.password ? true : false}
                        helperText={errors.password ? errors.password.message : undefined}
                        {...register('password', {
                            required: intl.formatMessage({
                                id: 'reportForm.stepSeven.validation.emptyPassword',
                            }),
                            pattern: {
                                value: passwordRegExp,
                                message: intl.formatMessage({
                                    id: 'reportForm.stepSeven.validation.wrongPasswordFormat',
                                }),
                            },
                        })}
                    />
                    <PasswordInput
                        label={intl.formatMessage({
                            id: 'reportForm.stepSeven.passwordConfirmationLabel',
                        })}
                        error={errors.confirmPassword ? true : false}
                        helperText={
                            errors.confirmPassword ? errors.confirmPassword.message : undefined
                        }
                        {...register('confirmPassword', {
                            required: intl.formatMessage({
                                id: 'reportForm.stepSeven.validation.emptyPassword',
                            }),
                            pattern: {
                                value: passwordRegExp,
                                message: intl.formatMessage({
                                    id: 'reportForm.stepSeven.validation.wrongPasswordFormat',
                                }),
                            },
                            validate: (value) =>
                                value === watch('password') ||
                                intl.formatMessage({
                                    id: 'reportForm.stepSeven.validation.differentPasswords',
                                }),
                        })}
                    />
                </PasswordContainer>
                <HCaptcha
                    sitekey={resolveEnv('REACT_APP_HCAPTCHA_KEY')}
                    onVerify={(hcaptchaToken: string) => {
                        setValue('hcaptchaToken', hcaptchaToken);
                    }}
                    languageOverride={getUserLanguage() === 'en' ? 'en' : 'pl'}
                />
            </FormWrapper>
            <DevTool control={control} placement="top-right" />
        </DefaultLayout>
    );
};

export default ReportStepSeven;
