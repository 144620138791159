import { gql, useMutation } from 'urql';
import { MutationResetPasswordArgs, ResetPasswordMutation } from 'src/generated/graphql';

const RESET_PASSWORD_MUTATION = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input)
    }
`;

export default () =>
    useMutation<ResetPasswordMutation, MutationResetPasswordArgs>(RESET_PASSWORD_MUTATION);
