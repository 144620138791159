import { DefaultLayout } from '@components/Layouts';
import { Title, Img, Card, Paragraph } from '@components/_universal';
import Divider from '../../../components/_universal/Divider/Divider';
import Highlight from '../../../components/_universal/Highlight/Highlight';
import img from '@assets/images/new-message-cuate.svg';
import useRegistrationActivation from './useRegistrationActivation';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';

type LocationState = {
    email?: string;
};

const RegistrationActivationPage = () => {
    const { email = '' } = useLocation().state as LocationState;
    const { resendEmail, emailSent } = useRegistrationActivation();
    return (
        <DefaultLayout enablePaddingBottomOnMobile>
            <Card>
                <Img src={img} alt="Aktywuj swoje konto" />
                <Title marginTop="0" center={true} id="registrationActivationPage.title" />
                <Highlight>
                    <FormattedMessage id="registrationActivationPage.emailSent" />{' '}
                    <strong>{email}</strong>
                </Highlight>
                <Paragraph center marginTop="20px" fontSize="15px" marginBottom="40px">
                    <FormattedMessage id="registrationActivationPage.openYourEmailBox" />
                </Paragraph>
                <Divider />
                <Paragraph center marginTop="40px" fontSize="15px" marginBottom="20px">
                    <FormattedMessage id="registrationActivationPage.spam" />
                    <div>
                        <br />
                        {!emailSent ? (
                            <a onClick={() => resendEmail(email)} style={{ cursor: 'pointer' }}>
                                <FormattedMessage id="registrationActivationPage.sendNewEmail" />
                            </a>
                        ) : (
                            <p>
                                <FormattedMessage id="registrationActivationPage.emailSentAgain" />
                            </p>
                        )}
                    </div>
                </Paragraph>
            </Card>
        </DefaultLayout>
    );
};

export default RegistrationActivationPage;
