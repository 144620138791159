import { Grid } from '@material-ui/core';
import { IDashboardHeaderProps } from './DashboardHeader.d';
import { Logo, LogoText, LanguageSwitcher } from '@components/_universal';
import PageTitle from './PageTitle/PageTitle';
import UserMenu from './UserMenu/UserMenu';
import { Link, HeaderWrapper, HeaderContainer } from './DashboardHeader.styles';
import paths from '@shared/paths';
import { FormattedMessage } from 'react-intl';
import { DisplayFlex } from '../Header/Header.styled';

const DashboardHeader = ({ companyName, nameAndSurname }: IDashboardHeaderProps) => {
    const calculateFontForCompanyName = () => {
        if (companyName) {
            if (companyName.length < 11) return 28;
            else {
                return Math.floor(
                    (28 * (100 - ((companyName.length * 117) / 1000) * companyName.length)) / 100,
                );
            }
        } else return 28;
    };

    return (
        <HeaderContainer>
            <HeaderWrapper>
                <Grid container justifyContent="space-between">
                    <DisplayFlex>
                        <Link href={paths.dashboard}>
                            {companyName ? (
                                <LogoText fontSize={calculateFontForCompanyName()} cutLength>
                                    {companyName}
                                </LogoText>
                            ) : (
                                <a href="/">
                                    <Logo />
                                </a>
                            )}
                        </Link>
                        <PageTitle>
                            <FormattedMessage id="dashboardPage.header.title" />
                        </PageTitle>
                    </DisplayFlex>
                    <DisplayFlex>
                        <LanguageSwitcher />
                        <UserMenu nameAndSurname={nameAndSurname} />
                    </DisplayFlex>
                </Grid>
            </HeaderWrapper>
        </HeaderContainer>
    );
};

export default DashboardHeader;
