import { gql, useMutation } from 'urql';
import {
    AddAttachmentsToExistingReportMutation,
    MutationAddAttachmentsToExistingReportArgs,
} from 'src/generated/graphql';

const ADD_ATTACHMENTS_TO_EXISTING_REPORT = gql`
    mutation AddAttachmentsToExistingReport($input: AddAttachmentsToExistingReportInput!) {
        addAttachmentsToExistingReport(input: $input) {
            _id
        }
    }
`;

export default () =>
    useMutation<AddAttachmentsToExistingReportMutation, MutationAddAttachmentsToExistingReportArgs>(
        ADD_ATTACHMENTS_TO_EXISTING_REPORT,
    );
