import { DefaultLayout } from '@components/Layouts';
import { Title, Img, Card, Button, Toast, Paragraph } from '@components/_universal';
import img from '@assets/images/invoice-cuate.svg';
import { TextInput } from '@components/_form-elements/Inputs';
import useRegistrationComplete from './useRegistrationComplete';
import { Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TITLE_TYPE } from '@components/_universal/Title/Title.d';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Domain from '@domains/RegistrationDomain/Components/Domain/Domain';
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { resolveEnv } from '@utils/helpers';
import getUserLanguage from '@utils/getUserLanguage';

const RegistrationCompletePage = () => {
    const {
        createCompany,
        error,
        errors,
        handleSubmit,
        register,
        validateVatEU,
        countries,
        control,
    } = useRegistrationComplete();
    const theme = useTheme();
    const intl = useIntl();
    return (
        <DefaultLayout enablePaddingBottomOnMobile>
            <Card>
                <Img src={img} />
                <Title id="registrationCompletePage.title" center={true} />
                <form>
                    <TextInput
                        required
                        error={Boolean(errors.companyName)}
                        helperText={errors.companyName ? errors.companyName.message : undefined}
                        label={intl.formatMessage({
                            id: 'registrationCompletePage.companyNameInputLabel',
                        })}
                        {...register('companyName', {
                            required: intl.formatMessage({
                                id: 'registrationCompletePage.validation.emptyCompanyName',
                            }),
                            minLength: {
                                value: 3,
                                message: intl.formatMessage(
                                    {
                                        id: 'registrationCompletePage.validation.companyNameLength',
                                    },
                                    { chars: 3 },
                                ),
                            },
                        })}
                    />
                    <TextInput
                        required
                        error={Boolean(errors.vatEU)}
                        helperText={errors.vatEU ? errors.vatEU.message : undefined}
                        label="NIP"
                        type="number"
                        {...register('vatEU', {
                            required: intl.formatMessage({
                                id: 'registrationCompletePage.validation.emptyVatEu',
                            }),
                            validate: {
                                validateVat: (value) =>
                                    validateVatEU(value) ||
                                    intl.formatMessage({
                                        id: 'registrationCompletePage.validation.wrongVatEu',
                                    }),
                            },
                        })}
                    />
                    <TextInput
                        required
                        error={Boolean(errors.shortName)}
                        helperText={errors.shortName ? errors.shortName.message : undefined}
                        label={intl.formatMessage({
                            id: 'registrationCompletePage.companyShortNameInputLabel',
                        })}
                        {...register('shortName', {
                            required: intl.formatMessage({
                                id: 'registrationCompletePage.validation.emptyCompanyShortName',
                            }),
                            minLength: {
                                value: 2,
                                message: intl.formatMessage(
                                    {
                                        id: 'registrationCompletePage.validation.companyShortNameMinLength',
                                    },
                                    { chars: 2 },
                                ),
                            },
                            maxLength: {
                                value: 20,
                                message: intl.formatMessage(
                                    {
                                        id: 'registrationCompletePage.validation.companyShortNameMaxLength',
                                    },
                                    { chars: 20 },
                                ),
                            },
                        })}
                    />
                    <Paragraph
                        color="input_text_color"
                        fontSize="12px"
                        marginBottom="30px"
                        marginTop="-20px"
                    >
                        <FormattedMessage id="registrationCompletePage.companyShortNameDescription" />
                    </Paragraph>
                    <Title
                        id={'registrationCompletePage.addressTitle'}
                        type={TITLE_TYPE.H4}
                        color="input_text_color"
                    />
                    <TextInput
                        required
                        error={Boolean(errors.street)}
                        helperText={errors.street ? errors.street.message : undefined}
                        label={intl.formatMessage({
                            id: 'registrationCompletePage.streetInputLabel',
                        })}
                        {...register('street', {
                            required: intl.formatMessage({
                                id: 'registrationCompletePage.validation.emptyStreet',
                            }),
                            minLength: {
                                value: 2,
                                message: intl.formatMessage(
                                    {
                                        id: 'registrationCompletePage.validation.streetLength',
                                    },
                                    { chars: 2 },
                                ),
                            },
                        })}
                    />
                    <Grid container spacing={useMediaQuery(theme.breakpoints.down('sm')) ? 0 : 1}>
                        <Grid item xs={12} md={6}>
                            <TextInput
                                required
                                error={Boolean(errors.buildingNumber)}
                                helperText={
                                    errors.buildingNumber
                                        ? errors.buildingNumber.message
                                        : undefined
                                }
                                label={intl.formatMessage({
                                    id: 'registrationCompletePage.buildingNumberLabel',
                                })}
                                {...register('buildingNumber', {
                                    required: intl.formatMessage({
                                        id: 'registrationCompletePage.validation.emptyBuldingNumber',
                                    }),
                                    minLength: {
                                        value: 1,
                                        message: intl.formatMessage(
                                            {
                                                id: 'registrationCompletePage.validation.buldingNumberLength',
                                            },
                                            { chars: 1 },
                                        ),
                                    },
                                })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput
                                error={Boolean(errors.apartmentNumber)}
                                helperText={
                                    errors.apartmentNumber
                                        ? errors.apartmentNumber.message
                                        : undefined
                                }
                                label={intl.formatMessage({
                                    id: 'registrationCompletePage.apartmentNumberLabel',
                                })}
                                {...register('apartmentNumber')}
                            />
                        </Grid>
                    </Grid>
                    <TextInput
                        required
                        error={Boolean(errors.city)}
                        helperText={errors.city ? errors.city.message : undefined}
                        label={intl.formatMessage({ id: 'registrationCompletePage.cityLabel' })}
                        {...register('city', {
                            required: intl.formatMessage({
                                id: 'registrationCompletePage.validation.emptyCity',
                            }),
                            minLength: {
                                value: 2,
                                message: intl.formatMessage(
                                    {
                                        id: 'registrationCompletePage.validation.cityLength',
                                    },
                                    { chars: 2 },
                                ),
                            },
                        })}
                    />
                    <Controller
                        name="postCode"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                            <InputMask mask="99-999" value={value} onChange={onChange}>
                                {(inputProps: any) => (
                                    <TextInput
                                        required
                                        error={Boolean(errors.postCode)}
                                        helperText={
                                            errors.postCode ? errors.postCode.message : undefined
                                        }
                                        label={intl.formatMessage({
                                            id: 'registrationCompletePage.postCodeLabel',
                                        })}
                                        {...inputProps}
                                    />
                                )}
                            </InputMask>
                        )}
                    />
                    <Autocomplete
                        id="combo-box-demo"
                        options={countries[getUserLanguage()]}
                        getOptionLabel={(option) => option.country}
                        renderInput={(params) => (
                            <TextInput
                                required
                                error={Boolean(errors.country)}
                                helperText={errors.country ? errors.country.message : undefined}
                                {...params}
                                label={intl.formatMessage({
                                    id: 'registrationCompletePage.countryLabel',
                                })}
                                variant="outlined"
                                {...register('country', {
                                    required: intl.formatMessage({
                                        id: 'registrationCompletePage.validation.emptyCountry',
                                    }),
                                })}
                            />
                        )}
                        getOptionSelected={(option, value) => option.value === value.value}
                    />
                    <Title
                        id="registrationCompletePage.subdomainTitle"
                        type={TITLE_TYPE.H4}
                        color="input_text_color"
                    />
                    <Grid container>
                        <Grid item xs={8} md={6}>
                            <TextInput
                                required
                                error={Boolean(errors.domain)}
                                helperText={errors.domain ? errors.domain.message : undefined}
                                label={intl.formatMessage({
                                    id: 'registrationCompletePage.subdomainLabel',
                                })}
                                {...register('domain', {
                                    required: intl.formatMessage({
                                        id: 'registrationCompletePage.validation.emptySubdomain',
                                    }),
                                    minLength: {
                                        value: 3,
                                        message: intl.formatMessage(
                                            {
                                                id: 'registrationCompletePage.validation.subdomainLength',
                                            },
                                            { chars: 3 },
                                        ),
                                    },
                                })}
                            />
                        </Grid>
                        <Grid item xs={4} md={6}>
                            <Domain>.{resolveEnv('REACT_APP_BASE_REPORT_DOMAIN')}</Domain>
                        </Grid>
                        <Paragraph
                            color="input_text_color"
                            fontSize="12px"
                            marginBottom="30px"
                            marginTop="-20px"
                        >
                            <FormattedMessage id="registrationCompletePage.whistleblowerChannel" />
                        </Paragraph>
                    </Grid>
                    <Button
                        type="submit"
                        size="full"
                        variant="primary"
                        onSubmit={handleSubmit(createCompany)}
                        onClick={handleSubmit(createCompany)}
                    >
                        <FormattedMessage id="registrationCompletePage.continueButton" />
                    </Button>
                    {error.length > 0 && (
                        <>
                            <br />
                            <br />
                            <Alert severity="error">{error}</Alert>
                        </>
                    )}
                </form>
            </Card>
            <Toast
                position={{ vertical: 'top', horizontal: 'center' }}
                text={
                    error.length === 0
                        ? intl.formatMessage({ id: 'registrationCompletePage.toastText' })
                        : error
                }
                autoHide={6000}
                isSuccess={error.length === 0}
            />
        </DefaultLayout>
    );
};

export default RegistrationCompletePage;
