import { DefaultLayout } from '@components/Layouts';
import { Img, Card, Button, Title, Paragraph, Footer, Highlight } from '@components/_universal';
import { TITLE_TYPE } from '@components/_universal/Title/Title.d';
import img from '@assets/images/icon-account-security.svg';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { Grid, CircularProgress } from '@material-ui/core';
import paths from '@shared/paths';
import useInitiateReport from '@state/reports/mutations/useInitiateReport';
import useReportPage from './useReportPage';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

const ReportLandingPage = () => {
    const navigate = useNavigate();
    const [_, initiateReport] = useInitiateReport();
    const {
        companyName,
        companyLogo,
        validCompanyName,
        loader,
        reportPageTitleValid,
        reportPageTitleInvalid,
    } = useReportPage();
    return (
        <DefaultLayout companyName={companyName} companyLogo={companyLogo}>
            <Card wide>
                <Img src={img} noPadding />
                <Title center id="reportPage.title" />
                {loader ? (
                    <Paragraph center marginTop="10%" marginBottom="10%">
                        <CircularProgress />
                    </Paragraph>
                ) : (
                    <>
                        <Title
                            marginTop="54px"
                            type={TITLE_TYPE.H3}
                            id={validCompanyName ? reportPageTitleValid : reportPageTitleInvalid}
                        />
                        {validCompanyName && (
                            <>
                                <Highlight variant="violet">
                                    <FormattedMessage id="reportPage.highlightText" />
                                </Highlight>
                                <Paragraph fontSize="14px" marginBottom="55px">
                                    <FormattedMessage id="reportPage.paragraph" />
                                </Paragraph>
                                <Grid container justifyContent="center">
                                    <Grid item md={6} xs={12}>
                                        <Button
                                            onClick={async () => {
                                                const result = await initiateReport();
                                                navigate(paths.reportForm, {
                                                    state: {
                                                        companyName,
                                                        reportNumber:
                                                            result.data?.initiateReport
                                                                .reportNumber,
                                                    },
                                                });
                                            }}
                                            size="full"
                                            variant="primary"
                                            icon={<NoteAddIcon />}
                                        >
                                            <FormattedMessage id="reportPage.reportButton" />
                                        </Button>
                                        <br />
                                        <br />
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="center">
                                    <Grid item md={6} xs={12}>
                                        <Button
                                            size="full"
                                            variant="outlined"
                                            onClick={() => {
                                                navigate(paths.loginToReport, {
                                                    state: {
                                                        companyName,
                                                    },
                                                });
                                            }}
                                        >
                                            <FormattedMessage id="reportPage.checkReportStatusButton" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </>
                )}
                <Footer />
            </Card>
        </DefaultLayout>
    );
};

export default ReportLandingPage;
