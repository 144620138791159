import { Grid, Container } from '@material-ui/core';
import { Wrapper, FullHeightGrid } from './ContentWrapper.styled';
import { IContentWrapperProps } from './ContentWrapper.d';

const ContentWrapper = ({
    children,
    alignItemsCenter,
    setFullHeight,
    enablePaddingBottomOnMobile,
}: IContentWrapperProps) => {
    return (
        <Wrapper $enablePaddingBottomOnMobile={enablePaddingBottomOnMobile}>
            <Container>
                <FullHeightGrid
                    container
                    justifyContent="center"
                    $alignItemsCenter={alignItemsCenter}
                    $setFullHeight={setFullHeight}
                >
                    {children}
                </FullHeightGrid>
            </Container>
        </Wrapper>
    );
};

export default ContentWrapper;
