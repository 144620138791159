import { TextInput } from '@components/_form-elements/Inputs';
import { DefaultLayout } from '@components/Layouts';
import { Card, Button, Title, Paragraph } from '@components/_universal';
import Alert from '@material-ui/lab/Alert';
import { emailRegExp } from '@utils/regexps';
import useResetPassword from './useResetPassword';
import { FormattedMessage } from 'react-intl';

const ResetPasswordPage = () => {
    const { errors, error, register, handleSubmit, onResetPassword, onCancel, intl } =
        useResetPassword();
    return (
        <DefaultLayout>
            <Card>
                <Title center id="resetPasswordPage.title" />
                <Paragraph fontSize="15px" marginBottom="25px">
                    <FormattedMessage id="resetPasswordPage.emailParagraph" />
                </Paragraph>
                <form>
                    <TextInput
                        required
                        error={Boolean(errors.email)}
                        helperText={errors.email ? errors.email.message : undefined}
                        label={intl.formatMessage({ id: 'resetPasswordPage.emailInputLabel' })}
                        type="email"
                        {...register('email', {
                            required: intl.formatMessage({
                                id: 'resetPasswordPage.validation.emptyEmail',
                            }),
                            pattern: {
                                value: emailRegExp,
                                message: intl.formatMessage({
                                    id: 'resetPasswordPage.validation.wrongEmail',
                                }),
                            },
                        })}
                    />
                    <Button
                        type="submit"
                        onSubmit={handleSubmit(onResetPassword)}
                        onClick={handleSubmit(onResetPassword)}
                        size="full"
                        variant="primary"
                    >
                        <FormattedMessage id="resetPasswordPage.submitButton" />
                    </Button>
                </form>
                <br />
                <br />
                <Button onClick={() => onCancel()} size="full" variant="outlined">
                    <FormattedMessage id="resetPasswordPage.cancelButton" />
                </Button>
                {error.length > 0 && <Alert severity="error">{error}</Alert>}
            </Card>
        </DefaultLayout>
    );
};

export default ResetPasswordPage;
