import RootRoutes from 'src/routes/RootRoutes';
import themeVariant from '@utils/themeVariant';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyled } from '@components/_global/Global.styled';
import { Theme } from '@@types/CommonTypes';
import { ThemeProvider } from 'styled-components';
import { Wrapper } from './App.styled';
import { resolveEnv } from '@utils/helpers';
import { Provider } from 'urql';
import client from '../boot/urqlClient';
import { en, pl } from '../translations/';
import { IntlProvider } from 'react-intl';
import useStore from '../store/useStore';

const App = () => {
    const { language } = useStore();
    const SUPPORTED_TRANSLATIONS: { [key: string]: {} } = {
        en,
        pl,
    };

    return (
        <IntlProvider
            defaultLocale="pl"
            locale={language}
            messages={SUPPORTED_TRANSLATIONS[language]}
        >
            <BrowserRouter basename={resolveEnv('PUBLIC_URL')}>
                <Provider value={client}>
                    <ThemeProvider theme={themeVariant[Theme.DEFAULT]}>
                        <GlobalStyled />
                        <Wrapper>
                            <RootRoutes />
                        </Wrapper>
                    </ThemeProvider>
                </Provider>
            </BrowserRouter>
        </IntlProvider>
    );
};

export default App;
