import {
    useDeleteBatchOfReportAttachments,
    useGenerateReportAttachmentsUploadLinksMutation,
} from '@state/reports';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { useIntl } from 'react-intl';
import useStore from '@store/useStore';
import { ACCEPTED_MIME_TYPE, IFile } from '@components/_universal/FilesUploader/FilesUploader.d';

const THREE_HUNDRED_MEGA_BYTES = 314572800;

export default (reportNumber: string, setOpenUploadModal: (status: boolean) => void) => {
    const [_, generateReportAttachmentsUploadLinks] =
        useGenerateReportAttachmentsUploadLinksMutation();
    const [__, deleteBatchOfReportAttachments] = useDeleteBatchOfReportAttachments();
    const { files, setFiles, fileProgresses, setFileProgresses } = useStore.getState();
    const [error, setError] = useState('');
    const [isSent, setIsSent] = useState(false);
    const intl = useIntl();

    const handleProgress = (progress: number, frontId: string) => {
        const foundIndex = fileProgresses.findIndex((el) => el.frontId === frontId);
        let arr = fileProgresses;
        if (foundIndex !== -1) {
            arr[foundIndex].progress = progress;
        } else {
            arr.push({ frontId, progress });
        }
        setFileProgresses([...arr]);
    };

    const onDelete = async (frontId: string) => {
        const fileToDelete = files.find((el) => el.frontId === frontId);
        if (fileToDelete) {
            await deleteBatchOfReportAttachments({
                input: {
                    reportNumber: '',
                    attachments: [fileToDelete.fileId],
                },
            });
            setFiles(files.filter((file) => file.frontId !== frontId));
            setFileProgresses(fileProgresses.filter((progress) => progress.frontId !== frontId));
        }
    };

    const uploadFile = async (file: IFile) => {
        try {
            const options = {
                onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
                    const { loaded, total } = progressEvent;
                    const progress = Math.floor((loaded * 100) / total);
                    handleProgress(progress, file.frontId);
                },
                headers: {
                    'Content-Type': file.file.type,
                },
            };
            await axios.put(file.url, file.file, options);
        } catch (error) {
            console.log(error);
        }
    };

    const handleFilesChange = async (e: any) => {
        const targetFiles = e.target.files;
        const type = targetFiles[0].type;

        if (targetFiles[0].size > THREE_HUNDRED_MEGA_BYTES) {
            setError(intl.formatMessage({ id: 'reportForm.stepFive.validation.tooBigFile' }));
            return false;
        }
        if (
            !Object.values(ACCEPTED_MIME_TYPE).includes(type as ACCEPTED_MIME_TYPE) &&
            !Object.values(ACCEPTED_MIME_TYPE).includes(type.split('/')[0] as ACCEPTED_MIME_TYPE)
        ) {
            setError(intl.formatMessage({ id: 'reportForm.stepFive.validation.wrongFileType' }));
            return false;
        }
        setError('');
        if (targetFiles) {
            const mappedFiles = Object.entries(targetFiles).map((el) => el[1]);
            const filesToUpload = mappedFiles.map((file: any) => ({
                frontId: uuidv4(),
                size: file.size,
                mimeType: file.type,
                name: file.name,
                file,
            }));
            const generateLinksResult = await generateReportAttachmentsUploadLinks({
                input: {
                    reportNumber,
                    asReplyToReport: true,
                    attachmentsData: filesToUpload.map((file) => ({
                        ...file,
                        name: undefined,
                        url: undefined,
                        file: undefined,
                    })),
                },
            });
            const filesMappedToState = filesToUpload.map((file) => {
                const foundFile =
                    generateLinksResult.data?.generateReportAttachmentsUploadLinks.find(
                        (el) => el.frontId === file.frontId,
                    );
                return {
                    ...file,
                    url: foundFile?.url as string,
                    fileId: foundFile?.attachmentId as string,
                };
            });
            setFiles([...files, ...filesMappedToState]);
            const promises = filesMappedToState.map((el: IFile) => uploadFile(el));
            await Promise.all(promises);
        }
    };
    const closeModal = () => {
        setFiles([]);
        setFileProgresses([]);
        setOpenUploadModal(false);
    };
    return {
        files,
        onDelete,
        handleFilesChange,
        fileProgresses,
        error,
        isSent,
        setIsSent,
        closeModal,
    };
};
