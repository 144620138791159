import { ShowDetailsButton } from './ShowDetails.styled';
import ChevronDown from '@assets/images/icon-icon-chevron-down.svg';
import { useIntl } from 'react-intl';

interface IShowDetails {
    visible: boolean;
    onClick: () => void;
}

export const ShowDetails: React.FC<IShowDetails> = (props) => {
    const intl = useIntl();
    const hideText = intl.formatMessage({ id: 'showDetails.hide' });
    const showText = intl.formatMessage({ id: 'showDetails.show' });
    return (
        <ShowDetailsButton visible={props.visible} onClick={() => props.onClick()}>
            <img src={ChevronDown} />
            {props.visible ? hideText : showText}
        </ShowDetailsButton>
    );
};

export default ShowDetails;
