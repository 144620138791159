import { IHighlightProps } from './Highlight.d';
import { Div } from './Highlight.styles';

const Highlight = ({ children, variant, marginTop }: IHighlightProps) => {
    return (
        <Div variant={variant} marginTop={marginTop}>
            {children}
        </Div>
    );
};

export default Highlight;
