export interface IPasswordErrorProps {
    status: PASSWORD_STATUS | (() => PASSWORD_STATUS);
    errorCopy: string;
}

export enum PASSWORD_STATUS {
    ERROR = 'ERROR',
    OK = 'OK',
    NORMAL = 'NORMAL',
}
