import { Grid } from '@material-ui/core';
import { FooterWrapper, Link, Icon, IconText, IconLink } from './Footer.styled';
import { Paragraph } from '@components/_universal';
import phoneIcon from '@assets/images/icon-phone.svg';
import mailIcon from '@assets/images/icon-mail.svg';
import questionIcon from '@assets/images/icon-question.svg';
import { FormattedMessage } from 'react-intl';

const Footer = () => {
    return (
        <FooterWrapper>
            <Grid item xs={12}>
                <Paragraph fontSize="14px">
                    <FormattedMessage id="reportPage.footer.needHelp" />
                </Paragraph>
            </Grid>
            <Grid container justifyContent="center">
                <Grid container>
                    <Grid item xs={4}>
                        <IconLink href="tel:+4850123123">
                            <Icon>
                                <img src={phoneIcon} />
                            </Icon>
                            <IconText>
                                <FormattedMessage id="reportPage.footer.phoneIconText" />
                            </IconText>
                        </IconLink>
                    </Grid>
                    <Grid item xs={4}>
                        <IconLink href="mailto:help@notibox.pl">
                            <Icon>
                                <img src={mailIcon} />
                            </Icon>
                            <IconText>
                                <FormattedMessage id="reportPage.footer.emailIconText" />
                            </IconText>
                        </IconLink>
                    </Grid>
                    <Grid item xs={4}>
                        <IconLink href="/help">
                            <Icon>
                                <img src={questionIcon} />
                            </Icon>
                            <IconText>FAQ</IconText>
                        </IconLink>
                    </Grid>
                </Grid>
            </Grid>
            <Link href="https://www.notibox.pl/privacypolicy">
                <FormattedMessage id="reportPage.footer.privacyPolicy" />
            </Link>
        </FooterWrapper>
    );
};

export default Footer;
