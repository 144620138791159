import {
    MenuTitle,
    MenuItemsWrapper,
    BorderRight,
    SpecialLink,
    BottomMenu,
    ChannelLink,
    ChannelLinkText,
    ChannelLinkSubText,
} from './Menu.styled';
import { MenuItem } from './MenuItem/MenuItem';
import {
    mdiProgressClock,
    mdiCheckboxMarkedCircleOutline,
    mdiFolderTableOutline,
    mdiAccountCircleOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useStyles } from './Menu.styled';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import {
    Get_Reports_For_Dashboard_Status_Filter,
    useGetLoggedUserQuery,
    User_Role,
} from 'src/generated/graphql';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import paths from '@shared/paths';
import { NoteAddOutlined } from '@material-ui/icons';
import { resolveEnv } from '../../../utils/helpers';
import useStore from '../../../store/useStore';

export interface IMenuProps {
    open: boolean;
}

const Menu = ({ open }: IMenuProps) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [loggedUserResult] = useGetLoggedUserQuery();
    if (
        !loggedUserResult.fetching &&
        window.location.pathname === paths.dashboard &&
        !loggedUserResult.data?.getLoggedUser
    ) {
        navigate(paths.login);
    }
    const userRole = loggedUserResult.data?.getLoggedUser.role || User_Role.Employee;
    const { activeStatus, setActiveStatus, counts } = useStore();

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <BorderRight />
            <List>
                <MenuTitle active={open}>
                    <FormattedMessage id="dashboardPageMenu.reports" />
                </MenuTitle>
                <MenuItemsWrapper active={open}>
                    <MenuItem
                        active={
                            window.location.pathname === paths.dashboard &&
                            activeStatus === Get_Reports_For_Dashboard_Status_Filter.New
                        }
                        icon={<NoteAddOutlined />}
                        number={counts.newCount}
                        onClick={() => {
                            setActiveStatus(Get_Reports_For_Dashboard_Status_Filter.New);
                            navigate(paths.dashboard);
                        }}
                    >
                        <FormattedMessage id="dashboardPageMenu.new" />
                    </MenuItem>
                    <MenuItem
                        active={
                            window.location.pathname === paths.dashboard &&
                            activeStatus === Get_Reports_For_Dashboard_Status_Filter.InProgress
                        }
                        icon={<Icon path={mdiProgressClock} />}
                        number={counts.inProgressCount}
                        onClick={() => {
                            setActiveStatus(Get_Reports_For_Dashboard_Status_Filter.InProgress);
                            navigate(paths.dashboard);
                        }}
                    >
                        <FormattedMessage id="dashboardPageMenu.inProgress" />
                    </MenuItem>
                    <MenuItem
                        icon={<Icon path={mdiCheckboxMarkedCircleOutline} />}
                        number={counts.closedCount}
                        active={
                            window.location.pathname === paths.dashboard &&
                            activeStatus === Get_Reports_For_Dashboard_Status_Filter.Closed
                        }
                        onClick={() => {
                            setActiveStatus(Get_Reports_For_Dashboard_Status_Filter.Closed);
                            navigate(paths.dashboard);
                        }}
                    >
                        <FormattedMessage id="dashboardPageMenu.closed" />
                    </MenuItem>
                </MenuItemsWrapper>
                <MenuItemsWrapper active={open}>
                    <MenuItem
                        icon={<Icon path={mdiFolderTableOutline} />}
                        active={window.location.pathname === paths.reportsSummary}
                        onClick={() => navigate(paths.reportsSummary)}
                    >
                        <FormattedMessage id="dashboardPageMenu.reportsSummary" />
                    </MenuItem>
                </MenuItemsWrapper>
                {userRole === User_Role.Maintainer && (
                    <>
                        <MenuTitle active={open}>
                            <FormattedMessage id="dashboardPageMenu.settings" />
                        </MenuTitle>
                        <MenuItemsWrapper active={open}>
                            <MenuItem
                                icon={<Icon path={mdiAccountCircleOutline} />}
                                active={window.location.pathname === paths.users}
                                onClick={() => navigate(paths.users)}
                            >
                                <FormattedMessage id="dashboardPageMenu.users" />
                            </MenuItem>
                        </MenuItemsWrapper>
                    </>
                )}
            </List>
            <BottomMenu active={open}>
                {loggedUserResult.data?.getLoggedUser.company?.domain && (
                    <ChannelLink
                        active={open}
                        onClick={() => {
                            if (loggedUserResult.data?.getLoggedUser.company?.domain) {
                                window.open(
                                    `https://${
                                        loggedUserResult.data.getLoggedUser.company.domain
                                    }.${resolveEnv('REACT_APP_BASE_REPORT_DOMAIN')}`,
                                    '_blank',
                                );
                            }
                        }}
                    >
                        <ChannelLinkText>
                            <FormattedMessage id="reportingChannel" />
                        </ChannelLinkText>
                        {open && (
                            <ChannelLinkSubText>
                                {`${
                                    loggedUserResult.data.getLoggedUser.company.domain
                                }.${resolveEnv('REACT_APP_BASE_REPORT_DOMAIN')}`}
                            </ChannelLinkSubText>
                        )}
                    </ChannelLink>
                )}
                <SpecialLink active={open}>
                    <a href="https://www.notibox.pl/blog" target="_blank">
                        Blog Notibox
                    </a>
                </SpecialLink>
            </BottomMenu>
        </Drawer>
    );
};

export default Menu;
