import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    Upload: any;
};

export type AddAttachmentsToExistingReportInput = {
    attachments: Array<Scalars['ID']>;
    reportNumber?: InputMaybe<Scalars['String']>;
};

export type Address = {
    __typename?: 'Address';
    apartment?: Maybe<Scalars['String']>;
    building: Scalars['String'];
    city: Scalars['String'];
    country: Scalars['String'];
    street: Scalars['String'];
    zipCode: Scalars['String'];
};

export type Attachment = {
    __typename?: 'Attachment';
    _id: Scalars['ID'];
    ext: Scalars['String'];
    size: Scalars['Int'];
};

export type AttachmentInput = {
    _id: Scalars['ID'];
    ext: Scalars['String'];
    size: Scalars['Int'];
};

export type AttachmentUploadLinkInput = {
    frontId: Scalars['String'];
    mimeType: Scalars['String'];
    size: Scalars['Int'];
};

export type AuthTokenInput = {
    token: Scalars['String'];
};

export type AuthTokensResponse = {
    __typename?: 'AuthTokensResponse';
    refreshToken: Scalars['String'];
    sessionToken: Scalars['String'];
};

export type ChangePasswordInput = {
    newPassword: Scalars['String'];
    oldPassword: Scalars['String'];
};

export type CloseBatchOfReportsInput = {
    closingStatement: Scalars['String'];
    reportNumbers: Array<Scalars['String']>;
};

export type ClosedInformations = {
    __typename?: 'ClosedInformations';
    date: Scalars['DateTime'];
    statement: Scalars['String'];
};

export type Company = {
    __typename?: 'Company';
    _id: Scalars['ID'];
    address: Address;
    createdAt?: Maybe<Scalars['DateTime']>;
    domain: Scalars['String'];
    name: Scalars['String'];
    shortName: Scalars['String'];
    updatedAt?: Maybe<Scalars['DateTime']>;
    vatEu: Scalars['String'];
};

export type ConfirmResetPasswordInput = {
    password: Scalars['String'];
    resetPasswordToken: Scalars['String'];
};

export type CreateCompanyAddressInput = {
    apartment?: InputMaybe<Scalars['String']>;
    building: Scalars['String'];
    city: Scalars['String'];
    country: Scalars['String'];
    street: Scalars['String'];
    zipCode: Scalars['String'];
};

export type CreateCompanyAsMaintainerInput = {
    companyData: CreateCompanyInput;
    token: Scalars['String'];
};

export type CreateCompanyInput = {
    address: CreateCompanyAddressInput;
    domain: Scalars['String'];
    name: Scalars['String'];
    shortName: Scalars['String'];
    vatEu: Scalars['String'];
};

export type CreateReportInput = {
    attachments?: InputMaybe<Array<Scalars['ID']>>;
    category: Report_Category;
    hcaptchaToken: Scalars['String'];
    message: Scalars['String'];
    occurringDetails?: InputMaybe<OccurringDetailsInput>;
    otherCategory?: InputMaybe<Scalars['String']>;
    password: Scalars['String'];
    personsInvolved?: InputMaybe<Array<PersonsInvolvedInput>>;
    reportNumber: Scalars['String'];
    whistleblower?: InputMaybe<WhistleblowerInput>;
    whistleblowerRelation?: InputMaybe<WhistleblowerRelationInput>;
};

export type CreateUserInput = {
    email: Scalars['String'];
    language?: InputMaybe<Language_Codes>;
    marketingConsent?: InputMaybe<Scalars['Boolean']>;
    name: Scalars['String'];
    password: Scalars['String'];
    surname: Scalars['String'];
};

export type DeleteBatchOfReportAttachmentsInput = {
    attachments: Array<Scalars['ID']>;
    reportNumber: Scalars['String'];
};

export type DeleteInvitationInput = {
    email: Scalars['String'];
};

export type DeleteUserInput = {
    _id: Scalars['ID'];
};

export enum DomainValidateDirectivePolicy {
    Resolver = 'RESOLVER',
    Throw = 'THROW',
}

export enum EmailValidateDirectivePolicy {
    Resolver = 'RESOLVER',
    Throw = 'THROW',
}

export enum File_Extensions {
    Avi = 'avi',
    Bmp = 'bmp',
    Doc = 'doc',
    Docx = 'docx',
    Jpeg = 'jpeg',
    Jpg = 'jpg',
    Mp3 = 'mp3',
    Mp4 = 'mp4',
    Pdf = 'pdf',
    Png = 'png',
    Pptx = 'pptx',
    Tif = 'tif',
    Wav = 'wav',
    Xlsx = 'xlsx',
    Zip = 'zip',
}

export enum Get_Reports_For_Dashboard_Sort {
    CreatedAt = 'CREATED_AT',
    LastMessageAt = 'LAST_MESSAGE_AT',
}

export enum Get_Reports_For_Dashboard_Status_Filter {
    Closed = 'CLOSED',
    InProgress = 'IN_PROGRESS',
    New = 'NEW',
}

export enum Get_Reports_Whistleblower {
    Anonymous = 'ANONYMOUS',
    Public = 'PUBLIC',
}

export type GenerateReportAttachmentsUploadLinksInput = {
    asReplyToReport?: InputMaybe<Scalars['Boolean']>;
    attachmentsData: Array<AttachmentUploadLinkInput>;
    reportNumber: Scalars['String'];
};

export type GenerateReportAttachmentsUploadLinksResult = {
    __typename?: 'GenerateReportAttachmentsUploadLinksResult';
    attachmentId: Scalars['ID'];
    ext: Scalars['String'];
    frontId: Scalars['String'];
    size: Scalars['Int'];
    url: Scalars['String'];
};

export type GetCompanyInfoFromDomainResult = {
    __typename?: 'GetCompanyInfoFromDomainResult';
    logoUrl?: Maybe<Scalars['String']>;
    shortName: Scalars['String'];
};

export type GetCompanyUsersResult = {
    __typename?: 'GetCompanyUsersResult';
    invitedEmails: Array<Scalars['String']>;
    users: Array<User>;
};

export type GetReportDetailsAsCompanyUserInput = {
    reportNumber: Scalars['String'];
};

export type GetReportFileDownloadLinkInput = {
    fileId: Scalars['ID'];
    reportNumber: Scalars['String'];
};

export type GetReportsForDashboardInput = {
    filter?: InputMaybe<GetReportsForDashboardInputFilter>;
    pagination?: InputMaybe<GetReportsForDashboardInputPagination>;
    sort?: InputMaybe<GetReportsForDashboardInputSort>;
};

export type GetReportsForDashboardInputFilter = {
    status: Get_Reports_For_Dashboard_Status_Filter;
};

export type GetReportsForDashboardInputPagination = {
    limit?: InputMaybe<Scalars['Int']>;
    page?: InputMaybe<Scalars['Int']>;
};

export type GetReportsForDashboardInputSort = {
    field: Get_Reports_For_Dashboard_Sort;
    order: Scalars['Int'];
};

export type GetReportsForDashboardResult = {
    __typename?: 'GetReportsForDashboardResult';
    counts: ReportsCounts;
    meta: PaginationMetadata;
    reports: Array<Report>;
};

export type GetReportsInput = {
    filters?: InputMaybe<GetReportsInputFilter>;
    pagination?: InputMaybe<GetReportsForDashboardInputPagination>;
};

export type GetReportsInputFilter = {
    category?: InputMaybe<Array<Report_Category>>;
    from?: InputMaybe<Scalars['DateTime']>;
    relation?: InputMaybe<Array<Whistleblower_Relation>>;
    reportNumber?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Array<Get_Reports_For_Dashboard_Status_Filter>>;
    to?: InputMaybe<Scalars['DateTime']>;
    whistleblower?: InputMaybe<Array<Get_Reports_Whistleblower>>;
};

export type GetReportsResult = {
    __typename?: 'GetReportsResult';
    meta: PaginationMetadata;
    reports: Array<Report>;
};

export type GetUserInput = {
    _id: Scalars['ID'];
};

export type InitiateReportResult = {
    __typename?: 'InitiateReportResult';
    reportNumber: Scalars['String'];
};

export type InviteEmployeeInput = {
    email: Scalars['String'];
};

export enum Language_Codes {
    En = 'en',
    Pl = 'pl',
}

export type LoginUserInput = {
    email: Scalars['String'];
    language?: InputMaybe<Language_Codes>;
    password: Scalars['String'];
};

export type LoginUserResponse = {
    __typename?: 'LoginUserResponse';
    tokens: AuthTokensResponse;
    user: User;
};

export type LoginWhistleblowerInput = {
    password: Scalars['String'];
    reportNumber: Scalars['String'];
};

export type LoginWhistleblowerResponse = {
    __typename?: 'LoginWhistleblowerResponse';
    sessionToken: Scalars['String'];
};

export enum MaxLengthValidateDirectivePolicy {
    Resolver = 'RESOLVER',
    Throw = 'THROW',
}

export type Message = {
    __typename?: 'Message';
    attachments?: Maybe<Array<Attachment>>;
    content?: Maybe<Scalars['String']>;
    sendAt: Scalars['DateTime'];
    sendBy?: Maybe<User>;
    type: Report_Message_Type;
};

export enum MinLengthValidateDirectivePolicy {
    Resolver = 'RESOLVER',
    Throw = 'THROW',
}

export enum MinValueValidateDirectivePolicy {
    Resolver = 'RESOLVER',
    Throw = 'THROW',
}

export type Mutation = {
    __typename?: 'Mutation';
    addAttachmentsToExistingReport: Report;
    addEmployee: User;
    changePassword: AuthTokensResponse;
    closeBatchOfReports: Array<Report>;
    confirmAccount: SuccessResponse;
    confirmResetPassword: SuccessResponse;
    createCompanyAsMaintainer: Company;
    createMaintainer: SuccessResponse;
    createReport: ReportNumberResponse;
    deleteBatchOfReportAttachments: SuccessResponse;
    deleteInvitation: SuccessResponse;
    deleteUser: User;
    generateReportAttachmentsUploadLinks: Array<GenerateReportAttachmentsUploadLinksResult>;
    initiateReport: ReportNumberResponse;
    inviteEmployee: SuccessResponse;
    loginUser: LoginUserResponse;
    loginWhistleblower: LoginWhistleblowerResponse;
    logoutUser: SuccessResponse;
    refreshSessionToken: AuthTokensResponse;
    registerFromInvitation: SuccessResponse;
    replyToReportAsCompanyUser: Report;
    replyToReportAsWhistleblower: Report;
    resendConfirmAccountEmail: SuccessResponse;
    resetPassword: Scalars['String'];
    updateBatchOfReportsStatus: Array<Report>;
    updateCompany: Company;
    updateUser: User;
};

export type MutationAddAttachmentsToExistingReportArgs = {
    input: AddAttachmentsToExistingReportInput;
};

export type MutationAddEmployeeArgs = {
    input: CreateUserInput;
};

export type MutationChangePasswordArgs = {
    input: ChangePasswordInput;
};

export type MutationCloseBatchOfReportsArgs = {
    input: CloseBatchOfReportsInput;
};

export type MutationConfirmAccountArgs = {
    input: AuthTokenInput;
};

export type MutationConfirmResetPasswordArgs = {
    input: ConfirmResetPasswordInput;
};

export type MutationCreateCompanyAsMaintainerArgs = {
    input: CreateCompanyAsMaintainerInput;
};

export type MutationCreateMaintainerArgs = {
    input: CreateUserInput;
};

export type MutationCreateReportArgs = {
    input: CreateReportInput;
};

export type MutationDeleteBatchOfReportAttachmentsArgs = {
    input: DeleteBatchOfReportAttachmentsInput;
};

export type MutationDeleteInvitationArgs = {
    input: DeleteInvitationInput;
};

export type MutationDeleteUserArgs = {
    input: DeleteUserInput;
};

export type MutationGenerateReportAttachmentsUploadLinksArgs = {
    input: GenerateReportAttachmentsUploadLinksInput;
};

export type MutationInviteEmployeeArgs = {
    input: InviteEmployeeInput;
};

export type MutationLoginUserArgs = {
    input: LoginUserInput;
};

export type MutationLoginWhistleblowerArgs = {
    input: LoginWhistleblowerInput;
};

export type MutationLogoutUserArgs = {
    input: AuthTokenInput;
};

export type MutationRefreshSessionTokenArgs = {
    input: AuthTokenInput;
};

export type MutationRegisterFromInvitationArgs = {
    input: RegisterFromInvitationInput;
};

export type MutationReplyToReportAsCompanyUserArgs = {
    input: ReplyToReportAsCompanyUserInput;
};

export type MutationReplyToReportAsWhistleblowerArgs = {
    input: ReplyToReportAsWhistleblowerInput;
};

export type MutationResendConfirmAccountEmailArgs = {
    input: ResendConfirmAccountEmailInput;
};

export type MutationResetPasswordArgs = {
    input: ResetPasswordInput;
};

export type MutationUpdateBatchOfReportsStatusArgs = {
    input: UpdateBatchOfReportsStatusInput;
};

export type MutationUpdateCompanyArgs = {
    input: UpdateCompanyInput;
};

export type MutationUpdateUserArgs = {
    input: UpdateUserInput;
};

export type OccurringDetails = {
    __typename?: 'OccurringDetails';
    firstTimeOccurredAt?: Maybe<Scalars['DateTime']>;
    recurring?: Maybe<Scalars['Boolean']>;
    recurringFrequency?: Maybe<Recurring_Frequency>;
};

export type OccurringDetailsInput = {
    firstTimeOccurredAt?: InputMaybe<Scalars['DateTime']>;
    recurring?: InputMaybe<Scalars['Boolean']>;
    recurringFrequency?: InputMaybe<Recurring_Frequency>;
};

export type PaginationMetadata = {
    __typename?: 'PaginationMetadata';
    hasNextPage?: Maybe<Scalars['Boolean']>;
    hasPrevPage?: Maybe<Scalars['Boolean']>;
    limit?: Maybe<Scalars['Int']>;
    nextPage?: Maybe<Scalars['Int']>;
    page?: Maybe<Scalars['Int']>;
    prevPage?: Maybe<Scalars['Int']>;
    totalDocs?: Maybe<Scalars['Int']>;
    totalPages?: Maybe<Scalars['Int']>;
};

export enum PasswordValidateDirectivePolicy {
    Resolver = 'RESOLVER',
    Throw = 'THROW',
}

export enum PatternValidateDirectivePolicy {
    Resolver = 'RESOLVER',
    Throw = 'THROW',
}

export type PersonsInvolved = {
    __typename?: 'PersonsInvolved';
    name?: Maybe<Scalars['String']>;
    relation?: Maybe<Scalars['String']>;
    surname?: Maybe<Scalars['String']>;
};

export type PersonsInvolvedInput = {
    name?: InputMaybe<Scalars['String']>;
    relation?: InputMaybe<Scalars['String']>;
    surname?: InputMaybe<Scalars['String']>;
};

export type Query = {
    __typename?: 'Query';
    getCompany: Company;
    getCompanyInfoFromDomain: GetCompanyInfoFromDomainResult;
    getCompanyUsers: GetCompanyUsersResult;
    getLoggedUser: User;
    getReportDetailsAsCompanyUser: Report;
    getReportFileDownloadLink: Scalars['String'];
    getReportForWhistleblower: Report;
    getReports: GetReportsResult;
    getReportsForDashboard: GetReportsForDashboardResult;
    getUser: User;
};

export type QueryGetReportDetailsAsCompanyUserArgs = {
    input: GetReportDetailsAsCompanyUserInput;
};

export type QueryGetReportFileDownloadLinkArgs = {
    input: GetReportFileDownloadLinkInput;
};

export type QueryGetReportsArgs = {
    input?: InputMaybe<GetReportsInput>;
};

export type QueryGetReportsForDashboardArgs = {
    input?: InputMaybe<GetReportsForDashboardInput>;
};

export type QueryGetUserArgs = {
    input: GetUserInput;
};

export enum Recurring_Frequency {
    SeveralTimesADay = 'SEVERAL_TIMES_A_DAY',
    SeveralTimesAMonth = 'SEVERAL_TIMES_A_MONTH',
    SeveralTimesAWeek = 'SEVERAL_TIMES_A_WEEK',
    SeveralTimesAYear = 'SEVERAL_TIMES_A_YEAR',
}

export enum Report_Category {
    AnimalHealthAndWelfare = 'ANIMAL_HEALTH_AND_WELFARE',
    ConsumerProtection = 'CONSUMER_PROTECTION',
    EnvironmentalProtection = 'ENVIRONMENTAL_PROTECTION',
    FinancialInterestsOfTheEu = 'FINANCIAL_INTERESTS_OF_THE_EU',
    FinancialServicesProductsAndMarkets = 'FINANCIAL_SERVICES_PRODUCTS_AND_MARKETS',
    FoodAndFeedSafety = 'FOOD_AND_FEED_SAFETY',
    InternalMarketOfTheEuIncludingPublicLawRulesRegardingStateAidAndLegalSolutions = 'INTERNAL_EU_MARKETS_INCLUDING_COMPETITION_AND_STATE_AID_RULES_AND_CORPORATE_TAXATION',
    Corruption = 'CORRUPTION',
    ConstitutionalFreedomsAndHumanAndCitizenRights = 'CONSTITUTIONAL_FREEDOMS_AND_HUMAN_AND_CITIZEN_RIGHTS',
    MoneyLaunderingAndTerroristFinancing = 'MONEY_LAUNDERING_AND_TERRORIST_FINANCING',
    Other = 'OTHER',
    ProductSafetyAndComplianceWithRequirements = 'PRODUCT_SAFETY_AND_COMPLIANCE_WITH_REQUIREMENTS',
    ProtectionOfPrivacyAndPersonalData = 'PROTECTION_OF_PRIVACY_AND_PERSONAL_DATA',
    PublicHealth = 'PUBLIC_HEALTH',
    PublicProcurement = 'PUBLIC_PROCUREMENT',
    RadiologicalProtectionAndNuclearSafety = 'RADIOLOGICAL_PROTECTION_AND_NUCLEAR_SAFETY',
    SecurityOfNetworksAndIctSystems = 'SECURITY_OF_NETWORKS_AND_ICT_SYSTEMS',
    TransportSafety = 'TRANSPORT_SAFETY',
}

export enum Report_Message_Type {
    Attachments = 'ATTACHMENTS',
    Text = 'TEXT',
}

export enum Report_Status {
    Archived = 'ARCHIVED',
    Closed = 'CLOSED',
    InProgress = 'IN_PROGRESS',
    Received = 'RECEIVED',
    Sent = 'SENT',
}

export enum RangeLengthValidateDirectivePolicy {
    Resolver = 'RESOLVER',
    Throw = 'THROW',
}

export enum RangeValidateDirectivePolicy {
    Resolver = 'RESOLVER',
    Throw = 'THROW',
}

export type RegisterFromInvitationInput = {
    marketingConsent?: InputMaybe<Scalars['Boolean']>;
    name: Scalars['String'];
    password: Scalars['String'];
    surname: Scalars['String'];
    token: Scalars['String'];
};

export type ReplyToReportAsCompanyUserInput = {
    message: Scalars['String'];
    reportNumber: Scalars['String'];
};

export type ReplyToReportAsWhistleblowerInput = {
    attachments?: InputMaybe<Array<AttachmentInput>>;
    message: Scalars['String'];
};

export type Report = {
    __typename?: 'Report';
    _id: Scalars['ID'];
    assignedEmployees?: Maybe<Array<Maybe<User>>>;
    attachments?: Maybe<Array<Attachment>>;
    category: Report_Category;
    closedInformations?: Maybe<ClosedInformations>;
    company: Company;
    createdAt?: Maybe<Scalars['DateTime']>;
    hasNewMessages: Scalars['Boolean'];
    lastMessageDate?: Maybe<Scalars['DateTime']>;
    messages: Array<Message>;
    occurringDetails?: Maybe<OccurringDetails>;
    otherCategory?: Maybe<Scalars['String']>;
    personsInvolved?: Maybe<Array<Maybe<PersonsInvolved>>>;
    reportNumber: Scalars['String'];
    status: Report_Status;
    updatedAt?: Maybe<Scalars['DateTime']>;
    whistleblower?: Maybe<Whistleblower>;
    whistleblowerRelation?: Maybe<WhistleblowerRelation>;
};

export type ReportNumberResponse = {
    __typename?: 'ReportNumberResponse';
    reportNumber: Scalars['String'];
};

export enum ReportNumberValidateDirectivePolicy {
    Resolver = 'RESOLVER',
    Throw = 'THROW',
}

export type ReportsCounts = {
    __typename?: 'ReportsCounts';
    closedCount: Scalars['Int'];
    inProgressCount: Scalars['Int'];
    newCount: Scalars['Int'];
};

export type ResendConfirmAccountEmailInput = {
    email: Scalars['String'];
    language?: InputMaybe<Language_Codes>;
};

export type ResetPasswordInput = {
    email: Scalars['String'];
    language?: InputMaybe<Language_Codes>;
};

export type SuccessResponse = {
    __typename?: 'SuccessResponse';
    success: Scalars['Boolean'];
};

export enum Update_Report_Status {
    InProgress = 'IN_PROGRESS',
    Received = 'RECEIVED',
}

export enum User_Role {
    Employee = 'EMPLOYEE',
    Maintainer = 'MAINTAINER',
}

export enum User_Status {
    Active = 'ACTIVE',
    Created = 'CREATED',
    Deleted = 'DELETED',
}

export type UpdateBatchOfReportsStatusInput = {
    reportNumbers: Array<Scalars['String']>;
    status: Update_Report_Status;
};

export type UpdateCompanyAddressInput = {
    apartment?: InputMaybe<Scalars['String']>;
    building?: InputMaybe<Scalars['String']>;
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    street?: InputMaybe<Scalars['String']>;
    zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateCompanyInput = {
    address?: InputMaybe<UpdateCompanyAddressInput>;
    name?: InputMaybe<Scalars['String']>;
    shortName?: InputMaybe<Scalars['String']>;
    vatEu?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
    _id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    surname?: InputMaybe<Scalars['String']>;
};

export type User = {
    __typename?: 'User';
    _id: Scalars['ID'];
    company?: Maybe<Company>;
    createdAt?: Maybe<Scalars['DateTime']>;
    email: Scalars['String'];
    name: Scalars['String'];
    role: User_Role;
    status: User_Status;
    surname: Scalars['String'];
    updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ValidatedInputError = {
    error: ValidatedInputErrorInstance;
    message: Scalars['String'];
    path: Array<Scalars['String']>;
};

export type ValidatedInputErrorInstance = {
    message: Scalars['String'];
};

export type ValidatedInputErrorOutput = {
    __typename?: 'ValidatedInputErrorOutput';
    message: Scalars['String'];
    path: Array<Scalars['String']>;
};

export enum VatEuValidateDirectivePolicy {
    Resolver = 'RESOLVER',
    Throw = 'THROW',
}

export enum Whistleblower_Relation {
    ContractorOrAgency = 'CONTRACTOR_OR_AGENCY',
    EmployeeOrPersonProvidingWorkOnABasisOtherThanAnEmploymentRelationship = 'EMPLOYEE_OR_PERSON_PROVIDING_WORK_ON_A_BASIS_OTHER_THAN_AN_EMPLOYMENT_RELATIONSHIP',
    FormerEmployee = 'FORMER_EMPLOYEE',
    JobApplicant = 'JOB_APPLICANT',
    MemberOfTheGoveringBodyOfALegalPerson = 'MEMBER_OF_THE_GOVERING_BODY_OF_A_LEGAL_PERSON',
    Other = 'OTHER',
    PersonWorkingInAnyFormForTheConstructor = 'PERSON_WORKING_IN_ANY_FORM_FOR_THE_CONSTRUCTOR',
    ShareholderOrPartner = 'SHAREHOLDER_OR_PARTNER',
    TraineeOrVolunteer = 'TRAINEE_OR_VOLUNTEER',
}

export type Whistleblower = {
    __typename?: 'Whistleblower';
    email?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    phone?: Maybe<Scalars['String']>;
    surname: Scalars['String'];
};

export type WhistleblowerInput = {
    email?: InputMaybe<Scalars['String']>;
    name: Scalars['String'];
    phone?: InputMaybe<Scalars['String']>;
    surname: Scalars['String'];
};

export type WhistleblowerRelation = {
    __typename?: 'WhistleblowerRelation';
    other?: Maybe<Scalars['String']>;
    relation: Whistleblower_Relation;
};

export type WhistleblowerRelationInput = {
    other?: InputMaybe<Scalars['String']>;
    relation: Whistleblower_Relation;
};

export type ConfirmResetPasswordMutationVariables = Exact<{
    input: ConfirmResetPasswordInput;
}>;

export type ConfirmResetPasswordMutation = {
    __typename?: 'Mutation';
    confirmResetPassword: { __typename?: 'SuccessResponse'; success: boolean };
};

export type CreateCompanyAsMaintainerMutationVariables = Exact<{
    input: CreateCompanyAsMaintainerInput;
}>;

export type CreateCompanyAsMaintainerMutation = {
    __typename?: 'Mutation';
    createCompanyAsMaintainer: { __typename?: 'Company'; _id: string };
};

export type CreateMaintainerMutationVariables = Exact<{
    input: CreateUserInput;
}>;

export type CreateMaintainerMutation = {
    __typename?: 'Mutation';
    createMaintainer: { __typename?: 'SuccessResponse'; success: boolean };
};

export type LoginUserMutationVariables = Exact<{
    input: LoginUserInput;
}>;

export type LoginUserMutation = {
    __typename?: 'Mutation';
    loginUser: {
        __typename?: 'LoginUserResponse';
        tokens: { __typename?: 'AuthTokensResponse'; sessionToken: string; refreshToken: string };
        user: { __typename?: 'User'; company?: { __typename?: 'Company'; name: string } | null };
    };
};

export type LoginWhistleblowerMutationVariables = Exact<{
    input: LoginWhistleblowerInput;
}>;

export type LoginWhistleblowerMutation = {
    __typename?: 'Mutation';
    loginWhistleblower: { __typename?: 'LoginWhistleblowerResponse'; sessionToken: string };
};

export type RefreshSessionTokenMutationVariables = Exact<{
    input: AuthTokenInput;
}>;

export type RefreshSessionTokenMutation = {
    __typename?: 'Mutation';
    refreshSessionToken: {
        __typename?: 'AuthTokensResponse';
        sessionToken: string;
        refreshToken: string;
    };
};

export type RegisterFromInvitationMutationVariables = Exact<{
    input: RegisterFromInvitationInput;
}>;

export type RegisterFromInvitationMutation = {
    __typename?: 'Mutation';
    registerFromInvitation: { __typename?: 'SuccessResponse'; success: boolean };
};

export type ResendConfirmAccountEmailMutationVariables = Exact<{
    input: ResendConfirmAccountEmailInput;
}>;

export type ResendConfirmAccountEmailMutation = {
    __typename?: 'Mutation';
    resendConfirmAccountEmail: { __typename?: 'SuccessResponse'; success: boolean };
};

export type ResetPasswordMutationVariables = Exact<{
    input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = { __typename?: 'Mutation'; resetPassword: string };

export type AddAttachmentsToExistingReportMutationVariables = Exact<{
    input: AddAttachmentsToExistingReportInput;
}>;

export type AddAttachmentsToExistingReportMutation = {
    __typename?: 'Mutation';
    addAttachmentsToExistingReport: { __typename?: 'Report'; _id: string };
};

export type CloseBatchOfReportsMutationVariables = Exact<{
    input: CloseBatchOfReportsInput;
}>;

export type CloseBatchOfReportsMutation = {
    __typename?: 'Mutation';
    closeBatchOfReports: Array<{ __typename: 'Report'; reportNumber: string }>;
};

export type CreateReportMutationVariables = Exact<{
    input: CreateReportInput;
}>;

export type CreateReportMutation = {
    __typename?: 'Mutation';
    createReport: { __typename?: 'ReportNumberResponse'; reportNumber: string };
};

export type DeleteBatchOfReportAttachmentsMutationVariables = Exact<{
    input: DeleteBatchOfReportAttachmentsInput;
}>;

export type DeleteBatchOfReportAttachmentsMutation = {
    __typename?: 'Mutation';
    deleteBatchOfReportAttachments: { __typename?: 'SuccessResponse'; success: boolean };
};

export type GenerateReportAttachmentsUploadLinksMutationVariables = Exact<{
    input: GenerateReportAttachmentsUploadLinksInput;
}>;

export type GenerateReportAttachmentsUploadLinksMutation = {
    __typename?: 'Mutation';
    generateReportAttachmentsUploadLinks: Array<{
        __typename?: 'GenerateReportAttachmentsUploadLinksResult';
        attachmentId: string;
        frontId: string;
        url: string;
    }>;
};

export type InitiateReportMutationVariables = Exact<{ [key: string]: never }>;

export type InitiateReportMutation = {
    __typename?: 'Mutation';
    initiateReport: { __typename?: 'ReportNumberResponse'; reportNumber: string };
};

export type ReplyToReportAsCompanyUserMutationVariables = Exact<{
    input: ReplyToReportAsCompanyUserInput;
}>;

export type ReplyToReportAsCompanyUserMutation = {
    __typename?: 'Mutation';
    replyToReportAsCompanyUser: { __typename?: 'Report'; _id: string };
};

export type ReplyToReportAsWhistleblowerMutationVariables = Exact<{
    input: ReplyToReportAsWhistleblowerInput;
}>;

export type ReplyToReportAsWhistleblowerMutation = {
    __typename?: 'Mutation';
    replyToReportAsWhistleblower: { __typename?: 'Report'; _id: string };
};

export type UpdateBatchOfReportsStatusMutationVariables = Exact<{
    input: UpdateBatchOfReportsStatusInput;
}>;

export type UpdateBatchOfReportsStatusMutation = {
    __typename?: 'Mutation';
    updateBatchOfReportsStatus: Array<{
        __typename?: 'Report';
        reportNumber: string;
        status: Report_Status;
    }>;
};

export type GetCompanyInfoFromDomainQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyInfoFromDomainQuery = {
    __typename?: 'Query';
    getCompanyInfoFromDomain: {
        __typename?: 'GetCompanyInfoFromDomainResult';
        shortName: string;
        logoUrl?: string | null;
    };
};

export type GetReportDetailsAsCompanyUserQueryVariables = Exact<{
    input: GetReportDetailsAsCompanyUserInput;
}>;

export type GetReportDetailsAsCompanyUserQuery = {
    __typename?: 'Query';
    getReportDetailsAsCompanyUser: {
        __typename?: 'Report';
        createdAt?: any | null;
        category: Report_Category;
        otherCategory?: string | null;
        reportNumber: string;
        status: Report_Status;
        whistleblower?: {
            __typename?: 'Whistleblower';
            name: string;
            surname: string;
            phone?: string | null;
            email?: string | null;
        } | null;
        whistleblowerRelation?: {
            __typename?: 'WhistleblowerRelation';
            relation: Whistleblower_Relation;
            other?: string | null;
        } | null;
        personsInvolved?: Array<{
            __typename?: 'PersonsInvolved';
            name?: string | null;
            surname?: string | null;
            relation?: string | null;
        } | null> | null;
        messages: Array<{
            __typename?: 'Message';
            sendAt: any;
            content?: string | null;
            sendBy?: { __typename?: 'User'; name: string; surname: string } | null;
            attachments?: Array<{
                __typename?: 'Attachment';
                size: number;
                ext: string;
                _id: string;
            }> | null;
        }>;
        attachments?: Array<{
            __typename?: 'Attachment';
            size: number;
            ext: string;
            _id: string;
        }> | null;
        occurringDetails?: {
            __typename?: 'OccurringDetails';
            recurring?: boolean | null;
            recurringFrequency?: Recurring_Frequency | null;
            firstTimeOccurredAt?: any | null;
        } | null;
    };
};

export type GetReportFileDownloadLinkQueryVariables = Exact<{
    input: GetReportFileDownloadLinkInput;
}>;

export type GetReportFileDownloadLinkQuery = {
    __typename?: 'Query';
    getReportFileDownloadLink: string;
};

export type GetReportForWhistleblowerQueryVariables = Exact<{ [key: string]: never }>;

export type GetReportForWhistleblowerQuery = {
    __typename?: 'Query';
    getReportForWhistleblower: {
        __typename?: 'Report';
        createdAt?: any | null;
        category: Report_Category;
        otherCategory?: string | null;
        reportNumber: string;
        status: Report_Status;
        whistleblower?: {
            __typename?: 'Whistleblower';
            name: string;
            surname: string;
            phone?: string | null;
            email?: string | null;
        } | null;
        whistleblowerRelation?: {
            __typename?: 'WhistleblowerRelation';
            relation: Whistleblower_Relation;
            other?: string | null;
        } | null;
        personsInvolved?: Array<{
            __typename?: 'PersonsInvolved';
            name?: string | null;
            surname?: string | null;
            relation?: string | null;
        } | null> | null;
        closedInformations?: {
            __typename: 'ClosedInformations';
            date: any;
            statement: string;
        } | null;
        messages: Array<{
            __typename?: 'Message';
            sendAt: any;
            content?: string | null;
            sendBy?: { __typename?: 'User'; name: string; surname: string } | null;
            attachments?: Array<{
                __typename?: 'Attachment';
                size: number;
                ext: string;
                _id: string;
            }> | null;
        }>;
        attachments?: Array<{
            __typename?: 'Attachment';
            size: number;
            ext: string;
            _id: string;
        }> | null;
        occurringDetails?: {
            __typename?: 'OccurringDetails';
            recurring?: boolean | null;
            recurringFrequency?: Recurring_Frequency | null;
            firstTimeOccurredAt?: any | null;
        } | null;
    };
};

export type GetReportsQueryVariables = Exact<{
    input?: InputMaybe<GetReportsInput>;
}>;

export type GetReportsQuery = {
    __typename?: 'Query';
    getReports: {
        __typename?: 'GetReportsResult';
        reports: Array<{
            __typename: 'Report';
            _id: string;
            reportNumber: string;
            createdAt?: any | null;
            status: Report_Status;
            category: Report_Category;
            otherCategory?: string | null;
            whistleblower?: {
                __typename?: 'Whistleblower';
                name: string;
                surname: string;
                email?: string | null;
                phone?: string | null;
            } | null;
            whistleblowerRelation?: {
                __typename?: 'WhistleblowerRelation';
                relation: Whistleblower_Relation;
                other?: string | null;
            } | null;
            closedInformations?: {
                __typename: 'ClosedInformations';
                date: any;
                statement: string;
            } | null;
        }>;
        meta: {
            __typename: 'PaginationMetadata';
            page?: number | null;
            limit?: number | null;
            nextPage?: number | null;
            prevPage?: number | null;
            hasPrevPage?: boolean | null;
            hasNextPage?: boolean | null;
            totalDocs?: number | null;
            totalPages?: number | null;
        };
    };
};

export type GetReportsForDashboardQueryVariables = Exact<{
    input?: InputMaybe<GetReportsForDashboardInput>;
}>;

export type GetReportsForDashboardQuery = {
    __typename?: 'Query';
    getReportsForDashboard: {
        __typename?: 'GetReportsForDashboardResult';
        reports: Array<{
            __typename: 'Report';
            _id: string;
            category: Report_Category;
            reportNumber: string;
            createdAt?: any | null;
            otherCategory?: string | null;
            lastMessageDate?: any | null;
            hasNewMessages: boolean;
            status: Report_Status;
            whistleblower?: { __typename: 'Whistleblower'; name: string; surname: string } | null;
            whistleblowerRelation?: {
                __typename: 'WhistleblowerRelation';
                relation: Whistleblower_Relation;
                other?: string | null;
            } | null;
            closedInformations?: {
                __typename: 'ClosedInformations';
                date: any;
                statement: string;
            } | null;
            messages: Array<{ __typename: 'Message' }>;
        }>;
        meta: {
            __typename: 'PaginationMetadata';
            totalDocs?: number | null;
            totalPages?: number | null;
        };
        counts: {
            __typename: 'ReportsCounts';
            newCount: number;
            inProgressCount: number;
            closedCount: number;
        };
    };
};

export type DeleteInvitationMutationVariables = Exact<{
    input: DeleteInvitationInput;
}>;

export type DeleteInvitationMutation = {
    __typename?: 'Mutation';
    deleteInvitation: { __typename?: 'SuccessResponse'; success: boolean };
};

export type DeleteUserMutationVariables = Exact<{
    input: DeleteUserInput;
}>;

export type DeleteUserMutation = {
    __typename?: 'Mutation';
    deleteUser: { __typename?: 'User'; _id: string };
};

export type InviteEmployeeMutationVariables = Exact<{
    input: InviteEmployeeInput;
}>;

export type InviteEmployeeMutation = {
    __typename?: 'Mutation';
    inviteEmployee: { __typename?: 'SuccessResponse'; success: boolean };
};

export type GetCompanyUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyUsersQuery = {
    __typename?: 'Query';
    getCompanyUsers: {
        __typename?: 'GetCompanyUsersResult';
        invitedEmails: Array<string>;
        users: Array<{
            __typename?: 'User';
            _id: string;
            name: string;
            surname: string;
            status: User_Status;
            email: string;
            role: User_Role;
        }>;
    };
};

export type GetLoggedUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetLoggedUserQuery = {
    __typename?: 'Query';
    getLoggedUser: {
        __typename: 'User';
        name: string;
        surname: string;
        role: User_Role;
        email: string;
        company?: { __typename: 'Company'; name: string; domain: string } | null;
    };
};

export const ConfirmResetPasswordDocument = gql`
    mutation ConfirmResetPassword($input: ConfirmResetPasswordInput!) {
        confirmResetPassword(input: $input) {
            success
        }
    }
`;

export function useConfirmResetPasswordMutation() {
    return Urql.useMutation<ConfirmResetPasswordMutation, ConfirmResetPasswordMutationVariables>(
        ConfirmResetPasswordDocument,
    );
}
export const CreateCompanyAsMaintainerDocument = gql`
    mutation CreateCompanyAsMaintainer($input: CreateCompanyAsMaintainerInput!) {
        createCompanyAsMaintainer(input: $input) {
            _id
        }
    }
`;

export function useCreateCompanyAsMaintainerMutation() {
    return Urql.useMutation<
        CreateCompanyAsMaintainerMutation,
        CreateCompanyAsMaintainerMutationVariables
    >(CreateCompanyAsMaintainerDocument);
}
export const CreateMaintainerDocument = gql`
    mutation CreateMaintainer($input: CreateUserInput!) {
        createMaintainer(input: $input) {
            success
        }
    }
`;

export function useCreateMaintainerMutation() {
    return Urql.useMutation<CreateMaintainerMutation, CreateMaintainerMutationVariables>(
        CreateMaintainerDocument,
    );
}
export const LoginUserDocument = gql`
    mutation LoginUser($input: LoginUserInput!) {
        loginUser(input: $input) {
            tokens {
                sessionToken
                refreshToken
            }
            user {
                company {
                    name
                }
            }
        }
    }
`;

export function useLoginUserMutation() {
    return Urql.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument);
}
export const LoginWhistleblowerDocument = gql`
    mutation LoginWhistleblower($input: LoginWhistleblowerInput!) {
        loginWhistleblower(input: $input) {
            sessionToken
        }
    }
`;

export function useLoginWhistleblowerMutation() {
    return Urql.useMutation<LoginWhistleblowerMutation, LoginWhistleblowerMutationVariables>(
        LoginWhistleblowerDocument,
    );
}
export const RefreshSessionTokenDocument = gql`
    mutation RefreshSessionToken($input: AuthTokenInput!) {
        refreshSessionToken(input: $input) {
            sessionToken
            refreshToken
        }
    }
`;

export function useRefreshSessionTokenMutation() {
    return Urql.useMutation<RefreshSessionTokenMutation, RefreshSessionTokenMutationVariables>(
        RefreshSessionTokenDocument,
    );
}
export const RegisterFromInvitationDocument = gql`
    mutation RegisterFromInvitation($input: RegisterFromInvitationInput!) {
        registerFromInvitation(input: $input) {
            success
        }
    }
`;

export function useRegisterFromInvitationMutation() {
    return Urql.useMutation<
        RegisterFromInvitationMutation,
        RegisterFromInvitationMutationVariables
    >(RegisterFromInvitationDocument);
}
export const ResendConfirmAccountEmailDocument = gql`
    mutation ResendConfirmAccountEmail($input: ResendConfirmAccountEmailInput!) {
        resendConfirmAccountEmail(input: $input) {
            success
        }
    }
`;

export function useResendConfirmAccountEmailMutation() {
    return Urql.useMutation<
        ResendConfirmAccountEmailMutation,
        ResendConfirmAccountEmailMutationVariables
    >(ResendConfirmAccountEmailDocument);
}
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input)
    }
`;

export function useResetPasswordMutation() {
    return Urql.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
        ResetPasswordDocument,
    );
}
export const AddAttachmentsToExistingReportDocument = gql`
    mutation AddAttachmentsToExistingReport($input: AddAttachmentsToExistingReportInput!) {
        addAttachmentsToExistingReport(input: $input) {
            _id
        }
    }
`;

export function useAddAttachmentsToExistingReportMutation() {
    return Urql.useMutation<
        AddAttachmentsToExistingReportMutation,
        AddAttachmentsToExistingReportMutationVariables
    >(AddAttachmentsToExistingReportDocument);
}
export const CloseBatchOfReportsDocument = gql`
    mutation CloseBatchOfReports($input: CloseBatchOfReportsInput!) {
        closeBatchOfReports(input: $input) {
            reportNumber
            __typename
        }
    }
`;

export function useCloseBatchOfReportsMutation() {
    return Urql.useMutation<CloseBatchOfReportsMutation, CloseBatchOfReportsMutationVariables>(
        CloseBatchOfReportsDocument,
    );
}
export const CreateReportDocument = gql`
    mutation CreateReport($input: CreateReportInput!) {
        createReport(input: $input) {
            reportNumber
        }
    }
`;

export function useCreateReportMutation() {
    return Urql.useMutation<CreateReportMutation, CreateReportMutationVariables>(
        CreateReportDocument,
    );
}
export const DeleteBatchOfReportAttachmentsDocument = gql`
    mutation deleteBatchOfReportAttachments($input: DeleteBatchOfReportAttachmentsInput!) {
        deleteBatchOfReportAttachments(input: $input) {
            success
        }
    }
`;

export function useDeleteBatchOfReportAttachmentsMutation() {
    return Urql.useMutation<
        DeleteBatchOfReportAttachmentsMutation,
        DeleteBatchOfReportAttachmentsMutationVariables
    >(DeleteBatchOfReportAttachmentsDocument);
}
export const GenerateReportAttachmentsUploadLinksDocument = gql`
    mutation GenerateReportAttachmentsUploadLinks(
        $input: GenerateReportAttachmentsUploadLinksInput!
    ) {
        generateReportAttachmentsUploadLinks(input: $input) {
            attachmentId
            frontId
            url
        }
    }
`;

export function useGenerateReportAttachmentsUploadLinksMutation() {
    return Urql.useMutation<
        GenerateReportAttachmentsUploadLinksMutation,
        GenerateReportAttachmentsUploadLinksMutationVariables
    >(GenerateReportAttachmentsUploadLinksDocument);
}
export const InitiateReportDocument = gql`
    mutation InitiateReport {
        initiateReport {
            reportNumber
        }
    }
`;

export function useInitiateReportMutation() {
    return Urql.useMutation<InitiateReportMutation, InitiateReportMutationVariables>(
        InitiateReportDocument,
    );
}
export const ReplyToReportAsCompanyUserDocument = gql`
    mutation ReplyToReportAsCompanyUser($input: ReplyToReportAsCompanyUserInput!) {
        replyToReportAsCompanyUser(input: $input) {
            _id
        }
    }
`;

export function useReplyToReportAsCompanyUserMutation() {
    return Urql.useMutation<
        ReplyToReportAsCompanyUserMutation,
        ReplyToReportAsCompanyUserMutationVariables
    >(ReplyToReportAsCompanyUserDocument);
}
export const ReplyToReportAsWhistleblowerDocument = gql`
    mutation ReplyToReportAsWhistleblower($input: ReplyToReportAsWhistleblowerInput!) {
        replyToReportAsWhistleblower(input: $input) {
            _id
        }
    }
`;

export function useReplyToReportAsWhistleblowerMutation() {
    return Urql.useMutation<
        ReplyToReportAsWhistleblowerMutation,
        ReplyToReportAsWhistleblowerMutationVariables
    >(ReplyToReportAsWhistleblowerDocument);
}
export const UpdateBatchOfReportsStatusDocument = gql`
    mutation UpdateBatchOfReportsStatus($input: UpdateBatchOfReportsStatusInput!) {
        updateBatchOfReportsStatus(input: $input) {
            reportNumber
            status
        }
    }
`;

export function useUpdateBatchOfReportsStatusMutation() {
    return Urql.useMutation<
        UpdateBatchOfReportsStatusMutation,
        UpdateBatchOfReportsStatusMutationVariables
    >(UpdateBatchOfReportsStatusDocument);
}
export const GetCompanyInfoFromDomainDocument = gql`
    query GetCompanyInfoFromDomain {
        getCompanyInfoFromDomain {
            shortName
            logoUrl
        }
    }
`;

export function useGetCompanyInfoFromDomainQuery(
    options?: Omit<Urql.UseQueryArgs<GetCompanyInfoFromDomainQueryVariables>, 'query'>,
) {
    return Urql.useQuery<GetCompanyInfoFromDomainQuery, GetCompanyInfoFromDomainQueryVariables>({
        query: GetCompanyInfoFromDomainDocument,
        ...options,
    });
}
export const GetReportDetailsAsCompanyUserDocument = gql`
    query GetReportDetailsAsCompanyUser($input: GetReportDetailsAsCompanyUserInput!) {
        getReportDetailsAsCompanyUser(input: $input) {
            createdAt
            category
            otherCategory
            reportNumber
            status
            createdAt
            whistleblower {
                name
                surname
                phone
                email
            }
            whistleblowerRelation {
                relation
                other
            }
            personsInvolved {
                name
                surname
                relation
            }
            messages {
                sendBy {
                    name
                    surname
                }
                sendAt
                content
                attachments {
                    size
                    ext
                    _id
                }
            }
            attachments {
                size
                ext
                _id
            }
            occurringDetails {
                recurring
                recurringFrequency
                firstTimeOccurredAt
            }
        }
    }
`;

export function useGetReportDetailsAsCompanyUserQuery(
    options: Omit<Urql.UseQueryArgs<GetReportDetailsAsCompanyUserQueryVariables>, 'query'>,
) {
    return Urql.useQuery<
        GetReportDetailsAsCompanyUserQuery,
        GetReportDetailsAsCompanyUserQueryVariables
    >({ query: GetReportDetailsAsCompanyUserDocument, ...options });
}
export const GetReportFileDownloadLinkDocument = gql`
    query GetReportFileDownloadLink($input: GetReportFileDownloadLinkInput!) {
        getReportFileDownloadLink(input: $input)
    }
`;

export function useGetReportFileDownloadLinkQuery(
    options: Omit<Urql.UseQueryArgs<GetReportFileDownloadLinkQueryVariables>, 'query'>,
) {
    return Urql.useQuery<GetReportFileDownloadLinkQuery, GetReportFileDownloadLinkQueryVariables>({
        query: GetReportFileDownloadLinkDocument,
        ...options,
    });
}
export const GetReportForWhistleblowerDocument = gql`
    query GetReportForWhistleblower {
        getReportForWhistleblower {
            createdAt
            category
            otherCategory
            reportNumber
            status
            createdAt
            whistleblower {
                name
                surname
                phone
                email
            }
            whistleblowerRelation {
                relation
                other
            }
            personsInvolved {
                name
                surname
                relation
            }
            closedInformations {
                __typename
                date
                statement
            }
            messages {
                sendBy {
                    name
                    surname
                }
                sendAt
                content
                attachments {
                    size
                    ext
                    _id
                }
            }
            attachments {
                size
                ext
                _id
            }
            occurringDetails {
                recurring
                recurringFrequency
                firstTimeOccurredAt
            }
        }
    }
`;

export function useGetReportForWhistleblowerQuery(
    options?: Omit<Urql.UseQueryArgs<GetReportForWhistleblowerQueryVariables>, 'query'>,
) {
    return Urql.useQuery<GetReportForWhistleblowerQuery, GetReportForWhistleblowerQueryVariables>({
        query: GetReportForWhistleblowerDocument,
        ...options,
    });
}
export const GetReportsDocument = gql`
    query GetReports($input: GetReportsInput) {
        getReports(input: $input) {
            reports {
                _id
                reportNumber
                createdAt
                status
                whistleblower {
                    name
                    surname
                    email
                    phone
                }
                category
                otherCategory
                whistleblowerRelation {
                    relation
                    other
                }
                closedInformations {
                    __typename
                    date
                    statement
                }
                __typename
            }
            meta {
                page
                limit
                nextPage
                prevPage
                hasPrevPage
                hasNextPage
                totalDocs
                totalPages
                __typename
            }
        }
    }
`;

export function useGetReportsQuery(
    options?: Omit<Urql.UseQueryArgs<GetReportsQueryVariables>, 'query'>,
) {
    return Urql.useQuery<GetReportsQuery, GetReportsQueryVariables>({
        query: GetReportsDocument,
        ...options,
    });
}
export const GetReportsForDashboardDocument = gql`
    query GetReportsForDashboard($input: GetReportsForDashboardInput) {
        getReportsForDashboard(input: $input) {
            reports {
                _id
                whistleblower {
                    name
                    surname
                    __typename
                }
                whistleblowerRelation {
                    relation
                    other
                    __typename
                }
                category
                reportNumber
                createdAt
                otherCategory
                lastMessageDate
                hasNewMessages
                status
                closedInformations {
                    __typename
                    date
                    statement
                }
                messages {
                    __typename
                }
                __typename
            }
            meta {
                totalDocs
                totalPages
                __typename
            }
            counts {
                newCount
                inProgressCount
                closedCount
                __typename
            }
        }
    }
`;

export function useGetReportsForDashboardQuery(
    options?: Omit<Urql.UseQueryArgs<GetReportsForDashboardQueryVariables>, 'query'>,
) {
    return Urql.useQuery<GetReportsForDashboardQuery, GetReportsForDashboardQueryVariables>({
        query: GetReportsForDashboardDocument,
        ...options,
    });
}
export const DeleteInvitationDocument = gql`
    mutation DeleteInvitation($input: DeleteInvitationInput!) {
        deleteInvitation(input: $input) {
            success
        }
    }
`;

export function useDeleteInvitationMutation() {
    return Urql.useMutation<DeleteInvitationMutation, DeleteInvitationMutationVariables>(
        DeleteInvitationDocument,
    );
}
export const DeleteUserDocument = gql`
    mutation DeleteUser($input: DeleteUserInput!) {
        deleteUser(input: $input) {
            _id
        }
    }
`;

export function useDeleteUserMutation() {
    return Urql.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument);
}
export const InviteEmployeeDocument = gql`
    mutation InviteEmployee($input: InviteEmployeeInput!) {
        inviteEmployee(input: $input) {
            success
        }
    }
`;

export function useInviteEmployeeMutation() {
    return Urql.useMutation<InviteEmployeeMutation, InviteEmployeeMutationVariables>(
        InviteEmployeeDocument,
    );
}
export const GetCompanyUsersDocument = gql`
    query GetCompanyUsers {
        getCompanyUsers {
            invitedEmails
            users {
                _id
                name
                surname
                status
                email
                role
            }
        }
    }
`;

export function useGetCompanyUsersQuery(
    options?: Omit<Urql.UseQueryArgs<GetCompanyUsersQueryVariables>, 'query'>,
) {
    return Urql.useQuery<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>({
        query: GetCompanyUsersDocument,
        ...options,
    });
}
export const GetLoggedUserDocument = gql`
    query GetLoggedUser {
        getLoggedUser {
            __typename
            name
            surname
            role
            email
            company {
                __typename
                name
                domain
            }
        }
    }
`;

export function useGetLoggedUserQuery(
    options?: Omit<Urql.UseQueryArgs<GetLoggedUserQueryVariables>, 'query'>,
) {
    return Urql.useQuery<GetLoggedUserQuery, GetLoggedUserQueryVariables>({
        query: GetLoggedUserDocument,
        ...options,
    });
}
