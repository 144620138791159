import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { IFormData } from '../useReportForm';

export default () => {
    const {
        control,
        register,
        watch,
        formState: { errors },
        handleSubmit,
        setValue,
        getValues,
    } = useFormContext<IFormData>();

    useEffect(() => {
        register('hcaptchaToken');
    }, []);
    return {
        control,
        errors,
        watch,
        register,
        handleSubmit,
        setValue,
        getValues,
    };
};
