import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core';
import defaultTheme from '@shared/theme/default.theme';

export const StyledToast = withStyles({
    root: {
        '& .MuiAlert-message': {
            fontFamily: defaultTheme.fontFamily,
            fontSize: defaultTheme.fontSizes.m,
        },
    },
})(Snackbar);
