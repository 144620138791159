import { Get_Reports_Whistleblower } from '../generated/graphql';

export default {
    pl: {
        page: 'Strona',
        [Get_Reports_Whistleblower.Anonymous]: 'Anonim',
        [Get_Reports_Whistleblower.Public]: 'Jawny',
    },
    en: {
        page: 'Page',
        [Get_Reports_Whistleblower.Anonymous]: 'Anonymous',
        [Get_Reports_Whistleblower.Public]: 'Public',
    },
};
