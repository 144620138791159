import paths from '@shared/paths';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { ICloseCaseModalForm, ICloseCaseModalProps } from './CloseCaseModal.d';

export default function useModal(
    onSend: ICloseCaseModalProps['onSend'],
    setOpenModal: ICloseCaseModalProps['setOpenModal'],
    reexecuteGetReports: ICloseCaseModalProps['reexecuteGetReports'],
) {
    const [error, setError] = useState('');
    const intl = useIntl();
    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    } = useForm<ICloseCaseModalForm>({ mode: 'all' });
    const navigate = useNavigate();
    const sendCloseResponse = async ({ closeResponseValue }: { closeResponseValue: string }) => {
        try {
            setValue('closeResponseValue', '');
            await onSend(closeResponseValue);
            setOpenModal(false);
            reexecuteGetReports();
            navigate(paths.dashboard);
        } catch (err) {
            setError(intl.formatMessage({ id: 'validation.unkownError' }));
        }
    };

    const closeManyCasesTitle = intl.formatMessage({
        id: 'closeCaseModal.closeManyCasesTitle',
    });
    const closeOneCaseTitle = intl.formatMessage({ id: 'closeCaseModal.closeOneCaseTitle' });
    const closeManyCasesHighlightText = intl.formatMessage({
        id: 'closeCaseModal.closeManyCasesHighlight',
    });
    const closeOneCaseHighlightText = intl.formatMessage({
        id: 'closeCaseModal.closeOneCaseHighlight',
    });
    const closeManyCasesParagraphFirstPart = intl.formatMessage({
        id: 'closeCaseModal.closeManyCasesParagraphFirstPart',
    });
    const closeManyCasesParagraphSecondPart = intl.formatMessage({
        id: 'closeCaseModal.closeManyCasesParagraphSecondPart',
    });
    const closeManyCasesParagraphThirdPart = intl.formatMessage({
        id: 'closeCaseModal.closeManyCasesParagraphThirdPart',
    });
    const closeOneCaseParagraph = intl.formatMessage({
        id: 'closeCaseModal.closeOneCaseParagraph',
    });
    const closeManyCasesButtonText = intl.formatMessage({
        id: 'closeCaseModal.closeManyCasesButton',
    });
    const closeOneCaseButtonText = intl.formatMessage({
        id: 'closeCaseModal.closeOneCaseButton',
    });

    return {
        error,
        errors,
        handleSubmit,
        sendCloseResponse,
        register,
        control,
        watch,
        intl,
        closeManyCasesTitle,
        closeOneCaseTitle,
        closeManyCasesHighlightText,
        closeOneCaseHighlightText,
        closeManyCasesParagraphFirstPart,
        closeManyCasesParagraphSecondPart,
        closeManyCasesParagraphThirdPart,
        closeOneCaseParagraph,
        closeManyCasesButtonText,
        closeOneCaseButtonText,
    };
}
