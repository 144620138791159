import styled from 'styled-components';

export const ItemWrapper = styled.div`
    width: 100%;
    text-align: left;
    font-size: 15px;
    color: ${(props) => props.theme.colors.input_text_color};
    font-weight: 500;
    margin-bottom: 5px;

    ${(props) => props.theme.media.md} {
        padding-right: 50px;
        text-align: right;
    }
`;
