import { useState } from 'react';
import { useIntl } from 'react-intl';
import {
    useDeleteInvitationMutation,
    useDeleteUserMutation,
    useGetCompanyUsersQuery,
    useInviteEmployeeMutation,
    User,
    User_Role,
    User_Status,
} from 'src/generated/graphql';

export default () => {
    const [openModal, setOpenModal] = useState(false);
    const [results, reexecuteGetCompanyUsersQuery] = useGetCompanyUsersQuery();
    const [_, inviteEmployeeMutation] = useInviteEmployeeMutation();
    const [__, deleteInvitationMutation] = useDeleteInvitationMutation();
    const [___, deleteUserMutation] = useDeleteUserMutation();
    const intl = useIntl();
    const mapStatus = (status: User_Status) => {
        if (status === User_Status.Active)
            return intl.formatMessage({ id: 'usersPage.status.active' });
        if (status === User_Status.Created)
            return intl.formatMessage({ id: 'usersPage.status.created' });
        if (status === User_Status.Deleted)
            return intl.formatMessage({ id: 'usersPage.status.deleted' });
    };

    const mapRole = (role: User_Role) => {
        if (role === User_Role.Employee)
            return intl.formatMessage({ id: 'usersPage.role.employee' });
        return intl.formatMessage({ id: 'usersPage.role.maintainer' });
    };

    const mapUser = (user: User) => {
        return {
            id: user._id,
            name: `${user.name} ${user.surname}`,
            status: user.status,
            mappedStatus: mapStatus(user.status),
            email: user.email,
            role: user.role,
            mappedRole: mapRole(user.role),
        };
    };

    const mapInvitedEmail = (invitedEmail: string, index: number) => {
        return {
            id: index,
            name: '',
            status: intl.formatMessage({ id: 'usersPage.status.invited' }),
            mappedStatus: intl.formatMessage({ id: 'usersPage.status.invited' }),
            mappedRole: intl.formatMessage({ id: 'usersPage.role.employee' }),
            email: invitedEmail,
        };
    };
    const mappedUsers = results.data?.getCompanyUsers
        ? [
              ...results.data.getCompanyUsers.users.map(mapUser),
              ...results.data.getCompanyUsers.invitedEmails.map(mapInvitedEmail),
          ]
        : [];

    const inviteEmployee = async (email: string) => {
        await inviteEmployeeMutation({ input: { email } });
        reexecuteGetCompanyUsersQuery();
    };

    const deleteInvitation = async (email: string) => {
        await deleteInvitationMutation({ input: { email } });
        reexecuteGetCompanyUsersQuery();
    };

    const deleteUser = async (_id: string) => {
        await deleteUserMutation({ input: { _id } });
        reexecuteGetCompanyUsersQuery();
    };
    return {
        openModal,
        setOpenModal,
        users: mappedUsers,
        reexecuteGetCompanyUsersQuery,
        inviteEmployee,
        deleteInvitation,
        deleteUser,
    };
};
