import { LogoWrapper } from './Logo.styled';

const Logo = () => {
    return (
        <LogoWrapper>
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="320 320 2775 760"
                width="137"
            >
                <defs>
                    <style>{'.cls-2{fill:#343434}.cls-3{fill:#7400f3}'}</style>
                </defs>
                <title>{'Logo basic - on white'}</title>
                <path
                    style={{
                        fill: 'none',
                    }}
                    d="M0 0h3440v1280H0z"
                />
                <path
                    className="cls-2"
                    d="M1593.09 511.35c-87 0-149.73 62.17-149.73 148.65 0 86 62.7 148.65 149.73 148.65S1743.36 746 1743.36 660c0-86.48-63.24-148.65-150.27-148.65Zm0 230.27c-43.78 0-74.06-34-74.06-82.7 0-48.1 30.28-81.62 74.06-81.62 44.32 0 74.05 33.52 74.05 81.62 0 48.65-29.73 82.7-74.05 82.7ZM2048.2 394.6c-24.87 0-44.86 19.46-44.86 44.32s20 44.33 44.86 44.33 44.87-19.46 44.87-44.33-20-44.32-44.87-44.32ZM2010.36 520h75.67v280h-75.67zM2331.61 511.35c-37.83 0-71.34 15.68-85.94 39.46h-1.62c.54-4.32 1.08-9.73 1.08-24.32V400H2170v400h69.73v-10.81a255.15 255.15 0 0 0-1.09-25.94h1.62c17.85 28.64 53 45.4 94.06 45.4 79.46 0 135.67-61.08 135.67-148.65s-58.38-148.65-138.38-148.65Zm-13.51 230.27c-44.32 0-75.67-34-75.67-82.7s31.35-81.62 75.67-81.62 76.21 33 76.21 81.62-31.88 82.7-76.21 82.7ZM2654.73 511.35c-87 0-149.73 62.17-149.73 148.65 0 86 62.71 148.65 149.73 148.65S2805 746 2805 660c0-86.48-63.25-148.65-150.27-148.65Zm0 230.27c-43.79 0-74.05-34-74.05-82.7 0-48.1 30.26-81.62 74.05-81.62 44.32 0 74.05 33.52 74.05 81.62 0 48.65-29.73 82.7-74.05 82.7ZM3003.1 658.92 3094.45 520h-83.23l-51.89 88.11h-2.17L2904.19 520h-84.32l91.89 138.92L2819.87 800h83.78l51.35-89.73h2.16l54.06 89.73H3095l-91.9-141.08zM1160 520v280h75.67V584.87h86.49V800h75.67V597.22L1339.92 520H1160zM1875.68 448.65H1800V520h-26.48v64.87H1800v157.21l43.44 57.92h88.99v-65.95h-56.75V584.87h64.86V520h-64.86v-71.35z"
                />
                <path
                    className="cls-3"
                    d="M800 480v320H680L560 640v160h-80v120h320l120 160V480H800z"
                />
                <path className="cls-3" d="M680 600V440h80V320H320v440h120V440h120l120 160z" />
            </svg>
        </LogoWrapper>
    );
};

export default Logo;
