import { gql, useMutation } from 'urql';
import { LoginUserMutation, MutationLoginUserArgs } from 'src/generated/graphql';

const LOGIN_USER_MUTATION = gql`
    mutation LoginUser($input: LoginUserInput!) {
        loginUser(input: $input) {
            tokens {
                sessionToken
                refreshToken
            }
            user {
                company {
                    name
                }
            }
        }
    }
`;

export default () => useMutation<LoginUserMutation, MutationLoginUserArgs>(LOGIN_USER_MUTATION);
