import styled from 'styled-components';

export const ShowDetailsButton = styled.div<{ visible: boolean }>`
    position: absolute;
    left: 50%;
    margin: -16px 0 0 0;
    transform: translateX(-50%);
    top: 0;
    background: #fff;
    border-radius: 16px;
    width: auto;
    height: 30px;
    padding-right: 15px;
    border: 2px solid ${(props) => props.theme.colors.border};
    display: flex;
    align-items: center;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
    font-size: 14px;
    transition: 500ms all ease;

    &:hover {
        background: ${(props) => props.theme.colors.lightPrimary};
        border-color: ${(props) => props.theme.colors.primary};
    }

    img {
        margin-right: 5px;
        margin-left: 5px;
    }

    ${(props) =>
        props.visible &&
        `
        img {
            transform: rotate(180deg);
        }
    `}
`;
