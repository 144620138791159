import React from 'react';
import { IDashboardLayoutProps } from './DashboardLayout.d';
import { Body, Main, IconButtonStyled } from './DashboardLayout.styled';
import { DashboardHeader, Menu, Burger } from '../../_universal';
import { useMediaQuery } from '@material-ui/core';
import defaultTheme from '@shared/theme/default.theme';
import { useGetLoggedUserQuery } from 'src/generated/graphql';

export function DefaultLayout({
    bgColor,
    companyName,
    children,
}: IDashboardLayoutProps): JSX.Element {
    const isDesktop = useMediaQuery(defaultTheme.media.lg, { noSsr: true });
    const [open, setOpen] = React.useState(isDesktop);
    const [result] = useGetLoggedUserQuery();
    const nameAndSurname = `${result.data?.getLoggedUser.name} ${result.data?.getLoggedUser.surname}`;
    const handleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Body>
            <div>
                <IconButtonStyled onClick={handleDrawer}>
                    <Burger active={open} />
                </IconButtonStyled>
                <DashboardHeader companyName={companyName} nameAndSurname={nameAndSurname} />
                <Menu open={open} />
            </div>
            <Main bgColor={bgColor}>{children}</Main>
        </Body>
    );
}
export default DefaultLayout;
