import styled from 'styled-components';

export const PickedFileContainer = styled.div`
    display: flex;
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 8px;
    padding: 20px 16px;
    justify-content: space-between;
    margin-top: 8px;
    word-break: break-all;
`;

export const LeftSide = styled.div`
    display: flex;
    align-items: center;
`;

export const DeleteFileButton = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;

    > div {
        min-width: 50px;
    }
`;

export const ProgressHolder = styled.div`
    display: flex;
    align-items: center;

    div:first-child {
        position: static;
        svg {
            color: ${(props) => props.theme.colors.border};
        }
    }

    div:last-child {
        position: absolute;
        svg {
            color: ${(props) => props.theme.colors.primary};
        }
    }
`;
