import styled from 'styled-components';

export const FooterWrapper = styled.div`
    width: 100%;
    margin: 55px 0 20px;
    border-top: 1px solid ${(props) => props.theme.colors.border};
    padding-top: 22px;
`;

export const Icon = styled.div`
    width: 100%;
    text-align: center;
`;
export const IconText = styled.div`
    font-size: 14px;
    color: ${(props) => props.theme.colors.text};
    letter-spacing: 0.19px;
    width: 100%;
    text-align: center;
    margin-top: 11px;
`;

export const IconLink = styled.a`
    text-decoration: none;
    color: ${(props) => props.theme.colors.text};
    margin-top: 15px;
    display: block;

    &:hover {
        * {
            opacity: 0.8;
        }
    }
`;

export const Link = styled.a`
    display: block;
    text-align: center;
    margin: 65px auto 0;
    font-size: 14px;
    color: ${(props) => props.theme.colors.input_text_color};
    &:hover {
        opacity: 0.8;
    }
`;
