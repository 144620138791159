import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import paths from '@shared/paths';
import { useNavigate } from 'react-router';

export default () => {
    const navigate = useNavigate();
    const onLogout = () => {
        LocalStorageManager.setValue('sessionToken', '');
        navigate(paths.report);
    };

    return {
        onLogout,
    };
};
