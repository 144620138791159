import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import paths from '@shared/paths';
import React from 'react';
import { useNavigate } from 'react-router';

export default function useSelectOption() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        LocalStorageManager.clear();
        navigate(paths.home);
    };

    return {
        anchorEl,
        handleClick,
        handleClose,
        handleLogout,
    };
}
