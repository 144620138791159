import { gql, useMutation } from 'urql';
import {
    MutationReplyToReportAsWhistleblowerArgs,
    ReplyToReportAsWhistleblowerMutation,
} from 'src/generated/graphql';

const REPLY_TO_REPORT_AS_WHISTLEBLOWER = gql`
    mutation ReplyToReportAsWhistleblower($input: ReplyToReportAsWhistleblowerInput!) {
        replyToReportAsWhistleblower(input: $input) {
            _id
        }
    }
`;

export default () =>
    useMutation<ReplyToReportAsWhistleblowerMutation, MutationReplyToReportAsWhistleblowerArgs>(
        REPLY_TO_REPORT_AS_WHISTLEBLOWER,
    );
