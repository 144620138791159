import React, { useEffect } from 'react';
import useStore from '@store/useStore';
import getUserLanguage from '@utils/getUserLanguage';

export default function useLanguageSwitcher() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { setLanguage } = useStore();
    const language = getUserLanguage();

    useEffect(() => {
        setLanguage(language);
    }, [language]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return {
        handleClose,
        handleClick,
        open,
        anchorEl,
    };
}
