import styled from 'styled-components';

export const Title = styled.h1`
    font-size: 24px;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    display: flex;
    align-items: center;
`;
export const Number = styled.span`
    min-width: 40px;
    height: 40px;
    background: ${(props) => props.theme.colors.primary};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    margin-right: 14px;
    display: inline-block;
    color: ${(props) => props.theme.colors.white};
`;

export const Text = styled.span`
    display: inline-block;
    margin-top: 2px;
    line-height: 1.5;
`;
