import { Recurring_Frequency } from 'src/generated/graphql';

const recurringFrequency = {
    pl: {
        [Recurring_Frequency.SeveralTimesADay]: 'kilka razy dziennie',
        [Recurring_Frequency.SeveralTimesAMonth]: 'kilka razy w miesiącu',
        [Recurring_Frequency.SeveralTimesAWeek]: 'kilka razy w tygodniu',
        [Recurring_Frequency.SeveralTimesAYear]: 'kilka razy w roku',
    },
    en: {
        [Recurring_Frequency.SeveralTimesADay]: 'several times a day',
        [Recurring_Frequency.SeveralTimesAMonth]: 'several times a month',
        [Recurring_Frequency.SeveralTimesAWeek]: 'several times a week',
        [Recurring_Frequency.SeveralTimesAYear]: 'several times a year',
    },
};
export default recurringFrequency;
