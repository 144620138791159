import { palette } from '@shared/theme/config';
import styled from 'styled-components';

export const ShareInfoButton = styled.button<{ active?: boolean }>`
    background: ${(props) => props.theme.colors.highlight};
    border-radius: 24px;
    text-transform: none;
    font-size: 15px;
    padding: 15px 20px;
    margin-top: 20px;
    box-shadow: none;
    border: 0;
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
    margin-left: 5px;
    &:hover {
        ${(props) =>
            `
        color: ${props.theme.colors.primary};
        background: #e7d7fc;
    `};
    }
    margin-right: 5px;
    ${(props) =>
        props.active &&
        `
        color: ${props.theme.colors.primary};
        background: #e7d7fc;
    `};
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: center;
`;

export const SvgContainer = styled.div<{ biggerMargin?: boolean }>`
    height: 80px;
    width: 80px;
    background-color: ${palette.lightergrey};
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    ${(props) => props.biggerMargin && `margin: 40px auto;`}
`;
