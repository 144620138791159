import React from 'react';

export default () => {
    const [open, setOpen] = React.useState(true);

    const handleOpen = () => {
        if (!open) setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return {
        open,
        setOpen,
        handleClose,
        handleOpen,
    };
};
