import { Whistleblower_Relation } from 'src/generated/graphql';

export default {
    pl: {
        [Whistleblower_Relation.EmployeeOrPersonProvidingWorkOnABasisOtherThanAnEmploymentRelationship]:
            'Pracownik, pracownik tymczasowy lub osoba świadcząca pracę na innej podstawie niż stosunek pracy',
        [Whistleblower_Relation.JobApplicant]: 'Osoba ubiegająca się o zatrudnienie',
        [Whistleblower_Relation.FormerEmployee]: 'Były pracownik',
        [Whistleblower_Relation.ContractorOrAgency]: 'Przedsiębiorca',
        [Whistleblower_Relation.ShareholderOrPartner]: 'Akcjonariusz lub wspólnik',
        [Whistleblower_Relation.MemberOfTheGoveringBodyOfALegalPerson]:
            'Członek organu osoby prawnej',
        [Whistleblower_Relation.PersonWorkingInAnyFormForTheConstructor]:
            'Osoba świadcząca pracę pod nadzorem i kierownictwem wykonawcy, podwykonawcy lub dostawcy, w tym na podstawie umowy cywilnoprawnej',
        [Whistleblower_Relation.TraineeOrVolunteer]: 'Stażysta, wolontariusz lub praktykant',
        [Whistleblower_Relation.Other]: 'Inna relacja',
    },
    en: {
        [Whistleblower_Relation.EmployeeOrPersonProvidingWorkOnABasisOtherThanAnEmploymentRelationship]:
            'An employee, temporary employee or a person providing work on a basis other than an employment relationship',
        [Whistleblower_Relation.JobApplicant]: 'A job applicant',
        [Whistleblower_Relation.FormerEmployee]: 'Former employee',
        [Whistleblower_Relation.ContractorOrAgency]: 'Entrepreneur',
        [Whistleblower_Relation.ShareholderOrPartner]: 'Shareholder or partner',
        [Whistleblower_Relation.MemberOfTheGoveringBodyOfALegalPerson]:
            'Member of a body of a legal person',
        [Whistleblower_Relation.PersonWorkingInAnyFormForTheConstructor]:
            'A person performing work under the supervision and management of a contractor, subcontractor or supplier, including under a civil law contract',
        [Whistleblower_Relation.TraineeOrVolunteer]: 'Intern, volunteer or trainee',
        [Whistleblower_Relation.Other]: 'Other relationship',
    },
};
