import { ColorType } from '@@types/CommonTypes';

export interface ITitleProps {
    content?: string | React.ReactNode;
    type?: TITLE_TYPE;
    center?: boolean;
    color?: ColorType;
    marginTop?: CSSProperties;
    id?: string;
}
export interface ITextProps {
    center?: boolean;
    color?: ColorType;
    marginTop?: CSSProperties;
}

export enum TITLE_TYPE {
    H1 = 'H1',
    H2 = 'H2',
    H3 = 'H3',
    H4 = 'H4',
}
