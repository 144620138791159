import { DefaultLayout } from '@components/Layouts';
import { Card, Img, Button, Title } from '@components/_universal';
import img from '@assets/images/security-pana_ok.svg';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import paths from '@shared/paths';

const ResetPasswordConfirmationPage = () => {
    const navigate = useNavigate();
    return (
        <DefaultLayout>
            <Card>
                <Img src={img} alt="Hasło zostało zmienione" />
                <Title center id="resetPasswordConfirmationPage.title" />
                <Button
                    type="submit"
                    onClick={() => navigate(paths.login)}
                    size="full"
                    variant="primary"
                    icon={<AccountCircleIcon />}
                >
                    <FormattedMessage id="resetPasswordConfirmationPage.submitButton" />
                </Button>
            </Card>
        </DefaultLayout>
    );
};

export default ResetPasswordConfirmationPage;
