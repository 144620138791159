import { PasswordInput } from '@components/_form-elements/Inputs';
import { DefaultLayout } from '@components/Layouts';
import { Card, Button, Title, Img } from '@components/_universal';
import img from '@assets/images/security-pana.svg';
import { passwordRegExp } from '@utils/regexps';
import useNewPassword from './useNewPassword';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Alert } from '@material-ui/lab';
import { FormattedMessage } from 'react-intl';

const NewPasswordPage = () => {
    const { register, handleSubmit, errors, error, watch, onConfirm, intl } = useNewPassword();
    const theme = useTheme();
    const shortPasswordInputLabel = intl.formatMessage({
        id: 'newPasswordPage.passwordConfirmationShortLabel',
    });
    const longPasswordInputLabel = intl.formatMessage({
        id: 'newPasswordPage.passwordConfirmationLongLabel',
    });
    return (
        <DefaultLayout>
            <Card>
                <Img src={img} alt="Link aktywacyjny wygasł" />
                <Title center id="newPasswordPage.title" />
                <PasswordInput
                    label={intl.formatMessage({ id: 'newPasswordPage.passwordInputLabel' })}
                    error={errors.password ? true : false}
                    helperText={errors.password ? errors.password.message : undefined}
                    {...register('password', {
                        required: intl.formatMessage({
                            id: 'newPasswordPage.validation.emptyPassword',
                        }),
                        pattern: {
                            value: passwordRegExp,
                            message: intl.formatMessage({
                                id: 'newPasswordPage.validation.wrongPasswordFormat',
                            }),
                        },
                    })}
                />
                <PasswordInput
                    label={
                        useMediaQuery(theme.breakpoints.down('xs'))
                            ? shortPasswordInputLabel
                            : longPasswordInputLabel
                    }
                    error={errors.confirmPassword ? true : false}
                    helperText={errors.confirmPassword ? errors.confirmPassword.message : undefined}
                    {...register('confirmPassword', {
                        required: intl.formatMessage({
                            id: 'newPasswordPage.validation.emptyPassword',
                        }),
                        pattern: {
                            value: passwordRegExp,
                            message: intl.formatMessage({
                                id: 'newPasswordPage.passwordConfirmationLongLabel',
                            }),
                        },
                        validate: (value) =>
                            value === watch('password') ||
                            intl.formatMessage({
                                id: 'newPasswordPage.validation.differentPassword',
                            }),
                    })}
                />
                <Button size="full" variant="primary" onClick={handleSubmit(onConfirm)}>
                    <FormattedMessage id="newPasswordPage.submitButton" />
                </Button>
                {error.length > 0 && <Alert severity="error">{error}</Alert>}
            </Card>
        </DefaultLayout>
    );
};

export default NewPasswordPage;
