import { Report_Category } from 'src/generated/graphql';

export default {
    pl: {
        [Report_Category.PublicProcurement]: 'Zamówienia publiczne',
        [Report_Category.FinancialServicesProductsAndMarkets]: 'Usługi, produkty i rynki finansowe',
        [Report_Category.MoneyLaunderingAndTerroristFinancing]:
            'Zapobieganie praniu pieniędzy i finansowaniu terroryzmu',
        [Report_Category.ProductSafetyAndComplianceWithRequirements]:
            'Bezpieczeństwo produktów i ich zgodności z wymogami',
        [Report_Category.TransportSafety]: 'Bezpieczeństwo transportu',
        [Report_Category.EnvironmentalProtection]: 'Ochrona środowiska',
        [Report_Category.RadiologicalProtectionAndNuclearSafety]:
            'Ochrona radiologiczna i bezpieczeństwo jądrowe',
        [Report_Category.FoodAndFeedSafety]: 'Bezpieczeństwo żywności i pasz',
        [Report_Category.AnimalHealthAndWelfare]: 'Zdrowie i dobrostan zwierząt',
        [Report_Category.PublicHealth]: 'Zdrowie publiczne',
        [Report_Category.ConsumerProtection]: 'Ochrona konsumenta',
        [Report_Category.ProtectionOfPrivacyAndPersonalData]:
            'Ochrona prywatności i danych osobowych',
        [Report_Category.SecurityOfNetworksAndIctSystems]:
            'Bezpieczeństwo sieci i systemów teleinformatycznych',
        [Report_Category.FinancialInterestsOfTheEu]:
            'Interesy finansowe skarbu państwa Rzeczypospolitej Polskiej oraz Unii Europejskiej',
        [Report_Category.InternalMarketOfTheEuIncludingPublicLawRulesRegardingStateAidAndLegalSolutions]:
            'Rynku wewnętrznego Unii Europejskiej, w tym publicznoprawnych zasad konkurencji i pomocy państwa oraz opodatkowania osób prawnych',
        [Report_Category.ConstitutionalFreedomsAndHumanAndCitizenRights]:
            'Konstytucyjne wolnosci i prawa czlowieka i obywatela',
        [Report_Category.Corruption]: 'Korupcja',
        [Report_Category.Other]: 'Inne',
    },
    en: {
        [Report_Category.PublicProcurement]: 'Public procurement',
        [Report_Category.FinancialServicesProductsAndMarkets]:
            'Financial services, products and markets',
        [Report_Category.MoneyLaunderingAndTerroristFinancing]:
            'Prevention of money laundering and terrorist financing',
        [Report_Category.ProductSafetyAndComplianceWithRequirements]:
            'Product safety and compliance with the requirements',
        [Report_Category.TransportSafety]: 'Transport safety',
        [Report_Category.EnvironmentalProtection]: 'Environmental Protection',
        [Report_Category.RadiologicalProtectionAndNuclearSafety]:
            'Radiological protection and nuclear safety',
        [Report_Category.FoodAndFeedSafety]: 'Food and feed safety',
        [Report_Category.AnimalHealthAndWelfare]: 'Animal health and welfare',
        [Report_Category.PublicHealth]: 'Public health',
        [Report_Category.ConsumerProtection]: 'Consumer protection',
        [Report_Category.ProtectionOfPrivacyAndPersonalData]:
            'Protection of privacy and personal data',
        [Report_Category.SecurityOfNetworksAndIctSystems]: 'Security of networks and ICT systems',
        [Report_Category.FinancialInterestsOfTheEu]:
            'Financial interest of the Republic of Poland and the European Union',
        [Report_Category.InternalMarketOfTheEuIncludingPublicLawRulesRegardingStateAidAndLegalSolutions]:
            'Internal market of the European Union, including public law rules regarding state aid and legal solutions',
        [Report_Category.ConstitutionalFreedomsAndHumanAndCitizenRights]:
            'Constitutional freedoms and human and citizen rights',
        [Report_Category.Corruption]: 'Corruption',
        [Report_Category.Other]: 'Other',
    },
};
