import { gql, useQuery } from 'urql';
import { Query } from 'src/generated/graphql';

const GET_REPORTS = gql`
    query GetReports($input: GetReportsInput) {
        getReports(input: $input) {
            reports {
                _id
                reportNumber
                createdAt
                status
                whistleblower {
                    name
                    surname
                    email
                    phone
                }
                category
                otherCategory
                whistleblowerRelation {
                    relation
                    other
                }
                closedInformations {
                    __typename
                    date
                    statement
                }
                __typename
            }
            meta {
                page
                limit
                nextPage
                prevPage
                hasPrevPage
                hasNextPage
                totalDocs
                totalPages
                __typename
            }
        }
    }
`;

export default () => useQuery<Query['getReports']>({ query: GET_REPORTS });
