import * as Icons from '@shared/svgs/Icons';
import defaultTheme from '@shared/theme/default.theme';
import paths from '@shared/paths';

export enum EnvType {
    development = 'development',
    production = 'production',
}

export enum Theme {
    DEFAULT = 'DEFAULT',
    DARK = 'DARK',
}

export type ThemeType = typeof defaultTheme;
export type ColorType = keyof ThemeType['colors'];
export type FontWeights = keyof ThemeType['fontWeight'];
export type FontSizes = keyof ThemeType['fontSizes'];

export type IconName = keyof typeof Icons;

export type ButtonTypes = 'primary' | 'secondary';

export type FieldTypes = 'text' | 'email' | 'number' | 'password';
