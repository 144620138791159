import styled from 'styled-components';

export const ActionWrapper = styled.div`
    position: sticky;
    background: #fff;
    z-index: 1;
    bottom: 0;
    width: calc(100% + 30px);
    margin-left: -15px;
    height: 104px;
    padding: 0 24px;

    ${(props) => props.theme.media.md} {
        width: 100%;
        margin-left: 0;
    }

    &::after {
        content: '';
        width: 100%;
        height: 50px;
        background: linear-gradient(
            -180deg,
            rgba(255, 255, 255, 0.3) 0%,
            rgba(255, 255, 255, 1) 100%
        );
        position: absolute;
        top: -50px;
        left: 0;
        pointer-events: none;
    }
`;

export const ButtonWrapper = styled.div<{ snapToRight?: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    height: 104px;
    ${(props) => props.snapToRight && 'justify-content: flex-end;'}
`;
