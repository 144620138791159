import { Container, Grid } from '@material-ui/core';
import { HeaderWrapper, DisplayFlex, Link } from './Header.styled';
import Logo from '../Logo/Logo';
import Button from '../Button/Button';
import LogoText from '../LogoText/LogoText';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { PageTitle } from '../DashboardHeader/PageTitle/PageTitle';
import { IHeaderProps } from './Header.d';
import useHeader from './useHeader';
import { FormattedMessage } from 'react-intl';
import CompanyLogo from '../CompanyLogo/CompanyLogo';

const Header = ({ companyName, companyLogo, pageTitle, displayLogOut }: IHeaderProps) => {
    const { onLogout } = useHeader();

    return (
        <HeaderWrapper>
            <Container>
                <Grid container justifyContent="space-between">
                    <DisplayFlex>
                        <Link href={`/`} borderRight={Boolean(pageTitle)}>
                            {companyLogo ? (
                                <CompanyLogo src={companyLogo} alt="company logo" />
                            ) : companyName ? (
                                <LogoText cutLength={Boolean(pageTitle)}>{companyName}</LogoText>
                            ) : (
                                <Logo />
                            )}
                        </Link>
                        {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
                    </DisplayFlex>
                    <DisplayFlex>
                        {displayLogOut ? (
                            <>
                                <LanguageSwitcher />
                                <Button variant="outlined" className="bold" onClick={onLogout}>
                                    <FormattedMessage id="userMenu.logout" />
                                </Button>
                            </>
                        ) : (
                            <LanguageSwitcher />
                        )}
                    </DisplayFlex>
                </Grid>
            </Container>
        </HeaderWrapper>
    );
};

export default Header;
