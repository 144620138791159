import { Modal } from '@material-ui/core/';
import { Title, Button, Paragraph } from '@components/_universal';
import { IModalProps } from './UploadModal.d';
import { ModalBody, CloseButton, ButtonsWrapper } from '../Modal/Modal.styled';
import { TITLE_TYPE } from '@components/_universal/Title/Title.d';
import useUploadModal from './useUploadModal';
import { useMediaQuery } from '@material-ui/core';
import defaultTheme from '@shared/theme/default.theme';
import CloseIcon from '@components/_universal/CloseIcon/CloseIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import ModalStatus from '../ModalStatus/ModalStatus';
import FilesUploader from '@components/_universal/FilesUploader/FilesUploader';
import { useAddAttachmentsToExistingReport } from '@state/reports';

const FIVE_SECONDS = 1000 * 5;

export const UploadModal = ({
    openUploadModal,
    setOpenUploadModal,
    reportNumber,
    onRefresh,
    sendReportNumber,
}: IModalProps) => {
    const isTablet = useMediaQuery(defaultTheme.media.md, { noSsr: true });
    const {
        files,
        onDelete,
        handleFilesChange,
        fileProgresses,
        error,
        isSent,
        setIsSent,
        closeModal,
    } = useUploadModal(reportNumber, setOpenUploadModal);
    const [_, generateReportAttachmentsUploadLinks] = useAddAttachmentsToExistingReport();

    const intl = useIntl();
    const modalTitleText = intl.formatMessage({
        id: 'checkReportPage.uploadFileModal.successInfo',
    });
    const modalButtonText = intl.formatMessage({
        id: 'checkReportPage.uploadFileModal.closeButton',
    });
    return (
        <div>
            <Modal open={openUploadModal} onClose={closeModal}>
                <ModalBody>
                    {!isSent ? (
                        <>
                            <CloseButton onClick={closeModal}>
                                <CloseIcon />
                            </CloseButton>
                            <Title
                                center
                                type={TITLE_TYPE.H1}
                                id="checkReportPage.uploadFileModal.title"
                            />
                            <Paragraph fontSize="14px" marginBottom="10px">
                                <FormattedMessage id="checkReportPage.uploadFileModal.description" />
                            </Paragraph>
                            <FilesUploader
                                files={files}
                                onDelete={onDelete}
                                handleChange={handleFilesChange}
                                fileProgresses={fileProgresses}
                                error={error}
                            />
                            <ButtonsWrapper>
                                <Button size={isTablet ? 'small' : 'full'} onClick={closeModal}>
                                    <FormattedMessage id="checkReportPage.uploadFileModal.cancelButton" />
                                </Button>
                                <span></span>
                                <Button
                                    size={isTablet ? 'small' : 'full'}
                                    variant="primary"
                                    disabled={files.length === 0}
                                    onClick={() => {
                                        generateReportAttachmentsUploadLinks({
                                            input: {
                                                attachments: files.map((file) => file.fileId),
                                                reportNumber: sendReportNumber
                                                    ? reportNumber
                                                    : undefined,
                                            },
                                        });
                                        closeModal();
                                        setTimeout(onRefresh, FIVE_SECONDS);
                                    }}
                                >
                                    <FormattedMessage id="checkReportPage.uploadFileModal.sendButton" />
                                </Button>
                            </ButtonsWrapper>
                        </>
                    ) : (
                        <ModalStatus
                            setOpenModal={setOpenUploadModal}
                            openModal={openUploadModal}
                            setStatus={setIsSent}
                            titleText={modalTitleText}
                            closeButtonText={modalButtonText}
                        />
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default UploadModal;
