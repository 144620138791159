import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import defaultTheme from '../../../../shared/theme/default.theme';
export const NumberOfReportsContainer = styled.div`
    background-color: ${(props) => props.theme.colors.lighterGrey};
    border-radius: 7px;
    padding: 7px 0;
    display: flex;
    justify-content: center;
`;

export const NumberOfReportsTextContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const NumberOfReportsText = styled.p`
    background-color: ${(props) => props.theme.colors.lighterGrey};
    color: ${(props) => props.theme.colors.black};
    font-weight: ${(props) => props.theme.fontWeight.middleBold};
    font-size: ${(props) => props.theme.fontSizes.mm}px;
    margin-right: 3px;
`;

export const NumberOfReportsCount = styled.p`
    color: ${(props) => props.theme.colors.darkBlue};
    font-size: ${(props) => props.theme.fontSizes.mm}px;
    font-weight: ${(props) => props.theme.fontWeight.middleBold};
`;

export const CheckboxesContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 10px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    padding: 24px 0px;
    justify-content: flex-end;
`;

export const useStyles = makeStyles(() => ({
    label: {
        fontFamily: defaultTheme.fontFamily,
        fontSize: defaultTheme.fontSizes.mm,
        fontWeight: defaultTheme.fontWeight.semiBold,
    },
}));
