import styled from 'styled-components';

export const CardWrapper = styled.div<{ wide?: boolean; shadow?: boolean }>`
    position: relative;
    flex: 0 0 auto;
    width: 100%;
    border-radius: 24px;
    background: #fff;

    ${(props) => props.theme.media.md} {
        padding: 24px;
        border-radius: 0;
        box-shadow: none;
        max-width: ${(props) => (props.wide ? '800px' : '568px')};
        box-shadow: ${(props) => (props.shadow ? '0 0 30px 0 rgba(141, 151, 158, 0.2);' : 'none')};
    }
`;
