import { DefaultLayout } from '@components/Layouts';
import { Title, Button, Card, Paragraph } from '@components/_universal';
import { TextInput, PasswordInput, Checkbox } from '@components/_form-elements/Inputs';
import Alert from '@material-ui/lab/Alert';
import useEmailRegistration from './useRegistration';
import { emailRegExp, passwordRegExp } from '@utils/regexps';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';

const RegistrationPage = () => {
    const query = new URLSearchParams(useLocation().search);
    const invitedEmail = query.get('email');
    const token = query.get('token');

    const {
        signup,
        error,
        errors,
        handleSubmit,
        register,
        registrationInProgress,
        setValue,
        intl,
    } = useEmailRegistration(token, invitedEmail);

    return (
        <DefaultLayout enablePaddingBottomOnMobile>
            <Card>
                <Title center={true} id="registrationPage.registrationTitle" />
                <form>
                    <TextInput
                        error={Boolean(errors.name)}
                        helperText={errors.name ? errors.name.message : undefined}
                        label={intl.formatMessage({
                            id: 'registrationPage.nameInputLabel',
                        })}
                        {...register('name', {
                            required: intl.formatMessage({
                                id: 'registrationPage.validation.emptyName',
                            }),
                            minLength: {
                                value: 3,
                                message: intl.formatMessage(
                                    {
                                        id: 'registrationPage.validation.nameLength',
                                    },
                                    { chars: 3 },
                                ),
                            },
                        })}
                    />
                    <TextInput
                        error={Boolean(errors.surname)}
                        helperText={errors.surname ? errors.surname.message : undefined}
                        label={intl.formatMessage({
                            id: 'registrationPage.surnameInputLabel',
                        })}
                        {...register('surname', {
                            required: intl.formatMessage({
                                id: 'registrationPage.validation.emptySurname',
                            }),
                            minLength: {
                                value: 3,
                                message: intl.formatMessage(
                                    {
                                        id: 'registrationPage.validation.surnameLength',
                                    },
                                    { chars: 3 },
                                ),
                            },
                        })}
                    />
                    <TextInput
                        error={Boolean(errors.email)}
                        helperText={errors.email ? errors.email.message : undefined}
                        label={intl.formatMessage({
                            id: 'registrationPage.emailInputLabel',
                        })}
                        type="email"
                        disabled={Boolean(invitedEmail)}
                        {...register('email', {
                            required: intl.formatMessage({
                                id: 'registrationPage.validation.emptyEmail',
                            }),
                            pattern: {
                                value: emailRegExp,
                                message: intl.formatMessage({
                                    id: 'registrationPage.validation.wrongEmail',
                                }),
                            },
                        })}
                    />
                    <PasswordInput
                        error={Boolean(errors.password)}
                        label={intl.formatMessage({
                            id: 'registrationPage.passwordInputLabel',
                        })}
                        showSingleErrors
                        {...register('password', {
                            pattern: passwordRegExp,
                            required: intl.formatMessage({
                                id: 'registrationPage.validation.emptyPassword',
                            }),
                        })}
                    />
                    <Paragraph fontSize="12px" marginTop="20px" marginBottom="20px">
                        <FormattedMessage id="registrationPage.policyAcceptation" />
                        &nbsp;
                        <a href="https://notibox.pl/work/privacypolicy">
                            <FormattedMessage id="registrationPage.privacyPolicy" />
                        </a>
                    </Paragraph>
                    <Button
                        type="submit"
                        size="full"
                        variant="primary"
                        onSubmit={handleSubmit(signup)}
                        onClick={handleSubmit(signup)}
                        disabled={registrationInProgress}
                    >
                        {invitedEmail ? (
                            <FormattedMessage id="registrationPage.inviteSubmit" />
                        ) : (
                            <FormattedMessage id="registrationPage.submitButton" />
                        )}
                    </Button>
                </form>
                <Paragraph fontSize="12px" marginTop="20px" marginBottom="10px">
                    <FormattedMessage id="registrationPage.marketingConsentsInformations" />
                </Paragraph>
                <Checkbox
                    label={intl.formatMessage({
                        id: 'registrationPage.marketingConsentsCheckbox',
                    })}
                    {...register('marketingConsent', {})}
                    onChange={(value) => setValue('marketingConsent', value)}
                />
                {error.length > 0 ? <Alert severity="error">{error}</Alert> : <></>}
            </Card>
        </DefaultLayout>
    );
};

export default RegistrationPage;
