import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Wrapper = styled.div<{
    $enablePaddingBottomOnMobile?: boolean;
}>`
    padding: ${(props) => (props.$enablePaddingBottomOnMobile ? '20px 0 40px' : '20px 0 0')};

    ${(props) => props.theme.media.md} {
        padding: 30px 0;
    }
`;

export const FullHeightGrid = styled(Grid)<{
    $setFullHeight?: boolean;
    $alignItemsCenter?: boolean;
}>`
    ${(props) => props.$setFullHeight && 'min-height: calc(100vh - 132px);'};
    align-items: ${(props) => (props.$alignItemsCenter ? 'flex-start' : 'normal')};

    ${(props) => props.theme.media.md} {
        align-items: ${(props) => (props.$alignItemsCenter ? 'center' : 'normal')};
    }
`;
