import styled from 'styled-components';

export const DomainWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 62px;
    padding-left: 10px;
    align-items: center;
    font-size: ${(props) => props.theme.fontSizes.m};
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
`;
