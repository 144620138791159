export const en = {
    'loginPage.loginTitle': 'Sign in',
    'loginPage.emailInputLabel': 'E-mail',
    'loginPage.passwordInputLabel': 'Password',
    'loginPage.loginButton': 'Sign in',
    'loginPage.forgottenLink': 'Forgot Password',
    'loginPage.dontHaveAccount': "Don't have an account yet?",
    'loginPage.createAccount': 'Create an account',
    'loginPage.validation.loginError': 'Incorrect login details or account does not exist',
    'loginPage.validation.emptyEmail': "Email can't be blank",
    'loginPage.validation.wrongEmail': 'Invalid email address',
    'loginPage.validation.emptyPassword': "Password can't be blank",
    'registrationPage.registrationTitle': 'Create an account',
    'registrationPage.nameInputLabel': 'Name',
    'registrationPage.validation.emptyName': 'First Name is required',
    'registrationPage.validation.nameLength': 'First Name must be at least {chars} characters',
    'registrationPage.surnameInputLabel': 'Surname',
    'registrationPage.validation.emptySurname': 'Surname is required ',
    'registrationPage.validation.surnameLength': 'Surname must be at least {chars} characters',
    'registrationPage.emailInputLabel': 'E-mail',
    'registrationPage.validation.emptyEmail': "Email can't be blank",
    'registrationPage.validation.wrongEmail': 'Invalid email address',
    'registrationPage.passwordInputLabel': 'Password',
    'registrationPage.validation.emptyPassword': "Password can't be blank",
    'registrationPage.validation.passwordChars': 'Minimum number of characters is {chars}',
    'registrationPage.validation.passwordNumber': 'Includes a digit',
    'registrationPage.validation.passwordSpecialChar': 'Includes special characters ',
    'registrationPage.validation.passwordSmallAndCapitalLetters':
        'Includes upper and lower case letters ',
    'registrationPage.policyAcceptation':
        'By clicking the submit button below, I hereby agree to and accept the following terms',
    'registrationPage.privacyPolicy': 'Privacy Policy',
    'registrationPage.submitButton': 'Agree and continue',
    'registrationPage.inviteSubmit': 'Agree and register',
    'registrationPage.marketingConsentsInformations':
        'We will send you marketing emails and information about offers. You can unsubscribe at any time in your account settings or in a marketing message. ',
    'registrationPage.marketingConsentsCheckbox':
        'I want to receive marketing messages from Notibox ',
    'registrationActivationPage.title': 'Activate your account',
    'registrationActivationPage.emailSent': 'We sent an e-mail to the following address',
    'registrationActivationPage.openYourEmailBox':
        'Open your email inbox and click the link in the email to activate your account.',
    'registrationActivationPage.spam': "If you can't find our email please check your spam folder.",
    'registrationActivationPage.sendNewEmail': 'Send a new confirmation message ',
    'registrationActivationPage.emailSentAgain': 'Message sent again',
    'registrationCompletePage.title': 'Fill your company information',
    'registrationCompletePage.companyNameInputLabel': 'Company/Institution name',
    'registrationCompletePage.companyShortNameInputLabel': 'Company/Institution short name',
    'registrationCompletePage.validation.emptyCompanyName': 'Company name is required',
    'registrationCompletePage.validation.emptyCompanyShortName': 'Company short name is required',
    'registrationCompletePage.companyShortNameDescription':
        'Ta nazwa będzie widoczna w skrzynce odbiorczej ze zgłoszeniami',
    'registrationCompletePage.validation.companyShortNameMinLength':
        'Company short name should have at least {chars} letters',
    'registrationCompletePage.validation.companyShortNameMaxLength':
        'Company short name should have maximum {chars} letters',
    'registrationCompletePage.validation.companyNameLength':
        'Company name should have at least {chars} letters',
    'registrationCompletePage.validation.wrongVatEuNumber':
        'Incorrect Tax Identification Number for your country.',
    'registrationCompletePage.validation.existVatEuNumberOrSubdomain':
        'The Tax Identification Number or subdomain already exist.',
    'registrationCompletePage.validation.tokenExpired': 'Token is not longer valid.',
    'registrationCompletePage.validation.emptyVatEu': 'Tax Identification Number is required',
    'registrationCompletePage.validation.wrongVatEu': 'Incorrect Tax Identification Number format',
    'registrationCompletePage.addressTitle': 'Billing address',
    'registrationCompletePage.streetInputLabel': 'Street',
    'registrationCompletePage.validation.emptyStreet': 'Street is required',
    'registrationCompletePage.validation.streetLength':
        'Street should have at least {chars} letters',
    'registrationCompletePage.buildingNumberLabel': 'Building number',
    'registrationCompletePage.validation.emptyBuldingNumber': 'The building number is required ',
    'registrationCompletePage.validation.buldingNumberLength':
        'The building number should has at least {chars} letters',
    'registrationCompletePage.apartmentNumberLabel': 'Apartment number',
    'registrationCompletePage.cityLabel': 'City',
    'registrationCompletePage.validation.emptyCity': 'City is required',
    'registrationCompletePage.validation.cityLength': 'City should has at least {chars} letters',
    'registrationCompletePage.postCodeLabel': 'Postcode',
    'registrationCompletePage.countryLabel': 'Country',
    'registrationCompletePage.validation.emptyCountry': 'Country is required',
    'registrationCompletePage.subdomainTitle': 'Subdomain',
    'registrationCompletePage.subdomainLabel': 'Label of your subdomain',
    'registrationCompletePage.validation.emptySubdomain': 'Subdomain is required',
    'registrationCompletePage.validation.subdomainLength':
        'Subdomain should have at least {chars} letters',
    'registrationCompletePage.whistleblowerChannel':
        'The reporting channel for your whistleblowers will be available at this web address. ',
    'registrationCompletePage.continueButton': 'Continue',
    'registrationCompletePage.toastText': 'The account has been created',
    'registrationLinkExpiredPage.title': 'The activation link has expired ',
    'registrationLinkExpiredPage.accountNotCreated':
        'Your account has not been created. Try again.',
    'registrationLinkExpiredPage.createAccountButton': 'Create an account',
    'registrationPaymentPage.title': "Make the payment and you're done! ",
    'registrationPaymentPage.subtitle': 'Your reporting channel has been activated',
    'registrationPaymentPage.shareAddress':
        'Share this web address with your employees and contractors. From now on, you can freely manage notifications from the Notibox inbox.',
    'registrationPaymentPage.trialInfo':
        'The reporting channel will remain active for another 14 days. ',
    'registrationPaymentPage.paymentInstructions':
        'During this time, make a subscription fee in the amount agreed upon with our sales department. Failure to make a payment within the allotted time will result in the suspension of access to the report channel and your Notibox inbox. Please make a payment to the following bank account details, providing the e-mail address used in the registration in the transfer title:',
    'registrationPaymentPage.receiverName': "Recipient's name:",
    'registrationPaymentPage.accountNumber': 'Account number:',
    'registrationPaymentPage.swiftCode': 'BIC SWIFT code:',
    'registrationPaymentPage.paymentTitle': 'Transfer title:',
    'registrationPaymentPage.emailNotification':
        'We will notify you by e-mail when the payment is accounted for. At the same time, we will activate the service for the full billing period. ',
    'registrationPaymentPage.questions':
        'Do you have any questions? Contact us: kontakt@notibox.pl, 508 320 393',
    'resetPasswordPage.title': 'Change Password',
    'resetPasswordPage.emailParagraph':
        'Enter your email address associated with your Notibox account. We will send a link to change your password to this address.',
    'resetPasswordPage.emailInputLabel': 'E-mail adress',
    'resetPasswordPage.validation.emptyEmail': "Email can't be blank",
    'resetPasswordPage.validation.wrongEmail': 'Invalid email address',
    'resetPasswordPage.submitButton': 'Send a link',
    'resetPasswordPage.cancelButton': 'Cancel',
    'resetPasswordNotify.title': 'Open the link to reset your password',
    'resetPasswordNotify.emailSent': 'We have sent an e-mail to the address ',
    'resetPasswordNotify.openEmailParagraph':
        'Open your mailbox and click the link in the email to change your password. ',
    'resetPasswordNotify.checkSpam': "If you can't find our email please check your spam.",
    'resetPasswordConfirmationPage.title': 'Password has been changed',
    'resetPasswordConfirmationPage.submitButton': 'Sign in',
    'newPasswordPage.title': 'Change Password',
    'newPasswordPage.passwordInputLabel': 'New password',
    'newPasswordPage.validation.emptyPassword': "Password can't be blank",
    'newPasswordPage.validation.wrongPasswordFormat':
        'The password must contain a minimum of 8 characters, upper and lower case letter, a special character and a digit.',
    'newPasswordPage.passwordConfirmationShortLabel': 'Please re-enter your password',
    'newPasswordPage.passwordConfirmationLongLabel': 'Please re-enter your new password',
    'newPasswordPage.validation.differentPassword': 'Passwords vary',
    'newPasswordPage.submitButton': 'Change Password',
    'resetLinkExpiredPage.title': 'Password has not been changed',
    'resetLinkExpiredPage.alert': 'The link used to change the password has expired.',
    'resetLinkExpiredPage.provideEmailAgain':
        'Please re-enter the email address associated with your Notibox account. We will send you a new link to change your password to this address.',
    'resetLinkExpiredPage.emailInputLabel': 'E-mail',
    'resetLinkExpiredPage.validation.emptyEmail': "Email can't be blank",
    'resetLinkExpiredPage.validation.wrongEmail': 'Invalid email address',
    'resetLinkExpiredPage.validation.submitButton': 'Send a link',
    'reportPage.title': 'Safe whistleblower channel',
    'reportPage.secondTitleValid':
        'We encourage you to report cases of misconduct in our organization.',
    'reportPage.secondTitleInvalid':
        'Oops. The domain of the organization to which you are trying to report misconduct is invalid.',
    'reportPage.highlightText':
        'The information you provide is secure and encrypted. Your identity remains completely anonymous. It is up to you to decide if you want to disclose your data. ',
    'reportPage.paragraph':
        'Only specially designated people in the organization will have access to your report.',
    'reportPage.reportButton': 'Report an incident',
    'reportPage.checkReportStatusButton': 'Check status / update the case',
    'reportPage.footer.needHelp': 'You need help?',
    'reportPage.footer.phoneIconText': 'Call us',
    'reportPage.footer.emailIconText': 'Write an e-mail',
    'reportPage.footer.privacyPolicy': 'Privacy policy',
    'reportForm.nextStepButton': 'Next',
    'reportForm.skipStepButton': 'Skip it',
    'reportForm.stepOne.title': 'What is your relationship with {companyShortName}?',
    'reportForm.stepOne.textInputLabel': 'Specify the relationship',
    'reportForm.stepTwo.textInputLabel': 'Specify the category',
    'reportForm.stepTwo.title': 'What does the reported case concern?',
    'reportForm.stepThree.title': 'Do you know about other people involved in the case?',
    'reportForm.stepThree.paragraph':
        'For example, they can be victims, witnesses or perpetrators.',
    'reportForm.stepThree.addPersonButton': 'Add another person',
    'reportForm.stepFive.title': 'Describe the reported case',
    'reportForm.stepFive.paragraph':
        'Please describe the exact incident or problem you want to share with us. Remember that a lot of details will help to resolve your case quickly and reliably',
    'reportForm.stepFive.messageLabel': 'Description of the incident or problem',
    'reportForm.stepFive.validation.emptyMessage': 'This field is required.',
    'reportForm.stepFive.validation.minLength': 'This field should have at least {chars} letters',
    'reportForm.stepFive.validation.maxLength':
        'This field should have a maximum of {chars} characters.',
    'reportForm.stepFive.attachmentsFirstParagraph':
        'Do you want to attach evidence or other material related to the case? ',
    'reportForm.stepFive.attachmentsSecondParagraph':
        'Attachments can be photos, recordings, documents, etc.',
    'reportForm.stepFive.addAttachmentButton': 'Add an attachment ',
    'reportForm.stepFive.validation.tooBigFile':
        'The size of this file is too large, try to compress or trim it.',
    'reportForm.stepFive.validation.wrongFileType': 'File format not supported.',

    'reportForm.stepFour.title': 'When was the incident first witnessed?',
    'reportForm.stepFour.dateParagraph':
        'If you remember a precise date and / or approximate time, please enter them in the fields below:',
    'reportForm.stepFour.recurringParagraph': 'Was the described incident repeated?',
    'reportForm.stepFour.emptyDateLabel': 'Date',
    'reportForm.stepFour.emptyHourLabel': 'Time',
    'reportForm.stepFour.radioYesLabel': 'Yes',
    'reportForm.stepFour.radioNoLabel': 'No',
    'reportForm.stepFour.recurringFrequencyLabel': 'The frequency of the incidents',
    'reportForm.stepSix.title': 'Do you want to share your data with us?',
    'reportForm.stepSix.notShareButton': "Don't share",
    'reportForm.stepSix.shareButton': 'Share',
    'reportForm.stepSix.anonymousParagraph':
        'None of your data will be shared with us. The report is completely anonymous.',
    'reportForm.stepSix.notAnonymousParagraph':
        'Data provision is not required. However, they may help us to contact you about your report. ',
    'reportForm.stepSix.highlight':
        'In case of refusal, we will contact you via this platform only.',
    'reportForm.stepSix.sharingDataParagraph':
        'Your data will be shared with us. Decide what do you want to share. Only specially designated people in the organization will have access to them.',
    'reportForm.stepSix.nameLabel': 'Name',
    'reportForm.stepSix.validation.emptyName': 'First Name is required',
    'reportForm.stepSix.validation.nameMinLength': 'First Name must be at least {chars} characters',
    'reportForm.stepSix.surnameLabel': 'Surname',
    'reportForm.stepSix.validation.emptySurname': 'Surname is required',
    'reportForm.stepSix.validation.surnameMinLength': 'Surname must be at least {chars} characters',
    'reportForm.stepSix.emailInputLabel': 'E-mail',
    'reportForm.stepSix.validation.wrongEmail': "Email can't be blank",
    'reportForm.stepSix.phone': 'Phone number',
    'reportForm.stepSeven.sendReportButton': 'Send your report',
    'reportForm.stepSeven.title': 'Create a password to access for your report',
    'reportForm.stepSeven.highlight': "Remember it well, you won't be able to change it later.",
    'reportForm.stepSeven.passwordParagraph':
        'Password will be required to view our response to your report',
    'reportForm.stepSeven.passwordLabel': 'Password',
    'reportForm.stepSeven.validation.emptyPassword': "Password can't be blank",
    'reportForm.stepSeven.validation.wrongPasswordFormat':
        'The password must contain a minimum of 8 characters, upper and lower case letters, a special character and a digit.',
    'reportForm.stepSeven.passwordConfirmationLabel': 'Please re-enter your password',
    'reportForm.stepSeven.validation.differentPasswords': 'Passwords vary',
    'reportSubmittedPage.title': 'Your report has been sent',
    'reportSubmittedPage.downloadReport': 'Download report',
    'reportSubmittedPage.highlightText':
        'Please, note the password you created and the case number below. They will be required to view our response to your report.',

    'reportSubmittedPdf.confirmationText': 'Report submission confirmation',
    'reportSubmittedPdf.reportNumber': 'Report number: {reportNumber}',
    'reportSubmittedPdf.date': 'Date: {date}',
    'reportSubmittedPdf.attachments': 'Attachments:',
    'reportSubmittedPdf.attachments.yes': 'Yes',
    'reportSubmittedPdf.attachments.no': 'No',
    'reportSubmittedPdf.message': 'Message:',
    'reportSubmittedPdf.checkReportText': 'Check if your report has an answer on:',
    'reportSubmittedPdf.loginUrlText': 'Log in using report number and your password.',
    'reportSubmittedPdf.linkText':
        'Using report number and password You can check report status and add more information on page:',

    'dashboardPageMenu.reports': 'Reports',
    'dashboardPageMenu.settings': 'Settings',
    'dashboardPageMenu.users': 'Users',
    'dashboardPageMenu.new': 'New',
    'dashboardPageMenu.inProgress': 'In progress',
    'dashboardPageMenu.closed': 'Closed',
    'dashboardPageMenu.reportsSummary': 'Reports summary',
    'dashboardPage.header.title': 'Inbox',
    'dashboardPage.titles.newReports': 'New reports',
    'dashboardPage.titles.reportsInProgress': 'Reports in progress',
    'dashboardPage.titles.closedReports': 'Closed reports',
    'dashboardPage.reporter': 'Whistleblower',
    'dashboardPage.relation': 'Relationship',
    'dashboardPage.category': 'Category',
    'dashboardPage.reportNumber': 'Number',
    'dashboardPage.lastMessage': 'Last message',
    'dashboardPage.createdDate': 'Report date',
    'dashboardPage.selectOption.selection.none': 'None',
    'dashboardPage.selectOption.selection.all': 'All',
    'dashboardPage.selectOption.selection.read': 'Read',
    'dashboardPage.selectOption.selection.unread': 'Unread',
    'dashboardPage.selectOption.status.new': 'New',
    'dashboardPage.selectOption.status.inProgress': 'In progress',
    'dashboardPage.selectOption.status.closed': 'Closed',
    'dashboardPage.reportsGrid.noRowsLabel': 'No reports',
    'dashboardPage.actionBar.refresh': 'Refresh',
    'dashboardPage.actionBar.sorting.newest': 'Recent',
    'dashboardPage.actionBar.sorting.oldest': 'Earliest',
    'dashboardPage.footer.oneSelectedReport': 'Selected report',
    'dashboardPage.footer.manySelectedReports': 'Selected reports',

    'usersPage.title': 'Users',
    'usersPage.name': 'Name',
    'usersPage.status': 'Status',
    'usersPage.email': 'Email',
    'usersPage.role': 'Role',
    'usersPage.action': 'Action',
    'usersPage.deleteButton': 'Delete',
    'usersPage.addButton': 'Add user',
    'usersPage.modal.title': 'Add user',
    'usersPage.emailInputLabel': 'E-mail',
    'usersPage.validation.emptyEmail': "Email can't be blank",
    'usersPage.validation.wrongEmail': 'Invalid email address',
    'usersPage.submitButton': 'Invite',
    'usersPage.modal.titleSuccess': 'Success',
    'usersPage.modal.successDesc': 'Invitation sent',
    'usersPage.continueButton': 'Continue',
    'usersPage.status.active': 'Active',
    'usersPage.status.created': 'Inactive',
    'usersPage.status.deleted': 'Deleted',
    'usersPage.status.invited': 'Invited',
    'usersPage.role.maintainer': 'Maintainer',
    'usersPage.role.employee': 'Employee',
    'usersPage.modal.confirmInvDelete': 'Please confirm you want to delete the invitation?',
    'usersPage.modal.confirmUserDelete': 'Please confirm you want to delete the user?',

    'reportDetailsPage.backButton': 'Go back',
    'reportDetailsPage.reporter': 'Whistleblower',
    'reportDetailsPage.anonymous': 'Anonymous',
    'reportDetailsPage.related': 'Affiliated',
    'reportDetailsPage.attachments': 'Attachments',
    'reportDetailsPage.date': 'Date of the incident',
    'reportDetailsPage.recurrent': 'Recurring',
    'reportDetailsPage.createMessage': 'Create a message',
    'reportDetailsPage.modal.title': 'Reply to the report',
    'reportDetailsPage.modal.messageLabel': 'Message content',
    'reportDetailsPage.modal.validation.emptyMessage': 'This field is required.',
    'reportDetailsPage.modal.validation.message.minLength':
        'This field should have at least {chars} characters.',
    'reportDetailsPage.modal.validation.message.maxLength':
        'This field should have a maximum of {chars} characters.',
    'reportDetailsPage.modal.sendMessageButton': 'Send',
    'reportDetailsPage.modal.cancelMessageButton': 'Cancel',
    'reportDetailsPage.modal.messageSentTitle': 'Message sent',
    'reportDetailsPage.modal.messageSentClose': 'Close',
    'reportDetailsPage.closeAlert': 'Report closed, no change of status possible.',
    'closeCaseModal.closeManyCasesTitle': 'Close reports',
    'closeCaseModal.closeOneCaseTitle': 'Close report ',
    'closeCaseModal.closeManyCasesHighlight':
        'Once the report is closed, its status can no longer be changed. Further communication within this report will be disabled. It will remain visible on the list of closed reports.',
    'closeCaseModal.closeOneCaseHighlight':
        'Once the reports are closed, their status can no longer be changed. Further communication within these reports will be disabled.  They will remain visible on the list of closed reports.',
    'closeCaseModal.closeManyCasesParagraphFirstPart':
        'When closing the report, it is necessary to inform the Whistleblower about the actions taken as a result of the report. Describe those actions and their effect in the summary below.',
    'closeCaseModal.closeManyCasesParagraphSecondPart':
        'As you close multiple reports at once, the Whistleblowers will receive the same summary. ',
    'closeCaseModal.closeManyCasesParagraphThirdPart':
        'If you want to submit different summaries, please close each report separately.',
    'closeCaseModal.closeOneCaseParagraph':
        'When closing the report, it is necessary to inform the Whistleblower about the actions taken as a result of the report. Describe those actions and their effect in the summary below. The summary will be automatically send to the Whistleblower.',
    'closeCaseModal.closeResponseLabel': 'Summary of the actions taken ',
    'closeCaseModal.validation.emptyCloseResponse': 'Summary of the actions taken ',
    'closeCaseModal.validation.closeResponseMinLength':
        'This field should contain at least {chars} characters.',
    'closeCaseModal.validation.closeResponseMaxLength':
        'This field accepts a maximum of {chars} characters.',
    'closeCaseModal.closeManyCasesButton': 'Close reports',
    'closeCaseModal.closeOneCaseButton': 'Close report',
    'closeCaseModal.cancel': 'Cancel',
    'loginToReportPage.pageTitle': 'Whistleblower channel',
    'loginToReportPage.title': 'Log into the case',
    'loginToReportPage.highlight':
        'Log in using the four-character number of your case and the password you created.',
    'loginToReportPage.reportNumberLabel': 'Case number',
    'loginToReportPage.validation.emptyReportNumber': "Report number can't be blank",
    'loginToReportPage.passwordLabel': 'Password',
    'loginToReportPage.validation.emptyPassword': "Password can't be blank",
    'loginToReportPage.loginButton': 'Login',
    'loginToReportPage.validation.loginError':
        'Incorrect login details or the case does not exist.',
    'checkReportPage.pageTitle': 'Whistleblower channel',
    'checkReportPage.reportClosedTooltip':
        'This case is closed. You cannot open it again. If you have anything to add, start a new case!',
    'checkReportPage.reportClosedInfoCardFirstPart': 'Case closed ',
    'checkReportPage.reportClosedInfoCardSecondPart': 'See the answer to the report.',
    'checkReportPage.reportInprogressTooltip': 'Case  pending.',
    'checkReportPage.reportInprogressInfoCardFirstPart': 'Case pending.',
    'checkReportPage.reportInprogressInfoCardSecondPart':
        'You will receive an answer within 3 months of reporting.',
    'checkReportPage.refreshButton': 'Refresh',
    'checkReportPage.sendMessageButton': 'Send a message',
    'checkReportPage.messageButton': 'Message',
    'checkReportPage.whistleblowerTitle': 'Whistleblower',
    'checkReportPage.relatedTitle': 'Affiliated',
    'checkReportPage.attachmentsTitle': 'Attachments',
    'checkReportPage.dateTitle': 'Date of the incident',
    'checkReportPage.recurring': 'Recurring',
    'checkReportPage.anonymous': 'Anonymous',
    'checkReportPage.createMessageButton': 'Create a message',
    'checkReportPage.sendFile': 'Upload file',
    'checkReportPage.sendFileShort': 'Upload',
    'checkReportPage.uploadFileModal.title': 'Upload files',
    'checkReportPage.uploadFileModal.description':
        'Uploaded files can be photos, videos or documents.',
    'checkReportPage.uploadFileModal.cancelButton': 'Cancel',
    'checkReportPage.uploadFileModal.sendButton': 'Upload',
    'checkReportPage.uploadFileModal.closeButton': 'Close',
    'checkReportPage.uploadFileModal.addAttachmentButton': 'Upload a file',
    'checkReportPage.uploadFileModal.successInfo': 'Files were uploaded succesfully',
    'checkReportPage.showAttachmentLink': 'Show',

    'reportsSummary.title': 'Reports',
    'reportsSummary.reportNumber': 'Number',
    'reportsSummary.createdDate': 'Created date',
    'reportsSummary.status': 'Status',
    'reportsSummary.status.empty': 'All',
    'reportsSummary.whistleblower': 'Whistleblower',
    'reportsSummary.whistleblower.empty': 'All',
    'reportsSummary.relation': 'Relationship',
    'reportsSummary.relation.empty': 'All',
    'reportsSummary.category': 'Category',
    'reportsSummary.category.empty': 'All',
    'reportsSummary.createReportButton': 'Create report',
    'reportsSummary.downloadButton': 'Download',
    'reportsSummary.numberOfReports': 'Number of reports',
    'reportsSummary.search.placeholder': 'Report number',
    'reportsSummary.reportNumberInputError': 'Report number must have 4 chars',
    'reportsSummary.dateInput.from': 'From',
    'reportsSummary.dateInput.to': 'To',
    'reportsSummary.list.empty': 'No rows. Try adjust filters.',
    'reportsSummary.list.pagination.of': 'of',
    'reportsSummary.generateReportsModal.title': 'Specify report content',
    'reportsSummary.generateReportsModal.numberOfReports': 'Reports summary creation for:',
    'reportsSummary.generateReportsModal.numberOfReportsCount': '{count} reports',
    'reportsSummary.generateReportsModal.numberOfReportsCountOne': '{count} report',
    'reportsSummary.generateReportsModal.reportsRegistry': 'Reports registry',
    'reportsSummary.generateReportsModal.finalProtocol': 'Final protocol',
    'reportsSummary.generateReportsModal.cancel': 'Cancel',

    'finalProtocolPdf.header': 'Final protocol',
    'finalProtocolPdf.creator': 'Document creator',
    'finalProtocolPdf.generatedAt': 'Document generated at:',
    'finalProtocolPdf.generatedFor': 'Document generated for:',
    'finalProtocolPdf.nameAndSurname': 'Name and surname',
    'finalProtocolPdf.email': 'E-mail address',
    'finalProtocolPdf.reportDetails': 'Report details',
    'finalProtocolPdf.createdAt': 'Created at',
    'finalProtocolPdf.closedDate': 'Closed date',
    'finalProtocolPdf.reportNumber': 'Report number',
    'finalProtocolPdf.whistleblower': 'Whistleblower',
    'finalProtocolPdf.anonymous': 'Anonymous',
    'finalProtocolPdf.contactData': 'Contact data',
    'finalProtocolPdf.contactData.none': 'None',
    'finalProtocolPdf.relation': 'Relation',
    'finalProtocolPdf.category': 'Category',
    'finalProtocolPdf.closedMessage': 'Closed message',
    'finalProtocolPdf.page': 'Page {pages}',

    'reportSubmittedPdf.header': 'Report submitted',

    'reportsRegistryPdf.header': 'Reports registry',
    'reportsRegistryPdf.reportsCount': 'Number of reports:',
    'reportsRegistryPdf.filters': 'Used filters',
    'reportsRegistryPdf.filters.date': 'Dates',
    'reportsRegistryPdf.filters.status': 'Status',
    'reportsRegistryPdf.filters.whistleblower': 'Whistleblower',
    'reportsRegistryPdf.filters.relation': 'Relation',
    'reportsRegistryPdf.filters.category': 'Category',
    'reportsRegistryPdf.filters.none': 'None',
    'reportsRegistryPdf.filters.whistleblowerRelationsAll': 'All relation types',
    'reportsRegistryPdf.table.status': 'Status',
    'reportsRegistryPdf.table.contactData': 'Contact',

    'userMenu.myAccount': 'My account',
    'userMenu.changePassword': 'Change Password',
    'userMenu.logout': 'Log out',
    'quickMenu.setStatus': 'Set the status',
    'quickMenu.setStatusShort': 'status',

    'quickMenu.sort': 'Sort',
    'downloadMenu.download': 'Download',
    'showDetails.hide': 'Hide details',
    'showDetails.show': 'Show details',
    'person.personNumber': 'Person no',
    'person.delete': 'Delete',
    'person.nameLabel': 'Name',
    'person.surnameLabel': 'Surname',
    'person.relationLabel': "Describe the person's relationship to the case",
    'pickedFile.delete': 'Delete',
    'reportFooter.firstParagraph':
        'You can also update your case or provide additional information using your password and case number.',
    'reportFooter.secondParagraphStrong': 'Maximum time for responding to your report is 3 months.',
    'reportFooter.secondParagraph':
        'Nevertheless, we will make every effort to ensure that it happens sooner. Therefore, we encourage you to systematically log in and check the status of your application at the following address:',
    'reportNumber.reportNumber': 'Case number:',
    'validation.unknownError': 'Something went wrong, please try again later.',

    reportingChannel: 'Reporting channel',
};
