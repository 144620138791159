import { gql, useMutation } from 'urql';
import {
    GenerateReportAttachmentsUploadLinksMutation,
    MutationGenerateReportAttachmentsUploadLinksArgs,
} from 'src/generated/graphql';

const GENERATE_REPORT_ATTACHMENTS_UPLOAD_LINKS = gql`
    mutation GenerateReportAttachmentsUploadLinks(
        $input: GenerateReportAttachmentsUploadLinksInput!
    ) {
        generateReportAttachmentsUploadLinks(input: $input) {
            attachmentId
            frontId
            url
        }
    }
`;

export default () =>
    useMutation<
        GenerateReportAttachmentsUploadLinksMutation,
        MutationGenerateReportAttachmentsUploadLinksArgs
    >(GENERATE_REPORT_ATTACHMENTS_UPLOAD_LINKS);
