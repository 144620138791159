import { gql, useMutation } from 'urql';
import { LoginWhistleblowerMutation, MutationLoginWhistleblowerArgs } from 'src/generated/graphql';

const LOGIN_WHISTLEBLOWER_MUTATION = gql`
    mutation LoginWhistleblower($input: LoginWhistleblowerInput!) {
        loginWhistleblower(input: $input) {
            sessionToken
        }
    }
`;

export default () =>
    useMutation<LoginWhistleblowerMutation, MutationLoginWhistleblowerArgs>(
        LOGIN_WHISTLEBLOWER_MUTATION,
    );
