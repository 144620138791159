import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    padding: 10px 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    position: relative;
`;

export const DisplayFlex = styled.div`
    display: flex;
    align-items: center;
`;

export const Link = styled.a<{ borderRight: boolean }>`
    text-decoration: none;
    padding-right: 20px;

    ${(props) =>
        props.borderRight &&
        `
    ${props.theme.media.md} {
        border-right: 1px solid ${props.theme.colors.border};
    }`}
`;
