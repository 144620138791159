import styled from 'styled-components';

export const ActionBarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px 0 25px;
`;

export const LeftWrapper = styled.div`
    display: flex;
`;
