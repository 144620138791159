import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Arrow from '@assets/images/icon-icon-arrow-down-purple.svg';
import SortingIcon from '@assets/images/icon-sort.svg';
import UnSelectedCheckbox from '@assets/images/icon-checkbox-unselected.svg';
import SelectedCheckbox from '@assets/images/icon-checkbox-half-selected.svg';
import SetStatusIcon from '@assets/images/icon-folder-move-outlined.svg';
import { QuickMenuWrapper, ButtonStyled, MenuItemStyled, useStyles } from './QuickMenu.styled';
import useQuickMenu from './useQuickMenu';
import { IQuickMenuProps } from './QuickMenu.d';
import { FormattedMessage } from 'react-intl';

const QuickMenu = ({
    name,
    options,
    type,
    messageId,
    position,
    onClick,
    onSelectManyReports,
    onSort,
}: IQuickMenuProps) => {
    const { handleClose, anchorEl, handleClick } = useQuickMenu();
    const classes = useStyles();

    return (
        <QuickMenuWrapper>
            <ButtonStyled
                aria-controls={name}
                aria-haspopup="true"
                onClick={(e) => {
                    handleClick(e);
                }}
            >
                {type === 'Sorting' && (
                    <>
                        <img src={SortingIcon} />
                        <FormattedMessage id={'quickMenu.sort'} />
                        <img src={Arrow} />
                    </>
                )}
                {type === 'Selection' && (
                    <>
                        {Boolean(anchorEl) ? (
                            <img src={SelectedCheckbox} />
                        ) : (
                            <img src={UnSelectedCheckbox} />
                        )}
                        <img src={Arrow} />
                    </>
                )}
                {type === 'SetStatus' && (
                    <>
                        <img src={SetStatusIcon} />
                        <FormattedMessage id={messageId || 'quickMenu.setStatus'} />
                        <img src={Arrow} />
                    </>
                )}
                {type === 'Default' && (
                    <>
                        {name}
                        <img src={Arrow} />
                    </>
                )}
            </ButtonStyled>
            <Menu
                id={name}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: position ? position : 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: position ? position : 'left',
                }}
            >
                {type === 'SetStatus' &&
                    options.map((menuItem, index) => {
                        return (
                            <MenuItemStyled
                                key={menuItem.name + index}
                                className={classes.menuItem}
                                onClick={() => {
                                    onClick && onClick(menuItem.value);
                                    handleClose();
                                }}
                                active={menuItem.active}
                            >
                                <div></div>
                                {menuItem.name}
                            </MenuItemStyled>
                        );
                    })}
                {type === 'Sorting' &&
                    options.map((menuItem, index) => {
                        return (
                            <MenuItem
                                key={menuItem.name + index}
                                className={classes.menuItem}
                                value={menuItem.value}
                                onClick={() => {
                                    onSort && onSort(menuItem.value);
                                    handleClose();
                                }}
                            >
                                {menuItem.name}
                            </MenuItem>
                        );
                    })}
                {type === 'Selection' &&
                    options.map((menuItem, index) => {
                        return (
                            <MenuItem
                                key={menuItem.name + index}
                                className={classes.menuItem}
                                value={menuItem.value}
                                onClick={() => {
                                    onSelectManyReports && onSelectManyReports(menuItem.value);
                                    handleClose();
                                }}
                            >
                                {menuItem.name}
                            </MenuItem>
                        );
                    })}
            </Menu>
        </QuickMenuWrapper>
    );
};

export default QuickMenu;
