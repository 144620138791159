import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const Body = styled.div`
    display: flex;
    width: 100%;
    padding: 0;
    margin: 0;
`;

export const Main = styled.div<{ bgColor?: string }>`
    width: 100%;
    height: 100%;
    padding: 16px 16px 0;
    margin: 83px 0 0 0;
    background: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
    min-width: 320px;

    ${(props) => props.theme.media.md} {
        padding: 16px 32px 0;
    }
`;

export const IconButtonStyled = styled(IconButton)`
    position: fixed !important;
    left: 8px;
    top: 15px;
    z-index: 100;
`;
