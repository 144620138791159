import styled from 'styled-components';
import { Button } from '@components/_universal';

export const PersonWrapper = styled.div`
    width: 100%;
    height: auto;
    margin: 18px 0 20px;
    padding: 24px 16px 7px;
    border-radius: 8px;
    border: solid 1px ${(props) => props.theme.colors.border};
    background-color: #fff;
    position: relative;
`;

export const Title = styled.span`
    display: block;
    text-align: center;
    margin: 8px 0 24px;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    letter-spacing: 0.19px;
    color: ${(props) => props.theme.colors.text};
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

    & span {
        display: block;
        margin-left: 5px;
    }
`;

export const PositionedButton = styled(Button)`
    top: 18px;
    right: 7px;
`;
