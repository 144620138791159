import { FormattedMessage } from 'react-intl';
import { Refresh } from './RefreshButton.styled';

interface IRefreshButtoProps {
    onClick: () => void;
    buttonText?: string;
}

export const RefreshButton = ({ onClick, buttonText }: IRefreshButtoProps) => {
    return (
        <Refresh onClick={onClick}>
            {buttonText ? buttonText : <FormattedMessage id="dashboardPage.actionBar.refresh" />}
        </Refresh>
    );
};
