import styled from 'styled-components';

export const SubtitleWrapper = styled.div`
    font-size: 18px;
    line-height: 1.6;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    margin-top: 30px;
    a {
        text-decoration: none;
        color: ${(props) => props.theme.colors.primary};
    }

    ${(props) => props.theme.media.md} {
        font-size: 24px;
        margin-top: 50px;
    }
`;
