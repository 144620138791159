import paths from '@shared/paths';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
    useGetReportFileDownloadLinkQuery,
    useGetReportForWhistleblowerQuery,
    Report_Status,
    useReplyToReportAsWhistleblowerMutation,
} from 'src/generated/graphql';

type UseCheckReportProps = {
    reportNumber: string;
};

export default ({ reportNumber }: UseCheckReportProps) => {
    const [reportDetailsVisible, setReportDetailsVisible] = useState(false);
    const [downloadFileId, setDownloadFileId] = useState<string | null>(null);
    const [openModal, setOpenModal] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [_, replyToReportAsWhistleblower] = useReplyToReportAsWhistleblowerMutation();
    const navigate = useNavigate();
    const [result, reexecuteGetReport] = useGetReportForWhistleblowerQuery();

    if (result.error?.message) {
        navigate(paths.report);
    }

    const [fileDownloadLinkResult] = useGetReportFileDownloadLinkQuery({
        variables: {
            input: {
                fileId: downloadFileId as string,
                reportNumber,
            },
        },
        pause: !!!downloadFileId,
    });

    useEffect(() => {
        const url = fileDownloadLinkResult.data?.getReportFileDownloadLink;
        if (url && downloadFileId && !fileDownloadLinkResult.fetching) {
            window.open(url, '_blank');
            setDownloadFileId(null);
        }
    }, [fileDownloadLinkResult.fetching]);

    const sendReply = async (message: string) => {
        const result = await replyToReportAsWhistleblower({
            input: {
                message,
            },
        });
        if (result.data?.replyToReportAsWhistleblower._id) {
            reexecuteGetReport();
        }
    };

    const onRefresh = () => {
        reexecuteGetReport();
    };

    return {
        reportDetailsVisible,
        setReportDetailsVisible,
        openModal,
        setOpenModal,
        openUploadModal,
        setOpenUploadModal,
        report: result.data?.getReportForWhistleblower,
        setDownloadFileId,
        sendReply,
        onRefresh,
        isReportClosed: result.data?.getReportForWhistleblower.status === Report_Status.Closed,
    };
};
