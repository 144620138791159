import { Get_Reports_Whistleblower } from 'src/generated/graphql';

const reportsWhistleblowerType = {
    pl: {
        [Get_Reports_Whistleblower.Anonymous]: 'Anonim',
        [Get_Reports_Whistleblower.Public]: 'Jawne',
    },
    en: {
        [Get_Reports_Whistleblower.Anonymous]: 'Anonymous',
        [Get_Reports_Whistleblower.Public]: 'Public',
    },
};
export default reportsWhistleblowerType;
