import { TextInput } from '@components/_form-elements/Inputs';
import styled from 'styled-components';

export const ModalBody = styled.div`
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 16px;
    padding: 20px 20px 0;
    width: 100%;
    height: auto;
    min-height: 300px;
    max-height: 90vh;
    overflow: scroll;

    ${(props) => props.theme.media.md} {
        width: 568px;
        top: 50%;
        transform: translate(-50%, -50%);
        bottom: auto;
        overflow: auto;
        overflow-x: hidden;
    }
`;

export const CloseButton = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;

    &:hover {
        background: rgba(0, 0, 0, 0.04);
    }
`;

export const TextInputStyled = styled(TextInput)`
    border-width: 2px !important;
    font-size: 15px !important;
`;

export const ButtonsWrapper = styled.div`
    position: sticky;
    bottom: 0;
    left: 0;
    width: calc(100% + 40px);
    margin-left: -20px;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${(props) => props.theme.colors.border};
    padding: 20px;
    margin-top: 20px;
    flex-wrap: wrap;

    ${(props) => props.theme.media.md} {
        flex-wrap: nowrap;
    }

    > span {
        display: inline-block;
        width: 30px;
        height: 10px;
        order: 2;
    }

    > button:last-of-type {
        order: 1;
        ${(props) => props.theme.media.md} {
            order: 3;
        }
    }

    > button:first-of-type {
        order: 3;
        ${(props) => props.theme.media.md} {
            order: 1;
        }
    }
`;
