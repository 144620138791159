import { StyledToast } from './Toast.styled';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import useToast from './useToast';
import { IToastProps } from './Toast.d';

export default function Toast({ position, text, autoHide, isSuccess = true }: IToastProps) {
    const { open, handleClose } = useToast();

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    return (
        <>
            <StyledToast
                anchorOrigin={{
                    vertical: position ? position.vertical : 'top',
                    horizontal: position ? position.horizontal : 'center',
                }}
                open={open}
                onClose={handleClose}
                key={text}
                autoHideDuration={autoHide ? autoHide : null}
            >
                <Alert onClose={handleClose} severity={isSuccess ? 'success' : 'error'}>
                    {text}
                </Alert>
            </StyledToast>
        </>
    );
}
