import { gql, useMutation } from 'urql';
import {
    CloseBatchOfReportsMutation,
    MutationCloseBatchOfReportsArgs,
} from 'src/generated/graphql';

const CLOSE_BATCH_OF_REPORTS = gql`
    mutation CloseBatchOfReports($input: CloseBatchOfReportsInput!) {
        closeBatchOfReports(input: $input) {
            reportNumber
            __typename
        }
    }
`;

export default () =>
    useMutation<CloseBatchOfReportsMutation, MutationCloseBatchOfReportsArgs>(
        CLOSE_BATCH_OF_REPORTS,
    );
