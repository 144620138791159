import { useStyles } from './ReportsGrid.styled';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import LastMessageCell from './LastMessageCell/LastMessageCell';
import { READ_STATUS } from './LastMessageCell/LastMessageCell';
import ActionBar from '../ActionBar/ActionBar';
import { ReportRow } from '@domains/DashboardDomain/DashboardPage/useDashboardPage';
import { useNavigate } from 'react-router';
import paths from '@shared/paths';
import { Get_Reports_For_Dashboard_Status_Filter } from 'src/generated/graphql';
import { useIntl } from 'react-intl';
import useStore from '../../../../store/useStore';

interface IReportsGridProps {
    onRefresh: () => void;
    totalDocs: number;
    loading: boolean;
    onPageChange: (page: number) => void;
    rows: ReportRow[];
    selectedReports: GridSelectionModel;
    setSelectedReports: (selectedReports: GridSelectionModel) => void;
    onSelectManyReports: (value?: string) => void;
    onSort: (value?: string) => void;
    setStatus: (value?: string) => void;
}
const REPORTS_PER_PAGE = 50 as const;

export default function ReportsGrid({
    onRefresh,
    rows,
    totalDocs,
    loading,
    onPageChange,
    selectedReports,
    setSelectedReports,
    onSelectManyReports,
    onSort,
    setStatus,
}: IReportsGridProps) {
    const classes = useStyles();
    const navigate = useNavigate();
    const { activeStatus } = useStore();

    const intl = useIntl();
    const columns: GridColDef[] = [
        {
            field: 'reporter',
            headerName: intl.formatMessage({ id: 'dashboardPage.reporter' }),
            sortable: false,
            hide: false,
            width: 150,
        },
        {
            field: 'relation',
            headerName: intl.formatMessage({ id: 'dashboardPage.relation' }),
            sortable: false,
            hide: false,
            width: 160,
        },
        {
            field: 'category',
            headerName: intl.formatMessage({ id: 'dashboardPage.category' }),
            width: 310,
            sortable: false,
            hide: false,
            flex: 1,
        },
        {
            field: 'reportNumber',
            headerName: intl.formatMessage({ id: 'dashboardPage.reportNumber' }),
            sortable: false,
            hide: false,
        },
        {
            field: 'lastMessageDate',
            headerName: intl.formatMessage({ id: 'dashboardPage.lastMessage' }),
            sortable: false,
            hide: false,
            width: 120,
            renderCell: (params) => {
                if (params.getValue(params.id, 'status') === READ_STATUS.REPLIED)
                    return (
                        <LastMessageCell
                            variant={READ_STATUS.REPLIED}
                            value={params.value as string}
                        />
                    );
                if (params.getValue(params.id, 'status') === READ_STATUS.READ)
                    return (
                        <LastMessageCell
                            variant={READ_STATUS.READ}
                            value={params.value as string}
                        />
                    );
                return <LastMessageCell value={params.value as string} />;
            },
        },
        {
            field: 'createdAt',
            headerName: intl.formatMessage({ id: 'dashboardPage.createdDate' }),
            hide: false,
            sortable: false,
            width: 130,
        },
        {
            field: 'status',
            headerName: '',
            hide: true,
            width: 0,
        },
    ];
    const oneSelectedReportText = intl.formatMessage({
        id: 'dashboardPage.footer.oneSelectedReport',
    });
    const manySelectedReportsText = intl.formatMessage({
        id: 'dashboardPage.footer.manySelectedReports',
    });
    return (
        <>
            <ActionBar
                onRefresh={onRefresh}
                onSelectManyReports={onSelectManyReports}
                onSort={onSort}
                showSetStatus={selectedReports.length > 0}
                setStatus={setStatus}
                showMenu={
                    activeStatus !== Get_Reports_For_Dashboard_Status_Filter.Closed ? true : false
                }
            />
            <div className={classes.root}>
                <DataGrid
                    checkboxSelection
                    disableColumnMenu
                    rows={rows}
                    disableSelectionOnClick={true}
                    columns={columns}
                    onRowClick={(params) =>
                        navigate({
                            pathname: paths.dashboard,
                            search: `?reportNumber=${params.id}`,
                        })
                    }
                    rowCount={totalDocs}
                    pageSize={REPORTS_PER_PAGE}
                    onSelectionModelChange={setSelectedReports}
                    rowHeight={42}
                    rowsPerPageOptions={[5]}
                    selectionModel={selectedReports}
                    paginationMode={'server'}
                    loading={loading}
                    onPageChange={onPageChange}
                    getRowClassName={(params) => `status--${params.getValue(params.id, 'status')}`}
                    localeText={{
                        footerRowSelected: (count) =>
                            `${count.toLocaleString()} ${
                                count !== -1 ? manySelectedReportsText : oneSelectedReportText
                            }`,
                        noRowsLabel: intl.formatMessage({
                            id: 'dashboardPage.reportsGrid.noRowsLabel',
                        }),
                    }}
                />
            </div>
        </>
    );
}
