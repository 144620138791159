import { ChangeEvent } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { ISelectProps } from './Select.d';
import { SelectWrapper, useStyles } from './Select.styled';

const SelectInput = ({
    label,
    valuesArray,
    onChange,
    value: currentValue,
    defaultValue,
}: ISelectProps) => {
    const value = currentValue ?? defaultValue;
    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        onChange(event.target.value as string);
    };
    const classes = useStyles();

    return (
        <SelectWrapper>
            <FormControl fullWidth>
                <InputLabel className={classes.label}>{label}</InputLabel>
                <Select onChange={handleChange} fullWidth value={value}>
                    {valuesArray.map((menuItem, i) => {
                        const isSelected = menuItem.value === value;
                        return (
                            <MenuItem
                                key={menuItem.label + i}
                                value={menuItem.value}
                                selected={isSelected}
                            >
                                {menuItem.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </SelectWrapper>
    );
};

export default SelectInput;
