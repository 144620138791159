import { SelectOptionWrapper } from './SelectOption.styled';
import { QuickMenu } from '@components/_universal/';
import { Report_Status, Update_Report_Status } from 'src/generated/graphql';
import { SELECT_OPTION } from '@domains/DashboardDomain/DashboardPage/useDashboardPage';
import { useIntl } from 'react-intl';

export interface ISelectOptionProps {
    onSelectManyReports: (value?: string) => void;
    onSort: (value?: string) => void;
    showSetStatus: boolean;
    setStatus: (value?: string) => void;
}
export const SelectOption = ({
    onSelectManyReports,
    onSort,
    showSetStatus,
    setStatus,
}: ISelectOptionProps) => {
    const intl = useIntl();
    return (
        <SelectOptionWrapper>
            <QuickMenu
                name="Selection"
                options={[
                    {
                        name: intl.formatMessage({
                            id: 'dashboardPage.selectOption.selection.none',
                        }),
                        value: SELECT_OPTION.NONE,
                    },
                    {
                        name: intl.formatMessage({
                            id: 'dashboardPage.selectOption.selection.all',
                        }),
                        value: SELECT_OPTION.ALL,
                    },
                    {
                        name: intl.formatMessage({
                            id: 'dashboardPage.selectOption.selection.read',
                        }),
                        value: SELECT_OPTION.READ,
                    },
                    {
                        name: intl.formatMessage({
                            id: 'dashboardPage.selectOption.selection.unread',
                        }),
                        value: SELECT_OPTION.UNREAD,
                    },
                ]}
                type="Selection"
                onSelectManyReports={onSelectManyReports}
                onSort={onSort}
            />

            {showSetStatus && (
                <QuickMenu
                    name="SetStatus"
                    options={[
                        {
                            name: intl.formatMessage({
                                id: 'dashboardPage.selectOption.status.new',
                            }),
                            active: false,
                            value: Update_Report_Status.Received,
                        },
                        {
                            name: intl.formatMessage({
                                id: 'dashboardPage.selectOption.status.inProgress',
                            }),
                            active: false,
                            value: Update_Report_Status.InProgress,
                        },
                        {
                            name: intl.formatMessage({
                                id: 'dashboardPage.selectOption.status.closed',
                            }),
                            active: false,
                            value: Report_Status.Closed,
                        },
                    ]}
                    type="SetStatus"
                    onClick={setStatus}
                />
            )}
        </SelectOptionWrapper>
    );
};

export default SelectOption;
