import { gql, useMutation } from 'urql';
import { CreateMaintainerMutation, MutationCreateMaintainerArgs } from 'src/generated/graphql';

const CREATE_MAINTAINER_MUTATION = gql`
    mutation CreateMaintainer($input: CreateUserInput!) {
        createMaintainer(input: $input) {
            success
        }
    }
`;

export default () =>
    useMutation<CreateMaintainerMutation, MutationCreateMaintainerArgs>(CREATE_MAINTAINER_MUTATION);
