import styled from 'styled-components';
import issueInProgress from '@assets/images/icon-issue-in-progress.svg';
import issueClosed from '@assets/images/icon-issue-closed.svg';

export const InfoCardWrapper = styled.div`
    width: 100%;
    display: flex;
    margin: 0 0 16px;
    justify-content: center;
`;

export const Card = styled.div<{ type: 'inProgress' | 'closed' }>`
    max-width: 560px;
    padding: 12px 12px 12px 52px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.border};
    font-size: 14px;
    line-height: 22px;
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
    position: relative;
    display: flex;
    align-items: center;
    background: ${(props) =>
        props.type === 'closed'
            ? `url(${issueClosed}) 18px 50% no-repeat`
            : `url(${issueInProgress}) 18px 50% no-repeat`};
    svg {
        margin-left: 10px;
        fill: ${(props) => props.theme.colors.border};
    }

    &:before {
        content: '';
        width: 6px;
        height: calc(100% + 2px);
        position: absolute;
        top: -1px;
        left: -1px;
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
        background: ${(props) => (props.type === 'closed' ? props.theme.colors.orange : '#00c95a')};
    }
`;
