import { Button } from '@components/_universal';
import { Grid } from '@material-ui/core';
import { ActionWrapper, ButtonWrapper } from './Action.styled';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Stepper from '../Stepper/Stepper';
import { IActionBarProps } from './Action.d';
import { FormattedMessage } from 'react-intl';

const ActionBar = ({
    activeStep,
    lastStep,
    setStep,
    nextBtnText = 'Pomiń',
    disableNextBtn = false,
    onNextClick,
    onBackClick,
}: IActionBarProps) => {
    return (
        <ActionWrapper>
            <Stepper activeStep={activeStep} stepsLength={lastStep} />
            <Grid container>
                <Grid item xs={6}>
                    <ButtonWrapper>
                        <Button
                            variant="text"
                            icon={<ArrowBackIosIcon />}
                            onClick={(e) => {
                                e.preventDefault();
                                if (onBackClick && activeStep === 1) {
                                    onBackClick();
                                } else if (activeStep > 1) {
                                    setStep(activeStep - 1);
                                }
                            }}
                        >
                            <FormattedMessage id="reportDetailsPage.backButton" />
                        </Button>
                    </ButtonWrapper>
                </Grid>
                <Grid item xs={6}>
                    <ButtonWrapper snapToRight>
                        <Button
                            variant="primary"
                            onClick={(e) => {
                                e.preventDefault();
                                if (onNextClick) {
                                    onNextClick();
                                } else if (activeStep < lastStep) {
                                    setStep(activeStep + 1);
                                }
                            }}
                            disabled={disableNextBtn}
                        >
                            {nextBtnText}
                        </Button>
                    </ButtonWrapper>
                </Grid>
            </Grid>
        </ActionWrapper>
    );
};

export default ActionBar;
