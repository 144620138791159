import { Button, InfoCard, Toast } from '@components/_universal';
import useReportDetails from './useCheckReportPage';
import { Container, Grid } from '@material-ui/core';
import SignalistIcon from '@assets/images/icon-account-security-small.svg';
import AnonymousIcon from '@assets/images/icon-anonymus-on.svg';
import { ExpandableGrid } from './CheckReportPage.styled';
import categories from 'src/dict/categories';
import { format } from 'date-fns';
import whistleblowerRelations from 'src/dict/whistleblowerRelations';
import recurringFrequency from 'src/dict/recurringFrequency';
import {
    DetailsTitleWithReport,
    ReportDetailsBar,
    ReportInfoRow,
    Modal,
    Message,
    ShowDetails,
    DownloadMenu,
} from '@components/ReportDetails';
import { AttachFile, ChatBubbleOutlineOutlined, Refresh } from '@material-ui/icons';
import { CheckReportLayout } from '@components/Layouts';
import { useParams } from 'react-router';
import { Report_Category, Report_Status } from 'src/generated/graphql';
import { useMediaQuery } from '@material-ui/core';
import defaultTheme from '@shared/theme/default.theme';
import useGetCompanyInfoFromDomain from '@utils/hooks/useGetCompanyInfoFromDomain';
import { FormattedMessage, useIntl } from 'react-intl';
import getUserLanguage from '@utils/getUserLanguage';
import UploadModal from '@components/ReportDetails/UploadModal/UploadModal';

const ReportDetails = () => {
    const { reportNumber = '' } = useParams<{ reportNumber: string }>();

    const {
        reportDetailsVisible,
        setReportDetailsVisible,
        openModal,
        setOpenModal,
        openUploadModal,
        setOpenUploadModal,
        report,
        setDownloadFileId,
        sendReply,
        onRefresh,
    } = useReportDetails({ reportNumber });
    const intl = useIntl();

    const isTablet = useMediaQuery(defaultTheme.media.md, { noSsr: true });
    const { companyName, companyLogo } = useGetCompanyInfoFromDomain();
    const refreshText = intl.formatMessage({ id: 'checkReportPage.refreshButton' });
    const sendMessageText = intl.formatMessage({ id: 'checkReportPage.sendMessageButton' });
    const messageText = intl.formatMessage({ id: 'checkReportPage.messageButton' });
    const anonymousText = intl.formatMessage({ id: 'checkReportPage.anonymous' });
    const uploadFileText = intl.formatMessage({ id: 'checkReportPage.sendFile' });
    const uploadFileShortText = intl.formatMessage({ id: 'checkReportPage.sendFileShort' });
    return report ? (
        <CheckReportLayout
            displayLogOut
            companyName={companyName}
            companyLogo={companyLogo}
            pageTitle={intl.formatMessage({ id: 'checkReportPage.pageTitle' })}
        >
            <Container>
                {/* <Toast text="Nowa wiadomość do zgłoszenia" autoHide={5000} /> */}
                {report.status === Report_Status.Closed ? (
                    <InfoCard
                        type="closed"
                        tooltilCopy={intl.formatMessage({
                            id: 'checkReportPage.reportClosedTooltip',
                        })}
                    >
                        <strong>
                            <FormattedMessage id="checkReportPage.reportClosedInfoCardFirstPart" />
                        </strong>{' '}
                        <FormattedMessage id="checkReportPage.reportClosedInfoCardSecondPart" />
                    </InfoCard>
                ) : (
                    <>
                        <InfoCard type="inProgress" tooltilCopy="Sprawa jest w toku.">
                            <strong>
                                <FormattedMessage id="checkReportPage.reportInprogressInfoCardFirstPart" />
                            </strong>{' '}
                            <FormattedMessage id="checkReportPage.reportInprogressInfoCardSecondPart" />
                        </InfoCard>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid>
                                <Button
                                    onClick={onRefresh}
                                    variant="text"
                                    className="bold"
                                    icon={<Refresh />}
                                >
                                    {isTablet ? refreshText : ''}
                                </Button>
                            </Grid>
                            <Grid>
                                <Button
                                    onClick={(e) => setOpenUploadModal(!openUploadModal)}
                                    variant="text"
                                    className="bold"
                                    icon={<AttachFile />}
                                >
                                    {isTablet ? uploadFileText : uploadFileShortText}
                                </Button>
                                <Button
                                    onClick={(e) => setOpenModal(!openModal)}
                                    variant="text"
                                    className="bold"
                                    icon={<ChatBubbleOutlineOutlined />}
                                >
                                    {isTablet ? sendMessageText : messageText}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
                <DetailsTitleWithReport
                    title={
                        report.category !== Report_Category.Other
                            ? categories[getUserLanguage()][report.category]
                            : `${categories[getUserLanguage()][report.category]} - ${
                                  report.otherCategory
                              }`
                    }
                    reportNumber={report.reportNumber}
                    date={format(new Date(report.createdAt), 'HH:mm, dd.MM.yyyy')}
                />
                <ExpandableGrid container $isVisible={reportDetailsVisible}>
                    <Grid item xs={12}>
                        <ReportInfoRow className="bold">
                            <div className="title">
                                <FormattedMessage id="checkReportPage.whistleblowerTitle" />
                            </div>
                            <div className="data">
                                <span>
                                    <img
                                        src={report.whistleblower ? SignalistIcon : AnonymousIcon}
                                        alt="Sygnalista"
                                    />
                                    {report.whistleblower
                                        ? `${report.whistleblower.name} ${report.whistleblower.surname}`
                                        : anonymousText}
                                </span>
                                {report.whistleblowerRelation && (
                                    <span>
                                        {report.whistleblowerRelation.other ||
                                            whistleblowerRelations[getUserLanguage()][
                                                report.whistleblowerRelation.relation
                                            ]}
                                    </span>
                                )}
                                {report.whistleblower?.email && (
                                    <a href={report.whistleblower.email}>
                                        {report.whistleblower.email}
                                    </a>
                                )}
                                {report.whistleblower?.phone && (
                                    <a href={`tel:${report.whistleblower.phone}`}>
                                        {report.whistleblower.phone}
                                    </a>
                                )}
                            </div>
                        </ReportInfoRow>
                        {report?.personsInvolved && report.personsInvolved.length > 0 && (
                            <ReportInfoRow>
                                <div className="title">
                                    <FormattedMessage id="checkReportPage.relatedTitle" />
                                </div>

                                <div className="data">
                                    {report.personsInvolved.map((person) => (
                                        <span key={person?.surname}>
                                            {person?.name} {person?.surname}{' '}
                                            {person?.relation ? `(${person?.relation})` : ''}
                                        </span>
                                    ))}
                                </div>
                            </ReportInfoRow>
                        )}

                        {report.attachments && report.attachments.length > 0 && (
                            <ReportInfoRow>
                                <div className="title">
                                    <FormattedMessage id="checkReportPage.attachmentsTitle" />
                                </div>
                                <div className="data">
                                    {report.attachments.map((attachment, index) => (
                                        <a
                                            href=""
                                            key={index}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setDownloadFileId(attachment._id);
                                            }}
                                        >
                                            file_{index}.{attachment.ext}
                                        </a>
                                    ))}

                                    <DownloadMenu
                                        files={report.attachments}
                                        onFileClick={setDownloadFileId}
                                    />
                                </div>
                            </ReportInfoRow>
                        )}
                        {report.occurringDetails?.firstTimeOccurredAt && (
                            <ReportInfoRow>
                                <div className="title">
                                    <FormattedMessage id="checkReportPage.dateTitle" />
                                </div>
                                <div className="data">
                                    <>
                                        <span>
                                            {format(
                                                new Date(
                                                    report.occurringDetails.firstTimeOccurredAt,
                                                ),
                                                'HH:mm, dd.MM.yyyy',
                                            )}
                                        </span>
                                        {report.occurringDetails.recurring &&
                                            report.occurringDetails.recurringFrequency && (
                                                <>
                                                    <span>
                                                        <FormattedMessage id="checkReportPage.recurring" />
                                                    </span>
                                                    <span>
                                                        {
                                                            recurringFrequency[getUserLanguage()][
                                                                report.occurringDetails
                                                                    .recurringFrequency
                                                            ]
                                                        }
                                                    </span>
                                                </>
                                            )}
                                    </>
                                </div>
                            </ReportInfoRow>
                        )}
                    </Grid>
                </ExpandableGrid>
            </Container>

            <ReportDetailsBar>
                <ShowDetails
                    visible={reportDetailsVisible}
                    onClick={() => {
                        setReportDetailsVisible(!reportDetailsVisible);
                    }}
                />
                <Container>
                    {report.messages.map((message, index) =>
                        !message.sendBy ? (
                            <Grid
                                key={message.sendAt + index}
                                container
                                justifyContent="flex-start"
                            >
                                <Grid container justifyContent="flex-start">
                                    <div className="signalist">
                                        <strong>
                                            {report.whistleblower
                                                ? `${report.whistleblower.name} ${report.whistleblower.surname}`
                                                : anonymousText}
                                        </strong>
                                        <span>
                                            {format(new Date(message.sendAt), 'HH:mm, dd.MM.yyyy')}
                                        </span>
                                    </div>
                                </Grid>
                                {message?.content === null ? (
                                    message?.attachments &&
                                    message.attachments.map((attachment, i) => (
                                        <Grid container>
                                            <Message
                                                fileName={`${attachment._id}.${attachment.ext}`}
                                                onClick={() => {
                                                    setDownloadFileId(attachment._id);
                                                }}
                                            />
                                        </Grid>
                                    ))
                                ) : (
                                    <Message>
                                        <p>{message.content}</p>
                                    </Message>
                                )}
                            </Grid>
                        ) : (
                            <Grid key={message.sendAt + index} container justifyContent="flex-end">
                                <Grid container justifyContent="flex-end">
                                    <div className="signalist">
                                        <strong>
                                            {message.sendBy.name} {message.sendBy.surname}
                                        </strong>
                                        <span>
                                            {format(new Date(message.sendAt), 'HH:mm, dd.MM.yyyy')}
                                        </span>
                                    </div>
                                </Grid>
                                {message?.content === null ? (
                                    message?.attachments &&
                                    message.attachments.map((attachment, i) => (
                                        <Grid container justifyContent="flex-end">
                                            <Message
                                                fileName={`${attachment._id}.${attachment.ext}`}
                                                onClick={() => {
                                                    setDownloadFileId(attachment._id);
                                                }}
                                            />
                                        </Grid>
                                    ))
                                ) : (
                                    <Message>
                                        <p>{message.content}</p>
                                    </Message>
                                )}
                            </Grid>
                        ),
                    )}
                    {report.closedInformations ? (
                        <Grid
                            key={report.closedInformations.date}
                            container
                            justifyContent="flex-end"
                        >
                            <Grid container justifyContent="flex-end">
                                <div className="signalist">
                                    <span>
                                        {format(
                                            new Date(report.closedInformations.date),
                                            'HH:mm, dd.MM.yyyy',
                                        )}
                                    </span>
                                </div>
                            </Grid>

                            <Message>{report.closedInformations.statement}</Message>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Container>

                <Grid
                    container
                    justifyContent="center"
                    style={{ width: '100%', marginBottom: '20px' }}
                >
                    <Button
                        variant="primary"
                        className="marginTop60"
                        onClick={(e) => setOpenModal(!openModal)}
                        style={{
                            marginBottom: '20px',
                        }}
                    >
                        <FormattedMessage id="checkReportPage.createMessageButton" />
                    </Button>
                </Grid>
            </ReportDetailsBar>
            <Modal openModal={openModal} setOpenModal={setOpenModal} sendReply={sendReply} />
            <UploadModal
                openUploadModal={openUploadModal}
                setOpenUploadModal={setOpenUploadModal}
                reportNumber={reportNumber}
                onRefresh={onRefresh}
            />
        </CheckReportLayout>
    ) : (
        <></>
    );
};

export default ReportDetails;
