import { Get_Reports_For_Dashboard_Status_Filter, Report_Status } from 'src/generated/graphql';

const reportsForDashboardStatusFilter = {
    pl: {
        [Get_Reports_For_Dashboard_Status_Filter.Closed]: 'Zamknięte',
        [Get_Reports_For_Dashboard_Status_Filter.InProgress]: 'W toku',
        [Get_Reports_For_Dashboard_Status_Filter.New]: 'Nowe',
        [Report_Status.Archived]: 'Zarchiwizowane',
        [Report_Status.Received]: 'Odebrane',
        [Report_Status.Sent]: 'Wysłane',
    },
    en: {
        [Get_Reports_For_Dashboard_Status_Filter.Closed]: 'Closed',
        [Get_Reports_For_Dashboard_Status_Filter.InProgress]: 'In progress',
        [Get_Reports_For_Dashboard_Status_Filter.New]: 'New',
        [Report_Status.Archived]: 'Archived',
        [Report_Status.Received]: 'Received',
        [Report_Status.Sent]: 'Sent',
    },
};
export default reportsForDashboardStatusFilter;
