import { Tooltip } from '@material-ui/core';
import { InfoCardWrapper, Card } from './InfoCard.styled';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

type InfoCardProps = {
    children: React.ReactNode;
    type: 'inProgress' | 'closed';
    tooltilCopy?: string;
};

const InfoCard = ({ children, type, tooltilCopy }: InfoCardProps) => {
    return (
        <InfoCardWrapper>
            <Card type={type}>
                <span>{children}</span>
                {/* {tooltilCopy && (
                    <Tooltip title={tooltilCopy} placement="top">
                        <HelpOutlineIcon />
                    </Tooltip>
                )} */}
            </Card>
        </InfoCardWrapper>
    );
};

export default InfoCard;
