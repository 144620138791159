import { gql, useQuery } from 'urql';
import { Query } from 'src/generated/graphql';

const GET_COMPANY_INFO_FROM_DOMAIN_QUERY = gql`
    query GetCompanyInfoFromDomain {
        getCompanyInfoFromDomain {
            shortName
            logoUrl
        }
    }
`;

export default () =>
    useQuery<Query['getCompanyInfoFromDomain']>({
        query: GET_COMPANY_INFO_FROM_DOMAIN_QUERY,
    });
