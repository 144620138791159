import defaultTheme from '@shared/theme/default.theme';
import styled from 'styled-components';

export const Div = styled.div<{ variant?: 'violet' | 'lightOrange'; marginTop?: string }>`
    padding: 20px 10px;
    text-align: center;
    background: ${defaultTheme.colors.highlight};
    line-height: 1.6;
    color: '#1c1c3f';
    border-radius: 5px;

    ${(props) =>
        props.variant &&
        `
        font-size: 14px;
        margin: 35px 0;
        padding: 20px 15px;
        text-align: left;
        background: ${
            props.variant === 'violet'
                ? defaultTheme.colors.lightPrimary
                : defaultTheme.colors.lightOrange
        };
        color: ${
            props.variant === 'violet' ? defaultTheme.colors.primary : defaultTheme.colors.orange
        };
        font-weight: 500;
    `}

    ${(props) =>
        props.marginTop &&
        `
        margin-top: ${props.marginTop}`}
`;
