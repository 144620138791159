import { useEffect, useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

export default () => {
    const {
        control,
        watch,
        register,
        setValue,
        getValues,
        formState: { dirtyFields },
    } = useFormContext();
    const [selectedDate, setDate] = useState<Date | null>(null);
    const [selectedTime, setTime] = useState<Date | null>(null);
    const [displayNextButton, setDisplayNextButton] = useState<boolean>(false);
    useEffect(() => {
        register('date');
        register('time');
        // if (!getValues().date) {
        //     setDate(new Date());
        //     setValue('date', new Date());
        // } else {
        //     setDisplayNextButton(true);
        // }
        // if (!getValues().time) {
        //     setTime(new Date());
        //     setValue('time', new Date());
        // } else {
        //     setDisplayNextButton(true);
        // }
    }, [register]);

    const handleDateChange = (date: Date) => {
        setDate(date);
        setValue('date', date);
        setDisplayNextButton(true);
    };

    const handleTimeChange = (date: Date) => {
        setTime(date);
        setValue('time', date);
        setDisplayNextButton(true);
    };

    const reccuringEl = useRef<null | HTMLDivElement>(null);
    const handleScroll = () => {
        setDisplayNextButton(true);
        setTimeout(() => {
            reccuringEl.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    };

    useEffect(() => {
        if (dirtyFields.recurring === true || dirtyFields.recurringFrequency === true) {
            setDisplayNextButton(true);
        }
    }, []);
    return {
        control,
        selectedDate,
        selectedTime,
        handleTimeChange,
        handleDateChange,
        register,
        watch,
        isRecurringSelected: getValues().recurring === 'Yes',
        reccuringEl,
        handleScroll,
        displayNextButton,
    };
};
