import { StepperWrapper, Step } from './Stepper.styled';
import { IStepperProps } from './Stepper.d';
import useStepper from './useStepper';

export const Stepper = (props: IStepperProps) => {
    const { steps } = useStepper(props);
    return (
        <StepperWrapper>
            {steps.map((step, i) => (
                <Step
                    key={`step${i}`}
                    steps={steps.length}
                    active={step.isActive}
                    last={step.isLast}
                />
            ))}
        </StepperWrapper>
    );
};

export default Stepper;
