import { gql, useMutation } from 'urql';
import {
    MutationReplyToReportAsCompanyUserArgs,
    ReplyToReportAsCompanyUserMutation,
} from 'src/generated/graphql';

const REPLY_TO_REPORT_AS_COMPANY_USER = gql`
    mutation ReplyToReportAsCompanyUser($input: ReplyToReportAsCompanyUserInput!) {
        replyToReportAsCompanyUser(input: $input) {
            _id
        }
    }
`;

export default () =>
    useMutation<ReplyToReportAsCompanyUserMutation, MutationReplyToReportAsCompanyUserArgs>(
        REPLY_TO_REPORT_AS_COMPANY_USER,
    );
