import styled, { css } from 'styled-components';
import { ITextProps } from './Title.d';

export const repeatableStyles = css<ITextProps>`
    color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.text)};
    font-weight: bold;
    text-align: ${({ center }) => (center ? 'center' : 'left')};
`;

export const HeaderH1 = styled.h1<ITextProps>`
    margin: 20px 0 20px;
    font-size: 20px;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '20px')};
    ${repeatableStyles};
    ${(props) => props.theme.media.md} {
        margin: 32px 0;
        font-size: 32px;
        margin-top: ${(props) => (props.marginTop ? props.marginTop : '32px')};
    }
`;

export const HeaderH2 = styled.h2<ITextProps>`
    font-size: 18px;
    margin: 18px 0;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '18px')};
    ${repeatableStyles};
    ${(props) => props.theme.media.md} {
        font-size: 28px;
        margin: 28px 0;
        margin-top: ${(props) => (props.marginTop ? props.marginTop : '28px')};
    }
`;

export const HeaderH3 = styled.h3<ITextProps>`
    font-size: 16px;
    margin: 16px 0;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '16px')};
    ${repeatableStyles};
    ${(props) => props.theme.media.md} {
        font-size: 24px;
        margin: 24px 0;
        margin-top: ${(props) => (props.marginTop ? props.marginTop : '24px')};
    }
`;

export const HeaderH4 = styled.h4<ITextProps>`
    font-size: 15px;
    text-transform: uppercase;
    margin: 20px 0;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
    ${repeatableStyles};
`;
