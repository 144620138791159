import { useEffect, useState } from 'react';
import { countries } from '../../../shared/constants';
import { useForm } from 'react-hook-form';
import { IRegistrationComplete } from './RegistrationCompletePage.d';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCreateCompanyAsMaintainerMutation } from '@state/auth/mutations';
import jwt_decode from 'jwt-decode';
import paths from '@shared/paths';
import { checkVAT, countries as validationCountries } from 'jsvat';
import { useIntl } from 'react-intl';

// TODO: Change vateu validation when new countries will be available

export default function useEmailRegistration() {
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const intl = useIntl();

    const [error, setError] = useState('');
    const [_createCompanyAsMaintainerResult, createCompanyAsMaintainer] =
        useCreateCompanyAsMaintainerMutation();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IRegistrationComplete>();

    const makeVatEu = (country: string, vatEu: string) => {
        const countryCode = countries['pl'].find((el) => el.country === country)?.value as string;
        return `${countryCode}${vatEu}`;
    };

    const mapCompanyData = (data: IRegistrationComplete) => ({
        name: data.companyName,
        shortName: data.shortName,
        vatEu: makeVatEu('Polska', data.vatEU),
        domain: data.domain.replace(/\s+/g, '_').replace(/\W+/g, ''),
        address: {
            street: data.street,
            building: data.buildingNumber,
            apartment: data.apartmentNumber,
            city: data.city,
            zipCode: data.postCode,
            country: data.country,
        },
    });

    const createCompany = async (data: IRegistrationComplete) => {
        try {
            const mappedCompanyData = mapCompanyData(data);
            const result = await createCompanyAsMaintainer({
                input: {
                    companyData: mappedCompanyData,
                    token: searchParams.get('token') as string,
                },
            });
            if (
                result.error?.graphQLErrors.some(
                    (error) => error.message === 'Value is not a valid VatEu number',
                )
            ) {
                setError(
                    intl.formatMessage({
                        id: 'registrationCompletePage.validation.wrongVatEuNumber',
                    }),
                );
            }
            if (
                result.error?.graphQLErrors.some(
                    (error) => error.message === 'Invalid VatEu number or domain name',
                )
            ) {
                setError(
                    intl.formatMessage({
                        id: 'registrationCompletePage.validation.existVatEuNumberOrSubdomain',
                    }),
                );
            }
            if (
                result.error?.graphQLErrors.some(
                    (error) => error.message === 'Wrong token type or token has been already used',
                )
            ) {
                setError('Token wygasł lub został wykorzystany.');
            }
            if (result.data) {
                const decodedToken = jwt_decode<{ email: string }>(
                    searchParams.get('token') as string,
                );
                navigate(paths.registrationPayment, {
                    state: {
                        email: decodedToken.email,
                        domain: mappedCompanyData.domain,
                    },
                });
            }
        } catch (err) {
            setError(
                intl.formatMessage({
                    id: 'registrationCompletePage.validation.tokenExpired',
                }),
            );
        }
    };

    useEffect(() => {
        if (searchParams.get('token')) {
            const decodedToken = jwt_decode<{ exp: number }>(searchParams.get('token') as string);
            const expInMiliseconds = decodedToken.exp * 1000;
            if (new Date(expInMiliseconds).valueOf() < new Date().valueOf()) {
                navigate(paths.registrationLinkExpired);
            }
        }
    }, []);

    const validateVatEU = (vatEU: string) => {
        const result = checkVAT(makeVatEu('Polska', vatEU), validationCountries);
        return result.isValid;
    };

    return {
        createCompany,
        error,
        errors,
        handleSubmit,
        register,
        control,
        validateVatEU,
        countries,
    };
}
