import { gql, useQuery } from 'urql';
import { Query } from 'src/generated/graphql';

const GET_REPORT_FILE_DOWNLOAD_LINK = gql`
    query GetReportFileDownloadLink($input: GetReportFileDownloadLinkInput!) {
        getReportFileDownloadLink(input: $input)
    }
`;

export default () =>
    useQuery<Query['getReportFileDownloadLink']>({ query: GET_REPORT_FILE_DOWNLOAD_LINK });
