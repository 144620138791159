import paths from '@shared/paths';
import { useCreateMaintainerMutation } from '@state/auth/mutations';
import getUserLanguage from '@utils/getUserLanguage';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import {
    CreateUserInput,
    Language_Codes,
    useRegisterFromInvitationMutation,
} from 'src/generated/graphql';
import { ISignupForm } from './RegistrationPage.d';

export default function useEmailRegistration(token?: string | null, invitedEmail?: string | null) {
    const [error, setError] = useState('');
    const [registrationInProgress, setRegistrationInProgress] = useState(false);
    const navigate = useNavigate();
    const intl = useIntl();
    const {
        control,
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<ISignupForm>({
        defaultValues: {
            name: '',
            surname: '',
            email: invitedEmail || '',
            password: '',
            marketingConsent: false,
        },
    });
    const [_, createMaintainer] = useCreateMaintainerMutation();
    const [__, registerFromInvitation] = useRegisterFromInvitationMutation();

    const signup = async (data: CreateUserInput) => {
        setRegistrationInProgress(true);
        try {
            if (!token) {
                const result = await createMaintainer({
                    input: {
                        ...data,
                        language: getUserLanguage() as Language_Codes,
                    },
                });
                if (result.error) {
                    throw new Error();
                }
                navigate(paths.registrationActivation, { state: { email: data.email } });
            } else {
                const result = await registerFromInvitation({
                    input: {
                        token,
                        name: data.name,
                        surname: data.surname,
                        password: data.password,
                        marketingConsent: data.marketingConsent,
                    },
                });
                if (result.error) {
                    throw new Error();
                }
                navigate(paths.login, {
                    state: { showToast: true },
                });
            }
        } catch (err) {
            setError(intl.formatMessage({ id: 'validation.unknownError' }));
            setRegistrationInProgress(false);
        }
    };
    return {
        signup,
        error,
        errors,
        handleSubmit,
        register,
        control,
        registrationInProgress,
        setValue,
        intl,
    };
}
