import { Typography, TypographyProps, withStyles } from '@material-ui/core';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import defaultTheme from '@shared/theme/default.theme';

export const StyledRadio = withStyles({
    root: {
        marginLeft: '20px',
        '&$checked': {
            color: defaultTheme.colors.primary,
        },
    },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

export const StyledLabel = withStyles({
    root: {
        color: defaultTheme.colors.text,
        fontWeight: defaultTheme.fontWeight.bold,
    },
})((props: TypographyProps) => <Typography {...props} />);
