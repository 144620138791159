import { Modal } from '@material-ui/core/';
import { Title, Button, Highlight, Paragraph } from '@components/_universal';
import { ICloseCaseModalProps } from './CloseCaseModal.d';
import { ButtonsWrapper } from './CloseCaseModal.styled';
import { ModalBody, CloseButton, TextInputStyled } from '../Modal/Modal.styled';
import { TITLE_TYPE } from '@components/_universal/Title/Title.d';
import useCloseCaseModal from './useCloseCaseModal';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@components/_universal/CloseIcon/CloseIcon';
import { FormattedMessage } from 'react-intl';

export const CloseCaseModal = ({
    openModal,
    setOpenModal,
    isMultiple,
    onSend,
    reexecuteGetReports,
}: ICloseCaseModalProps) => {
    const {
        errors,
        error,
        handleSubmit,
        register,
        sendCloseResponse,
        watch,
        intl,
        closeManyCasesTitle,
        closeOneCaseTitle,
        closeManyCasesHighlightText,
        closeOneCaseHighlightText,
        closeManyCasesParagraphFirstPart,
        closeManyCasesParagraphSecondPart,
        closeManyCasesParagraphThirdPart,
        closeOneCaseParagraph,
        closeManyCasesButtonText,
        closeOneCaseButtonText,
    } = useCloseCaseModal(onSend, setOpenModal, reexecuteGetReports);

    return (
        <div>
            <Modal open={openModal} onClose={() => setOpenModal(!openModal)}>
                <ModalBody>
                    <CloseButton onClick={() => setOpenModal(!openModal)}>
                        <CloseIcon />
                    </CloseButton>
                    <Title
                        center
                        type={TITLE_TYPE.H1}
                        content={isMultiple ? closeManyCasesTitle : closeOneCaseTitle}
                    />
                    <Highlight variant="lightOrange">
                        {isMultiple ? closeManyCasesHighlightText : closeOneCaseHighlightText}
                    </Highlight>
                    <Paragraph fontSize="14px" marginBottom="25px">
                        {isMultiple ? (
                            <>
                                {closeManyCasesParagraphFirstPart}{' '}
                                <strong>{closeManyCasesParagraphSecondPart}</strong>
                                {closeManyCasesParagraphThirdPart}
                            </>
                        ) : (
                            closeOneCaseParagraph
                        )}
                    </Paragraph>
                    <TextInputStyled
                        multiline
                        error={Boolean(errors.closeResponseValue)}
                        id="closeResponseValue"
                        helperText={
                            errors.closeResponseValue
                                ? errors?.closeResponseValue.message
                                : undefined
                        }
                        {...register('closeResponseValue', {
                            required: intl.formatMessage({
                                id: 'closeCaseModal.validation.emptyCloseResponse',
                            }),
                            minLength: {
                                value: 20,
                                message: intl.formatMessage(
                                    {
                                        id: 'closeCaseModal.validation.closeResponseMinLength',
                                    },
                                    { chars: 20 },
                                ),
                            },
                            maxLength: {
                                value: 2800,
                                message: intl.formatMessage(
                                    {
                                        id: 'closeCaseModal.validation.closeResponseMaxLength',
                                    },
                                    { chars: 2800 },
                                ),
                            },
                        })}
                        rows={10}
                        label={intl.formatMessage({ id: 'closeCaseModal.closeResponseLabel' })}
                    />
                    <ButtonsWrapper>
                        <Button
                            size="full"
                            variant="primary"
                            onClick={handleSubmit(sendCloseResponse)}
                            disabled={watch('closeResponseValue')?.length >= 20 ? false : true}
                        >
                            {isMultiple ? closeManyCasesButtonText : closeOneCaseButtonText}
                        </Button>
                        <Button
                            variant="outlined"
                            size="full"
                            onClick={() => setOpenModal(!openModal)}
                        >
                            <FormattedMessage id="closeCaseModal.cancel" />
                        </Button>
                    </ButtonsWrapper>
                    {error.length > 0 ? <Alert severity="error">{error}</Alert> : <></>}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default CloseCaseModal;
