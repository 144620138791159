import UnstyledDivider from '@material-ui/core/Divider';
import styled from 'styled-components';

export const Divider = styled(UnstyledDivider)`
    && {
        background-color: ${(props) => props.theme.colors.border};
    }
`;

export default Divider;
