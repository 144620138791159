import styled from 'styled-components';

export const Text = styled.div<{ fontSize?: number; cutLength?: boolean }>`
    font-family: ${(props) => props.theme.fontFamily};
    font-size: ${(props) => (props.fontSize ? props.fontSize + 'px' : '28px')};
    font-weight: 800;
    color: ${(props) => props.theme.colors.text};
    ${(props) =>
        props.cutLength &&
        `
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 120px;`}

    @media (min-width: 400px) {
        max-width: 170px;
    }
    @media (min-width: 500px) {
        max-width: 200px;
    }
    ${(props) => props.theme.media.md} {
        max-width: 220px;
    }
`;
