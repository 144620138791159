import { DashboardLayout } from '@components/Layouts';
import Title from '../Components/Title/Title';
import ReportsGrid from '@domains/DashboardDomain/Components/ReportsGrid/ReportsGrid';
import useGetCompanyInfoFromDomain from '@utils/hooks/useGetCompanyInfoFromDomain';
import useDashboardPage from './useDashboardPage';
import ReportDetails from '../ReportDetails/ReportDetails';
import { CloseCaseModal } from '@components/ReportDetails';
import { useLocation } from 'react-router';
import { Get_Reports_For_Dashboard_Status_Filter } from 'src/generated/graphql';

type LocationState = {
    companyName?: string;
    activeStatus?: Get_Reports_For_Dashboard_Status_Filter;
};
const DashboardPage = () => {
    const companyNameFromProps = (useLocation().state as LocationState)?.companyName;
    const { companyName } = useGetCompanyInfoFromDomain({ companyName: companyNameFromProps });
    const {
        rows,
        totalDocs,
        onPageChange,
        loading,
        onRefresh,
        selectedReports,
        setSelectedReports,
        onSelectManyReports,
        onSort,
        title,
        setStatus,
        queryReportNumber,
        reexecuteGetReports,
        openCloseCaseModal,
        setOpenCloseCaseModal,
        closeReports,
    } = useDashboardPage();
    return (
        <DashboardLayout companyName={companyNameFromProps || companyName}>
            {!queryReportNumber ? (
                <>
                    <Title>{title}</Title>
                    <ReportsGrid
                        rows={rows}
                        totalDocs={totalDocs}
                        onPageChange={onPageChange}
                        loading={loading}
                        onRefresh={onRefresh}
                        selectedReports={selectedReports}
                        setSelectedReports={setSelectedReports}
                        onSelectManyReports={onSelectManyReports}
                        onSort={onSort}
                        setStatus={setStatus}
                    />
                </>
            ) : (
                <ReportDetails
                    reportNumber={queryReportNumber}
                    reexecuteGetReports={reexecuteGetReports}
                ></ReportDetails>
            )}
            <CloseCaseModal
                isMultiple={selectedReports.length > 1}
                openModal={openCloseCaseModal}
                setOpenModal={setOpenCloseCaseModal}
                onSend={closeReports}
                reexecuteGetReports={reexecuteGetReports}
            />
        </DashboardLayout>
    );
};

export default DashboardPage;
