import { palette, global } from './config';

const defaultTheme = {
    colors: {
        primary: palette.violet,
        secondary: palette.darkgrey,
        hover: palette.violetHover,
        buttonHover: palette.violetHover,
        disable: palette.disabled,
        border: palette.lightgrey,
        highlight: palette.lightergrey,
        text: palette.black,
        dark: palette.dark,
        white: palette.white,
        error: palette.red,
        green: palette.green,
        input_text_color: palette.grey,
        orange: palette.orange,
        pink: palette.pink,
        lightPrimary: palette.lightPrimary,
        darkBlue: palette.darkBlue,
        lightOrange: palette.lightOrange,
        lighterGrey: palette.lightergrey,
        black: palette.black,
    },
    ...global,
};

export default defaultTheme;
