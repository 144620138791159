import { DefaultLayout } from '@components/Layouts';
import { Paragraph } from '@components/_universal';
import FormWrapper from '@domains/ReportDomain/Components/FormWrapper/FormWrapper';
import { DevTool } from '@hookform/devtools';
import StepTitle from '@domains/ReportDomain/Components/StepTitle/StepTitle';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import useReportStepFour from './useReportStepFour';
import { FormControl, Grid, IconButton, InputAdornment, RadioGroup } from '@material-ui/core';
import { Radio, SelectInput } from '@components/_form-elements/Inputs';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TodayIcon from '@material-ui/icons/Today';
import { Controller } from 'react-hook-form';
import { Recurring_Frequency } from 'src/generated/graphql';
import recurringFrequencyObject from 'src/dict/recurringFrequency';
import { FormattedMessage, useIntl } from 'react-intl';
import getUserLanguage from '@utils/getUserLanguage';
import { IReportStepProps } from '../ReportStepOne/ReportStepOne.d';

const ReportStepFour = ({
    activeStep,
    setStep,
    lastStep,
    companyName,
    companyLogo,
}: IReportStepProps) => {
    const {
        control,
        handleTimeChange,
        handleDateChange,
        isRecurringSelected,
        watch,
        reccuringEl,
        handleScroll,
        displayNextButton,
    } = useReportStepFour();
    const values = watch();
    const intl = useIntl();
    const nextStepButtonText = intl.formatMessage({ id: 'reportForm.nextStepButton' });
    const skipStepButtonText = intl.formatMessage({ id: 'reportForm.skipStepButton' });
    const recurringFrequency = recurringFrequencyObject[getUserLanguage()];
    return (
        <DefaultLayout
            alignItemsCenter
            setFullHeight
            companyName={companyName}
            companyLogo={companyLogo}
        >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <FormWrapper
                    activeStep={activeStep}
                    setStep={setStep}
                    lastStep={lastStep}
                    nextBtnText={displayNextButton ? nextStepButtonText : skipStepButtonText}
                >
                    <StepTitle number={4}>
                        <FormattedMessage id="reportForm.stepFour.title" />
                    </StepTitle>
                    <Paragraph marginTop="30px" fontSize="15px">
                        <FormattedMessage id="reportForm.stepFour.dateParagraph" />
                    </Paragraph>
                    <DatePicker
                        style={{ marginTop: '20px' }}
                        variant="inline"
                        inputVariant="outlined"
                        format="dd.MM.yyyy"
                        emptyLabel={intl.formatMessage({
                            id: 'reportForm.stepFour.emptyDateLabel',
                        })}
                        disableToolbar
                        maxDate={new Date()}
                        value={values.date ?? null}
                        onChange={(date) => date && handleDateChange(date)}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <TodayIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TimePicker
                        style={{ marginTop: '20px' }}
                        variant="inline"
                        inputVariant="outlined"
                        value={values.time ?? null}
                        emptyLabel={intl.formatMessage({
                            id: 'reportForm.stepFour.emptyHourLabel',
                        })}
                        onChange={(date) => date && handleTimeChange(date)}
                        fullWidth
                        ampm={false}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <AccessTimeIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Paragraph marginTop="30px" fontSize="15px">
                        <FormattedMessage id="reportForm.stepFour.recurringParagraph" />
                    </Paragraph>
                    <Controller
                        name="recurring"
                        render={({ field: { onChange, value } }) => (
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="recurring"
                                    value={value || ''}
                                    onChange={(e) => {
                                        onChange(e);
                                        handleScroll();
                                    }}
                                >
                                    <Radio
                                        label={intl.formatMessage({
                                            id: 'reportForm.stepFour.radioYesLabel',
                                        })}
                                        value={'Yes'}
                                    />
                                    <Radio
                                        label={intl.formatMessage({
                                            id: 'reportForm.stepFour.radioNoLabel',
                                        })}
                                        value={'No'}
                                    />
                                </RadioGroup>
                            </FormControl>
                        )}
                    />

                    {isRecurringSelected && (
                        <Grid item xs={12} ref={reccuringEl}>
                            <Controller
                                name="recurringFrequency"
                                render={({ field: { onChange, value } }) => (
                                    <SelectInput
                                        value={value || ''}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        label={intl.formatMessage({
                                            id: 'reportForm.stepFour.recurringFrequencyLabel',
                                        })}
                                        valuesArray={[
                                            {
                                                value: Recurring_Frequency.SeveralTimesAYear,
                                                label: recurringFrequency[
                                                    Recurring_Frequency.SeveralTimesAYear
                                                ],
                                            },
                                            {
                                                value: Recurring_Frequency.SeveralTimesAMonth,
                                                label: recurringFrequency[
                                                    Recurring_Frequency.SeveralTimesAMonth
                                                ],
                                            },
                                            {
                                                value: Recurring_Frequency.SeveralTimesAWeek,
                                                label: recurringFrequency[
                                                    Recurring_Frequency.SeveralTimesAWeek
                                                ],
                                            },
                                            {
                                                value: Recurring_Frequency.SeveralTimesADay,
                                                label: recurringFrequency[
                                                    Recurring_Frequency.SeveralTimesADay
                                                ],
                                            },
                                        ]}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                </FormWrapper>
            </MuiPickersUtilsProvider>
            <DevTool control={control} placement="top-right" />
        </DefaultLayout>
    );
};

export default ReportStepFour;
