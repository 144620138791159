import { Page, Text, Image, Document, StyleSheet, Font, View } from '@react-pdf/renderer';
import LogoImg from '@assets/images/logo.png';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { en, pl } from '../translations/';
import { IntlProvider } from 'react-intl';
import useStore from '../store/useStore';
import { Report_Category, WhistleblowerRelation } from '../generated/graphql';
import whistleblowerRelations from '../dict/whistleblowerRelations';
import getUserLanguage from '../utils/getUserLanguage';
import categories from '../dict/categories';
import pdfsTranslations from '../dict/pdfsTranslations';
type Props = {
    createdAt: Date;
    reportNumber: string;
    reportUrl: string;
    attachments: string[];
    message: string;
    whistleblower?: {
        name: string;
        surname: string;
        email: string;
        phone: string;
    };
    whistleblowerRelation: WhistleblowerRelation;
    category: Report_Category | string;
    otherCategory?: string;
};

export const ReportSubmittedPdf = ({
    createdAt,
    reportNumber,
    whistleblower,
    attachments,
    whistleblowerRelation,
    category,
    otherCategory,
    reportUrl,
    message,
}: Props) => {
    const { language } = useStore();
    const SUPPORTED_TRANSLATIONS: { [key: string]: {} } = {
        en,
        pl,
    };

    return (
        <IntlProvider
            defaultLocale="pl"
            locale={language}
            messages={SUPPORTED_TRANSLATIONS[language]}
        >
            <Document>
                <Page style={styles.body}>
                    <View style={styles.headerSection}>
                        <View style={styles.firstSection}>
                            <Text style={styles.leftFirstSection}>
                                <FormattedMessage id="reportSubmittedPdf.header" />
                            </Text>
                            <Image src={LogoImg} style={styles.rightFirstSection}></Image>
                        </View>
                        <View style={styles.secondSection}>
                            <Text style={styles.leftSecondSection}>
                                <Text style={{ fontWeight: 300 }}>
                                    <FormattedMessage id="finalProtocolPdf.generatedAt" />
                                </Text>
                                <Text style={{ fontWeight: 600 }}>
                                    {` ${format(new Date(), 'dd.MM.yyyy, kk:mm')}`}
                                </Text>
                            </Text>
                            <Text style={styles.rightSecondSection}>Notibox sp. z o.o.</Text>
                        </View>
                        <View style={styles.thirdSection}>
                            <Text>www.notibox.pl</Text>
                        </View>
                    </View>

                    <Text style={styles.sectionHeader}>
                        <FormattedMessage id="finalProtocolPdf.reportDetails" />
                    </Text>
                    <View style={styles.detailsSectionsContainer}>
                        <View style={styles.detailsLeftSectionContainer}>
                            <View style={styles.detailsSection}>
                                <View>
                                    <Text style={styles.sectionTitle}>
                                        <FormattedMessage id="finalProtocolPdf.createdAt"></FormattedMessage>
                                    </Text>
                                    <Text style={styles.sectionValue}>
                                        {format(new Date(createdAt), 'dd.MM.yyyy')}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.detailsSection}>
                                <View>
                                    <Text style={styles.sectionTitle}>
                                        <FormattedMessage id="finalProtocolPdf.reportNumber"></FormattedMessage>
                                    </Text>
                                    <Text style={styles.sectionValue}>{reportNumber}</Text>
                                </View>
                            </View>
                            <View style={styles.detailsSection}>
                                <View>
                                    <Text style={styles.sectionTitle}>
                                        <FormattedMessage id="finalProtocolPdf.whistleblower"></FormattedMessage>
                                    </Text>
                                    {whistleblower ? (
                                        <Text style={styles.sectionValue}>
                                            {whistleblower.name} {whistleblower.surname}
                                        </Text>
                                    ) : (
                                        <Text style={styles.sectionValue}>
                                            <FormattedMessage id="finalProtocolPdf.anonymous"></FormattedMessage>
                                        </Text>
                                    )}
                                </View>
                            </View>
                            <View style={styles.detailsSection}>
                                <View>
                                    <Text style={styles.sectionTitle}>
                                        <FormattedMessage id="finalProtocolPdf.contactData"></FormattedMessage>
                                    </Text>
                                    {whistleblower ? (
                                        <Text style={styles.sectionValue}>
                                            {whistleblower.email}
                                            {whistleblower.email && whistleblower.phone
                                                ? ', '
                                                : ' '}
                                            {whistleblower.phone}
                                        </Text>
                                    ) : (
                                        <Text style={styles.sectionValue}>
                                            <FormattedMessage id="finalProtocolPdf.contactData.none"></FormattedMessage>
                                        </Text>
                                    )}
                                </View>
                            </View>
                            <View style={styles.detailsSection}>
                                <View>
                                    <Text style={styles.sectionTitle}>
                                        <FormattedMessage id="reportSubmittedPdf.attachments"></FormattedMessage>
                                    </Text>
                                    {whistleblower ? (
                                        <Text style={styles.sectionValue}>
                                            {attachments.join(', ')}
                                        </Text>
                                    ) : (
                                        <Text style={styles.sectionValue}>
                                            <FormattedMessage id="finalProtocolPdf.contactData.none"></FormattedMessage>
                                        </Text>
                                    )}
                                </View>
                            </View>
                        </View>

                        <View style={styles.detailsCenterSectionContainer}></View>

                        <View style={styles.detailsRightSectionContainer}>
                            <View style={styles.detailsSection}>
                                <View>
                                    <Text style={styles.sectionTitle}>
                                        <FormattedMessage id="finalProtocolPdf.relation"></FormattedMessage>
                                    </Text>
                                    <Text style={styles.sectionValue}>
                                        {whistleblowerRelation?.other ||
                                            (whistleblowerRelation?.relation &&
                                                whistleblowerRelations[getUserLanguage()][
                                                    whistleblowerRelation?.relation
                                                ]) ||
                                            ''}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.detailsSection}>
                                <View>
                                    <Text style={styles.sectionTitle}>
                                        <FormattedMessage id="finalProtocolPdf.category"></FormattedMessage>
                                    </Text>
                                    <Text style={styles.sectionValue}>
                                        {category !== Report_Category.Other
                                            ? categories[getUserLanguage()][
                                                  category as Report_Category
                                              ]
                                            : `${
                                                  categories[getUserLanguage()][category]
                                              } - ${otherCategory}`}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={styles.grayContainer}>
                        <View style={styles.closedMessageHeader}>
                            <Text style={styles.sectionHeader}>
                                <FormattedMessage id="reportSubmittedPdf.message" />
                            </Text>
                        </View>
                        <View style={styles.closedMessageContent}>
                            <Text>{message}</Text>
                        </View>
                    </View>

                    <View style={styles.linkContainer}>
                        <View style={styles.linkTextContainer}>
                            <Text>
                                <FormattedMessage id="reportSubmittedPdf.linkText" />
                                {'\n'}
                                <Text style={styles.blueColor}>{reportUrl}</Text>
                            </Text>
                        </View>
                    </View>

                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) =>
                            `${
                                pdfsTranslations[getUserLanguage()].page
                            } ${pageNumber} / ${totalPages}`
                        }
                        fixed
                    />
                </Page>
            </Document>
        </IntlProvider>
    );
};

Font.register({
    family: 'Poppins',
    fonts: [
        {
            src: '/fonts/Poppins-Light.ttf',
            fontWeight: 300,
        },
        {
            src: '/fonts/Poppins-Medium.ttf',
            fontWeight: 400,
        },
        {
            src: '/fonts/Poppins-SemiBold.ttf',
            fontWeight: 500,
        },
        {
            src: '/fonts/Poppins-Bold.ttf',
            fontWeight: 600,
        },
    ],
});

const styles = StyleSheet.create({
    body: {
        paddingTop: '32px',
        paddingHorizontal: '32px',
        paddingBottom: '48px',
        fontFamily: 'Poppins',
    },
    headerSection: {
        marginBottom: '20px',
    },
    firstSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    secondSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    leftFirstSection: {
        fontSize: '24px',
        fontWeight: 400,
        marginBottom: '9px',
        marginTop: '6px',
        width: '300px',
    },
    rightFirstSection: {
        width: '134px',
        height: '50',
    },
    leftSecondSection: {
        fontSize: '8px',
        fontWeight: 500,
    },
    rightSecondSection: {
        fontSize: '8px',
        fontWeight: 500,
        paddingRight: '11px',
    },
    thirdSection: {
        alignSelf: 'flex-end',
        paddingRight: '11px',
        fontSize: '8px',
        fontWeight: 300,
    },
    creatorContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    sectionHeader: {
        fontSize: '14px',
        fontWeight: 400,
        marginBottom: '8px',
    },
    sectionTitle: {
        fontSize: '9px',
        fontWeight: 500,
    },
    sectionValue: {
        fontWeight: 300,
        fontSize: '9px',
        marginTop: '3px',
    },
    divider: {
        width: '100%',
        opacity: 0.16,
        borderBottom: '1px solid black',
        marginTop: '20px',
        marginBottom: '20px',
    },
    detailsSectionsContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    detailsLeftSectionContainer: {
        display: 'flex',
        width: '40%',
    },
    detailsCenterSectionContainer: {
        display: 'flex',
        borderLeft: '1px solid black',
        opacity: 0.16,
        marginLeft: '50px',
        marginRight: '10px',
        width: '5%',
    },
    detailsSection: {
        marginBottom: '10px',
    },
    detailsRightSectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '45%',
    },
    grayContainer: {
        marginTop: '30px',
        backgroundColor: '#f4f4f4',
        width: '100%',
        height: 'auto',
        paddingBottom: '6px',
    },
    closedMessageHeader: {
        margin: '12px',
        marginBottom: '4px',
    },
    closedMessageContent: {
        backgroundColor: 'white',
        fontSize: '9px',
        padding: '4px',
        marginLeft: '4px',
        marginRight: '4px',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        bottom: 30,
        paddingTop: '7px',
        left: 32,
        right: 32,
        textAlign: 'right',
        color: 'grey',
        borderTop: '1px solid #f4f4f4',
    },
    linkContainer: {
        position: 'absolute',
        bottom: 80,
        left: 32,
        right: 32,
    },
    linkTextContainer: {
        display: 'flex',
        fontSize: '11px',
        fontWeight: 500,
        padding: '12px',
        border: '2px solid #7400f3',
    },
    blueColor: {
        color: '#7400f3',
    },
});
