import styled from 'styled-components';

export const ImgWrapper = styled.div<{ noPadding?: boolean }>`
    padding: ${(props) => (props.noPadding ? '0' : '20px 0 30px')};
    text-align: center;

    & img {
        display: inline;
    }
`;
