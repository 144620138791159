import styled from 'styled-components';
import iconReply from '@assets/images/icon-replied.svg';
import unReadIcon from '@assets/images/icon-unread.svg';
import readIcon from '@assets/images/icon-read.svg';
import { READ_STATUS } from './LastMessageCell';

export const Cell = styled.span<{ variant?: READ_STATUS }>`
    padding-left: 30px;
    background: url(${unReadIcon}) 0 50% no-repeat;
    background-size: 15px;
    margin: ${(props) => props.variant};

    ${(props) =>
        props.variant === READ_STATUS.REPLIED &&
        `
        background: url(${iconReply}) 0 50% no-repeat;
        background-size: 15px;
    `}

    ${(props) =>
        props.variant === READ_STATUS.READ &&
        `
        background: url(${readIcon}) 0 50% no-repeat;
        background-size: 15px;
    `}
`;
