import { Page, Text, Image, Document, StyleSheet, Font, View } from '@react-pdf/renderer';
import LogoImg from '@assets/images/logo.png';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { en, pl } from '../translations';
import { IntlProvider } from 'react-intl';
import useStore from '../store/useStore';
import {
    Get_Reports_For_Dashboard_Status_Filter,
    Get_Reports_Whistleblower,
    Report_Category,
    Report_Status,
    WhistleblowerRelation,
    Whistleblower_Relation,
} from '../generated/graphql';
import whistleblowerRelations from '../dict/whistleblowerRelations';
import getUserLanguage from '../utils/getUserLanguage';
import categories from '../dict/categories';
import pdfsTranslations from '../dict/pdfsTranslations';
import reportsForDashboardStatusFilter from '../dict/reportsForDashboardStatusFilter';
type Props = {
    filters: {
        from?: Date;
        to?: Date;
        status?: Get_Reports_For_Dashboard_Status_Filter[];
        reportNumber?: string;
        whistleblower?: Get_Reports_Whistleblower[];
        relation: Whistleblower_Relation[];
        category: Report_Category[];
    };
    reports: {
        reportNumber: string;
        category: Report_Category;
        whistleblowerRelation: WhistleblowerRelation;
        whistleblower?: {
            name: string;
            surname: string;
            email: string;
            phone: string;
        };
        closedInformations?: {
            statement: string;
            date: Date;
        };
        status: Get_Reports_For_Dashboard_Status_Filter;
        createdAt: Date;
        otherCategory?: string;
    }[];
    reporter: {
        nameAndSurname: string;
        email: string;
    };
    companyName: string;
};

export const ReportsRegistryPdf = ({ reports, reporter, filters, companyName }: Props) => {
    const { language } = useStore();
    const SUPPORTED_TRANSLATIONS: { [key: string]: {} } = {
        en,
        pl,
    };
    return (
        <IntlProvider
            defaultLocale="pl"
            locale={language}
            messages={SUPPORTED_TRANSLATIONS[language]}
        >
            <Document>
                <Page style={styles.body}>
                    <View style={styles.headerSection}>
                        <View style={styles.firstSection}>
                            <Text style={styles.leftFirstSection}>
                                <FormattedMessage id="reportsRegistryPdf.header" />
                            </Text>
                            <Image src={LogoImg} style={styles.rightFirstSection}></Image>
                        </View>
                        <View style={styles.secondSection}>
                            <Text style={styles.leftSecondSection}>
                                <Text style={{ fontWeight: 300 }}>
                                    <FormattedMessage id="finalProtocolPdf.generatedAt" />
                                </Text>
                                <Text style={{ fontWeight: 600 }}>
                                    {` ${format(new Date(), 'dd.MM.yyyy, kk:mm')}`}
                                </Text>
                            </Text>
                            <Text style={styles.rightSecondSection}>Notibox sp. z o.o.</Text>
                        </View>
                        <View style={styles.secondSection}>
                            <Text style={styles.leftSecondSection}>
                                <Text style={{ fontWeight: 300 }}>
                                    <FormattedMessage id="reportsRegistryPdf.reportsCount" />
                                </Text>
                                <Text style={{ fontWeight: 600 }}> {reports.length}</Text>
                            </Text>
                            <View style={styles.thirdSection}>
                                <Text>www.notibox.pl</Text>
                            </View>
                        </View>
                        <View style={styles.thirdSection}>
                            <Text style={styles.leftThirdSection}>
                                <Text style={{ fontWeight: 300 }}>
                                    <FormattedMessage id="finalProtocolPdf.generatedFor" />
                                </Text>
                                <Text style={{ fontWeight: 600 }}>{` ${companyName}`}</Text>
                            </Text>
                        </View>
                    </View>

                    <Text style={styles.sectionHeader}>
                        <FormattedMessage id="finalProtocolPdf.creator" />
                    </Text>
                    <View style={styles.creatorContainer}>
                        <View style={{ flex: 1 }}>
                            <Text style={styles.sectionTitle}>
                                <FormattedMessage id="finalProtocolPdf.nameAndSurname"></FormattedMessage>
                            </Text>
                            <Text style={styles.sectionValue}>{reporter.nameAndSurname}</Text>
                        </View>
                        <View style={{ flex: 1 }}>
                            <Text style={styles.sectionTitle}>
                                <FormattedMessage id="finalProtocolPdf.email"></FormattedMessage>
                            </Text>
                            <Text style={styles.sectionValue}>{reporter.email}</Text>
                        </View>
                        <View style={{ flex: 1 }}></View>
                    </View>

                    <View style={styles.divider}></View>

                    <Text style={styles.sectionHeader}>
                        <FormattedMessage id="reportsRegistryPdf.filters" />
                    </Text>
                    <View style={styles.detailsSectionsContainer}>
                        <View style={styles.detailsLeftSectionContainer}>
                            <View style={styles.detailsSection}>
                                <View>
                                    <Text style={styles.sectionTitle}>
                                        <FormattedMessage id="reportsRegistryPdf.filters.date"></FormattedMessage>
                                    </Text>
                                    {filters.from && filters.to ? (
                                        <Text style={styles.sectionValue}>
                                            {`${format(
                                                new Date(filters.from),
                                                'dd.MM.yyyy',
                                            )} - ${format(new Date(filters.to), 'dd.MM.yyyy')}`}
                                        </Text>
                                    ) : (
                                        <Text>
                                            <FormattedMessage id="reportsRegistryPdf.filters.none" />
                                        </Text>
                                    )}
                                </View>
                            </View>
                            <View style={styles.detailsSection}>
                                <View>
                                    <Text style={styles.sectionTitle}>
                                        <FormattedMessage id="reportsRegistryPdf.filters.status"></FormattedMessage>
                                    </Text>
                                    {filters.status && filters.status.length > 0 ? (
                                        <Text style={styles.sectionValue}>
                                            {filters.status
                                                .map(
                                                    (status) =>
                                                        reportsForDashboardStatusFilter[
                                                            getUserLanguage()
                                                        ][status],
                                                )
                                                .join(', ')}
                                        </Text>
                                    ) : (
                                        <Text style={styles.sectionValue}>
                                            <FormattedMessage id="reportsRegistryPdf.filters.none" />
                                        </Text>
                                    )}
                                </View>
                            </View>
                            <View style={styles.detailsSection}>
                                <View>
                                    <Text style={styles.sectionTitle}>
                                        <FormattedMessage id="reportsRegistryPdf.filters.whistleblower"></FormattedMessage>
                                    </Text>
                                    {filters.whistleblower && filters.whistleblower.length > 0 ? (
                                        <Text style={styles.sectionValue}>
                                            {filters.whistleblower
                                                .map(
                                                    (el) => pdfsTranslations[getUserLanguage()][el],
                                                )
                                                .join(', ')}
                                        </Text>
                                    ) : (
                                        <Text style={styles.sectionValue}>
                                            <FormattedMessage id="reportsRegistryPdf.filters.none" />
                                        </Text>
                                    )}
                                </View>
                            </View>
                        </View>

                        <View style={styles.detailsCenterSectionContainer}></View>

                        <View style={styles.detailsRightSectionContainer}>
                            <View style={styles.detailsSection}>
                                <View>
                                    <Text style={styles.sectionTitle}>
                                        <FormattedMessage id="finalProtocolPdf.relation"></FormattedMessage>
                                    </Text>
                                    {filters?.relation && filters.relation.length > 0 ? (
                                        filters.relation.length ===
                                        Object.keys(Whistleblower_Relation).length - 1 ? (
                                            <Text style={styles.sectionValue}>
                                                <FormattedMessage id="reportsRegistryPdf.filters.whistleblowerRelationsAll" />
                                            </Text>
                                        ) : (
                                            <View style={styles.list}>
                                                {filters.relation.map((el) => (
                                                    <View style={styles.listElement}>
                                                        <Text>
                                                            {`• ${
                                                                whistleblowerRelations[
                                                                    getUserLanguage()
                                                                ][el]
                                                            }`}
                                                        </Text>
                                                    </View>
                                                ))}
                                            </View>
                                        )
                                    ) : (
                                        <Text style={styles.sectionValue}>
                                            <FormattedMessage id="reportsRegistryPdf.filters.none" />
                                        </Text>
                                    )}
                                </View>
                            </View>
                            <View style={styles.detailsSection}>
                                <View>
                                    <Text style={styles.sectionTitle}>
                                        <FormattedMessage id="finalProtocolPdf.category"></FormattedMessage>
                                    </Text>
                                    {filters.category && filters.category.length > 0 ? (
                                        <View style={styles.list}>
                                            {filters.category.map((el) => (
                                                <View style={styles.listElement}>
                                                    <Text>
                                                        {`• ${categories[getUserLanguage()][el]}`}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                    ) : (
                                        <Text style={styles.sectionValue}>
                                            <FormattedMessage id="reportsRegistryPdf.filters.none" />
                                        </Text>
                                    )}
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.table}>
                        <View style={styles.firstRowsContainer}>
                            <Text style={styles.firstRow}>
                                <FormattedMessage id="finalProtocolPdf.createdAt" />
                            </Text>
                            <Text style={styles.firstRow}>
                                <FormattedMessage id="finalProtocolPdf.closedDate" />
                            </Text>
                            <Text style={styles.firstRow}>
                                <FormattedMessage id="finalProtocolPdf.reportNumber" />
                            </Text>
                            <Text style={styles.firstRow}>
                                <FormattedMessage id="reportsRegistryPdf.table.status" />
                            </Text>
                            <Text style={styles.firstRow}>
                                <FormattedMessage id="finalProtocolPdf.whistleblower" />
                            </Text>
                            <Text style={styles.firstRow}>
                                <FormattedMessage id="finalProtocolPdf.relation" />
                            </Text>
                            <Text style={styles.firstRow}>
                                <FormattedMessage id="finalProtocolPdf.category" />
                            </Text>
                            <Text style={styles.firstRow}>
                                <FormattedMessage id="finalProtocolPdf.contactData" />
                            </Text>
                        </View>
                        {reports.map((row, i) => (
                            <View key={i} style={styles.rowContainer} wrap={true}>
                                <Text style={styles.row}>
                                    {format(new Date(row.createdAt), 'dd.MM.yyyy')}
                                </Text>
                                <Text style={styles.row}>
                                    {row.closedInformations ? (
                                        format(new Date(row.closedInformations.date), 'dd.MM.yyyy')
                                    ) : (
                                        <Text>
                                            <FormattedMessage id="reportsRegistryPdf.filters.none" />
                                        </Text>
                                    )}
                                </Text>
                                <Text style={styles.row}>{row.reportNumber}</Text>
                                <Text style={styles.row}>
                                    {reportsForDashboardStatusFilter[getUserLanguage()][row.status]}
                                </Text>
                                <Text style={styles.row}>
                                    {row.whistleblower ? (
                                        `${row.whistleblower.name} ${row.whistleblower.surname}`
                                    ) : (
                                        <FormattedMessage id="finalProtocolPdf.anonymous" />
                                    )}
                                </Text>
                                <Text style={styles.row}>
                                    {row.whistleblowerRelation?.other
                                        ? row.whistleblowerRelation.other
                                        : whistleblowerRelations[getUserLanguage()][
                                              row.whistleblowerRelation.relation
                                          ]}
                                </Text>
                                <Text style={styles.row}>
                                    {row.otherCategory
                                        ? row.otherCategory
                                        : categories[getUserLanguage()][row.category]}
                                </Text>
                                <Text style={styles.row}>
                                    {row.whistleblower ? (
                                        `${row.whistleblower.email || ''}${
                                            row.whistleblower.email && row.whistleblower.phone
                                                ? ','
                                                : ''
                                        }, ${row.whistleblower.phone || ''}`
                                    ) : (
                                        <Text>
                                            <FormattedMessage id="reportsRegistryPdf.filters.none" />
                                        </Text>
                                    )}
                                </Text>
                            </View>
                        ))}
                    </View>
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) =>
                            `${
                                pdfsTranslations[getUserLanguage()].page
                            } ${pageNumber} / ${totalPages}`
                        }
                        fixed
                    />
                </Page>
            </Document>
        </IntlProvider>
    );
};

Font.register({
    family: 'Poppins',
    fonts: [
        {
            src: '/fonts/Poppins-Light.ttf',
            fontWeight: 300,
        },
        {
            src: '/fonts/Poppins-Medium.ttf',
            fontWeight: 400,
        },
        {
            src: '/fonts/Poppins-SemiBold.ttf',
            fontWeight: 500,
        },
        {
            src: '/fonts/Poppins-Bold.ttf',
            fontWeight: 600,
        },
    ],
});

const styles = StyleSheet.create({
    body: {
        paddingTop: '32px',
        paddingHorizontal: '32px',
        paddingBottom: '48px',
        fontFamily: 'Poppins',
    },
    headerSection: {
        marginBottom: '20px',
    },
    firstSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    secondSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    leftFirstSection: {
        fontSize: '24px',
        fontWeight: 400,
        marginBottom: '9px',
        marginTop: '6px',
    },
    rightFirstSection: {
        width: '134px',
        height: '50',
    },
    leftSecondSection: {
        fontSize: '8px',
        fontWeight: 500,
    },
    rightSecondSection: {
        fontSize: '8px',
        fontWeight: 500,
        paddingRight: '11px',
    },
    thirdSection: {
        alignSelf: 'flex-end',
        paddingRight: '11px',
        fontSize: '8px',
        fontWeight: 300,
    },
    leftThirdSection: {
        fontSize: '8px',
        fontWeight: 500,
        alignSelf: 'flex-start',
    },
    creatorContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    sectionHeader: {
        fontSize: '14px',
        fontWeight: 400,
        marginBottom: '8px',
    },
    sectionTitle: {
        fontSize: '9px',
        fontWeight: 500,
    },
    sectionValue: {
        fontWeight: 300,
        fontSize: '9px',
        marginTop: '3px',
    },
    divider: {
        width: '100%',
        opacity: 0.16,
        borderBottom: '1px solid black',
        paddingHorizontal: '32px',
        marginTop: '20px',
        marginBottom: '20px',
    },
    detailsSectionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 20,
    },
    detailsLeftSectionContainer: {
        display: 'flex',
        flex: 2,
    },
    detailsCenterSectionContainer: {
        display: 'flex',
        backgroundColor: 'black',
        opacity: 0.16,
        width: '1px',
        marginLeft: '50px',
        marginRight: '10px',
    },
    detailsSection: {
        marginBottom: '10px',
    },
    detailsRightSectionContainer: {
        display: 'flex',
        flex: 3,
        flexDirection: 'column',
    },
    grayContainer: {
        marginTop: '30px',
        backgroundColor: '#f4f4f4',
        width: '100%',
        height: 'auto',
        paddingBottom: '6px',
    },
    closedMessageHeader: {
        margin: '12px',
        marginBottom: '4px',
    },
    closedMessageContent: {
        backgroundColor: 'white',
        fontSize: '9px',
        padding: '4px',
        marginLeft: '4px',
        marginRight: '4px',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        marginTop: 10,
        bottom: 30,
        paddingTop: '7px',
        left: 32,
        right: 32,
        textAlign: 'right',
        color: 'grey',
        borderTop: '1px solid #f4f4f4',
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        fontWeight: 300,
        fontSize: '9px',
    },
    listElement: {},
    table: {
        width: '100%',
        fontSize: '6px',
    },
    firstRow: {
        flex: 1,
    },
    firstRowsContainer: {
        display: 'flex',
        paddingTop: 19,
        paddingLeft: 8,
        paddingBottom: 18,
        flexDirection: 'row',
        backgroundColor: '#f4f4f4',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 8,
        paddingBottom: 8,
        flex: 1,
    },
    rowContainer: {
        borderBottom: '1px solid #D6D6D6',
        paddingLeft: 8,
        display: 'flex',
        flexDirection: 'row',
    },
    header: {
        borderTop: 'none',
    },
});
