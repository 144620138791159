import { DefaultLayout } from '@components/Layouts';
import { Title, Img, Card, Paragraph, Button } from '@components/_universal';
import img from '@assets/images/feeling-sorry-cuate.svg';
import useRegistrationLinkExpiredPage from './useRegistrationLinkExpiredPage';
import { FormattedMessage } from 'react-intl';

const RegistrationLinkExpiredPage = () => {
    const { redirectToRegistrationPage } = useRegistrationLinkExpiredPage();
    return (
        <DefaultLayout>
            <Card>
                <Img src={img} alt="Link aktywacyjny wygasł" />
                <Title marginTop="0" center={true} id="registrationLinkExpiredPage.title" />
                <Paragraph center marginTop="20px" fontSize="15px" marginBottom="40px">
                    <FormattedMessage id="registrationLinkExpiredPage.accountNotCreated" />
                </Paragraph>
                <Button size="full" variant="primary" onClick={() => redirectToRegistrationPage()}>
                    <FormattedMessage id="registrationLinkExpiredPage.createAccountButton" />
                </Button>
            </Card>
        </DefaultLayout>
    );
};

export default RegistrationLinkExpiredPage;
