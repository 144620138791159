import styled from 'styled-components';

export const HeaderContainer = styled.div`
    width: 100%;
`;

export const HeaderWrapper = styled.div`
    height: 72px;
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    background: #fff;
    z-index: 10;

    ${(props) => props.theme.media.md} {
        padding-right: 24px;
    }
`;

export const Link = styled.a`
    display: inline-block;
    padding-left: 10px;
    margin-left: 20px;
    text-decoration: none;

    ${(props) => props.theme.media.md} {
        padding-left: 15px;
        margin-left: 30px;
        width: 220px;
        border-right: 1px solid ${(props) => props.theme.colors.border};
    }
`;
