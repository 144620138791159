import refreshIcon from '@assets/images/icon-refresh.svg';
import styled from 'styled-components';

export const Refresh = styled.button`
    background: url(${refreshIcon}) 2px 50% no-repeat;
    background-size: 24px;
    height: 36px;
    font-size: 14px;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    color: ${(props) => props.theme.colors.primary};
    padding: 3px 10px 3px 40px;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;
