import { Title, Number, Text } from './StepTitle.styled';
import { IStepTitleProps } from './StepTitle.d';

const StepTitle = ({ children, number }: IStepTitleProps) => {
    return (
        <Title>
            <Number>{number}</Number>
            <Text>{children}</Text>
        </Title>
    );
};

export default StepTitle;
