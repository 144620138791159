import { DefaultLayout } from '@components/Layouts';
import { Button, Paragraph } from '@components/_universal';
import FormWrapper from '@domains/ReportDomain/Components/FormWrapper/FormWrapper';
import StepTitle from '@domains/ReportDomain/Components/StepTitle/StepTitle';
import useReportStepThree from './useReportStepThree';
import { DevTool } from '@hookform/devtools';
import Person from '../../Components/Person/Person';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Grid } from '@material-ui/core';
import { IReportStepProps } from '../ReportStepOne/ReportStepOne.d';
import { FormattedMessage, useIntl } from 'react-intl';

const ReportStepThree = ({
    activeStep,
    setStep,
    lastStep,
    companyName,
    companyLogo,
}: IReportStepProps) => {
    const { watch, setValue, fields, append, remove, control, register, shouldShowNextBtn } =
        useReportStepThree();
    const intl = useIntl();
    return (
        <DefaultLayout
            alignItemsCenter
            setFullHeight
            companyName={companyName}
            companyLogo={companyLogo}
        >
            <FormWrapper
                activeStep={activeStep}
                setStep={setStep}
                lastStep={lastStep}
                nextBtnText={
                    shouldShowNextBtn
                        ? intl.formatMessage({ id: 'reportForm.nextStepButton' })
                        : intl.formatMessage({ id: 'reportForm.skipStepButton' })
                }
            >
                <StepTitle number={3}>
                    <FormattedMessage id="reportForm.stepThree.title" />
                </StepTitle>
                <Paragraph marginTop="30px" fontSize="15px">
                    <FormattedMessage id="reportForm.stepThree.paragraph" />
                </Paragraph>
                {fields.map((item, index) => {
                    return (
                        <Person
                            key={item.id}
                            index={index}
                            data={item as any}
                            remove={remove}
                            register={register}
                            setValue={setValue}
                            watch={watch}
                        />
                    );
                })}
                <Grid container justifyContent="center">
                    <Button
                        variant="text"
                        size="medium"
                        className="black"
                        icon={<AddCircleIcon />}
                        onClick={() => {
                            append({});
                        }}
                    >
                        <FormattedMessage id="reportForm.stepThree.addPersonButton" />
                    </Button>
                </Grid>
            </FormWrapper>
            <DevTool control={control} placement="top-right" />
        </DefaultLayout>
    );
};

export default ReportStepThree;
