import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { INewPasswordPageForm, INewPasswordPageQueryParams } from './NewPasswordPage.d';
import { useNavigate } from 'react-router';
import jwt_decode from 'jwt-decode';
import paths from '@shared/paths';
import { ConfirmResetPasswordInput, useConfirmResetPasswordMutation } from 'src/generated/graphql';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

export default function useNewPassword() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const intl = useIntl();
    const [_confirmResetPasswordResult, confirmResetPassword] = useConfirmResetPasswordMutation();
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<INewPasswordPageForm>();

    const onConfirm = async ({ password }: { password: string }) => {
        try {
            const input: ConfirmResetPasswordInput = {
                password,
                resetPasswordToken: searchParams.get('token') as string,
            };
            const result = await confirmResetPassword({ input });
            if (result.error) {
                throw new Error();
            }
            setError('');
            navigate(paths.resetPasswordConfirmation);
        } catch (err) {
            setError(intl.formatMessage({ id: 'validation.unknownError' }));
        }
    };

    useEffect(() => {
        if (searchParams.get('token')) {
            const decodedToken = jwt_decode<{ exp: number }>(searchParams.get('token') as string);
            const expInMiliseconds = decodedToken.exp * 1000;
            if (new Date(expInMiliseconds).valueOf() < new Date().valueOf()) {
                navigate(paths.resetLinkExpired);
            }
        }
    }, []);

    return {
        onConfirm,
        error,
        handleSubmit,
        errors,
        register,
        control,
        watch,
        intl,
    };
}
