import { Page, Text, Image, Document, StyleSheet, Font, View } from '@react-pdf/renderer';
import LogoImg from '@assets/images/logo.png';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { en, pl } from '../translations';
import { IntlProvider } from 'react-intl';
import useStore from '../store/useStore';
import { Report_Category, WhistleblowerRelation } from '../generated/graphql';
import whistleblowerRelations from '../dict/whistleblowerRelations';
import getUserLanguage from '../utils/getUserLanguage';
import categories from '../dict/categories';
import pdfsTranslations from '../dict/pdfsTranslations';
type Props = {
    reports: {
        reportNumber: string;
        category: Report_Category | string;
        whistleblowerRelation: WhistleblowerRelation;
        whistleblower?: {
            name: string;
            surname: string;
            email: string;
            phone: string;
        };
        closedInformations?: {
            statement: string;
            date: Date;
        };
        createdAt: Date;
        otherCategory?: string;
    }[];
    reporter: {
        nameAndSurname: string;
        email: string;
    };
    companyName: string;
};

export const FinalProtocolPdf = ({ reports, reporter, companyName }: Props) => {
    const { language } = useStore();
    const SUPPORTED_TRANSLATIONS: { [key: string]: {} } = {
        en,
        pl,
    };
    return (
        <IntlProvider
            defaultLocale="pl"
            locale={language}
            messages={SUPPORTED_TRANSLATIONS[language]}
        >
            <Document>
                {reports.map((report, key) => (
                    <Page style={styles.body} key={key}>
                        <View style={styles.headerSection}>
                            <View style={styles.firstSection}>
                                <Text style={styles.leftFirstSection}>
                                    <FormattedMessage id="finalProtocolPdf.header" />
                                </Text>
                                <Image src={LogoImg} style={styles.rightFirstSection}></Image>
                            </View>
                            <View style={styles.secondSection}>
                                <Text style={styles.leftSecondSection}>
                                    <Text style={{ fontWeight: 300 }}>
                                        <FormattedMessage id="finalProtocolPdf.generatedAt" />
                                    </Text>
                                    <Text style={{ fontWeight: 600 }}>
                                        {` ${format(new Date(), 'dd.MM.yyyy, kk:mm')}`}
                                    </Text>
                                </Text>
                                <Text style={styles.rightSecondSection}>Notibox sp. z o.o.</Text>
                            </View>
                            <View style={styles.thirdSection}>
                                <Text style={styles.leftThirdSection}>
                                    <Text style={{ fontWeight: 300 }}>
                                        <FormattedMessage id="finalProtocolPdf.generatedFor" />
                                    </Text>
                                    <Text style={{ fontWeight: 600 }}>{` ${companyName}`}</Text>
                                </Text>
                                <Text style={styles.rightThirdSection}>www.notibox.pl</Text>
                            </View>
                        </View>

                        <Text style={styles.sectionHeader}>
                            <FormattedMessage id="finalProtocolPdf.creator" />
                        </Text>
                        <View style={styles.creatorContainer}>
                            <View style={{ flex: 1 }}>
                                <Text style={styles.sectionTitle}>
                                    <FormattedMessage id="finalProtocolPdf.nameAndSurname"></FormattedMessage>
                                </Text>
                                <Text style={styles.sectionValue}>{reporter.nameAndSurname}</Text>
                            </View>
                            <View style={{ flex: 1 }}>
                                <Text style={styles.sectionTitle}>
                                    <FormattedMessage id="finalProtocolPdf.email"></FormattedMessage>
                                </Text>
                                <Text style={styles.sectionValue}>{reporter.email}</Text>
                            </View>
                            <View style={{ flex: 1 }}></View>
                        </View>

                        <View style={styles.divider}></View>

                        <Text style={styles.sectionHeader}>
                            <FormattedMessage id="finalProtocolPdf.reportDetails" />
                        </Text>
                        <View style={styles.detailsSectionsContainer}>
                            <View style={styles.detailsLeftSectionContainer}>
                                <View style={styles.detailsSection}>
                                    <View>
                                        <Text style={styles.sectionTitle}>
                                            <FormattedMessage id="finalProtocolPdf.createdAt"></FormattedMessage>
                                        </Text>
                                        <Text style={styles.sectionValue}>
                                            {format(new Date(report.createdAt), 'dd.MM.yyyy')}
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.detailsSection}>
                                    <View>
                                        <Text style={styles.sectionTitle}>
                                            <FormattedMessage id="finalProtocolPdf.closedDate"></FormattedMessage>
                                        </Text>
                                        <Text style={styles.sectionValue}>
                                            {report.closedInformations?.date
                                                ? format(
                                                      new Date(report.closedInformations.date),
                                                      'dd.MM.yyyy',
                                                  )
                                                : ''}
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.detailsSection}>
                                    <View>
                                        <Text style={styles.sectionTitle}>
                                            <FormattedMessage id="finalProtocolPdf.reportNumber"></FormattedMessage>
                                        </Text>
                                        <Text style={styles.sectionValue}>
                                            {report.reportNumber}
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.detailsSection}>
                                    <View>
                                        <Text style={styles.sectionTitle}>
                                            <FormattedMessage id="finalProtocolPdf.whistleblower"></FormattedMessage>
                                        </Text>
                                        {report.whistleblower ? (
                                            <Text style={styles.sectionValue}>
                                                {report.whistleblower.name}{' '}
                                                {report.whistleblower.surname}
                                            </Text>
                                        ) : (
                                            <Text style={styles.sectionValue}>
                                                <FormattedMessage id="finalProtocolPdf.anonymous"></FormattedMessage>
                                            </Text>
                                        )}
                                    </View>
                                </View>
                                <View style={styles.detailsSection}>
                                    <View>
                                        <Text style={styles.sectionTitle}>
                                            <FormattedMessage id="finalProtocolPdf.contactData"></FormattedMessage>
                                        </Text>
                                        {report.whistleblower ? (
                                            <Text style={styles.sectionValue}>
                                                {report.whistleblower.email}
                                                {report.whistleblower.email &&
                                                report.whistleblower.phone
                                                    ? ', '
                                                    : ' '}
                                                {report.whistleblower.phone}
                                            </Text>
                                        ) : (
                                            <Text style={styles.sectionValue}>
                                                <FormattedMessage id="finalProtocolPdf.contactData.none"></FormattedMessage>
                                            </Text>
                                        )}
                                    </View>
                                </View>
                            </View>

                            <View style={styles.detailsCenterSectionContainer}></View>

                            <View style={styles.detailsRightSectionContainer}>
                                <View style={styles.detailsSection}>
                                    <View>
                                        <Text style={styles.sectionTitle}>
                                            <FormattedMessage id="finalProtocolPdf.relation"></FormattedMessage>
                                        </Text>
                                        <Text style={styles.sectionValue}>
                                            {report.whistleblowerRelation?.other ||
                                                (report.whistleblowerRelation?.relation &&
                                                    whistleblowerRelations[getUserLanguage()][
                                                        report.whistleblowerRelation?.relation
                                                    ]) ||
                                                ''}
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.detailsSection}>
                                    <View>
                                        <Text style={styles.sectionTitle}>
                                            <FormattedMessage id="finalProtocolPdf.category"></FormattedMessage>
                                        </Text>
                                        <Text style={styles.sectionValue}>
                                            {report.category !== Report_Category.Other
                                                ? categories[getUserLanguage()][
                                                      report.category as Report_Category
                                                  ]
                                                : `${
                                                      categories[getUserLanguage()][report.category]
                                                  } - ${report.otherCategory}`}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={styles.grayContainer}>
                            <View style={styles.closedMessageHeader}>
                                <Text style={styles.sectionHeader}>
                                    <FormattedMessage id="finalProtocolPdf.closedMessage" />
                                </Text>
                            </View>
                            <View style={styles.closedMessageContent}>
                                <Text>{report.closedInformations?.statement}</Text>
                            </View>
                        </View>

                        <Text
                            style={styles.pageNumber}
                            render={({ pageNumber, totalPages }) =>
                                `${
                                    pdfsTranslations[getUserLanguage()].page
                                } ${pageNumber} / ${totalPages}`
                            }
                            fixed
                        />
                    </Page>
                ))}
            </Document>
        </IntlProvider>
    );
};

Font.register({
    family: 'Poppins',
    fonts: [
        {
            src: '/fonts/Poppins-Light.ttf',
            fontWeight: 300,
        },
        {
            src: '/fonts/Poppins-Medium.ttf',
            fontWeight: 400,
        },
        {
            src: '/fonts/Poppins-SemiBold.ttf',
            fontWeight: 500,
        },
        {
            src: '/fonts/Poppins-Bold.ttf',
            fontWeight: 600,
        },
    ],
});

const styles = StyleSheet.create({
    body: {
        paddingTop: '32px',
        paddingHorizontal: '32px',
        paddingBottom: '48px',
        fontFamily: 'Poppins',
    },
    headerSection: {
        marginBottom: '20px',
    },
    firstSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    secondSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    thirdSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    leftFirstSection: {
        fontSize: '24px',
        fontWeight: 400,
        marginBottom: '9px',
        marginTop: '6px',
    },
    rightFirstSection: {
        width: '134px',
        height: '50',
    },
    leftSecondSection: {
        fontSize: '8px',
        fontWeight: 500,
    },
    leftThirdSection: {
        fontSize: '8px',
        fontWeight: 500,
    },
    rightThirdSection: {
        paddingRight: '11px',
        fontSize: '8px',
        fontWeight: 300,
    },
    rightSecondSection: {
        fontSize: '8px',
        fontWeight: 500,
        paddingRight: '11px',
    },
    fourthSection: {
        alignSelf: 'flex-end',
        paddingRight: '11px',
        fontSize: '8px',
        fontWeight: 300,
    },
    creatorContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    sectionHeader: {
        fontSize: '14px',
        fontWeight: 400,
        marginBottom: '8px',
    },
    sectionTitle: {
        fontSize: '9px',
        fontWeight: 500,
    },
    sectionValue: {
        fontWeight: 300,
        fontSize: '9px',
        marginTop: '3px',
    },
    divider: {
        width: '100%',
        opacity: 0.16,
        borderBottom: '1px solid black',
        paddingHorizontal: '32px',
        marginTop: '20px',
        marginBottom: '20px',
    },
    detailsSectionsContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    detailsLeftSectionContainer: {
        display: 'flex',
        width: '40%',
    },
    detailsCenterSectionContainer: {
        display: 'flex',
        borderLeft: '1px solid black',
        opacity: 0.16,
        marginLeft: '50px',
        marginRight: '10px',
        width: '5%',
    },
    detailsSection: {
        marginBottom: '10px',
    },
    detailsRightSectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '45%',
    },
    grayContainer: {
        marginTop: '30px',
        backgroundColor: '#f4f4f4',
        width: '100%',
        height: 'auto',
        paddingBottom: '6px',
    },
    closedMessageHeader: {
        margin: '12px',
        marginBottom: '4px',
    },
    closedMessageContent: {
        backgroundColor: 'white',
        fontSize: '9px',
        padding: '4px',
        marginLeft: '4px',
        marginRight: '4px',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 8,
        bottom: 30,
        paddingTop: '7px',
        left: 32,
        right: 32,
        textAlign: 'right',
        color: 'grey',
        borderTop: '1px solid #f4f4f4',
    },
});
