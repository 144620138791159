import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
    position: sticky;
    bottom: 0;
    left: 0;
    width: calc(100% + 40px);
    margin-left: -20px;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${(props) => props.theme.colors.border};
    padding: 20px;
    margin-top: 20px;
    flex-wrap: wrap;

    > button:last-of-type {
        margin-top: 20px;
    }
`;
