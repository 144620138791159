import { DefaultLayout } from '@components/Layouts';
import { Title, Img, Card, Paragraph } from '@components/_universal';
import img from '@assets/images/credit-card-cuate.svg';
import { Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import Highlight from '../../../components/_universal/Highlight/Highlight';
import Item from '../Components/Item/Item';
import Subtitle from '../Components/Subtitle/Subtitle';
import Info from '../Components/Info/Info';
import { FormattedMessage } from 'react-intl';
import { resolveEnv } from '@utils/helpers';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
type LocationState = {
    email: string;
    domain: string;
};

const RegistrationPaymentPage = () => {
    const theme = useTheme();
    const { email, domain } = (useLocation()?.state as LocationState) || { email: '', domain: '' };
    const fullDomain = `https://${domain}.${resolveEnv('REACT_APP_BASE_REPORT_DOMAIN')}`;
    useEffect(() => {
        if (+resolveEnv('REACT_APP_ENABLE_GTM') === 1) {
            TagManager.initialize({ gtmId: 'AW-11099933413' });
        }
    }, []);
    return (
        <DefaultLayout enablePaddingBottomOnMobile>
            <Card wide>
                <Img src={img} alt="Dokonaj płatności i gotowe!" />
                <Title marginTop="0" center={true} id="registrationPaymentPage.title" />
                <Subtitle>
                    <FormattedMessage id="registrationPaymentPage.subtitle" />
                    <a href={fullDomain} target="_blank">
                        {' ' + fullDomain}
                    </a>
                </Subtitle>
                <Paragraph
                    marginTop="20px"
                    fontSize={useMediaQuery(theme.breakpoints.down('xs')) ? '14px' : '18px'}
                    marginBottom="40px"
                >
                    <FormattedMessage id="registrationPaymentPage.shareAddress" />
                </Paragraph>
                <Info>
                    <FormattedMessage id="registrationPaymentPage.trialInfo" />
                </Info>
                <Paragraph
                    marginTop="20px"
                    marginBottom="40px"
                    fontSize={useMediaQuery(theme.breakpoints.down('xs')) ? '14px' : '18px'}
                >
                    <FormattedMessage id="registrationPaymentPage.paymentInstructions" />
                    <strong> {email}</strong>.
                </Paragraph>
                <Highlight>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Item>
                                <FormattedMessage id="registrationPaymentPage.receiverName" />
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Paragraph
                                left
                                fontWeight="semiBold"
                                fontSize="15px"
                                marginBottom="5px"
                            >
                                Notibox Sp. z o.o.
                            </Paragraph>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Item>
                                <FormattedMessage id="registrationPaymentPage.accountNumber" />
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Paragraph
                                left
                                fontSize="15px"
                                marginBottom="5px"
                                fontWeight="semiBold"
                            >
                                25 1140 2004 0000 3402 8193 1769 MBANK
                            </Paragraph>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Item>
                                <FormattedMessage id="registrationPaymentPage.swiftCode" />
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Paragraph
                                left
                                fontSize="15px"
                                marginBottom="5px"
                                fontWeight="semiBold"
                            >
                                INGBPLPW
                            </Paragraph>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Item>
                                <FormattedMessage id="registrationPaymentPage.paymentTitle" />
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Paragraph
                                left
                                fontSize="15px"
                                marginBottom="5px"
                                fontWeight="semiBold"
                            >
                                {email}
                            </Paragraph>
                        </Grid>
                    </Grid>
                </Highlight>
                <Paragraph
                    marginTop="40px"
                    marginBottom="45px"
                    fontSize={useMediaQuery(theme.breakpoints.down('xs')) ? '14px' : '18px'}
                >
                    <FormattedMessage id="registrationPaymentPage.emailNotification" />
                </Paragraph>
                <Divider />
                <Paragraph
                    marginTop="23px"
                    marginBottom="30px"
                    fontSize={useMediaQuery(theme.breakpoints.down('xs')) ? '14px' : '15px'}
                    center
                >
                    <FormattedMessage id="registrationPaymentPage.questions" />
                </Paragraph>
            </Card>
        </DefaultLayout>
    );
};

export default RegistrationPaymentPage;
