import styled from 'styled-components';

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export const Main = styled.main`
    flex: 1 0 auto;
    background: '#ffffff';
`;

export const Content = styled.div`
    padding: 10px 0 0;

    ${(props) => props.theme.media.md} {
        padding: 30px 0 0;
    }
`;
