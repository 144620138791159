import { IImgProps } from './Img.d';
import { ImgWrapper } from './Img.styles';

const Img = ({ src, alt, noPadding }: IImgProps) => {
    return (
        <ImgWrapper noPadding={noPadding}>
            <img src={src} alt={alt} />
        </ImgWrapper>
    );
};

export default Img;
