import { makeStyles, createStyles } from '@material-ui/core/styles';
import defaultTheme from '@shared/theme/default.theme';
import styled from 'styled-components';

export const useStyles = makeStyles(() =>
    createStyles({
        small: {
            width: '40px',
            height: '40px',
            fontSize: '14px',
            color: defaultTheme.colors.text,
            background: defaultTheme.colors.border,
        },
    }),
);

export const UserMenuWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 0;
`;
