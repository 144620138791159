import { Button, QuickMenu } from '@components/_universal';
import useReportDetails from './useReportDetails';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Grid, useMediaQuery } from '@material-ui/core';
import {
    Report_Category,
    Report_Status,
    Update_Report_Status,
    Whistleblower_Relation,
} from 'src/generated/graphql';
import paths from '@shared/paths';
import SignalistIcon from '@assets/images/icon-account-security-small.svg';
import AnonymousIcon from '@assets/images/icon-anonymus-on.svg';
import { ExpandableGrid } from './ReportDetails.styled';
import categories from 'src/dict/categories';
import { format } from 'date-fns';
import whistleblowerRelations from 'src/dict/whistleblowerRelations';
import recurringFrequency from 'src/dict/recurringFrequency';
import {
    DetailsTitleWithReport,
    ReportDetailsBar,
    ReportInfoRow,
    Modal,
    CloseCaseModal,
    Message,
    ShowDetails,
    DownloadMenu,
} from '@components/ReportDetails';
import { FormattedMessage, useIntl } from 'react-intl';
import getUserLanguage from '@utils/getUserLanguage';
import { useNavigate } from 'react-router';
import { AttachFile, ChatBubbleOutlineOutlined } from '@material-ui/icons';
import defaultTheme from '../../../shared/theme/default.theme';
import { useState } from 'react';
import UploadModal from '../../../components/ReportDetails/UploadModal/UploadModal';

export type ReportDetailsProps = {
    reportNumber: string;
    reexecuteGetReports: () => void;
};

const ReportDetails = ({ reportNumber, reexecuteGetReports }: ReportDetailsProps): any => {
    const {
        reportDetailsVisible,
        setReportDetailsVisible,
        openModal,
        setOpenModal,
        report,
        setDownloadFileId,
        updateReportStatus,
        sendReply,
        openCloseCaseModal,
        setOpenCloseCaseModal,
        closeReport,
        bottomEl,
        onRefresh,
    } = useReportDetails({ reportNumber, reexecuteGetReports });
    const navigate = useNavigate();
    const intl = useIntl();
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const closeAlertText = intl.formatMessage({ id: 'reportDetailsPage.closeAlert' });
    const isTablet = useMediaQuery(defaultTheme.media.xs, { noSsr: true });
    const sendMessageText = intl.formatMessage({ id: 'checkReportPage.sendMessageButton' });
    const messageText = intl.formatMessage({ id: 'checkReportPage.messageButton' });
    const uploadFileText = intl.formatMessage({ id: 'checkReportPage.sendFile' });
    const uploadFileShortText = intl.formatMessage({ id: 'checkReportPage.sendFileShort' });
    return report ? (
        <>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid>
                    <Button
                        onClick={() => navigate(paths.dashboard)}
                        variant="text"
                        className="bold"
                        icon={<ArrowBackIcon />}
                    >
                        <FormattedMessage id="reportDetailsPage.backButton" />
                    </Button>
                </Grid>

                <Grid style={{ display: 'flex' }}>
                    <Button
                        onClick={(e) => setOpenUploadModal(!openUploadModal)}
                        variant="text"
                        className="bold"
                        icon={<AttachFile />}
                    >
                        {isTablet ? uploadFileText : uploadFileShortText}
                    </Button>
                    <Button
                        onClick={(e) => setOpenModal(!openModal)}
                        variant="text"
                        className="bold"
                        icon={<ChatBubbleOutlineOutlined />}
                    >
                        {isTablet ? sendMessageText : messageText}
                    </Button>

                    <QuickMenu
                        name="SetStatus"
                        messageId={isTablet ? 'quickMenu.setStatusShort' : 'quickMenu.setStatus'}
                        options={[
                            {
                                name: intl.formatMessage({
                                    id: 'dashboardPage.selectOption.status.new',
                                }),
                                active:
                                    report.status === Report_Status.Received ||
                                    report.status === Report_Status.Sent,
                                value: Update_Report_Status.Received,
                            },
                            {
                                name: intl.formatMessage({
                                    id: 'dashboardPage.selectOption.status.inProgress',
                                }),
                                value: Update_Report_Status.InProgress,
                                active: report.status === Report_Status.InProgress,
                            },
                            {
                                name: intl.formatMessage({
                                    id: 'dashboardPage.selectOption.status.closed',
                                }),
                                value: Report_Status.Closed,
                                active: report.status === Report_Status.Closed,
                            },
                        ]}
                        type="SetStatus"
                        onClick={
                            report.status === Report_Status.Closed
                                ? () => alert(closeAlertText)
                                : updateReportStatus
                        }
                    />
                </Grid>
            </Grid>
            <DetailsTitleWithReport
                title={
                    report.category !== Report_Category.Other
                        ? categories[getUserLanguage()][report.category]
                        : `${categories[getUserLanguage()][report.category]} - ${
                              report.otherCategory
                          }`
                }
                reportNumber={report.reportNumber}
                date={format(new Date(report.createdAt), 'HH:mm, dd.MM.yyyy')}
            />
            <ExpandableGrid container visible={reportDetailsVisible}>
                <Grid item xs={12}>
                    <ReportInfoRow className="bold">
                        <div className="title">
                            <FormattedMessage id="reportDetailsPage.reporter" />
                        </div>
                        <div className="data">
                            <span>
                                <img
                                    src={report.whistleblower ? SignalistIcon : AnonymousIcon}
                                    alt="Sygnalista"
                                />
                                {report.whistleblower
                                    ? `${report.whistleblower.name} ${report.whistleblower.surname}`
                                    : intl.formatMessage({ id: 'reportDetailsPage.anonymous' })}
                            </span>
                            {report.whistleblowerRelation && (
                                <span>
                                    {report.whistleblowerRelation.other ||
                                        whistleblowerRelations[getUserLanguage()][
                                            report.whistleblowerRelation.relation
                                        ]}
                                </span>
                            )}
                            {report.whistleblower?.email && (
                                <a href={report.whistleblower.email}>
                                    {report.whistleblower.email}
                                </a>
                            )}
                            {report.whistleblower?.phone && (
                                <a href={`tel:${report.whistleblower.phone}`}>
                                    {report.whistleblower.phone}
                                </a>
                            )}
                        </div>
                    </ReportInfoRow>
                    {report?.personsInvolved && report.personsInvolved.length > 0 && (
                        <ReportInfoRow>
                            <div className="title">
                                <FormattedMessage id="reportDetailsPage.related" />
                            </div>

                            <div className="data">
                                {report.personsInvolved.map((person) => (
                                    <span key={person?.name}>
                                        {person?.name} {person?.surname}{' '}
                                        {person?.relation ? `(${person.relation})` : ''}
                                    </span>
                                ))}
                            </div>
                        </ReportInfoRow>
                    )}

                    {report.attachments && report.attachments.length > 0 && (
                        <ReportInfoRow>
                            <div className="title">
                                <FormattedMessage id="reportDetailsPage.attachments" />
                            </div>
                            <div className="data attachments">
                                {report.attachments.map((attachment, index) => (
                                    <a
                                        key={index}
                                        href=""
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setDownloadFileId(attachment._id);
                                        }}
                                    >
                                        {attachment._id}.{attachment.ext}
                                    </a>
                                ))}

                                <DownloadMenu
                                    files={report.attachments}
                                    onFileClick={setDownloadFileId}
                                />
                            </div>
                        </ReportInfoRow>
                    )}
                    {report.occurringDetails?.firstTimeOccurredAt && (
                        <ReportInfoRow>
                            <div className="title">
                                <FormattedMessage id="reportDetailsPage.date" />
                            </div>
                            <div className="data">
                                <>
                                    <span>
                                        {format(
                                            new Date(report.occurringDetails.firstTimeOccurredAt),
                                            'HH:mm, dd.MM.yyyy',
                                        )}
                                    </span>
                                    {report.occurringDetails.recurring &&
                                        report.occurringDetails.recurringFrequency && (
                                            <>
                                                <span>
                                                    <FormattedMessage id="reportDetailsPage.recurrent" />
                                                </span>
                                                <span>
                                                    {
                                                        recurringFrequency[getUserLanguage()][
                                                            report.occurringDetails
                                                                .recurringFrequency
                                                        ]
                                                    }
                                                </span>
                                            </>
                                        )}
                                </>
                            </div>
                        </ReportInfoRow>
                    )}
                </Grid>
            </ExpandableGrid>

            <ReportDetailsBar>
                <ShowDetails
                    visible={reportDetailsVisible}
                    onClick={() => {
                        setReportDetailsVisible(!reportDetailsVisible);
                    }}
                />
                {report.messages.map((message, index) =>
                    !message.sendBy ? (
                        <Grid key={index} container justifyContent="flex-start">
                            <Grid container justifyContent="flex-start">
                                <div className="signalist">
                                    <strong>
                                        {report.whistleblower
                                            ? `${report.whistleblower.name} ${report.whistleblower.surname}`
                                            : intl.formatMessage({
                                                  id: 'reportDetailsPage.anonymous',
                                              })}
                                    </strong>
                                    <span>
                                        {format(new Date(message.sendAt), 'HH:mm, dd.MM.yyyy')}
                                    </span>
                                </div>
                            </Grid>
                            {message?.content === null ? (
                                message?.attachments &&
                                message.attachments.map((attachment, i) => (
                                    <Grid container>
                                        <Message
                                            fileName={`${attachment._id}.${attachment.ext}`}
                                            onClick={() => {
                                                setDownloadFileId(attachment._id);
                                            }}
                                        />
                                    </Grid>
                                ))
                            ) : (
                                <Message>
                                    <p>{message.content}</p>
                                </Message>
                            )}
                        </Grid>
                    ) : (
                        <Grid key={index} container justifyContent="flex-end">
                            <Grid container justifyContent="flex-end">
                                <div className="signalist">
                                    <strong>
                                        {message.sendBy.name} {message.sendBy.surname}
                                    </strong>
                                    <span>
                                        {format(new Date(message.sendAt), 'HH:mm, dd.MM.yyyy')}
                                    </span>
                                </div>
                            </Grid>
                            {message?.content === null ? (
                                message?.attachments &&
                                message.attachments.map((attachment, i) => (
                                    <Grid container justifyContent="flex-end">
                                        <Message
                                            fileName={`${attachment._id}.${attachment.ext}`}
                                            onClick={() => {
                                                setDownloadFileId(attachment._id);
                                            }}
                                        />
                                    </Grid>
                                ))
                            ) : (
                                <Message>
                                    <p>{message.content}</p>
                                </Message>
                            )}
                        </Grid>
                    ),
                )}

                <Grid ref={bottomEl} container justifyContent="center">
                    <Button
                        variant="primary"
                        className="marginTop60"
                        onClick={(e) => setOpenModal(!openModal)}
                    >
                        <FormattedMessage id="reportDetailsPage.createMessage" />
                    </Button>
                </Grid>
            </ReportDetailsBar>
            <Modal openModal={openModal} setOpenModal={setOpenModal} sendReply={sendReply} />
            <UploadModal
                openUploadModal={openUploadModal}
                setOpenUploadModal={setOpenUploadModal}
                reportNumber={reportNumber}
                onRefresh={onRefresh}
                sendReportNumber
            />
            <CloseCaseModal
                openModal={openCloseCaseModal}
                setOpenModal={setOpenCloseCaseModal}
                onSend={closeReport}
                reexecuteGetReports={reexecuteGetReports}
            />
        </>
    ) : (
        <></>
    );
};

export default ReportDetails;
