import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoginWhistleblowerInput, useLoginWhistleblowerMutation } from 'src/generated/graphql';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import paths from '@shared/paths';
import { ILoginToReportRequest } from './LoginToReportPage.d';
import { useIntl } from 'react-intl';

export default function useLogin() {
    const [error, setError] = useState('');
    const [_, loginWhistleBlower] = useLoginWhistleblowerMutation();
    const intl = useIntl();
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ILoginToReportRequest>();

    const signin = async ({ password, reportNumber }: LoginWhistleblowerInput) => {
        try {
            const result = await loginWhistleBlower({
                input: {
                    password,
                    reportNumber: reportNumber.toUpperCase(),
                },
            });
            if (!result.data?.loginWhistleblower) {
                throw new Error();
            }
            LocalStorageManager.setValue(
                'sessionToken',
                result.data?.loginWhistleblower.sessionToken,
            );
            LocalStorageManager.setValue('refreshToken', '');
            window.location.href = `${paths.report}${reportNumber}`;
        } catch (err) {
            setShowReportNumberError(true);
            setShowPasswordError(true);
            setError(intl.formatMessage({ id: 'loginToReportPage.validation.loginError' }));
        }
    };

    const [showReportNumberError, setShowReportNumberError] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);

    useEffect(() => {
        if (errors.reportNumber) setShowReportNumberError(true);
        if (errors.password) setShowPasswordError(true);
    }, [errors]);

    return {
        signin,
        control,
        register,
        handleSubmit,
        errors,
        error,
        showReportNumberError,
        showPasswordError,
        intl,
    };
}
