import paths from '@shared/paths';
import { useNavigate } from 'react-router';

export default function useRegistrationLinkExpiredPage() {
    const navigate = useNavigate();

    const redirectToRegistrationPage = () => {
        navigate(paths.registration);
    };

    return {
        redirectToRegistrationPage,
    };
}
