import React, { ChangeEvent } from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import { IPasswordInputProps } from './PasswordInput.d';
import usePasswordInput from './usePasswordInput';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { CssTextField, Group } from '../Inputs.styles';
import { PasswordError } from './PasswordError/PasswordError';
import { UseFormRegister } from 'react-hook-form';
import { useIntl } from 'react-intl';

export const PasswordInput = React.forwardRef<
    HTMLInputElement,
    IPasswordInputProps & ReturnType<UseFormRegister<Record<string, any>>>
>(({ error, showSingleErrors, ...rest }, ref) => {
    const {
        handleMouseDownPassword,
        handleClickShowPassword,
        passwordValues,
        passwordErrors,
        handleChange,
    } = usePasswordInput();

    const intl = useIntl();
    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        handleChange(event);
        rest.onChange(event);
    };

    return (
        <Group>
            <CssTextField
                error={Boolean(error)}
                required
                variant="outlined"
                type={passwordValues.showPassword ? 'text' : 'password'}
                inputRef={ref}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {passwordValues.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                {...rest}
                onChange={handlePasswordChange}
            />
            {showSingleErrors && (
                <div style={{ marginTop: '10px' }}>
                    <PasswordError
                        status={passwordErrors.min}
                        errorCopy={intl.formatMessage(
                            {
                                id: 'registrationPage.validation.passwordChars',
                            },
                            { chars: 8 },
                        )}
                    />
                    <PasswordError
                        status={passwordErrors.digit}
                        errorCopy={intl.formatMessage({
                            id: 'registrationPage.validation.passwordNumber',
                        })}
                    />
                    <PasswordError
                        status={passwordErrors.specialCharacter}
                        errorCopy={intl.formatMessage({
                            id: 'registrationPage.validation.passwordSpecialChar',
                        })}
                    />
                    <PasswordError
                        status={passwordErrors.smallAndCapitalLetters}
                        errorCopy={intl.formatMessage({
                            id: 'registrationPage.validation.passwordSmallAndCapitalLetters',
                        })}
                    />
                </div>
            )}
        </Group>
    );
});

export default PasswordInput;
