import { useState } from 'react';
import { IPasswordResetRequest } from './ResetPasswordPage.d';
import { useForm } from 'react-hook-form';
import paths from '@shared/paths';
import { Language_Codes, useResetPasswordMutation } from 'src/generated/graphql';
import { useIntl } from 'react-intl';
import getUserLanguage from '@utils/getUserLanguage';
import { useNavigate } from 'react-router';

export default function useLogin() {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [_, resetPassword] = useResetPasswordMutation();
    const intl = useIntl();
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IPasswordResetRequest>();

    const onResetPassword = async (data: IPasswordResetRequest) => {
        try {
            const result = await resetPassword({
                input: {
                    ...data,
                    language: getUserLanguage() as Language_Codes,
                },
            });
            if (result.error) {
                throw new Error();
            }
            navigate(paths.resetPasswordNotify, { state: { email: data.email } });
        } catch (err) {
            setError(intl.formatMessage({ id: 'validation.unknownError' }));
        }
    };

    const onCancel = () => {
        navigate(paths.home);
    };

    return {
        onResetPassword,
        control,
        register,
        handleSubmit,
        onCancel,
        errors,
        error,
        intl,
    };
}
