import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { StyledButton } from './Button.styled';
import { IButtonProps } from './Button.d';

const Button: React.FC<IButtonProps> = forwardRef(
    ({ size = 'small', variant = 'text', ...rest }: IButtonProps = {}, ref) => {
        const { className, icon, children, textVariant, ...other } = rest;

        return (
            <StyledButton
                className={clsx(
                    size && `size-${size}`,
                    variant && `variant-${variant}`,
                    textVariant && `textVariant-${textVariant}`,
                    className,
                )}
                {...other}
                ref={ref}
            >
                <div className="blend"></div>
                {icon && <span className="icon">{icon}</span>}
                {children && <span className="label">{children}</span>}
            </StyledButton>
        );
    },
);

export default Button;
