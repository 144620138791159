import React from 'react';
import { ITextInputProps } from './TextInput.d';
import { CssTextField, Group } from '../Inputs.styles';

const TextInput = React.forwardRef<HTMLInputElement | HTMLSelectElement, ITextInputProps>(
    ({ error, ...rest }: ITextInputProps, ref) => {
        return (
            <Group>
                <CssTextField error={!!error} variant="outlined" inputRef={ref} {...rest} />
            </Group>
        );
    },
);

export default TextInput;
