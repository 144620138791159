import { DefaultLayout } from '@components/Layouts';
import FormWrapper from '@domains/ReportDomain/Components/FormWrapper/FormWrapper';
import StepTitle from '@domains/ReportDomain/Components/StepTitle/StepTitle';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { Radio, TextInput } from '@components/_form-elements/Inputs';
import { DevTool } from '@hookform/devtools';
import { IReportStepProps } from './ReportStepOne.d';
import useReportStepOne from './useReportStepOne';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Whistleblower_Relation } from 'src/generated/graphql';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import useGetCompanyInfoFromDomain from '@utils/hooks/useGetCompanyInfoFromDomain';

const ReportStepOne = ({
    activeStep,
    setStep,
    lastStep,
    companyName,
    companyLogo,
}: IReportStepProps) => {
    const { relationsArray, register, control, values, setValue } = useReportStepOne();
    const intl = useIntl();
    const result = useGetCompanyInfoFromDomain();
    return (
        <DefaultLayout
            alignItemsCenter
            setFullHeight
            companyName={companyName}
            companyLogo={companyLogo}
        >
            <FormWrapper
                activeStep={activeStep}
                setStep={setStep}
                lastStep={lastStep}
                nextBtnText={intl.formatMessage({ id: 'reportForm.nextStepButton' })}
                disableNextBtn={values?.relation?.length < 0 || values?.relation === undefined}
            >
                <StepTitle number={1}>
                    <p>
                        {intl.formatMessage(
                            { id: 'reportForm.stepOne.title' },
                            {
                                companyShortName: result.companyName,
                            },
                        )}
                    </p>
                </StepTitle>
                <Controller
                    name="relation"
                    render={({ field: { onChange, value } }) => (
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="relation"
                                value={value || ''}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                            >
                                {relationsArray.map((relation, i) => (
                                    <Radio
                                        key={relation.label + i}
                                        label={relation.label}
                                        value={relation.value}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    )}
                />
                {values?.relation === Whistleblower_Relation.Other && (
                    <TextInput
                        label={intl.formatMessage({ id: 'reportForm.stepOne.textInputLabel' })}
                        InputProps={{
                            endAdornment: values?.relationOther?.length > 0 && (
                                <IconButton
                                    onClick={() => {
                                        setValue('relationOther', '');
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        {...register('relationOther')}
                    />
                )}
            </FormWrapper>
            <DevTool control={control} placement="top-right" />
        </DefaultLayout>
    );
};

export default ReportStepOne;
