import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IFormData } from '../useReportForm';

export enum SHOW_INFO {
    SHOW = 'SHOW',
    HIDE = 'HIDE',
    NOTHING = 'NOTHING',
}

export default () => {
    const {
        control,
        register,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext<IFormData>();
    const [showInfo, setShowInfo] = useState(SHOW_INFO.NOTHING);

    useEffect(() => {
        setValue('anonymous', false);
    }, []);

    return {
        control,
        values: watch(),
        register,
        setValue,
        showInfo,
        errors,
        setShowInfo,
    };
};
