import { useState } from 'react';
import { IPasswordResetRequest } from './ResetLinkExpiredPage.d';
import { useForm } from 'react-hook-form';
import { useResetPasswordMutation } from 'src/generated/graphql';
import paths from '@shared/paths';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

export default function useLogin() {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const intl = useIntl();
    const [resetPasswordResult, resetPassword] = useResetPasswordMutation();
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IPasswordResetRequest>();

    const onResetPassword = async (data: IPasswordResetRequest) => {
        try {
            await resetPassword({ input: data });
            if (resetPasswordResult.error) {
                throw new Error();
            }
            navigate(paths.resetPasswordNotify, { state: { email: data.email } });
        } catch (err) {
            setError(intl.formatMessage({ id: 'validation.unknownError' }));
        }
    };

    return {
        onResetPassword,
        control,
        register,
        handleSubmit,
        errors,
        error,
        intl,
    };
}
