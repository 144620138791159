import styled from 'styled-components';

export const ReportNumberWrapper = styled.div`
    display: block;
`;
export const Report = styled.div`
    color: ${(props) => props.theme.colors.input_text_color};
    border: 2px solid ${(props) => props.theme.colors.input_text_color};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    border-radius: 36px;
    font-size: 14px;
    padding: 4px 5px;
    min-width: 60px;
    text-align: center;
`;
