import {
    Get_Reports_For_Dashboard_Sort,
    Get_Reports_For_Dashboard_Status_Filter,
    Message,
    Report,
    Report_Status,
    Update_Report_Status,
    useGetReportsForDashboardQuery,
    useUpdateBatchOfReportsStatusMutation,
    useCloseBatchOfReportsMutation,
    Report_Category,
} from 'src/generated/graphql';
import { format, isToday } from 'date-fns';
import categories from 'src/dict/categories';
import whistleblowerRelations from 'src/dict/whistleblowerRelations';
import { useEffect, useState } from 'react';
import { READ_STATUS } from '../Components/ReportsGrid/LastMessageCell/LastMessageCell';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import paths from '@shared/paths';
import getUserLanguage from '@utils/getUserLanguage';
import { useIntl } from 'react-intl';
import useStore from '../../../store/useStore';

export type ReportRow = {
    id: string;
    reporter: string;
    relation: string;
    category: string;
    reportNumber: string;
    lastMessageDate: string;
    createdAt: string;
    status: string;
};

export type Counts = {
    newCount: number;
    inProgressCount: number;
    closedCount: number;
};

export enum SELECT_OPTION {
    NONE = 'NONE',
    ALL = 'ALL',
    READ = 'READ',
    UNREAD = 'UNREAD',
}

export default () => {
    const navigate = useNavigate();
    const [_, updateBatchOfReports] = useUpdateBatchOfReportsStatusMutation();

    const { activeStatus, setActiveStatus, counts, setCounts } = useStore();

    const [totalDocs, setTotalDocs] = useState(0);
    const [page, setPage] = useState(1);
    const [selectedReports, setSelectedReports] = useState<GridSelectionModel>([]);
    const [rows, setRows] = useState<ReportRow[]>([]);
    const [sortOrder, setSortOrder] = useState<number>(-1);
    const [openCloseCaseModal, setOpenCloseCaseModal] = useState(false);
    const [__, closeBatchOfReports] = useCloseBatchOfReportsMutation();
    const intl = useIntl();
    const mapRows = (reports: Omit<Report, 'company'>[]) =>
        reports.map((report) => ({
            id: report.reportNumber,
            reporter: report.whistleblower
                ? `${report.whistleblower.name} ${report.whistleblower.surname}`
                : intl.formatMessage({ id: 'reportDetailsPage.anonymous' }),
            relation:
                report.whistleblowerRelation?.other ||
                (report.whistleblowerRelation?.relation &&
                    whistleblowerRelations[getUserLanguage()][
                        report.whistleblowerRelation?.relation
                    ]) ||
                '',
            category:
                report.category !== Report_Category.Other
                    ? categories[getUserLanguage()][report.category]
                    : `${categories[getUserLanguage()][report.category]} - ${report.otherCategory}`,
            reportNumber: report.reportNumber,
            lastMessageDate: isToday(new Date(report.lastMessageDate))
                ? format(new Date(report.lastMessageDate), 'HH:mm')
                : format(new Date(report.lastMessageDate), 'dd.MM.yyyy'),
            createdAt: format(new Date(report.createdAt), 'dd.MM.yyyy'),
            status:
                report.status === Report_Status.Sent || report.hasNewMessages
                    ? READ_STATUS.UNREAD
                    : report.messages.length > 1
                    ? READ_STATUS.REPLIED
                    : READ_STATUS.READ,
        })) || [];
    const [result, reexecuteQuery] = useGetReportsForDashboardQuery({
        variables: {
            input: {
                pagination: {
                    page,
                },
                filter: {
                    status: activeStatus,
                },
                sort: {
                    field: Get_Reports_For_Dashboard_Sort.LastMessageAt,
                    order: sortOrder,
                },
            },
        },
        requestPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (result.data?.getReportsForDashboard.reports) {
            setRows(
                mapRows(
                    result.data.getReportsForDashboard.reports.map((report) => ({
                        ...report,
                        messages: report.messages as Message[],
                    })),
                ),
            );
            setCounts(result.data.getReportsForDashboard.counts);
            setTotalDocs(result.data.getReportsForDashboard.meta.totalDocs || 0);
        }
    }, [result]);

    const onPageChange = (page: number) => {
        setPage(page + 1);
    };

    const onRefresh = () => {
        reexecuteQuery();
    };

    const onSelectManyReports = (value?: string) => {
        switch (value) {
            case SELECT_OPTION.NONE:
                setSelectedReports([]);
                break;
            case SELECT_OPTION.ALL:
                setSelectedReports(rows.map(({ id }) => id));
                break;
            case SELECT_OPTION.READ:
                setSelectedReports(
                    rows
                        .filter(({ status }) =>
                            [READ_STATUS.READ, READ_STATUS.REPLIED].includes(status as READ_STATUS),
                        )
                        .map(({ id }) => id),
                );
                break;
            case SELECT_OPTION.UNREAD:
                setSelectedReports(
                    rows.filter(({ status }) => READ_STATUS.UNREAD === status).map(({ id }) => id),
                );
                break;
        }
    };

    const onSort = (value?: string) => {
        setSortOrder(value === 'DESC' ? -1 : 1);
    };

    const getTitle = () => {
        if (activeStatus === Get_Reports_For_Dashboard_Status_Filter.New) {
            return intl.formatMessage({ id: 'dashboardPage.titles.newReports' });
        }
        if (activeStatus === Get_Reports_For_Dashboard_Status_Filter.InProgress) {
            return intl.formatMessage({ id: 'dashboardPage.titles.reportsInProgress' });
        }
        return intl.formatMessage({ id: 'dashboardPage.titles.closedReports' });
    };

    const setStatus = async (status?: string) => {
        if (status) {
            if (status === Report_Status.Closed) {
                setOpenCloseCaseModal(!openCloseCaseModal);
            } else {
                const res = await updateBatchOfReports({
                    input: {
                        reportNumbers: selectedReports as string[],
                        status: status as Update_Report_Status,
                    },
                });
                const updatedReports = res.data?.updateBatchOfReportsStatus;

                if (updatedReports && updatedReports.length > 0) {
                    const reportsNumbers = updatedReports.map(({ reportNumber }) => reportNumber);
                    const filteredReports = rows.filter(
                        ({ reportNumber }) => !reportsNumbers.includes(reportNumber),
                    );
                    setRows(filteredReports);
                    const _mapVariable = (name: string) => {
                        if (
                            name === Update_Report_Status.Received ||
                            name === Get_Reports_For_Dashboard_Status_Filter.New
                        )
                            return 'newCount';
                        if (
                            name === Update_Report_Status.InProgress ||
                            name === Get_Reports_For_Dashboard_Status_Filter.InProgress
                        )
                            return 'inProgressCount';
                        return 'closedCount';
                    };
                    setCounts({
                        ...counts,
                        [_mapVariable(activeStatus)]:
                            counts[_mapVariable(activeStatus)] - updatedReports.length,
                        [_mapVariable(status)]:
                            counts[_mapVariable(status)] + updatedReports.length,
                    });
                    setTotalDocs(totalDocs - updatedReports.length);
                }
            }
        }
    };

    const query = new URLSearchParams(useLocation().search);
    const queryReportNumber = query.get('reportNumber');

    const setActiveStatusHandler = (status: Get_Reports_For_Dashboard_Status_Filter) => {
        setActiveStatus(status);
        if (queryReportNumber && queryReportNumber.length > 0) {
            navigate(paths.dashboard);
        }
    };

    const closeReports = async (closingStatement: string) => {
        await closeBatchOfReports({
            input: {
                reportNumbers: selectedReports as string[],
                closingStatement,
            },
        });
        onRefresh();
    };

    return {
        rows,
        totalDocs,
        onPageChange,
        loading: result.fetching,
        onRefresh,
        selectedReports,
        setSelectedReports,
        onSelectManyReports,
        onSort,
        title: getTitle(),
        setStatus,
        queryReportNumber,
        reexecuteGetReports: reexecuteQuery,
        openCloseCaseModal,
        setOpenCloseCaseModal,
        closeReports,
    };
};
