import styled from 'styled-components';

export const Paragraph = styled.div`
    margin: 25px auto 20px;
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.47;
    color: #1c1c3f;

    & > a {
        margin: 1px 0 3px 14px;
        font-size: 15px;
        font-weight: bold;
        line-height: 1.33;
        text-decoration: none;
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
        transition: 500ms opacity ease;

        &:hover {
            opacity: 0.6;
        }
    }
`;
