import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { IAddUserModalElement } from './AddUserModal';

export default (inviteEmploye: IAddUserModalElement['inviteEmployee']) => {
    const intl = useIntl();
    const [error, setError] = useState('');
    const [inviteInProgress, setInviteInProgress] = useState(false);
    const [success, setSuccess] = useState(false);
    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<{ email: string }>({
        defaultValues: {
            email: '',
        },
    });

    const addUser = async (data: { email: string }) => {
        setInviteInProgress(true);
        try {
            await inviteEmploye(data.email);
            setSuccess(true);
            setInviteInProgress(false);
            reset();
        } catch (err) {
            setInviteInProgress(false);
            setError(intl.formatMessage({ id: 'validation.unknownError' }));
        }
    };
    return {
        error,
        errors,
        control,
        register,
        handleSubmit,
        addUser,
        intl,
        inviteInProgress,
        success,
        setSuccess,
    };
};
