import { Button, Title } from '@components/_universal';
import { TITLE_TYPE } from '@components/_universal/Title/Title.d';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Modal } from '@material-ui/core/';
import { ModalBody, CloseButton } from '@components/ReportDetails/Modal/Modal.styled';
import CloseIcon from '@components/_universal/CloseIcon/CloseIcon';
import {
    ButtonsContainer,
    CheckboxesContainer,
    NumberOfReportsContainer,
    NumberOfReportsCount,
    NumberOfReportsText,
    useStyles,
} from './GenerateReportsModal.styled';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox } from '../../../../components/_form-elements/Inputs';
import { Divider } from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { ReportsRegistryPdf } from '../../../../pdfs/ReportsRegistryPdf';
import { FinalProtocolPdf } from '../../../../pdfs/FinalProtocolPdf';
import { useGetLoggedUserQuery } from '../../../../generated/graphql';
import { Filters } from '../../ReportsSummary/useReportsSummaryPage';
import JSZip from 'jszip';

export interface IGenerateReportsModalElement {
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    openModal: boolean;
    count: number;
    reports: any[];
    filters: Filters;
    getReportsBeforeDownload: () => void;
}

const GenerateReportsModal = ({
    setOpenModal,
    openModal,
    count,
    reports,
    filters,
    getReportsBeforeDownload,
}: IGenerateReportsModalElement) => {
    const intl = useIntl();
    const styles = useStyles();
    const [reportsRegistry, setReportsRegistry] = useState(false);
    const [finalReport, setFinalReport] = useState(false);
    const reportsRegistryRef = useRef<null | HTMLInputElement>();
    const finalReportRef = useRef<null | HTMLInputElement>();
    const [result] = useGetLoggedUserQuery();
    const nameAndSurname = `${result.data?.getLoggedUser.name} ${result.data?.getLoggedUser.surname}`;
    const companyName = result.data?.getLoggedUser.company?.name || '';
    useEffect(() => {
        getReportsBeforeDownload();
    }, []);
    useEffect(() => {
        setReportsRegistry(reportsRegistryRef.current?.checked || false);
        setFinalReport(finalReportRef.current?.checked || false);
    }, [reportsRegistryRef, finalReportRef]);
    const numberOfReportsCountOneMessage = intl.formatMessage(
        {
            id: 'reportsSummary.generateReportsModal.numberOfReportsCountOne',
        },
        { count },
    );
    const numberOfReportsCountMessage = intl.formatMessage(
        {
            id: 'reportsSummary.generateReportsModal.numberOfReportsCount',
        },
        { count },
    );
    return (
        <Modal
            open={openModal}
            onClose={() => {
                setOpenModal(!openModal);
            }}
        >
            <ModalBody>
                <CloseButton
                    onClick={() => {
                        setOpenModal(!openModal);
                    }}
                >
                    <CloseIcon />
                </CloseButton>

                <Title center type={TITLE_TYPE.H3} id="reportsSummary.generateReportsModal.title" />
                <NumberOfReportsContainer>
                    <NumberOfReportsText>
                        <FormattedMessage id="reportsSummary.generateReportsModal.numberOfReports" />
                    </NumberOfReportsText>
                    <NumberOfReportsCount>
                        {count === 1 ? numberOfReportsCountOneMessage : numberOfReportsCountMessage}
                    </NumberOfReportsCount>
                </NumberOfReportsContainer>
                <CheckboxesContainer>
                    <Checkbox
                        label={intl.formatMessage({
                            id: 'reportsSummary.generateReportsModal.reportsRegistry',
                        })}
                        onChange={(value) => setReportsRegistry(value)}
                        labelClass={styles.label}
                        inputRef={reportsRegistryRef}
                    />
                    <Checkbox
                        label={intl.formatMessage({
                            id: 'reportsSummary.generateReportsModal.finalProtocol',
                        })}
                        labelClass={styles.label}
                        onChange={(value) => setFinalReport(value)}
                    />
                </CheckboxesContainer>
                <Divider></Divider>
                <ButtonsContainer>
                    <Button
                        variant="text"
                        onClick={() => {
                            setOpenModal(!openModal);
                        }}
                    >
                        <FormattedMessage id="reportsSummary.generateReportsModal.cancel" />
                    </Button>
                    <Button
                        style={{ marginLeft: '10px' }}
                        variant="primary"
                        disabled={!reportsRegistry && !finalReport}
                        onClick={async () => {
                            const zip = new JSZip();
                            const folder = zip.folder('reports');
                            if (folder) {
                                let blob1, blob2;
                                if (finalReport) {
                                    blob1 = await pdf(
                                        <FinalProtocolPdf
                                            reports={reports}
                                            reporter={{
                                                nameAndSurname,
                                                email: result.data?.getLoggedUser.email || '',
                                            }}
                                            companyName={companyName}
                                        />,
                                    ).toBlob();
                                    folder.file(`${new Date().valueOf()}.pdf`, blob1);
                                }
                                if (reportsRegistry) {
                                    blob2 = await pdf(
                                        <ReportsRegistryPdf
                                            reports={reports}
                                            filters={filters}
                                            reporter={{
                                                nameAndSurname,
                                                email: result.data?.getLoggedUser.email || '',
                                            }}
                                            companyName={companyName}
                                        />,
                                    ).toBlob();
                                    folder.file(`${new Date().valueOf()}.pdf`, blob2);
                                }
                                folder.generateAsync({ type: 'blob' }).then((content) => {
                                    window.open(URL.createObjectURL(content), '_blank');
                                });
                            }
                        }}
                    >
                        <FormattedMessage id="reportsSummary.downloadButton" />
                    </Button>
                </ButtonsContainer>
            </ModalBody>
        </Modal>
    );
};

export default GenerateReportsModal;
