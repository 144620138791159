import { DashboardLayout } from '@components/Layouts';
import useGetCompanyInfoFromDomain from '@utils/hooks/useGetCompanyInfoFromDomain';
import useDashboardPage from '../DashboardPage/useDashboardPage';
import { useLocation } from 'react-router';
import Title from '../Components/Title/Title';
import { FormattedMessage, useIntl } from 'react-intl';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { RefreshButton } from '../Components/RefreshButton/RefreshButton';
import { Button } from '@components/_universal';
import { Grid } from '@material-ui/core';
import { useStyles } from '../Components/ReportsGrid/ReportsGrid.styled';
import AddUserModal from '../Components/AddUserModal/AddUserModal';
import useUsersPage from './useUsersPage';
import { DataGridButtonsWrapper } from './UsersPage.styled';
import { User_Role, User_Status } from 'src/generated/graphql';
import useMediaQuery from '@mui/material/useMediaQuery';
import defaultTheme from '@shared/theme/default.theme';

type LocationState = {
    companyName?: string;
};
const UsersPage = () => {
    const companyNameFromProps = (useLocation().state as LocationState)?.companyName;
    const { companyName } = useGetCompanyInfoFromDomain({ companyName: companyNameFromProps });
    const isTablet = useMediaQuery(defaultTheme.media.md, { noSsr: true });

    const {
        openModal,
        setOpenModal,
        users,
        reexecuteGetCompanyUsersQuery,
        inviteEmployee,
        deleteInvitation,
        deleteUser,
    } = useUsersPage();
    const intl = useIntl();
    const classes = useStyles();
    const columns: GridColDef[] = [
        { field: 'id', hide: true },
        {
            field: 'name',
            headerName: intl.formatMessage({ id: 'usersPage.name' }),
            sortable: true,
            width: 150,
        },
        {
            field: 'mappedStatus',
            headerName: intl.formatMessage({ id: 'usersPage.status' }),
            sortable: true,
            width: 100,
        },
        {
            field: 'mappedRole',
            headerName: intl.formatMessage({ id: 'usersPage.role' }),
            sortable: true,
            width: 100,
        },
        {
            field: 'email',
            headerName: intl.formatMessage({ id: 'usersPage.email' }),
            width: 160,
            sortable: true,
            flex: isTablet ? 1 : 0,
        },
        {
            field: 'action',
            headerName: intl.formatMessage({ id: 'usersPage.action' }),
            sortable: false,
            width: 100,
            renderCell: (params) => {
                const onInvitationDelete = (e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    if (confirm(intl.formatMessage({ id: 'usersPage.modal.confirmInvDelete' }))) {
                        deleteInvitation(params.row.email);
                    }
                };

                const onUserDelete = (e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    if (confirm(intl.formatMessage({ id: 'usersPage.modal.confirmUserDelete' }))) {
                        deleteUser(params.row.id);
                    }
                };
                return params.row.role !== User_Role.Maintainer &&
                    params.row.status !== User_Status.Deleted ? (
                    <Button onClick={!params.row.role ? onInvitationDelete : onUserDelete}>
                        <FormattedMessage id="usersPage.deleteButton" />
                    </Button>
                ) : (
                    <></>
                );
            },
        },
    ];

    return (
        <DashboardLayout companyName={companyNameFromProps || companyName}>
            <Title>
                <FormattedMessage id="usersPage.title" />
            </Title>
            <DataGridButtonsWrapper>
                <Grid item>
                    <Grid container>
                        <RefreshButton onClick={reexecuteGetCompanyUsersQuery} />
                    </Grid>
                </Grid>
                <Grid item>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setOpenModal(true);
                        }}
                    >
                        <FormattedMessage id="usersPage.addButton" />
                    </Button>
                </Grid>
            </DataGridButtonsWrapper>
            <br />
            <div className={classes.root}>
                <DataGrid
                    className="usersGrid"
                    columns={columns}
                    rows={users}
                    disableSelectionOnClick={true}
                    hideFooter={true}
                    disableColumnMenu={true}
                />
            </div>
            <AddUserModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                inviteEmployee={inviteEmployee}
            />
        </DashboardLayout>
    );
};

export default UsersPage;
