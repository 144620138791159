import { DashboardLayout } from '@components/Layouts';
import useGetCompanyInfoFromDomain from '@utils/hooks/useGetCompanyInfoFromDomain';
import { useLocation } from 'react-router';
import Title from '../Components/Title/Title';
import { FormattedMessage, useIntl } from 'react-intl';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button } from '@components/_universal';
import { Chip, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import useReportsSummary from './useReportsSummaryPage';
import {
    ButtonsWrapper,
    FiltersContainer,
    NumberOfReports,
    ReportNumberInputContainer,
    RightButtonsContainer,
    useStyles,
    ReportNumberInputError,
} from './ReportsSummary.styled';
import TodayIcon from '@material-ui/icons/Today';
import SearchIcon from '@material-ui/icons/Search';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
    Get_Reports_For_Dashboard_Status_Filter,
    Get_Reports_Whistleblower,
    Report_Category,
    Whistleblower_Relation,
} from '../../../generated/graphql';
import reportsForDashboardStatusFilter from '../../../dict/reportsForDashboardStatusFilter';
import getUserLanguage from '../../../utils/getUserLanguage';
import reportsWhistleblowerType from '../../../dict/reportsWhistleblowerType';
import { Autocomplete } from '@mui/material';
import whistleblowerRelations from '../../../dict/whistleblowerRelations';
import categories from '../../../dict/categories';
import GenerateReportsModal from '../Components/GenerateReportsModal/GenerateReportsModal';

type LocationState = {
    companyName?: string;
};
const ReportsSummaryPage = () => {
    const companyNameFromProps = (useLocation().state as LocationState)?.companyName;
    const { companyName } = useGetCompanyInfoFromDomain({ companyName: companyNameFromProps });

    const {
        filters,
        setFilters,
        rows,
        loading,
        setPage,
        totalDocs,
        openModal,
        setOpenModal,
        getReportsBeforeDownload,
        reportsToDownload,
    } = useReportsSummary();
    const intl = useIntl();
    const statusPlaceholder =
        filters.status.length === 0
            ? intl.formatMessage({ id: 'reportsSummary.status.empty' })
            : '';
    const whistleblowerPlaceholder =
        filters.whistleblower.length === 0
            ? intl.formatMessage({ id: 'reportsSummary.whistleblower.empty' })
            : '';
    const relationPlaceholder =
        filters.relation.length === 0
            ? intl.formatMessage({ id: 'reportsSummary.relation.empty' })
            : '';
    const categoryPlaceholder =
        filters.category.length === 0
            ? intl.formatMessage({
                  id: 'reportsSummary.category.empty',
              })
            : '';
    const classes = useStyles();
    const columns: GridColDef[] = [
        { field: 'id', hide: true },
        {
            field: 'reportNumber',
            headerName: intl.formatMessage({ id: 'reportsSummary.reportNumber' }),
            sortable: false,
        },
        {
            field: 'createdAt',
            headerName: intl.formatMessage({ id: 'reportsSummary.createdDate' }),
            sortable: false,
        },
        {
            field: 'status',
            headerName: intl.formatMessage({ id: 'reportsSummary.status' }),
            sortable: false,
        },
        {
            field: 'whistleblower',
            headerName: intl.formatMessage({ id: 'reportsSummary.whistleblower' }),
            flex: 1,
            sortable: false,
        },
        {
            field: 'relation',
            headerName: intl.formatMessage({ id: 'reportsSummary.relation' }),
            flex: 2,
            sortable: false,
        },
        {
            field: 'category',
            headerName: intl.formatMessage({ id: 'reportsSummary.category' }),
            flex: 2,
            sortable: false,
        },
    ];
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DashboardLayout companyName={companyNameFromProps || companyName}>
                <Title>
                    <FormattedMessage id="reportsSummary.title" />
                </Title>
                <ButtonsWrapper>
                    <Grid item>
                        <NumberOfReports>
                            <FormattedMessage id="reportsSummary.numberOfReports" />: {totalDocs}
                        </NumberOfReports>
                    </Grid>
                    <RightButtonsContainer>
                        <ReportNumberInputContainer>
                            <TextField
                                label={<FormattedMessage id="reportsSummary.search.placeholder" />}
                                variant="outlined"
                                size="small"
                                value={filters.reportNumber}
                                onChange={(e) => {
                                    setFilters({ ...filters, reportNumber: e.target.value });
                                }}
                                style={{ width: '200px', marginRight: '15px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            style={{ paddingRight: '10px' }}
                                        >
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {filters.reportNumber && filters.reportNumber.length !== 4 && (
                                <ReportNumberInputError>
                                    <FormattedMessage id="reportsSummary.reportNumberInputError" />
                                </ReportNumberInputError>
                            )}
                        </ReportNumberInputContainer>
                        <Grid item>
                            <Button
                                variant="primary"
                                disabled={rows.length === 0}
                                onClick={() => {
                                    setOpenModal(true);
                                }}
                            >
                                <FormattedMessage id="reportsSummary.createReportButton" />
                            </Button>
                        </Grid>
                    </RightButtonsContainer>
                </ButtonsWrapper>
                <br />
                <FiltersContainer>
                    <DatePicker
                        style={{ flex: 1, minWidth: 150 }}
                        variant="inline"
                        inputVariant="outlined"
                        label={intl.formatMessage({
                            id: 'reportsSummary.dateInput.from',
                        })}
                        size={'small'}
                        format="dd.MM.yyyy"
                        className={classes.filterMargin}
                        onChange={(date) => date && setFilters({ ...filters, from: date })}
                        emptyLabel={intl.formatMessage({
                            id: 'reportsSummary.dateInput.from',
                        })}
                        disableToolbar
                        value={filters.from}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton style={{ padding: 0 }}>
                                        <TodayIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <DatePicker
                        style={{ flex: 1, minWidth: 150 }}
                        className={classes.filterMargin}
                        variant="inline"
                        inputVariant="outlined"
                        size={'small'}
                        format="dd.MM.yyyy"
                        onChange={(date) => date && setFilters({ ...filters, to: date })}
                        emptyLabel={intl.formatMessage({
                            id: 'reportsSummary.dateInput.to',
                        })}
                        label={intl.formatMessage({
                            id: 'reportsSummary.dateInput.to',
                        })}
                        disableToolbar
                        value={filters.to}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton style={{ padding: 0 }}>
                                        <TodayIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Autocomplete
                        style={{ flex: 2 }}
                        className={classes.filterMargin}
                        multiple
                        size="small"
                        options={Object.values(Get_Reports_For_Dashboard_Status_Filter)}
                        getOptionLabel={(option) =>
                            reportsForDashboardStatusFilter[getUserLanguage()][option]
                        }
                        defaultValue={[]}
                        onChange={(_e, value) => {
                            setFilters({ ...filters, status: value });
                        }}
                        renderInput={(params) => {
                            params.inputProps.style = { padding: '0px 0px 3px 0px' };
                            return (
                                <TextField
                                    {...params}
                                    size={'small'}
                                    variant="outlined"
                                    placeholder={statusPlaceholder}
                                    label={<FormattedMessage id="reportsSummary.status" />}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            );
                        }}
                    />

                    <Autocomplete
                        style={{ flex: 2 }}
                        className={classes.filterMargin}
                        multiple
                        id="tags-outlined"
                        size="small"
                        options={Object.values(Get_Reports_Whistleblower)}
                        getOptionLabel={(option) =>
                            reportsWhistleblowerType[getUserLanguage()][option]
                        }
                        defaultValue={[]}
                        onChange={(_e, value) => {
                            setFilters({ ...filters, whistleblower: value });
                        }}
                        renderInput={(params) => {
                            params.inputProps.style = { padding: '0px 0px 3px 0px' };
                            return (
                                <TextField
                                    {...params}
                                    size={'small'}
                                    variant="outlined"
                                    placeholder={whistleblowerPlaceholder}
                                    label={<FormattedMessage id="reportsSummary.whistleblower" />}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            );
                        }}
                    />

                    <Autocomplete
                        style={{ flex: 2 }}
                        className={classes.filterMargin}
                        multiple
                        id="tags-outlined"
                        size="small"
                        options={Object.values(Whistleblower_Relation)}
                        getOptionLabel={(option) =>
                            whistleblowerRelations[getUserLanguage()][option]
                        }
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => {
                                const splitted =
                                    whistleblowerRelations[getUserLanguage()][option].split(' ');
                                return (
                                    <Chip
                                        label={`${splitted[0]} ${splitted[1] || ''} ${
                                            splitted[2] || ''
                                        }...`}
                                        {...getTagProps({ index })}
                                    />
                                );
                            })
                        }
                        defaultValue={[]}
                        onChange={(_e, value) => {
                            setFilters({ ...filters, relation: value });
                        }}
                        renderInput={(params) => {
                            params.inputProps.style = { padding: '0px 0px 3px 0px' };
                            return (
                                <TextField
                                    {...params}
                                    size={'small'}
                                    variant="outlined"
                                    placeholder={relationPlaceholder}
                                    label={<FormattedMessage id="reportsSummary.relation" />}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            );
                        }}
                    />

                    <Autocomplete
                        style={{ flex: 2 }}
                        className={classes.filterMargin}
                        multiple
                        id="tags-outlined"
                        size="small"
                        options={Object.values(Report_Category)}
                        getOptionLabel={(option) => categories[getUserLanguage()][option]}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => {
                                const splitted = categories[getUserLanguage()][option].split(' ');
                                return (
                                    <Chip
                                        label={`${splitted[0]} ${splitted[1] || ''} ${
                                            splitted[2] || ''
                                        }...`}
                                        {...getTagProps({ index })}
                                    />
                                );
                            })
                        }
                        defaultValue={[]}
                        onChange={(_e, value) => {
                            setFilters({ ...filters, category: value });
                        }}
                        renderInput={(params) => {
                            params.inputProps.style = { padding: '0px 0px 3px 0px' };
                            return (
                                <TextField
                                    {...params}
                                    size={'small'}
                                    variant="outlined"
                                    placeholder={categoryPlaceholder}
                                    label={<FormattedMessage id="reportsSummary.category" />}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            );
                        }}
                    />
                </FiltersContainer>
                <br />
                <div className={classes.root}>
                    <DataGrid
                        className="usersGrid"
                        columns={columns}
                        localeText={{
                            noRowsLabel: intl.formatMessage({ id: 'reportsSummary.list.empty' }),
                            MuiTablePagination: {
                                labelDisplayedRows: ({ from, to, count }) =>
                                    `${from}–${to} ${intl.formatMessage({
                                        id: 'reportsSummary.list.pagination.of',
                                    })} ${count}`,
                            },
                        }}
                        rows={rows}
                        pageSize={25}
                        pagination
                        rowsPerPageOptions={[25]}
                        loading={loading}
                        onPageChange={(newPage) => {
                            setPage(newPage + 1);
                        }}
                        rowCount={totalDocs}
                        disableSelectionOnClick={true}
                        hideFooter={false}
                        disableColumnMenu={true}
                        paginationMode={'server'}
                    />
                </div>
                {openModal && (
                    <GenerateReportsModal
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        count={totalDocs}
                        reports={reportsToDownload}
                        filters={filters}
                        getReportsBeforeDownload={getReportsBeforeDownload}
                    />
                )}
            </DashboardLayout>
        </MuiPickersUtilsProvider>
    );
};

export default ReportsSummaryPage;
