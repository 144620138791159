import { gql, useMutation } from 'urql';
import {
    RegisterFromInvitationMutation,
    MutationRegisterFromInvitationArgs,
} from 'src/generated/graphql';

const REGISTER_FROM_INVITATION_MUTATION = gql`
    mutation RegisterFromInvitation($input: RegisterFromInvitationInput!) {
        registerFromInvitation(input: $input) {
            success
        }
    }
`;

export default () =>
    useMutation<RegisterFromInvitationMutation, MutationRegisterFromInvitationArgs>(
        REGISTER_FROM_INVITATION_MUTATION,
    );
