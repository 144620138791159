import { IDefaultLayoutProps } from './DefaultLayout.d';
import Header from '../../_universal/Header/Header';
import { Body, Main } from './DefaultLayout.styled';
import ContentWrapper from '../../_universal/ContentWrapper/ContentWrapper';

export default function DefaultLayout({
    children,
    bgColor,
    companyName,
    companyLogo,
    setFullHeight,
    alignItemsCenter,
    enablePaddingBottomOnMobile,
    pageTitle,
}: IDefaultLayoutProps): JSX.Element {
    return (
        <Body>
            <Main bgColor={bgColor}>
                <Header companyName={companyName} companyLogo={companyLogo} pageTitle={pageTitle} />
                <ContentWrapper
                    enablePaddingBottomOnMobile={enablePaddingBottomOnMobile}
                    alignItemsCenter={alignItemsCenter}
                    setFullHeight={setFullHeight}
                >
                    {children}
                </ContentWrapper>
            </Main>
        </Body>
    );
}
