import styled from 'styled-components';

export const PageTitleWrapper = styled.div`
    margin: 4px 10px 4px 15px;
    opacity: 0.64;
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.text};
    display: none;

    ${(props) => props.theme.media.md} {
        display: inline-block;
    }
`;
