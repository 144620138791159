import { makeStyles } from '@material-ui/core/styles';
import defaultTheme from '@shared/theme/default.theme';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 530,
        marginBottom: '20px',

        '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
            display: 'none',
        },

        '& .MuiDataGrid-root': {
            borderLeft: 0,
            borderRight: 0,
            borderTop: `1px solid + ${defaultTheme.colors.border}`,
            borderBottom: `1px solid + ${defaultTheme.colors.border}`,
            borderRadius: 0,
            fontFamily: defaultTheme.fontFamily,

            '& .MuiDataGrid-columnSeparator': {
                display: 'none',
            },

            '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                lineHeight: 1.33,
                letterSpacing: '0.16px',
                fontWeight: defaultTheme.fontWeight.bold,
                color: '#000',
                opacity: 0.64,
                paddingTop: '5px',
                maxWidth: '100px',
                whiteSpace: 'normal',
            },

            '& .MuiDataGrid-row.Mui-selected': {
                background: '#fff7ca',
                '&:hover': {
                    background: defaultTheme.colors.lightPrimary,
                },
            },

            '& .MuiDataGrid-row': {
                cursor: 'pointer',
                '&:hover': {
                    background: defaultTheme.colors.lightPrimary,
                },
            },

            '& .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
            },

            '& .MuiDataGrid-cell:focus-within': {
                outline: 'none',
            },

            '& .MuiDataGrid-cell': {
                fontSize: '13px',
                lineHeight: 1.71,
                letterSpacing: '0.19px',
            },
        },

        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: defaultTheme.colors.primary,
        },

        '& .status--UNREAD': {
            fontWeight: defaultTheme.fontWeight.bold,
        },

        '& .usersGrid.MuiDataGrid-root': {
            '& .MuiDataGrid-columnHeader:last-of-type': {
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                    justifyContent: 'right',
                },
            },
            '& .MuiDataGrid-row': {
                cursor: 'default',
                '& > div:last-of-type': {
                    justifyContent: 'right',
                    paddingRight: '0',
                },
                '&:hover': {
                    background: 'none',
                },
            },
        },
    },
}));
