import { FormProvider, useForm } from 'react-hook-form';
import {
    ReportStepFour,
    ReportStepThree,
    ReportStepOne,
    ReportStepTwo,
    ReportStepFive,
    ReportStepSix,
    ReportStepSeven,
} from '.';
import useReportForm, { IFormData } from './useReportForm';
import { DefaultLayout } from '@components/Layouts';
import img from '@assets/images/icon-account-security.svg';
import { TITLE_TYPE } from '@components/_universal/Title/Title.d';
import { Img, Card, Title, Footer, Paragraph } from '@components/_universal';
import { CircularProgress } from '@material-ui/core';

const NUMBER_OF_STEPS = 7 as const;

const ReportForm = () => {
    const {
        onSubmit,
        step,
        setStep,
        reportNo,
        loader,
        stateReportNumber,
        companyName,
        companyLogo,
    } = useReportForm();

    const methods = useForm<IFormData>({
        mode: 'onChange',
        defaultValues: {
            reportNumber: stateReportNumber ? stateReportNumber : reportNo,
            personsInvolved: [{ name: '', surname: '', relation: '' }],
        },
    });
    if (companyName) {
        return (
            <FormProvider {...methods}>
                <form>
                    {step === 1 && (
                        <ReportStepOne
                            setStep={setStep}
                            activeStep={step}
                            lastStep={NUMBER_OF_STEPS}
                            companyName={companyName}
                            companyLogo={companyLogo}
                        ></ReportStepOne>
                    )}
                    {step === 2 && (
                        <ReportStepTwo
                            setStep={setStep}
                            activeStep={step}
                            lastStep={NUMBER_OF_STEPS}
                            companyName={companyName}
                            companyLogo={companyLogo}
                        ></ReportStepTwo>
                    )}
                    {step === 3 && (
                        <ReportStepThree
                            setStep={setStep}
                            activeStep={step}
                            lastStep={NUMBER_OF_STEPS}
                            companyName={companyName}
                            companyLogo={companyLogo}
                        ></ReportStepThree>
                    )}
                    {step === 4 && (
                        <ReportStepFour
                            setStep={setStep}
                            activeStep={step}
                            lastStep={NUMBER_OF_STEPS}
                            companyName={companyName}
                            companyLogo={companyLogo}
                        ></ReportStepFour>
                    )}
                    {step === 5 && (
                        <ReportStepFive
                            setStep={setStep}
                            activeStep={step}
                            lastStep={NUMBER_OF_STEPS}
                            companyName={companyName}
                            companyLogo={companyLogo}
                        ></ReportStepFive>
                    )}
                    {step === 6 && (
                        <ReportStepSix
                            setStep={setStep}
                            activeStep={step}
                            lastStep={NUMBER_OF_STEPS}
                            companyName={companyName}
                            companyLogo={companyLogo}
                        ></ReportStepSix>
                    )}
                    {step === 7 && (
                        <ReportStepSeven
                            setStep={setStep}
                            activeStep={step}
                            lastStep={NUMBER_OF_STEPS}
                            onNextClick={methods.handleSubmit(onSubmit)}
                            companyName={companyName}
                            companyLogo={companyLogo}
                        ></ReportStepSeven>
                    )}
                </form>
            </FormProvider>
        );
    }
    return (
        <DefaultLayout
            alignItemsCenter
            setFullHeight
            companyName={companyName}
            companyLogo={companyLogo}
        >
            <Card wide>
                <Img src={img} noPadding />
                <Title center id="reportPage.title" />
                {loader && (
                    <Paragraph center marginTop="10%" marginBottom="10%">
                        <CircularProgress />
                    </Paragraph>
                )}
                {!loader && (
                    <Title
                        marginTop="54px"
                        type={TITLE_TYPE.H3}
                        id="reportPage.secondTitleInvalid"
                    />
                )}
                <Footer />
            </Card>
        </DefaultLayout>
    );
};

export default ReportForm;
