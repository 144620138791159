import { useEffect, useState } from 'react';
import { ILoginRequest } from './LoginPage.d';
import { useForm } from 'react-hook-form';
import { Language_Codes, useLoginUserMutation } from 'src/generated/graphql';
import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import paths from '@shared/paths';
import { useIntl } from 'react-intl';
import getUserLanguage from '@utils/getUserLanguage';

export default function useLogin() {
    const [error, setError] = useState('');
    const [_loginUserResult, loginUser] = useLoginUserMutation();
    const intl = useIntl();
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ILoginRequest>();

    const signin = async (data: ILoginRequest) => {
        try {
            const result = await loginUser({
                input: {
                    ...data,
                    language: getUserLanguage() as Language_Codes,
                },
            });
            if (
                result.error?.graphQLErrors.some(
                    (error) => error.message === 'Please activate you account',
                )
            ) {
                window.location.href = paths.registrationActivation;
                return;
            }
            if (!result.data?.loginUser) {
                throw new Error();
            }

            LocalStorageManager.setValue(
                'sessionToken',
                result.data?.loginUser.tokens.sessionToken,
            );
            LocalStorageManager.setValue(
                'refreshToken',
                result.data?.loginUser.tokens.refreshToken,
            );
            window.location.href = paths.dashboard;
        } catch (err) {
            setShowEmailError(true);
            setShowPasswordError(true);
            setError(
                intl.formatMessage({
                    id: 'loginPage.validation.loginError',
                }),
            );
        }
    };

    const [showEmailError, setShowEmailError] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);

    useEffect(() => {
        if (errors.email) setShowEmailError(true);
        if (errors.password) setShowPasswordError(true);
    }, [errors]);

    useEffect(() => {
        const sessionToken = LocalStorageManager.getValue('sessionToken');
        const refreshToken = LocalStorageManager.getValue('refreshToken');
        if (sessionToken || refreshToken) {
            window.location.href = paths.dashboard;
        }
    });

    return {
        signin,
        control,
        register,
        handleSubmit,
        errors,
        error,
        showEmailError,
        showPasswordError,
    };
}
