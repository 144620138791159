import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';
import paths from '@shared/paths';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useGetCompanyInfoFromDomainQuery } from 'src/generated/graphql';

interface ICompanyInfoFromDomain {
    companyName?: string;
    companyLogo?: string;
}

export default (companyInfoFromProps?: ICompanyInfoFromDomain) => {
    const [companyName, setCompanyName] = useState('');
    const [companyLogo, setCompanyLogo] = useState('');
    const navigate = useNavigate();
    const [result] = useGetCompanyInfoFromDomainQuery();
    useEffect(() => {
        if (
            !companyInfoFromProps &&
            result.error?.graphQLErrors[0]?.extensions?.code === 'BAD_USER_INPUT'
        ) {
            LocalStorageManager.clear();
            navigate(paths.home);
        } else if (result.data?.getCompanyInfoFromDomain) {
            if (result.data.getCompanyInfoFromDomain.shortName) {
                setCompanyName(result.data.getCompanyInfoFromDomain.shortName);
            }
            if (result.data.getCompanyInfoFromDomain.logoUrl) {
                setCompanyLogo(result.data.getCompanyInfoFromDomain.logoUrl);
            }
        }
    }, [result]);

    return {
        companyName,
        companyLogo,
    };
};
