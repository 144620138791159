import { ICheckReportLayoutProps } from './CheckReportLayout.d';
import Header from '../../_universal/Header/Header';
import { Body, Main, Content } from './CheckReportLayout.styled';

export function DefaultLayout({
    children,
    companyName,
    companyLogo,
    pageTitle,
    displayLogOut,
}: ICheckReportLayoutProps): JSX.Element {
    return (
        <Body>
            <Main>
                <Header
                    companyName={companyName}
                    companyLogo={companyLogo}
                    pageTitle={pageTitle}
                    displayLogOut={displayLogOut}
                />
                <Content>{children}</Content>
            </Main>
        </Body>
    );
}
export default DefaultLayout;
