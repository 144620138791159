import { Grid } from '@material-ui/core';
import PickedFile from '@domains/ReportDomain/Components/PickedFile/PickedFile';
import { AttachFile } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { IFilesUploader, IFile } from './FilesUploader.d';
import { useRef } from 'react';
import { Button } from '@components/_universal';
import { AlertStyled } from '@domains/ReportDomain/ReportForm/ReportStepFive/ReportStepFive.styled';

const FilesUploader = ({
    files,
    onDelete,
    handleChange,
    fileProgresses,
    error,
}: IFilesUploader) => {
    const inputFile = useRef<HTMLDivElement>(null);
    return (
        <Grid container>
            <Grid item xs={12}>
                {files?.map((file: IFile, index: number) => (
                    <PickedFile
                        fileName={file.name}
                        index={index}
                        onDelete={onDelete}
                        inputRef={inputFile}
                        key={file.name}
                        progress={
                            fileProgresses.find((el) => el.frontId === file.frontId)?.progress
                        }
                        frontId={file.frontId}
                    />
                ))}
                <input
                    type={'file'}
                    ref={inputFile as any}
                    multiple
                    onChange={handleChange}
                    style={{ display: 'none' }}
                />
            </Grid>
            <Grid container justifyContent="center">
                <Button
                    variant="text"
                    icon={<AttachFile style={{ zIndex: 0 }} />}
                    className="marginTop20"
                    onClick={() => {
                        if (inputFile.current) {
                            inputFile.current.click();
                        }
                    }}
                >
                    <FormattedMessage id="checkReportPage.uploadFileModal.addAttachmentButton" />
                </Button>
            </Grid>
            {error && <AlertStyled severity="error">{error}</AlertStyled>}
        </Grid>
    );
};

export default FilesUploader;
