import { Grid, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import defaultTheme from '../../../shared/theme/default.theme';

export const ButtonsWrapper = styled(Grid)`
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    padding: 10px 0 25px;
    flex-direction: column;
    ${(props) => props.theme.media.md} {
        flex-direction: row;
    }
`;

export const NumberOfReports = styled.div`
    font-size: 18px;
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
    line-height: 1.33;
    letter-spacing: 0.24px;
    text-align: right;
    color: ${(props) => props.theme.colors.text};
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    opacity: 0.6;

    ${(props) => props.theme.media.md} {
        margin-top: 0;
        margin-bottom: 0;
        width: auto;
    }
`;

export const RightButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const FiltersContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${(props) => props.theme.media.md} {
        flex-direction: row;
    }}
`;

export const ReportNumberInputContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ReportNumberInputError = styled.p`
    color: ${(props) => props.theme.colors.error};
    font-size: ${(props) => props.theme.fontSizes.s}px;
`;

export const useStyles = makeStyles((theme) => ({
    inputRoot: {
        paddingBottom: '0px !important',
    },
    filterMargin: {
        marginTop: '10px',
        [theme.breakpoints.up('md')]: {
            marginLeft: '10px',
        },
    },
    root: {
        width: '100%',
        height: 530,
        marginBottom: '20px',

        '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
            display: 'none',
        },

        '& .MuiDataGrid-root': {
            borderLeft: 0,
            borderRight: 0,
            borderTop: `1px solid + ${defaultTheme.colors.border}`,
            borderBottom: `1px solid + ${defaultTheme.colors.border}`,
            borderRadius: 0,
            fontFamily: defaultTheme.fontFamily,

            '& .MuiDataGrid-columnSeparator': {
                display: 'none',
            },

            '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                lineHeight: 1.33,
                letterSpacing: '0.16px',
                fontWeight: defaultTheme.fontWeight.bold,
                color: '#000',
                opacity: 0.64,
                paddingTop: '5px',
                maxWidth: '100px',
                whiteSpace: 'normal',
            },

            '& .MuiDataGrid-row.Mui-selected': {
                background: '#fff7ca',
                '&:hover': {
                    background: defaultTheme.colors.lightPrimary,
                },
            },

            '& .MuiDataGrid-row': {
                cursor: 'pointer',
                '&:hover': {
                    background: defaultTheme.colors.lightPrimary,
                },
            },

            '& .MuiDataGrid-columnHeader:focus-within': {
                outline: 'none',
            },

            '& .MuiDataGrid-cell:focus-within': {
                outline: 'none',
            },

            '& .MuiDataGrid-cell': {
                fontSize: '13px',
                lineHeight: 1.71,
                letterSpacing: '0.19px',
            },
        },

        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: defaultTheme.colors.primary,
        },

        '& .status--UNREAD': {
            fontWeight: defaultTheme.fontWeight.bold,
        },

        '& .usersGrid.MuiDataGrid-root': {
            '& .MuiDataGrid-columnHeader:last-of-type': {
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                    // justifyContent: 'right',
                },
            },
            '& .MuiDataGrid-row': {
                cursor: 'default',
                '& > div:last-of-type': {
                    // justifyContent: 'right',
                    paddingRight: '0',
                },
                '&:hover': {
                    background: 'none',
                },
            },
        },
    },
}));
