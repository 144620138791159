import { InfoWrapper } from './Info.styled';
import img from '@assets/images/icon-timer.svg';

const Info: React.FC = ({ children }) => {
    return (
        <InfoWrapper>
            <img src={img} alt="timer" />
            <span>{children}</span>
        </InfoWrapper>
    );
};

export default Info;
