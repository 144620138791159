export default {
    home: '/',
    registration: '/auth/register',
    registrationActivation: '/auth/activate',
    registrationComplete: '/auth/complete-registration',
    registrationLinkExpired: '/auth/link-expired',
    registrationPayment: '/auth/payment',
    login: '/auth/login',
    resetPassword: '/auth/reset-password',
    resetPasswordNotify: '/auth/reset-notify',
    newPassword: '/auth/new-password',
    resetLinkExpired: '/auth/reset-link-expired',
    resetPasswordConfirmation: '/auth/reset-confirmation',
    dashboard: '/dashboard',
    users: '/dashboard/users',
    report: '/report/',
    reportForm: '/report/form',
    reportSubmitted: '/report/submitted',
    loginToReport: '/report/login',
    checkReportStatus: `/report/:reportNumber`,
    reportsSummary: '/dashboard/reports-summary',
};
