import Img from '../Img/Img';
import { ICompanyLogoProps } from './CompanyLogo.d';
import { CompanyLogoWrapper } from './CompanyLogo.styles';

const CompanyLogo = ({ src, alt }: ICompanyLogoProps) => {
    return (
        <CompanyLogoWrapper>
            <Img src={src} alt={alt} noPadding={true} />
        </CompanyLogoWrapper>
    );
};

export default CompanyLogo;
