import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { MenuItem } from '@material-ui/core';

export const QuickMenuWrapper = styled.div``;
export const ButtonStyled = styled(Button)`
    span {
        font-size: 14px;
        font-family: ${(props) => props.theme.fontFamily};
        font-weight: ${(props) => props.theme.fontWeight.bold};
        color: ${(props) => props.theme.colors.primary};
        letter-spacing: normal;
        text-transform: capitalize;

        img:first-of-type {
            margin-right: 10px;
        }
    }
`;

export const MenuItemStyled = styled(MenuItem)`
    &.MuiMenuItem-root {
        font-size: 14px;
        font-weight: ${(props) => props.theme.fontWeight.semiBold};
        letter-spacing: 0.19px;
        color: ${(props) => props.theme.colors.text};

        img {
            margin-right: 12px;
        }
    }
`;
