import alpha from 'color-alpha';
import styled from 'styled-components';
import defaultTheme from '@shared/theme/default.theme';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

export const CssTextField = withStyles({
    root: {
        fontFamily: '"Poppins", sans-serif',
        width: '100%',
        '& .MuiFormLabel-root': {
            fontFamily: '"Poppins", sans-serif',
            color: defaultTheme.colors.input_text_color,
            fontWeight: defaultTheme.fontWeight.semiBold,
        },
        '& .MuiInputBase-root': {
            fontFamily: '"Poppins", sans-serif',
            fontWeight: defaultTheme.fontWeight.semiBold,
        },
        '& .MuiInputBase-input': {
            fontFamily: '"Poppins", sans-serif',
            fontWeight: defaultTheme.fontWeight.semiBold,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: defaultTheme.colors.border,
                borderWidth: '2px',
            },
            '&:hover fieldset': {
                borderColor: defaultTheme.colors.input_text_color,
            },
            '&.Mui-focused fieldset': {
                borderColor: defaultTheme.colors.hover,
            },
            '&.Mui-error fieldset': {
                borderColor: defaultTheme.colors.error,
            },
        },
        '& .MuiFormLabel-root.Mui-error': {
            color: defaultTheme.colors.error,
            '& .MuiFormLabel-asterisk.Mui-error': {
                color: defaultTheme.colors.error,
            },
        },
        '& .MuiFormHelperText-root': {
            color: defaultTheme.colors.text,
            marginLeft: 0,
            fontFamily: '"Poppins", sans-serif',
            '&.Mui-error': {
                color: defaultTheme.colors.error,
            },
        },
        '& .MuiFormLabel-asterisk': {
            color: defaultTheme.colors.error,
        },
    },
})(TextField);

export const LabelChipGroup = withStyles({
    root: {
        margin: '0 0 16px',
        fontSize: '15px',
        color: '#1c1c3f',
        fontFamily: '"Poppins", sans-serif',
    },
})(FormLabel);

export const LabelChip = withStyles({
    root: {
        margin: '0 8px 0 0',
    },
    label: {
        fontSize: '15px',
        fontWeight: 500,
        padding: '13px 16px',
        borderRadius: '36px',
        background: alpha('#000000', 0.07),
    },
})(FormControlLabel);

export const RadioChip = withStyles({
    root: {
        width: 0,
        height: 0,
        padding: 0,
        margin: 0,
        overflow: 'hidden',
    },
})(Radio);

export const Group = styled.div`
    width: 100%;
    margin: 5px 0 24px;
`;
