import getUserLanguage from '@utils/getUserLanguage';
import { useFormContext } from 'react-hook-form';
import whistleblowerRelations from 'src/dict/whistleblowerRelations';

export default () => {
    const { control, register, watch, setValue, getValues } = useFormContext();
    const relationsArray = Object.entries(whistleblowerRelations[getUserLanguage()]).map(
        ([key, value]) => ({
            label: value,
            value: key,
        }),
    );

    return {
        control,
        register,
        relationsArray,
        values: watch(),
        setValue,
        watch,
        getValues,
    };
};
