import { Button, Paragraph } from '@components/_universal';
import { Grid } from '@material-ui/core';
import time from '@assets/images/icon-timer-2.svg';
import edit from '@assets/images/icon-edit-issue.svg';
import { ReportFooterWrapper, Domain, FlexContainer } from './ReportFooter.styled';
import { FormattedMessage } from 'react-intl';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ReportSubmittedPdf } from '../../../../pdfs/ReportSubmittedPdf';
import { Report_Category, WhistleblowerRelation } from '../../../../generated/graphql';
export interface IReportFooterProps {
    domain: string;
    createdAt: Date;
    reportNumber: string;
    reportUrl: string;
    attachments: string[];
    message: string;
    whistleblower?: {
        name: string;
        surname: string;
        email: string;
        phone: string;
    };
    whistleblowerRelation: WhistleblowerRelation;
    category: Report_Category | string;
    otherCategory?: string;
}
const ReportFooter = ({
    domain,
    reportNumber,
    createdAt,
    message,
    attachments,
    whistleblower,
    whistleblowerRelation,
    category,
    otherCategory,
}: IReportFooterProps) => {
    return (
        <ReportFooterWrapper>
            <Grid container alignContent="center" alignItems="center">
                <Grid item xs={1}>
                    <img src={time} alt="" />
                </Grid>
                <Grid item xs={11}>
                    <Paragraph fontSize="15px">
                        <FormattedMessage id="reportFooter.firstParagraph" />
                    </Paragraph>
                </Grid>
                <Grid item xs={12}>
                    <br />
                    <br />
                </Grid>
                <Grid item xs={1}>
                    <img src={edit} alt="" />
                </Grid>
                <Grid item xs={11}>
                    <Paragraph fontSize="15px">
                        <strong>
                            <FormattedMessage id="reportFooter.secondParagraphStrong" />
                        </strong>{' '}
                        <FormattedMessage id="reportFooter.secondParagraph" />
                    </Paragraph>
                </Grid>
                <FlexContainer>
                    {message.length > 0 && (
                        <PDFDownloadLink
                            style={{ textAlign: 'center', textDecoration: 'none' }}
                            document={
                                <ReportSubmittedPdf
                                    reportNumber={reportNumber}
                                    createdAt={createdAt}
                                    reportUrl={`${document.location.origin}/report`}
                                    attachments={attachments}
                                    message={message}
                                    whistleblower={whistleblower}
                                    whistleblowerRelation={whistleblowerRelation}
                                    category={category}
                                    otherCategory={otherCategory}
                                />
                            }
                            fileName={`${reportNumber}.pdf`}
                        >
                            <Button
                                size="medium"
                                variant="primary"
                                icon={<NoteAddIcon />}
                                style={{ margin: '20px auto 0px' }}
                            >
                                <FormattedMessage id="reportSubmittedPage.downloadReport" />
                            </Button>
                        </PDFDownloadLink>
                    )}
                    <Domain href={domain}>{domain}</Domain>
                </FlexContainer>
            </Grid>
        </ReportFooterWrapper>
    );
};

export default ReportFooter;
