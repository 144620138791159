import { useStyles } from '@components/_universal/CloseBtn/CloseBtn.styles';
import { useState } from 'react';
import { PASSWORD_STATUS } from './PasswordError/PasswordError.d';

interface IErrorProps {
    [key: string]: PASSWORD_STATUS;
}

export default function usePasswordInput() {
    const styles = useStyles();

    const [passwordValues, setPasswordValues] = useState({
        password: '',
        showPassword: false,
    });

    const [passwordErrors, setPasswordErrors] = useState<IErrorProps>({
        min: PASSWORD_STATUS.NORMAL,
        digit: PASSWORD_STATUS.NORMAL,
        specialCharacter: PASSWORD_STATUS.NORMAL,
        smallAndCapitalLetters: PASSWORD_STATUS.NORMAL,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const passwordString = event.target.value;
        setPasswordValues({ ...passwordValues, password: passwordString });
        let tempArray = passwordErrors;

        if (passwordString.length > 7) {
            tempArray.min = PASSWORD_STATUS.OK;
        } else if (passwordString.length > 0 && passwordString.length < 9) {
            tempArray.min = PASSWORD_STATUS.ERROR;
        } else {
            tempArray.min = PASSWORD_STATUS.NORMAL;
        }

        if (/\d/.test(passwordString)) {
            tempArray.digit = PASSWORD_STATUS.OK;
        } else {
            tempArray.digit = PASSWORD_STATUS.ERROR;
        }

        /* eslint-disable-next-line */
        if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(passwordString)) {
            tempArray.specialCharacter = PASSWORD_STATUS.OK;
        } else {
            tempArray.specialCharacter = PASSWORD_STATUS.ERROR;
        }

        if (/.*[A-Z].*/.test(passwordString) && /.*[a-z].*/.test(passwordString)) {
            tempArray.smallAndCapitalLetters = PASSWORD_STATUS.OK;
        } else {
            tempArray.smallAndCapitalLetters = PASSWORD_STATUS.ERROR;
        }
        setPasswordErrors(tempArray);
    };

    const handleClickShowPassword = () => {
        setPasswordValues({ ...passwordValues, showPassword: !passwordValues.showPassword });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return {
        handleMouseDownPassword,
        handleClickShowPassword,
        passwordValues,
        passwordErrors,
        styles,
        handleChange,
    };
}
