import { useState } from 'react';
import { ICheckboxProps } from './Checkbox';

export default function useCheckbox(onChange: ICheckboxProps['onChange']) {
    const [checkbox, setCheckbox] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckbox(!checkbox);
        if (onChange) {
            onChange(!checkbox);
        }
    };

    return { checkbox, handleChange };
}
