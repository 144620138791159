import { gql, useQuery } from 'urql';
import { Query } from 'src/generated/graphql';

const GET_REPORTS_FOR_DASHBOARD = gql`
    query GetReportsForDashboard($input: GetReportsForDashboardInput) {
        getReportsForDashboard(input: $input) {
            reports {
                _id
                whistleblower {
                    name
                    surname
                    __typename
                }
                whistleblowerRelation {
                    relation
                    other
                    __typename
                }
                category
                reportNumber
                createdAt
                otherCategory
                lastMessageDate
                hasNewMessages
                status
                closedInformations {
                    __typename
                    date
                    statement
                }
                messages {
                    __typename
                }
                __typename
            }
            meta {
                totalDocs
                totalPages
                __typename
            }
            counts {
                newCount
                inProgressCount
                closedCount
                __typename
            }
        }
    }
`;

export default () =>
    useQuery<Query['getReportsForDashboard']>({ query: GET_REPORTS_FOR_DASHBOARD });
