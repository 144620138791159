import ReportNumber from '../../../domains/DashboardDomain/Components/ReportNumber/ReportNumber';
import { IDetailsTitleWithReport } from './DetailsTitleWithReport.d';
import { Date, DetailsTitleWithReportWrapper } from './DetailsTitleWithReport.styled';

export const DetailsTitleWithReport = ({ title, reportNumber, date }: IDetailsTitleWithReport) => {
    return (
        <DetailsTitleWithReportWrapper>
            <div>
                <span>{title}</span>
                <ReportNumber reportNumber={reportNumber} />
            </div>
            <Date>{date}</Date>
        </DetailsTitleWithReportWrapper>
    );
};

export default DetailsTitleWithReport;
