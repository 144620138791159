import { createStyles, makeStyles } from '@material-ui/core';
import defaultTheme from '@shared/theme/default.theme';
import styled from 'styled-components';

export const SelectWrapper = styled.div`
    margin: 20px auto;
`;

export const useStyles = makeStyles(() =>
    createStyles({
        label: {
            color: defaultTheme.colors.text,
        },
    }),
);
