import { Route, Routes } from 'react-router-dom';
import {
    Registration,
    RegistrationActivation,
    RegistrationComplete,
    RegistrationLinkExpired,
    RegistrationPayment,
    Login,
    ResetPassword,
    ResetPasswordConfirmation,
    NewPassword,
    ResetLinkExpired,
    ResetPasswordNotify,
    Report,
    ReportSubmitted,
    Dashboard,
    LoginToReport,
    CheckReportStatus,
    Users,
} from '@domains/index';
import paths from '@shared/paths';
import ReportForm from '@domains/ReportDomain/ReportForm/ReportForm';
import { resolveEnv } from '@utils/helpers';
import ReportsSummaryPage from '../domains/DashboardDomain/ReportsSummary/ReportsSummary';

const RootRoutes = () => {
    const isDashboardApp = resolveEnv('REACT_APP_BASE_DOMAIN') === window.location.host;

    return (
        <Routes>
            <Route path={paths.home} element={isDashboardApp ? <Login /> : <Report />} />
            <Route
                path={paths.registration}
                element={isDashboardApp ? <Registration /> : <Report />}
            />
            <Route path={paths.registrationActivation} element={<RegistrationActivation />} />
            <Route path={paths.registrationComplete} element={<RegistrationComplete />} />
            <Route path={paths.registrationLinkExpired} element={<RegistrationLinkExpired />} />
            <Route path={paths.registrationPayment} element={<RegistrationPayment />} />
            <Route path={paths.login} element={isDashboardApp ? <Login /> : <Report />} />
            <Route path={paths.resetPassword} element={<ResetPassword />} />
            <Route path={paths.resetPasswordNotify} element={<ResetPasswordNotify />} />
            <Route path={paths.resetPasswordConfirmation} element={<ResetPasswordConfirmation />} />
            <Route path={paths.newPassword} element={<NewPassword />} />
            <Route path={paths.resetLinkExpired} element={<ResetLinkExpired />} />

            <Route path={paths.report} element={<Report />} />
            <Route path={paths.reportForm} element={<ReportForm />} />
            <Route path={paths.reportSubmitted} element={<ReportSubmitted />} />

            <Route path={paths.dashboard} element={<Dashboard />} />
            <Route path={paths.users} element={<Users />} />
            <Route path={paths.reportsSummary} element={<ReportsSummaryPage />} />

            <Route path={paths.loginToReport} element={<LoginToReport />} />
            <Route path={paths.checkReportStatus} element={<CheckReportStatus />} />
        </Routes>
    );
};

export default RootRoutes;
