import styled from 'styled-components';

export const ReportNumberWrapper = styled.div`
    display: flex;
    width: 320px;
    margin: 45px auto;
    align-items: center;
    justify-content: center;
`;
export const ReportText = styled.div`
    font-size: 18px;
    font-weight: ${(props) => props.theme.fontWeight.bold};
    color: ${(props) => props.theme.colors.text};
    margin-right: 10px;
`;
export const Report = styled.div`
    color: ${(props) => props.theme.colors.primary};
    border: 2px solid ${(props) => props.theme.colors.text};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    border-radius: 36px;
    padding: 7px 12px 8px;
`;
