import { gql, useMutation } from 'urql';
import {
    MutationUpdateBatchOfReportsStatusArgs,
    UpdateBatchOfReportsStatusMutation,
} from 'src/generated/graphql';

const UPDATE_BATCH_OF_REPORTS_STATUS = gql`
    mutation UpdateBatchOfReportsStatus($input: UpdateBatchOfReportsStatusInput!) {
        updateBatchOfReportsStatus(input: $input) {
            reportNumber
            status
        }
    }
`;

export default () =>
    useMutation<UpdateBatchOfReportsStatusMutation, MutationUpdateBatchOfReportsStatusArgs>(
        UPDATE_BATCH_OF_REPORTS_STATUS,
    );
