import { Cell } from './LastMessageCell.styled';
export interface ILastMessageCellProps {
    value: string;
    variant?: READ_STATUS;
}

export enum READ_STATUS {
    REPLIED = 'REPLIED',
    UNREAD = 'UNREAD',
    READ = 'READ',
}

export const LastMessageCell = ({ value, variant }: ILastMessageCellProps) => {
    return <Cell variant={variant}>{value}</Cell>;
};

export default LastMessageCell;
