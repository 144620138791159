import ActionBar from '../ActionBar/Action';
import { Form, SpaceFiller, Wrapper } from './FormWrapper.styled';
import { IFormWrapperProps } from './FormWrapper.d';
import { useNavigate } from 'react-router';

const FormWrapper = ({
    children,
    activeStep,
    setStep,
    lastStep,
    nextBtnText,
    disableNextBtn,
    onNextClick,
}: IFormWrapperProps) => {
    const navigate = useNavigate();
    return (
        <Form>
            <Wrapper>{children}</Wrapper>
            <SpaceFiller />
            <ActionBar
                activeStep={activeStep}
                setStep={setStep}
                lastStep={lastStep}
                nextBtnText={nextBtnText}
                disableNextBtn={disableNextBtn}
                onNextClick={onNextClick}
                onBackClick={() => navigate(-1)}
            />
        </Form>
    );
};

export default FormWrapper;
