import LocalStorageManager from '@services/LocalStorageManager/LocalStorageManager';

type SupportedLanguages = 'en' | 'pl';

export const SUPPORTED_LANGUAGES = ['en', 'pl'];
export default (): SupportedLanguages => {
    const localStorageLanguage = LocalStorageManager.getValue('language');
    const navigatorLanguageMapped = navigator.language.split('-')[0];
    const userLanguage =
        localStorageLanguage ||
        (SUPPORTED_LANGUAGES.includes(navigatorLanguageMapped) ? navigatorLanguageMapped : 'pl');
    if (!localStorageLanguage) {
        LocalStorageManager.setValue('language', userLanguage);
    }
    return userLanguage as SupportedLanguages;
};
