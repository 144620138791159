import styled from 'styled-components';

export const BurgerWrapper = styled.div<{ active: boolean }>`
    cursor: pointer;
    transition: 0.4s;

    &:hover {
        opacity: 0.5;
    }

    span {
        width: 18px;
        height: 2px;
        background-color: ${(props) => props.theme.colors.text};
        margin: 3px 0;
        transition: 0.4s;
        display: block;

        ${(props) =>
            props.active &&
            `
            &:nth-child(1) {
                transform: rotate(-45deg) translate(-3px, 3px);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                transform: rotate(45deg) translate(-4px, -4px);
            }
            `}
    }
`;
