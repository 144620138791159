import { DomainWrapper } from './Domain.styles';

const Domain: React.FC = ({ children }) => {
    return (
        <DomainWrapper>
            <div>{children}</div>
        </DomainWrapper>
    );
};

export default Domain;
