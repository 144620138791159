import { useEffect, useState } from 'react';
import { useGetCompanyInfoFromDomainQuery } from 'src/generated/graphql';
import { useIntl } from 'react-intl';

export default () => {
    const [result] = useGetCompanyInfoFromDomainQuery();
    const [loader, setLoader] = useState(true);
    const [validCompanyName, setValidCompanyName] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [companyLogo, setCompanyLogo] = useState('');

    useEffect(() => {
        if (result.error) {
            setLoader(false);
        }
        if (result.data?.getCompanyInfoFromDomain?.shortName) {
            setLoader(false);
            setValidCompanyName(true);
            setCompanyName(result.data?.getCompanyInfoFromDomain?.shortName);
        }
        if (result.data?.getCompanyInfoFromDomain?.logoUrl) {
            setCompanyLogo(result.data?.getCompanyInfoFromDomain?.logoUrl);
        }
    }, [result]);

    const intl = useIntl();
    const reportPageTitleValid = intl.formatMessage({
        id: 'reportPage.secondTitleValid',
    });
    const reportPageTitleInvalid = intl.formatMessage({ id: 'reportPage.secondTitleInvalid' });

    return {
        companyName,
        companyLogo,
        validCompanyName,
        loader,
        reportPageTitleValid,
        reportPageTitleInvalid,
    };
};
