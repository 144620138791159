import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    Get_Reports_For_Dashboard_Status_Filter,
    Get_Reports_Whistleblower,
    Whistleblower_Relation,
    Report_Category,
    Report,
    useGetReportsQuery,
    Report_Status,
} from 'src/generated/graphql';
import sub from 'date-fns/sub';
import categories from '../../../dict/categories';
import whistleblowerRelations from '../../../dict/whistleblowerRelations';
import getUserLanguage from '../../../utils/getUserLanguage';
import { format } from 'date-fns';

export type ReportRow = {
    id: string;
    whistleblower: string;
    relation: string;
    category: string;
    reportNumber: string;
    createdAt: string;
    status: string;
};

export type Filters = {
    from?: Date;
    to?: Date;
    status: Get_Reports_For_Dashboard_Status_Filter[];
    whistleblower: Get_Reports_Whistleblower[];
    relation: Whistleblower_Relation[];
    category: Report_Category[];
    reportNumber?: string;
};

export default () => {
    const [rows, setRows] = useState<ReportRow[]>([]);
    const [shouldPauseDownload, setShoudPauseDownload] = useState(true);
    const [page, setPage] = useState(1);
    const [openModal, setOpenModal] = useState(false);
    const [filters, setFilters] = useState<Filters>({
        from: sub(new Date(), { weeks: 1 }),
        to: new Date(),
        status: [],
        whistleblower: [],
        relation: [],
        category: [],
        reportNumber: undefined,
    });
    const intl = useIntl();

    const mapStatus = (status: Report_Status) => {
        if (status === Report_Status.Closed) {
            return intl.formatMessage({ id: 'dashboardPage.selectOption.status.closed' });
        }
        if (status === Report_Status.InProgress) {
            return intl.formatMessage({ id: 'dashboardPage.selectOption.status.inProgress' });
        }
        return intl.formatMessage({ id: 'dashboardPage.selectOption.status.new' });
    };

    const mapRows = (reports: Omit<Report, 'company' | 'messages' | 'hasNewMessages'>[]) =>
        reports.map((report) => ({
            id: report.reportNumber,
            whistleblower: report.whistleblower
                ? `${report.whistleblower.name} ${report.whistleblower.surname}`
                : intl.formatMessage({ id: 'reportDetailsPage.anonymous' }),
            relation:
                report.whistleblowerRelation?.other ||
                (report.whistleblowerRelation?.relation &&
                    whistleblowerRelations[getUserLanguage()][
                        report.whistleblowerRelation?.relation
                    ]) ||
                '',
            category:
                report.category !== Report_Category.Other
                    ? categories[getUserLanguage()][report.category]
                    : `${categories[getUserLanguage()][report.category]} ${
                          report.otherCategory ? `- ${report.otherCategory}` : ''
                      }`,
            reportNumber: report.reportNumber,
            createdAt: format(new Date(report.createdAt), 'dd.MM.yyyy'),
            status: mapStatus(report.status),
        })) || [];
    const [result, reexecuteQuery] = useGetReportsQuery({
        variables: {
            input: {
                pagination: {
                    page,
                    limit: 25,
                },
                filters: {
                    status: filters.status.length > 0 ? filters.status : undefined,
                    relation: filters.relation.length > 0 ? filters.relation : undefined,
                    category: filters.category.length > 0 ? filters.category : undefined,
                    whistleblower:
                        filters.whistleblower.length > 0 ? filters.whistleblower : undefined,
                    from: filters.from ? filters.from : undefined,
                    to: filters.to ? filters.to : undefined,
                    reportNumber: filters.reportNumber ? filters.reportNumber : undefined,
                },
            },
        },
    });
    const [resultReportsToDownload, reexecuteReportsToDownload] = useGetReportsQuery({
        variables: {
            input: {
                filters: {
                    status: filters.status.length > 0 ? filters.status : undefined,
                    relation: filters.relation.length > 0 ? filters.relation : undefined,
                    category: filters.category.length > 0 ? filters.category : undefined,
                    whistleblower:
                        filters.whistleblower.length > 0 ? filters.whistleblower : undefined,
                    from: filters.from ? filters.from : undefined,
                    to: filters.to ? filters.to : undefined,
                    reportNumber: filters.reportNumber ? filters.reportNumber : undefined,
                },
            },
        },
        // pause: shouldPauseDownload,
    });

    const getReportsBeforeDownload = () => {
        setShoudPauseDownload(false);
    };

    useEffect(() => {
        if (result.data?.getReports) {
            setRows(mapRows(result.data.getReports.reports));
        }
    }, [result]);

    useEffect(() => {
        if (resultReportsToDownload?.data?.getReports.reports) {
            setShoudPauseDownload(true);
        }
    }, [resultReportsToDownload]);

    return {
        filters,
        setFilters,
        rows,
        loading: result.fetching,
        page,
        setPage,
        totalDocs: result?.data?.getReports.meta.totalDocs || 0,
        openModal,
        setOpenModal,
        reports: result?.data?.getReports.reports || [],
        getReportsBeforeDownload,
        reportsToDownload: resultReportsToDownload?.data?.getReports.reports || [],
    };
};
