import styled from 'styled-components';

export const InfoWrapper = styled.div`
    text-align: center;
    font-size: 18px;
    line-height: 1.6;
    color: ${(props) => props.theme.colors.orange};
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
    margin: 32px auto;
    width: 100%;
    max-width: 400px;

    span {
        display: block;
        margin-top: 8px;
    }
`;
