import { gql, useQuery } from 'urql';
import { Query } from 'src/generated/graphql';

const GET_REPORT_FOR_WHISTLEBLOWER = gql`
    query GetReportForWhistleblower {
        getReportForWhistleblower {
            createdAt
            category
            otherCategory
            reportNumber
            status
            createdAt
            whistleblower {
                name
                surname
                phone
                email
            }
            whistleblowerRelation {
                relation
                other
            }
            personsInvolved {
                name
                surname
                relation
            }
            closedInformations {
                __typename
                date
                statement
            }
            messages {
                sendBy {
                    name
                    surname
                }
                sendAt
                content
                attachments {
                    size
                    ext
                    _id
                }
            }
            attachments {
                size
                ext
                _id
            }
            occurringDetails {
                recurring
                recurringFrequency
                firstTimeOccurredAt
            }
        }
    }
`;

export default () =>
    useQuery<Query['getReportForWhistleblower']>({ query: GET_REPORT_FOR_WHISTLEBLOWER });
