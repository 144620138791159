import { TextInput } from '@components/_form-elements/Inputs';
import { withStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import styled from 'styled-components';

export const TextInputStyled = withStyles({
    root: {
        marginTop: '30px',
        fontSize: '15px',
    },
})(TextInput);

export const AlertStyled = styled(Alert)`
    width: 100%;
    margin: 15px auto;
`;
