import { gql, useMutation } from 'urql';
import {
    MutationResendConfirmAccountEmailArgs,
    ResendConfirmAccountEmailMutation,
} from 'src/generated/graphql';

const RESEND_CONFIRM_ACCOUNT_EMAIL = gql`
    mutation ResendConfirmAccountEmail($input: ResendConfirmAccountEmailInput!) {
        resendConfirmAccountEmail(input: $input) {
            success
        }
    }
`;

export default () =>
    useMutation<ResendConfirmAccountEmailMutation, MutationResendConfirmAccountEmailArgs>(
        RESEND_CONFIRM_ACCOUNT_EMAIL,
    );
