import { gql, useMutation } from 'urql';
import {
    DeleteBatchOfReportAttachmentsMutation,
    MutationDeleteBatchOfReportAttachmentsArgs,
} from 'src/generated/graphql';

const DELETE_BATCH_OF_REPORT_ATTACHMENTS = gql`
    mutation deleteBatchOfReportAttachments($input: DeleteBatchOfReportAttachmentsInput!) {
        deleteBatchOfReportAttachments(input: $input) {
            success
        }
    }
`;

export default () =>
    useMutation<DeleteBatchOfReportAttachmentsMutation, MutationDeleteBatchOfReportAttachmentsArgs>(
        DELETE_BATCH_OF_REPORT_ATTACHMENTS,
    );
