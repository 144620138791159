import styled from 'styled-components';
import img from '@assets/images/icon-icon-star-outlined.svg';
import img2 from '@assets/images/icon-account-security-2.svg';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 273;

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        marginTop: '72px',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        border: 0,
        overflow: 'hidden',
    },
    drawerClose: {
        marginTop: '72px',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        border: 0,
        [theme.breakpoints.down('xs')]: {
            width: 0,
        },
    },
}));

export const MenuTitle = styled.span<{ active: boolean }>`
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.text};
    opacity: 0.64;
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
    margin: 24px 0 15px 24px;
    display: block;
    font-size: 14px;
    transition: 500ms opacity ease;

    ${(props) => (props.active ? `opacity: 1;` : 'display: none')}
`;

export const MenuItemsWrapper = styled.div<{ active: boolean; minusTopPadding?: boolean }>`
    padding-left: 15px;
    padding-bottom: 5px;
    padding-top: 5px;

    border-bottom: 1px solid ${(props) => props.theme.colors.border};

    transition: all 500ms ease;

    ${(props) =>
        props.active === false &&
        `
        padding-left: 2px;

    `}
`;

export const SpecialLink = styled.div<{ active: boolean }>`
    transition: 500ms padding ease;
    border-radius: 36px;
    padding: 8px 16px;
    &:hover {
        background: rgba(0, 0, 0, 0.04);
    }
    a {
        color: ${(props) => props.theme.colors.dark};
        background: url(${img}) 0 50% no-repeat;
        background-size: 24px;
        font-size: 14px;
        text-decoration: none;
        display: block;
        padding: 5px 0 5px 36px;
        font-weight: ${(props) => props.theme.fontWeight.semiBold};
        transition: 500ms all ease;
    }
`;

export const ChannelLink = styled.div<{ active: boolean }>`
    transition: 500ms padding ease;
    border-radius: 36px;
    padding: 8px 16px;
    &:hover {
        background: rgba(0, 0, 0, 0.04);
    }
    cursor: pointer;
`;

export const ChannelLinkText = styled.div`
    color: ${(props) => props.theme.colors.dark};
    background: url(${img2}) 0 50% no-repeat;
    background-size: 24px;
    font-size: 14px;
    text-decoration: none;
    display: block;
    padding: 5px 0 5px 36px;
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
    transition: 500ms all ease;
`;

export const ChannelLinkSubText = styled.div`
    font-size: 12px;
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
    opacity: 0.64;
    color: ${(props) => props.theme.colors.text};
    transition: 500ms padding ease;
    display: block;
    padding: 3px 0 5px 36px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const BorderRight = styled.div`
    position: absolute;
    width: 1px;
    height: 100vh;
    background: ${(props) => props.theme.colors.border};
    top: 0;
    right: 0;
    z-index: 1;
`;

export const BottomMenu = styled.div<{ active: boolean }>`
    position: absolute;
    bottom: 72px;
    padding: 30px 15px;
    border-top: 1px solid ${(props) => props.theme.colors.border};
    width: 275px;
    ${(props) =>
        props.active === false &&
        `
        padding: 30px 0px 22px 4px;
    `}
`;
