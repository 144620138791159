import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';

export const CheckboxRoot = styled.div``;

export const CheckboxWrapper = styled.div`
    display: flex;
    align-items: flex-start;
`;

export const Label = styled.label`
    cursor: pointer;
    line-height: 1.6;
    font-size: 0.9375rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.text};
`;

export const HelperText = styled.p<{ error?: boolean }>`
    display: flex;
    flex: 0 0 100%;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    color: ${(props) => (props.error ? props.theme.colors.error : props.theme.colors.text)};
`;

export const UncheckedIcon = styled.div`
    width: 1.375rem;
    height: 1.375rem;
    border-color: currentColor;
    background-color: transparent;
    border: 2px solid ${(props) => props.theme.colors.border};
    border-radius: 0.375rem;
`;

export const CheckedIcon = styled(CheckIcon)`
    && {
        width: 1.375rem;
        height: 1.375rem;
        background-color: ${(props) => props.theme.colors.primary};
        border-radius: 0.375rem;
        border: none;

        * {
            fill: white;
        }
    }
`;
