import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const DataGridButtonsWrapper = styled(Grid)`
    justify-content: space-between;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    padding: 10px 0 25px;
`;
