import { MenuItemProps } from './MenuItem.d';
import { MenuItemWrapper, IconWrapper, MenuNumber, MenuText } from './MenuItem.styled';

export const MenuItem = ({ children, icon, number, active, onClick }: MenuItemProps) => {
    return (
        <MenuItemWrapper active={active} onClick={onClick}>
            <IconWrapper>{icon}</IconWrapper>
            <MenuText>{children}</MenuText>
            {number !== undefined && <MenuNumber>{number}</MenuNumber>}
        </MenuItemWrapper>
    );
};
