import { HeaderH1, HeaderH2, HeaderH3, HeaderH4 } from './Title.styled';
import { ITitleProps, TITLE_TYPE } from './Title.d';
import { FormattedMessage } from 'react-intl';

const Title = ({ content, type, center, color, marginTop, id }: ITitleProps) => {
    const titleProps = { marginTop: marginTop, center: center, color: color };

    const titleDictionary: { [key in TITLE_TYPE]: JSX.Element } = {
        H1: <HeaderH1 {...titleProps}>{id ? <FormattedMessage id={id} /> : content}</HeaderH1>,
        H2: <HeaderH2 {...titleProps}>{id ? <FormattedMessage id={id} /> : content}</HeaderH2>,
        H3: <HeaderH3 {...titleProps}>{id ? <FormattedMessage id={id} /> : content}</HeaderH3>,
        H4: <HeaderH4 {...titleProps}>{id ? <FormattedMessage id={id} /> : content}</HeaderH4>,
    };

    return titleDictionary[type ? TITLE_TYPE[type] : TITLE_TYPE.H1];
};

export default Title;
