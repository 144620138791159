import { ActionBarWrapper, LeftWrapper } from './ActionBar.styled';
import SelectOption from '../SelectOption/SelectOption';
import { QuickMenu } from '@components/_universal/';
import { useIntl } from 'react-intl';
import { RefreshButton } from '../RefreshButton/RefreshButton';

interface IActionBarProps {
    onRefresh: () => void;
    onSelectManyReports: (value?: string) => void;
    onSort: (value?: string) => void;
    setStatus: (value?: string) => void;
    showSetStatus: boolean;
    showMenu?: boolean;
}

const ActionBar = ({
    onRefresh,
    onSelectManyReports,
    onSort,
    showSetStatus,
    setStatus,
    showMenu,
}: IActionBarProps) => {
    const intl = useIntl();
    return (
        <ActionBarWrapper>
            <LeftWrapper>
                {showMenu && (
                    <SelectOption
                        onSelectManyReports={onSelectManyReports}
                        onSort={onSort}
                        showSetStatus={showSetStatus || false}
                        setStatus={setStatus}
                    />
                )}
                <RefreshButton onClick={onRefresh} />
            </LeftWrapper>
            <QuickMenu
                name="Sorting"
                options={[
                    {
                        name: intl.formatMessage({ id: 'dashboardPage.actionBar.sorting.newest' }),
                        value: 'DESC',
                    },
                    {
                        name: intl.formatMessage({ id: 'dashboardPage.actionBar.sorting.oldest' }),
                        value: 'ASC',
                    },
                ]}
                position="right"
                type="Sorting"
                onSort={onSort}
            />
        </ActionBarWrapper>
    );
};

export default ActionBar;
