import { useEffect, useRef, useState } from 'react';
import {
    Report_Status,
    Update_Report_Status,
    useGetReportDetailsAsCompanyUserQuery,
    useUpdateBatchOfReportsStatusMutation,
    useGetReportFileDownloadLinkQuery,
    useReplyToReportAsCompanyUserMutation,
    useCloseBatchOfReportsMutation,
    Get_Reports_For_Dashboard_Status_Filter,
} from 'src/generated/graphql';
import useStore from '../../../store/useStore';
import { ReportDetailsProps } from './ReportDetails';

export default ({ reportNumber, reexecuteGetReports }: ReportDetailsProps) => {
    const [reportDetailsVisible, setReportDetailsVisible] = useState(true);
    const [downloadFileId, setDownloadFileId] = useState<string | null>(null);
    const [openModal, setOpenModal] = useState(false);
    const [openCloseCaseModal, setOpenCloseCaseModal] = useState(false);
    const [_, updateBatchOfReports] = useUpdateBatchOfReportsStatusMutation();
    const [__, replyToReportAsCompanyUser] = useReplyToReportAsCompanyUserMutation();
    const [___, closeBatchOfReports] = useCloseBatchOfReportsMutation();
    const { setActiveStatus } = useStore();

    const [result, reexecuteGetReportQuery] = useGetReportDetailsAsCompanyUserQuery({
        variables: {
            input: {
                reportNumber,
            },
        },
    });

    const [fileDownloadLinkResult] = useGetReportFileDownloadLinkQuery({
        variables: {
            input: {
                fileId: downloadFileId as string,
                reportNumber,
            },
        },
        pause: !!!downloadFileId,
    });

    useEffect(() => {
        async function updateReport() {
            if (result.data?.getReportDetailsAsCompanyUser.status === Report_Status.Sent) {
                await updateBatchOfReports({
                    input: {
                        reportNumbers: [result.data.getReportDetailsAsCompanyUser.reportNumber],
                        status: Update_Report_Status.Received,
                    },
                });
                reexecuteGetReports();
            }
        }
        updateReport();
    }, [result]);

    useEffect(() => {
        const url = fileDownloadLinkResult.data?.getReportFileDownloadLink;
        if (url && downloadFileId && !fileDownloadLinkResult.fetching) {
            window.open(url, '_blank');
            setDownloadFileId(null);
        }
    }, [fileDownloadLinkResult.fetching]);

    const bottomEl = useRef<null | HTMLDivElement>(null);
    useEffect(() => {
        bottomEl.current?.scrollIntoView({ behavior: 'smooth' });
    }, [result]);

    const updateReportStatus = async (status?: Update_Report_Status | string) => {
        if (status) {
            if (status === Report_Status.Closed) {
                setOpenCloseCaseModal(!openCloseCaseModal);
                setActiveStatus(Get_Reports_For_Dashboard_Status_Filter.Closed);
            } else {
                await updateBatchOfReports({
                    input: {
                        reportNumbers: [reportNumber],
                        status: status as Update_Report_Status,
                    },
                });
                if (status === Update_Report_Status.InProgress) {
                    setActiveStatus(Get_Reports_For_Dashboard_Status_Filter.InProgress);
                }
                if (status === Update_Report_Status.Received) {
                    setActiveStatus(Get_Reports_For_Dashboard_Status_Filter.New);
                }
                reexecuteGetReports();
            }
        }
    };

    const sendReply = async (message: string) => {
        const result = await replyToReportAsCompanyUser({
            input: {
                reportNumber,
                message,
            },
        });
        if (result.data?.replyToReportAsCompanyUser._id) {
            reexecuteGetReportQuery();
        }
    };
    const onRefresh = () => {
        reexecuteGetReportQuery();
    };
    const closeReport = async (closingStatement: string) => {
        await closeBatchOfReports({
            input: {
                reportNumbers: [reportNumber],
                closingStatement,
            },
        });
    };

    return {
        reportDetailsVisible,
        setReportDetailsVisible,
        openModal,
        setOpenModal,
        report: result.data?.getReportDetailsAsCompanyUser,
        setDownloadFileId,
        updateReportStatus,
        sendReply,
        openCloseCaseModal,
        setOpenCloseCaseModal,
        closeReport,
        bottomEl,
        onRefresh,
    };
};
