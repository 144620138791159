import styled from 'styled-components';

export const MessageWrapper = styled.div<{ response?: boolean }>`
    background: #ffffff;
    padding: 20px;
    margin-top: 20px;
    color: ${(props) => props.theme.colors.text};
    line-height: 1.71;
    position: relative;
    border-radius: 10px;
    max-width: 90%;
    font-size: 14px;

    ${(props) => props.theme.media.md} {
        max-width: 70%;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: -20px;
        border-style: solid;
        border-width: 0 30px 30px 0;
        border-color: transparent #fff transparent transparent;
    }

    ${(props) =>
        props.response &&
        `
        &:before {
            display:none;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0px;
            right: -20px;
            border-style: solid;
            border-width: 30px 30px 0 0;
            border-color: #fff transparent transparent transparent;`}

    p {
        margin-top: 0;
    }
`;

export const File = styled.div`
    display: flex;
    font-weight: 500;
    line-height: 20px;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;

    svg {
        margin-right: 20px;
    }

    span {
        display: inline-block;
        margin-right: 20px;
        padding: 5px 0;
    }

    a {
        font-weight: 700;
        color: ${(props) => props.theme.colors.primary};
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;
