import styled from 'styled-components';

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export const Main = styled.main<{ bgColor?: string }>`
    flex: 1 0 auto;
    background: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
`;
