import { makeStyles, createStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import defaultTheme from '@shared/theme/default.theme';
import { MenuItem } from '@material-ui/core';

export const QuickMenuWrapper = styled.div``;

export const useStyles = makeStyles(() =>
    createStyles({
        menuItem: {
            fontSize: '14px',
            fontWeight: defaultTheme.fontWeight.semiBold,
            letterSpacing: '0.19px',
            color: defaultTheme.colors.text,
        },
    }),
);

export const ButtonStyled = styled(Button)`
    span {
        font-size: 14px;
        font-family: ${(props) => props.theme.fontFamily};
        font-weight: ${(props) => props.theme.fontWeight.bold};
        color: ${(props) => props.theme.colors.primary};
        letter-spacing: normal;
        text-transform: capitalize;

        img:first-of-type {
            margin-right: 10px;
        }
    }
`;

export const MenuItemStyled = styled(MenuItem)<{ active?: boolean }>`
    div {
        width: 20px;
        height: 20px;
        position: relative;
        border: 2px solid ${(props) => props.theme.colors.border};
        border-radius: 50%;
        margin-right: 16px;

        ${(props) =>
            props.active &&
            `
            border-color: ${props.theme.colors.primary};
            &:before {
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: ${props.theme.colors.primary};
                top: 50%;
                left: 50%;
                margin: -6px 0 0 -6px;
                border-radius:50%;
            }
            `}
    }
`;
