import styled from 'styled-components';

export const MenuItemWrapper = styled.div<{ last?: boolean; active?: boolean }>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: ${(props) => props.theme.colors.dark};
    padding: 8px 16px;
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
    border-radius: 36px;
    margin-right: 8px;
    min-width: 220px;
    transition: 500ms all ease;
    cursor: pointer;
    &:hover {
        background: rgba(0, 0, 0, 0.04);
    }

    ${(props) =>
        props.active &&
        `background: #f3eafe;
        color: ${props.theme.colors.primary};
        
        `}
`;
export const IconWrapper = styled.div<{ last?: boolean; active?: boolean }>`
    width: 24px;
    height: 24px;
    margin-right: 15px;
    text-align: left;
    ${(props) =>
        props.active &&
        `
        color: ${props.theme.colors.primary};
        
        `}
`;
export const MenuText = styled.div`
    width: calc(100% - 64px);
`;
export const MenuNumber = styled.div`
    width: 40px;
    text-align: right;
`;
