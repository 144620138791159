import { IParagraphProps } from './Paragraph.d';
import { P } from './Paragraph.styles';

const Paragraph = ({
    children,
    marginTop,
    marginBottom,
    fontSize,
    center,
    marginLeft,
    color,
    left,
    fontWeight,
}: IParagraphProps) => {
    return (
        <P
            marginTop={marginTop}
            color={color}
            marginBottom={marginBottom}
            fontSize={fontSize}
            center={center}
            left={left}
            marginLeft={marginLeft}
            fontWeight={fontWeight}
        >
            {children}
        </P>
    );
};

export default Paragraph;
