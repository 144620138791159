import { DefaultLayout } from '@components/Layouts';
import { Paragraph } from '@components/_universal';
import FormWrapper from '@domains/ReportDomain/Components/FormWrapper/FormWrapper';
import StepTitle from '@domains/ReportDomain/Components/StepTitle/StepTitle';
import { DevTool } from '@hookform/devtools';
import { IReportStepProps } from '../ReportStepOne/ReportStepOne.d';
import { useRef } from 'react';
import { TextInputStyled } from './ReportStepFive.styled';
import { FormattedMessage } from 'react-intl';
import useReportStepFive from './useReportStepFive';
import FilesUploader from '@components/_universal/FilesUploader/FilesUploader';

const ReportStepFive = ({
    activeStep,
    setStep,
    lastStep,
    companyName,
    companyLogo,
}: IReportStepProps) => {
    const {
        control,
        register,
        files,
        onDelete,
        message,
        errors,
        fileProgresses,
        handleFilesChange,
        error,
        intl,
    } = useReportStepFive();
    const inputFile = useRef<HTMLDivElement>(null);
    return (
        <DefaultLayout
            alignItemsCenter
            setFullHeight
            companyName={companyName}
            companyLogo={companyLogo}
        >
            <FormWrapper
                activeStep={activeStep}
                setStep={setStep}
                lastStep={lastStep}
                disableNextBtn={!message || message.length < 20 || message.length > 2800}
                nextBtnText={intl.formatMessage({ id: 'reportForm.nextStepButton' })}
            >
                <StepTitle number={5}>
                    <FormattedMessage id="reportForm.stepFive.title" />
                </StepTitle>
                <Paragraph marginTop="30px" fontSize="15px">
                    <FormattedMessage id="reportForm.stepFive.paragraph" />
                </Paragraph>
                <TextInputStyled
                    multiline
                    error={Boolean(errors.message)}
                    helperText={errors.message ? errors.message.message : undefined}
                    {...register('message', {
                        required: intl.formatMessage({
                            id: 'reportForm.stepFive.validation.emptyMessage',
                        }),
                        minLength: {
                            value: 20,
                            message: intl.formatMessage(
                                {
                                    id: 'reportForm.stepFive.validation.minLength',
                                },
                                { chars: 20 },
                            ),
                        },
                        maxLength: {
                            value: 2800,
                            message: intl.formatMessage(
                                {
                                    id: 'reportForm.stepFive.validation.maxLength',
                                },
                                { chars: 2800 },
                            ),
                        },
                    })}
                    minRows={8}
                    label={intl.formatMessage({ id: 'reportForm.stepFive.messageLabel' })}
                />

                <Paragraph marginTop="30px" fontSize="14px" fontWeight={'bold'}>
                    <FormattedMessage id="reportForm.stepFive.attachmentsFirstParagraph" />
                </Paragraph>
                <Paragraph marginTop="7px" fontSize="14px" marginBottom="12px">
                    <FormattedMessage id="reportForm.stepFive.attachmentsSecondParagraph" />
                </Paragraph>
                <FilesUploader
                    files={files}
                    onDelete={onDelete}
                    handleChange={handleFilesChange}
                    fileProgresses={fileProgresses}
                    error={error}
                />
            </FormWrapper>
            <DevTool control={control} placement="top-right" />
        </DefaultLayout>
    );
};

export default ReportStepFive;
