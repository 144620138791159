import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@material-ui/core';
import useCheckbox from './useCheckbox';
import { CustomCheckbox, useStyles } from './Checkbox.styles';

export interface ICheckboxProps {
    label?: string;
    name?: string;
    inputRef?: any; // FIX
    labelClass?: string;
    onChange?: (value: boolean) => void;
}

export const Checkbox = ({ label, name, inputRef, onChange, labelClass }: ICheckboxProps) => {
    const { checkbox, handleChange } = useCheckbox(onChange);
    const styles = useStyles();

    return (
        <FormControlLabel
            control={
                <CustomCheckbox
                    checked={checkbox}
                    onChange={handleChange}
                    name={name}
                    inputRef={inputRef}
                />
            }
            label={<Typography className={labelClass || styles.label}>{label}</Typography>}
        />
    );
};
