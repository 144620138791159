import getUserLanguage from '@utils/getUserLanguage';
import { useFormContext } from 'react-hook-form';
import categories from 'src/dict/categories';

export default () => {
    const { control, register, getValues, setValue } = useFormContext();

    const mappedCategories = Object.entries(categories[getUserLanguage()]).map(([key, value]) => ({
        label: value,
        value: key,
    }));

    return {
        control,
        register,
        categories: mappedCategories,
        values: getValues(),
        setValue,
    };
};
