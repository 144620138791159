import { ColorType, FontWeights } from '@@types/CommonTypes';
import styled from 'styled-components';

export const P = styled.div<{
    marginTop?: string;
    marginBottom?: string;
    marginLeft?: string;
    fontSize?: string;
    center?: boolean;
    color?: ColorType;
    left?: boolean;
    fontWeight?: FontWeights;
}>`
    font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
    line-height: 1.67;
    color: ${(props) => (props.color ? props.theme.colors[props.color] : props.theme.colors.text)};
    font-weight: ${(props) =>
        props.fontWeight
            ? props.theme.fontWeight[props.fontWeight]
            : props.theme.fontWeight.regular};
    margin-top: ${(props) => props.marginTop};
    margin-left: ${(props) => props.marginLeft};
    margin-bottom: ${(props) => props.marginBottom};
    ${(props) =>
        props.center &&
        `
    text-align: center;
  `};
    ${(props) =>
        props.left &&
        `
    text-align: left;
  `};

    ${(props) => props.theme.media.md} {
        font-size: ${(props) => (props.fontSize ? props.fontSize : '18px')};
    }

    & a {
        color: ${(props) => props.theme.colors.primary};
        &:hover {
            color: ${(props) => props.theme.colors.secondary};
        }
    }
`;
