import styled from 'styled-components';

export const ReportInfoRowWrapper = styled.div`
    display: flex;
    align-items: center;
    line-height: 1.71;
    font-size: 14px;
    padding: 8px 0;
    flex-wrap: wrap;

    ${(props) => props.theme.media.md} {
        flex-wrap: nowrap;
    }

    &.bold {
        font-weight: ${(props) => props.theme.fontWeight.bold};
    }

    > .title {
        color: ${(props) => props.theme.colors.input_text_color};
        text-transform: uppercase;
        margin-bottom: 10px;
        font-weight: ${(props) => props.theme.fontWeight.bold};
        height: 30px;
        line-height: 28px;
        width: 100%;
        padding-right: 5px;

        ${(props) => props.theme.media.md} {
            min-width: 140px;
            margin-bottom: 0;
            width: auto;
        }
    }

    > .data {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: relative;

        ${(props) => props.theme.media.lg} {
            flex-wrap: nowrap;
        }

        &.attachments {
            ${(props) => props.theme.media.lg} {
                flex-wrap: wrap;
            }
        }

        > span,
        > a {
            margin-right: 20px;
            color: ${(props) => props.theme.colors.text};
            text-decoration: none;
            display: inline-flex;
            align-items: center;
            height: auto;

            img {
                display: inline-block;
                margin-right: 8px;
            }
        }

        > a:hover {
            text-decoration: underline;
        }

        > div {
            position: absolute;
            top: 0;
            right: 0;
            margin-top: -45px;
            ${(props) => props.theme.media.md} {
                position: static;
                margin-left: 20px;
                margin-top: 0;
            }
        }

        > a:last-of-type {
            margin-right: 0;
            min-width: 100px;
        }
    }
`;
