import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useStyles, LinkWrapper } from './ForgottenLink.styles';

export const ForgottenLink = ({ children, link }: { children: ReactNode; link: string }) => {
    const styles = useStyles();
    return (
        <LinkWrapper className={styles.link}>
            <Link to={link}>{children}</Link>
        </LinkWrapper>
    );
};
