import styled from 'styled-components';

export const ReportFooterWrapper = styled.div`
    margin-top: 40px;
    border-top: 1px solid ${(props) => props.theme.colors.border};
    padding-top: 30px;
`;

export const Domain = styled.a`
    color: ${(props) => props.theme.colors.primary};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    font-size: 15px;
    border-radius: 8px;
    border: 2px solid ${(props) => props.theme.colors.border};
    margin: 20px auto 30px;
    padding: 13px 19px 14px;
    text-decoration: none;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
