import { TextInput, PasswordInput } from '@components/_form-elements/Inputs';
import { DefaultLayout } from '@components/Layouts';
import { Card, Button, Title, Highlight } from '@components/_universal';
import useLoginPage from './useLoginToReportPage';
import Alert from '@material-ui/lab/Alert';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Footer } from '@components/_universal';
import useGetCompanyInfoFromDomain from '@utils/hooks/useGetCompanyInfoFromDomain';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';

const LoginToReportPage = () => {
    const companyNameFromProps = useLocation().state as {
        companyName?: string;
        companyLogo?: string;
    };
    const { companyName, companyLogo } = useGetCompanyInfoFromDomain(companyNameFromProps);

    const {
        showReportNumberError,
        errors,
        error,
        register,
        handleSubmit,
        showPasswordError,
        signin,
        intl,
    } = useLoginPage();
    return (
        <DefaultLayout
            companyName={companyNameFromProps.companyName || companyName}
            companyLogo={companyNameFromProps.companyLogo || companyLogo}
            pageTitle={intl.formatMessage({ id: 'loginToReportPage.pageTitle' })}
        >
            <Card>
                <Title center id="loginToReportPage.title" />
                <Highlight variant="violet">
                    <FormattedMessage id="loginToReportPage.highlight" />
                </Highlight>
                <form>
                    <TextInput
                        required
                        error={Boolean(errors.reportNumber) || showReportNumberError}
                        helperText={errors.reportNumber ? errors.reportNumber.message : undefined}
                        label={intl.formatMessage({ id: 'loginToReportPage.reportNumberLabel' })}
                        type="reportNumber"
                        {...register('reportNumber', {
                            required: intl.formatMessage({
                                id: 'loginToReportPage.validation.emptyReportNumber',
                            }),
                            minLength: 4,
                            maxLength: 4,
                        })}
                    />
                    <PasswordInput
                        label={intl.formatMessage({ id: 'loginToReportPage.passwordLabel' })}
                        error={Boolean(errors.password) || showPasswordError}
                        helperText={errors.password ? errors.password.message : undefined}
                        {...register('password', {
                            required: intl.formatMessage({
                                id: 'loginToReportPage.validation.emptyPassword',
                            }),
                            minLength: 1,
                        })}
                    />
                    <Button
                        type="submit"
                        onSubmit={handleSubmit(signin)}
                        onClick={handleSubmit(signin)}
                        size="full"
                        variant="primary"
                        icon={<AccountCircleIcon />}
                    >
                        <FormattedMessage id="loginToReportPage.loginButton" />
                    </Button>
                </form>
                {error.length > 0 && (
                    <>
                        <br />
                        <Alert severity="error">{error}</Alert>
                    </>
                )}
                <br />
                <br />
                <br />
            </Card>
            <Card wide>
                <Footer />
            </Card>
        </DefaultLayout>
    );
};

export default LoginToReportPage;
