import Menu from '@material-ui/core/Menu';
import Arrow from '@assets/images/icon-icon-arrow-down-purple.svg';
import { QuickMenuWrapper, ButtonStyled, MenuItemStyled } from './DownloadMenu.styled';
import useQuickMenu from './useDownloadMenu';
import { IDownloadMenuProps } from './DownloadMenu.d';
import DownloadIcon from '@assets/images/icon-download.svg';
import BlackDownloadIcon from '@assets/images/icon-download-black2.svg';
import FileAttachmentIcon from '@assets/images/icon-attachment.svg';
import { FormattedMessage } from 'react-intl';

export const DownloadMenu = ({ files, onClick, onFileClick }: IDownloadMenuProps) => {
    const { handleClose, anchorEl, handleClick } = useQuickMenu();

    return (
        <QuickMenuWrapper>
            <ButtonStyled
                aria-controls="download"
                aria-haspopup="true"
                onClick={(e) => {
                    handleClick(e);
                    if (onClick) onClick(e);
                }}
            >
                <img src={DownloadIcon} />
                <FormattedMessage id="downloadMenu.download" />
                <img src={Arrow} />
            </ButtonStyled>
            <Menu
                id="download"
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {files.map((file, index) => {
                    return (
                        <MenuItemStyled
                            key={`${file._id}${index}`}
                            onClick={() => onFileClick(file._id)}
                        >
                            <img src={FileAttachmentIcon} />
                            plik_{index}.{file.ext}
                        </MenuItemStyled>
                    );
                })}
                {/* <MenuItemStyled>
                    <img src={BlackDownloadIcon} />
                    Pobierz wszystkie
                </MenuItemStyled> */}
            </Menu>
        </QuickMenuWrapper>
    );
};

export default DownloadMenu;
