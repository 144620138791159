import { IStepperProps } from './Stepper.d';

export default ({ activeStep, stepsLength }: IStepperProps) => {
    let steps = [];

    for (let i = 1; i < stepsLength + 1; i++) {
        steps.push({
            isActive: i <= activeStep,
            isLast: i === stepsLength,
        });
    }

    return {
        steps,
    };
};
