import { DefaultLayout } from '@components/Layouts';
import FormWrapper from '@domains/ReportDomain/Components/FormWrapper/FormWrapper';
import StepTitle from '@domains/ReportDomain/Components/StepTitle/StepTitle';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { Radio, TextInput } from '@components/_form-elements/Inputs';
import ClearIcon from '@material-ui/icons/Clear';

import { DevTool } from '@hookform/devtools';
import { IReportStepProps } from '../ReportStepOne/ReportStepOne.d';
import useReportStepTwo from './useReportStepTwo';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Report_Category } from 'src/generated/graphql';
import { IconButton } from '@material-ui/core';

const ReportStepTwo = ({
    activeStep,
    setStep,
    lastStep,
    companyName,
    companyLogo,
}: IReportStepProps) => {
    const { control, categories, values, setValue, register } = useReportStepTwo();
    const intl = useIntl();
    return (
        <DefaultLayout
            alignItemsCenter
            setFullHeight
            companyName={companyName}
            companyLogo={companyLogo}
        >
            <FormWrapper
                activeStep={activeStep}
                setStep={setStep}
                lastStep={lastStep}
                nextBtnText={intl.formatMessage({ id: 'reportForm.nextStepButton' })}
                disableNextBtn={values?.category?.length < 0 || values?.category === undefined}
            >
                <StepTitle number={2}>
                    <FormattedMessage id="reportForm.stepTwo.title" />
                </StepTitle>
                <Controller
                    name="category"
                    render={({ field: { onChange, value } }) => (
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="category"
                                value={value || ''}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                            >
                                {categories.map((category, i) => (
                                    <Radio
                                        key={`${category.label}_${i}`}
                                        label={category.label}
                                        value={category.value}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    )}
                />
                {values?.category === Report_Category.Other && (
                    <TextInput
                        label={intl.formatMessage({ id: 'reportForm.stepTwo.textInputLabel' })}
                        InputProps={{
                            endAdornment: values?.otherCategory?.length > 0 && (
                                <IconButton
                                    onClick={() => {
                                        setValue('otherCategory', '');
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        {...register('otherCategory')}
                    />
                )}
            </FormWrapper>
            <DevTool control={control} placement="top-right" />
        </DefaultLayout>
    );
};

export default ReportStepTwo;
