export const pl = {
    'loginPage.loginTitle': 'Zaloguj się',
    'loginPage.emailInputLabel': 'Adres e-mail',
    'loginPage.passwordInputLabel': 'Hasło',
    'loginPage.loginButton': 'Zaloguj',
    'loginPage.forgottenLink': 'Nie pamiętasz hasła?',
    'loginPage.dontHaveAccount': 'Nie masz jeszcze konta?',
    'loginPage.createAccount': 'Utwórz konto',
    'loginPage.validation.loginError': 'Niepoprawne dane do logowania lub konto nie istnieje.',
    'loginPage.validation.emptyEmail': 'Email nie może być pusty',
    'loginPage.validation.wrongEmail': 'Podany adres email jest niepoprawny',
    'loginPage.validation.emptyPassword': 'Hasło nie może być puste',

    'registrationPage.registrationTitle': 'Utwórz konto',
    'registrationPage.nameInputLabel': 'Imię',
    'registrationPage.validation.emptyName': 'Imię jest wymagane',
    'registrationPage.validation.nameLength': 'Imię powinno posiadać minimum {chars} znaki',
    'registrationPage.surnameInputLabel': 'Nazwisko',
    'registrationPage.validation.emptySurname': 'Nazwisko jest wymagane',
    'registrationPage.validation.surnameLength': 'Nazwisko powinno posiadać minimum {chars} znaki',
    'registrationPage.emailInputLabel': 'E-mail',
    'registrationPage.validation.emptyEmail': 'Email nie może być pusty',
    'registrationPage.validation.wrongEmail': 'Podany adres email jest niepoprawny',
    'registrationPage.passwordInputLabel': 'Hasło',
    'registrationPage.validation.emptyPassword': 'Hasło nie może być puste',
    'registrationPage.validation.passwordChars': 'Minimalna liczba znaków: {chars}',
    'registrationPage.validation.passwordNumber': 'Zawiera cyfrę',
    'registrationPage.validation.passwordSpecialChar': 'Zawiera znaki specjalne',
    'registrationPage.validation.passwordSmallAndCapitalLetters': 'Zawiera małe i duże litery',
    'registrationPage.policyAcceptation':
        'Klikając Wyraź zgodę i kontynuuj, akceptuję następujące dokumenty:',
    'registrationPage.privacyPolicy': 'Polityka prywatności',
    'registrationPage.submitButton': 'Wyraź zgodę i kontynuuj',
    'registrationPage.inviteSubmit': 'Wyraź zgodę i zarejestruj',
    'registrationPage.marketingConsentsInformations':
        'Będziemy przesyłać Ci e-maile marketingowe i informacje o ofertach. Możesz z nich zrezygnować w dowolnym momencie w ustawieniach konta lub w wiadomości marketingowej.',
    'registrationPage.marketingConsentsCheckbox':
        'Chcę otrzymywać wiadomości marketingowe od Notibox',

    'registrationActivationPage.title': 'Aktywuj swoje konto',
    'registrationActivationPage.emailSent': 'Wysłaliśmy e-mail na adres:',
    'registrationActivationPage.openYourEmailBox':
        'Otwórz swoją skrzynkę e-mail i kliknij łącze w wiadomości e-mail, aby aktywować konto.',
    'registrationActivationPage.spam':
        'Jeśli nie możesz znaleźć naszego e-maila, sprawdź swój spam.',
    'registrationActivationPage.sendNewEmail': 'Wyślij nową wiadomość z potwierdzeniem',
    'registrationActivationPage.emailSentAgain': 'Wiadomość wysłana ponownie',

    'registrationCompletePage.title': 'Uzupełnij dane firmy',
    'registrationCompletePage.companyNameInputLabel': 'Nazwa firmy/instytucji',
    'registrationCompletePage.companyShortNameInputLabel': 'Skrócona nazwa firmy/instytucji',
    'registrationCompletePage.companyShortNameDescription':
        'Ta nazwa będzie widoczna w skrzynce odbiorczej ze zgłoszeniami',
    'registrationCompletePage.validation.emptyCompanyName': 'Nazwa firmy jest wymagana',
    'registrationCompletePage.validation.emptyCompanyShortName':
        'Skrócona nazwa firmy jest wymagana',
    'registrationCompletePage.validation.companyNameLength':
        'Nazwa firmy powinna posiadać minimum {chars} znaki',
    'registrationCompletePage.validation.companyShortNameMinLength':
        'Skrócona nazwa firmy powinna posiadać minimum {chars} znaki',
    'registrationCompletePage.validation.companyShortNameMaxLength':
        'Skrócona nazwa firmy powinna posiadać maksimum {chars} znaki',
    'registrationCompletePage.validation.wrongVatEuNumber':
        'Niepoprawny numer NIP dla Twojego kraju.',
    'registrationCompletePage.validation.existVatEuNumberOrSubdomain':
        'Podany NIP lub subdomena już istnieje.',
    'registrationCompletePage.validation.tokenExpired': 'Token wygasł lub został wykorzystany.',
    'registrationCompletePage.validation.emptyVatEu': 'NIP nie może być pusty',
    'registrationCompletePage.validation.wrongVatEu': 'Podany NIP jest nieprawidłowy.',
    'registrationCompletePage.addressTitle': 'Adres rozliczeniowy',
    'registrationCompletePage.streetInputLabel': 'Ulica',
    'registrationCompletePage.validation.emptyStreet': 'Ulica jest wymagana',
    'registrationCompletePage.validation.streetLength':
        'Ulica powinna posiadać minimum {chars} znaki',
    'registrationCompletePage.buildingNumberLabel': 'Numer budynku',
    'registrationCompletePage.validation.emptyBuldingNumber': 'Numer budynku jest wymagany',
    'registrationCompletePage.validation.buldingNumberLength':
        'Numer budynku powinnien posiadać minimum {chars} znak',
    'registrationCompletePage.apartmentNumberLabel': 'Numer lokalu',
    'registrationCompletePage.cityLabel': 'Miasto',
    'registrationCompletePage.validation.emptyCity': 'Miasto jest wymagane',
    'registrationCompletePage.validation.cityLength':
        'Miasto powinno posiadać minimum {chars} znaki',
    'registrationCompletePage.postCodeLabel': 'Kod pocztowy',
    'registrationCompletePage.countryLabel': 'Państwo',
    'registrationCompletePage.validation.emptyCountry': 'Państwo jest wymagane',
    'registrationCompletePage.subdomainTitle': 'Subdomena',
    'registrationCompletePage.subdomainLabel': 'twojasubdomena',
    'registrationCompletePage.validation.emptySubdomain': 'Subdomena jest wymagana',
    'registrationCompletePage.validation.subdomainLength':
        'Sudomena powinna posiadać minimum {chars} znaki',
    'registrationCompletePage.whistleblowerChannel':
        'Pod tym adresem będzie dostępny kanał zgłoszeń dla Twoich sygnalistów.',
    'registrationCompletePage.continueButton': 'Kontynuuj',
    'registrationCompletePage.toastText': 'Konto zostało utworzone',

    'registrationLinkExpiredPage.title': 'Link aktywacyjny wygasł',
    'registrationLinkExpiredPage.accountNotCreated':
        'Twoje konto nie zostało utworzone. Spróbuj jeszcze raz.',
    'registrationLinkExpiredPage.createAccountButton': 'Utwórz konto',

    'registrationPaymentPage.title': 'Dokonaj płatności i gotowe!',
    'registrationPaymentPage.subtitle':
        'Twój indywidualny kanał przyjmowania zgłoszeń jest już aktywny:',
    'registrationPaymentPage.shareAddress':
        'Podziel się tym adresem ze swoimi pracownikami i kontrahentami. Od tej pory możesz swobodnie zarządzać zgłoszeniami z poziomu skrzynki Notibox.',
    'registrationPaymentPage.trialInfo':
        'Kanał przyjmowania zgłoszeń pozostanie aktywny jeszcze przez 14 dni.',
    'registrationPaymentPage.paymentInstructions':
        'W tym czasie dokonaj opłaty abonamentowej w wysokości ustalonej z naszym działem sprzedaży. Brak wpłaty w wyznaczonym czasie spowoduje wstrzymanie dostępu do kanału zgłoszeń i Twojej skrzynki Notibox. Prosimy o wpłatę na poniższe dane podając w tytule przelewu adres e-mail wskazany w rejestracji:',
    'registrationPaymentPage.receiverName': 'Nazwa odbiorcy:',
    'registrationPaymentPage.accountNumber': 'Numer konta:',
    'registrationPaymentPage.swiftCode': 'Kod BIC SWIFT:',
    'registrationPaymentPage.paymentTitle': 'Tytuł przelewu:',
    'registrationPaymentPage.emailNotification':
        'Powiadomimy Cię mailowo o zaksięgowaniu wpłaty. Jednocześnie aktywujemy usługę na pełny okres rozliczeniowy.',
    'registrationPaymentPage.questions':
        'Masz pytania? Skontaktuj się z nami: kontakt@notibox.pl, 508 320 393',

    'resetPasswordPage.title': 'Zmień hasło',
    'resetPasswordPage.emailParagraph':
        'Podaj swój adres e-mail powiązany z kontem Notibox. Na ten adres wyślemy Ci link do zmiany hasła.',
    'resetPasswordPage.emailInputLabel': 'Adres e-mail',
    'resetPasswordPage.validation.emptyEmail': 'Email nie może być pusty',
    'resetPasswordPage.validation.wrongEmail': 'Podany adres email jest nie poprawny',
    'resetPasswordPage.submitButton': 'Wyślij link',
    'resetPasswordPage.cancelButton': 'Anuluj',

    'resetPasswordNotify.title': 'Otwórz link do resetu hasła',
    'resetPasswordNotify.emailSent': 'Wysłaliśmy e-mail na adres:',
    'resetPasswordNotify.openEmailParagraph':
        'Otwórz swoją skrzynkę e-mail i kliknij link w wiadomości e-mail, aby zmienić hasło.',
    'resetPasswordNotify.checkSpam': 'Jeśli nie możesz znaleźć naszego e-maila, sprawdź swój spam.',

    'resetPasswordConfirmationPage.title': 'Hasło zostało zmienione',
    'resetPasswordConfirmationPage.submitButton': 'Zaloguj',

    'newPasswordPage.title': 'Zmień hasło',
    'newPasswordPage.passwordInputLabel': 'Nowe hasło',
    'newPasswordPage.validation.emptyPassword': 'Hasło nie moze być puste',
    'newPasswordPage.validation.wrongPasswordFormat':
        'Hasło musi zawierać minimum 8 znaków, małą i dużą literę, znak specjalny oraz cyfrę.',
    'newPasswordPage.passwordConfirmationShortLabel': 'Wprowadź ponownie hasło',
    'newPasswordPage.passwordConfirmationLongLabel': 'Wprowadź ponownie nowe hasło',
    'newPasswordPage.validation.differentPassword': 'Hasła różnią się.',
    'newPasswordPage.submitButton': 'Zmień hasło',

    'resetLinkExpiredPage.title': 'Hasło nie zostało zmienione',
    'resetLinkExpiredPage.alert': 'Link użyty do zmiany hasła stracił ważność.',
    'resetLinkExpiredPage.provideEmailAgain':
        'Podaj ponownie swój adres e-mail powiązany z kontem Notibox. Na ten adres wyślemy Ci kolejny link do zmiany hasła.',
    'resetLinkExpiredPage.emailInputLabel': 'E-mail',
    'resetLinkExpiredPage.validation.emptyEmail': 'Email nie może być pusty',
    'resetLinkExpiredPage.validation.wrongEmail': 'Podany adres email jest nie poprawny',
    'resetLinkExpiredPage.validation.submitButton': 'Wyślij link',

    'reportPage.title': 'Bezpieczny kanał sygnalisty',
    'reportPage.secondTitleValid':
        'Zachęcamy do zgłaszania przypadków niewłaściwego postępowania w naszej organizacji.',
    'reportPage.secondTitleInvalid':
        'Ups. Domena organizacji do której próbujesz zgłosić przypadki niewłaściwego zachowania jest niepoprawna.',
    'reportPage.highlightText':
        'Informacje, które podajesz, są bezpieczne i zaszyfrowane. Twoja tożsamość pozostaje w pełni anonimowa. To Ty decydujesz czy chcesz udostępnić swoje dane.',
    'reportPage.paragraph':
        'Tylko specjalnie wyznaczone osoby w organizacji będą miały dostęp do Twojego zgłoszenia.',
    'reportPage.reportButton': 'Zgłoś sprawę',
    'reportPage.checkReportStatusButton': 'Sprawdź status/zaktualizuj sprawę',
    'reportPage.footer.needHelp': 'Potrzebujesz pomocy?',
    'reportPage.footer.phoneIconText': 'Zadzwoń',
    'reportPage.footer.emailIconText': 'Napisz e-mail',
    'reportPage.footer.privacyPolicy': 'Polityka prywatności',

    'reportForm.nextStepButton': 'Dalej',
    'reportForm.skipStepButton': 'Pomiń',
    'reportForm.stepOne.title': 'Jaka relacja wiąże Cię z {companyShortName}?',
    'reportForm.stepOne.textInputLabel': 'Określ relację',
    'reportForm.stepTwo.textInputLabel': 'Określ kategorię',
    'reportForm.stepTwo.title': 'Czego dotyczy zgłaszana sprawa?',
    'reportForm.stepThree.title': 'Czy wiesz o innych osobach związanych ze sprawą?',
    'reportForm.stepThree.paragraph':
        'Mogą to być np. osoby pokrzywdzone, świadkowie lub sprawcy zdarzenia.',
    'reportForm.stepThree.addPersonButton': 'Dodaj kolejną osobę',

    'reportForm.stepFive.title': 'Opisz zgłaszaną sprawę',
    'reportForm.stepFive.paragraph':
        'Opisz dokładnie zdarzenie lub problem, którym chcesz się z nami podzielić. Pamiętaj, że duża ilość szczegółów pomoże w szybkim i rzetelnym rozwiązaniu Twojej sprawy.',
    'reportForm.stepFive.messageLabel': 'Opis zdarzenia lub problemu',
    'reportForm.stepFive.validation.emptyMessage': 'Pole jest wymagane.',
    'reportForm.stepFive.validation.minLength': 'Pole powinno posiadać conajmniej {chars} znaków.',
    'reportForm.stepFive.validation.maxLength': 'Pole akceptuje maksimum {chars} znaków.',
    'reportForm.stepFive.attachmentsFirstParagraph':
        'Chcesz dołączyć dowody lub inne materiały związane ze sprawą?',
    'reportForm.stepFive.attachmentsSecondParagraph':
        'Załącznikami mogą być np. zdjęcia, nagrania lub dokumenty.',
    'reportForm.stepFive.addAttachmentButton': 'Dodaj załącznik',
    'reportForm.stepFive.validation.tooBigFile':
        'Za duży rozmiar pliku, spróbuj go skompresować lub przyciąć.',
    'reportForm.stepFive.validation.wrongFileType': 'Nie obsługiwany format pliku.',

    'reportForm.stepFour.title': 'Kiedy miała miejsce pierwsza obserwacja zdarzenia?',
    'reportForm.stepFour.dateParagraph':
        'Jeśli pamiętasz konkretną datę i/lub przybliżoną godzinę podaj je w poniższych polach:',
    'reportForm.stepFour.recurringParagraph': 'Czy opisane zdarzenie się powtarzało?',
    'reportForm.stepFour.emptyDateLabel': 'Data',
    'reportForm.stepFour.emptyHourLabel': 'Godzina',
    'reportForm.stepFour.radioYesLabel': 'Tak',
    'reportForm.stepFour.radioNoLabel': 'Nie',
    'reportForm.stepFour.recurringFrequencyLabel': 'Częstotliwość zdarzenia',
    'reportForm.stepSix.title': 'Czy chcesz udostępnić nam swoje dane?',
    'reportForm.stepSix.notShareButton': 'Nie udostępniam',
    'reportForm.stepSix.shareButton': 'Udostępniam',
    'reportForm.stepSix.anonymousParagraph':
        'Żadne Twoje dane nie zostaną nam udostępnione. Zgłoszenie jest w pełni anonimowe.',
    'reportForm.stepSix.notAnonymousParagraph':
        'Udostępnienie danych nie jest wymagane. Mogą one jednak nam pomóc skontaktować się z Tobą w sprawie zgłoszenia.',
    'reportForm.stepSix.highlight':
        'W przypadku odmowy będziemy się z Tobą kontaktować wyłącznie przy użyciu niniejszej platformy.',
    'reportForm.stepSix.sharingDataParagraph':
        'Twoje dane zostaną nam udostępnione. Zdecyduj, które z nich chcesz nam przekazać. Tylko specjalnie wyznaczone osoby w organizacji będą miały do nich dostęp.',
    'reportForm.stepSix.nameLabel': 'Imię',
    'reportForm.stepSix.validation.emptyName': 'Imię jest wymagane',
    'reportForm.stepSix.validation.nameMinLength': 'Imię powinno posiadać minimum {chars} znaki',
    'reportForm.stepSix.surnameLabel': 'Nazwisko',
    'reportForm.stepSix.validation.emptySurname': 'Nazwisko jest wymagane',
    'reportForm.stepSix.validation.surnameMinLength':
        'Nazwisko powinno posiadać minimum {chars} znaki',
    'reportForm.stepSix.emailInputLabel': 'E-mail',
    'reportForm.stepSix.validation.wrongEmail': 'Podany adres email jest nie poprawny',
    'reportForm.stepSix.phone': 'Telefon',
    'reportForm.stepSeven.sendReportButton': 'Wyślij zgłoszenie',
    'reportForm.stepSeven.title': 'Utwórz hasło dostępu do swojego zgłoszenia',
    'reportForm.stepSeven.highlight':
        'Dobrze je zapamiętaj, gdyż później nie będzie można go zmienić.',
    'reportForm.stepSeven.passwordParagraph':
        'Hasło będzie wymagane, aby wyświetlić naszą odpowiedź na Twoje zgłoszenie.',
    'reportForm.stepSeven.passwordLabel': 'Hasło',
    'reportForm.stepSeven.validation.emptyPassword': 'Hasło nie moze być puste',
    'reportForm.stepSeven.validation.wrongPasswordFormat':
        'Hasło musi zawierać minimum 8 znaków, małą i dużą literę, znak specjalny oraz cyfrę.',
    'reportForm.stepSeven.passwordConfirmationLabel': 'Wprowadź ponownie hasło',
    'reportForm.stepSeven.validation.differentPasswords': 'Hasła różnią się.',

    'reportSubmittedPage.title': 'Twoje zgłoszenie zostało wysłane',
    'reportSubmittedPage.downloadReport': 'Pobierz potwierdzenie zgłoszenia',
    'reportSubmittedPage.highlightText':
        'Zapisz stworzone hasło i numer sprawy zamieszczony poniżej. Będą one niezbędne do wyświetlenia odpowiedzi na Twoje zgłoszenie.',

    'reportSubmittedPdf.confirmationText': 'Potwierdzenie przesłania zgłoszenia',
    'reportSubmittedPdf.reportNumber': 'Numer sprawy: {reportNumber}',
    'reportSubmittedPdf.date': 'Data: {date}',
    'reportSubmittedPdf.attachments': 'Załączniki:',
    'reportSubmittedPdf.attachments.yes': 'Tak',
    'reportSubmittedPdf.attachments.no': 'Nie',
    'reportSubmittedPdf.message': 'Treść zgłoszenia:',
    'reportSubmittedPdf.checkReportText':
        'Możesz sprawdzić i aktualizować swoje zgłoszenie pod adresem',
    'reportSubmittedPdf.loginUrlText':
        'Do zgłoszenia loguj się przy użyciu numeru sprawy oraz ustalonego przez siebie hasła zgłoszenia.',
    'reportSubmittedPdf.linkText':
        'Przy użyciu numeru zgłoszenia i utworzonego hasła możesz sprawdzać status sprawy i przesyłać dodatkowe informacje wchodząc na stronę:',

    'dashboardPageMenu.reports': 'Zgłoszenia',
    'dashboardPageMenu.settings': 'Ustawienia',
    'dashboardPageMenu.users': 'Użytkownicy',
    'dashboardPageMenu.new': 'Nowe',
    'dashboardPageMenu.inProgress': 'W toku',
    'dashboardPageMenu.closed': 'Zamknięte',
    'dashboardPageMenu.reportsSummary': 'Raporty',

    'dashboardPage.header.title': 'Skrzynka zgłoszeń',
    'dashboardPage.titles.newReports': 'Nowe zgłoszenia',
    'dashboardPage.titles.reportsInProgress': 'Zgłoszenia w toku',
    'dashboardPage.titles.closedReports': 'Zamknięte zgłoszenia',
    'dashboardPage.reporter': 'Sygnalista',
    'dashboardPage.relation': 'Relacja',
    'dashboardPage.category': 'Kategoria',
    'dashboardPage.reportNumber': 'Numer',
    'dashboardPage.lastMessage': 'Ostatnia wiadomość',
    'dashboardPage.createdDate': 'Data zgłoszenia',
    'dashboardPage.selectOption.selection.none': 'Żadne',
    'dashboardPage.selectOption.selection.all': 'Wszystkie',
    'dashboardPage.selectOption.selection.read': 'Przeczytane',
    'dashboardPage.selectOption.selection.unread': 'Nieprzeczytane',
    'dashboardPage.selectOption.status.new': 'Nowe',
    'dashboardPage.selectOption.status.inProgress': 'W toku',
    'dashboardPage.selectOption.status.closed': 'Zamknięte',
    'dashboardPage.reportsGrid.noRowsLabel': 'Brak raportów',
    'dashboardPage.actionBar.refresh': 'Odśwież',
    'dashboardPage.actionBar.sorting.newest': 'Najnowsze',
    'dashboardPage.actionBar.sorting.oldest': 'Najstarsze',
    'dashboardPage.footer.oneSelectedReport': 'zaznaczony raport',
    'dashboardPage.footer.manySelectedReports': 'zaznaczone raporty',

    'usersPage.title': 'Użytkownicy',
    'usersPage.name': 'Imię i nazwisko',
    'usersPage.status': 'Status',
    'usersPage.email': 'Email',
    'usersPage.role': 'Typ',
    'usersPage.action': 'Akcja',
    'usersPage.deleteButton': 'Usuń',
    'usersPage.addButton': 'Dodaj',
    'usersPage.modal.title': 'Dodaj użytkownika',
    'usersPage.emailInputLabel': 'E-mail',
    'usersPage.validation.emptyEmail': 'Email nie może być pusty',
    'usersPage.validation.wrongEmail': 'Podany adres email jest niepoprawny',
    'usersPage.submitButton': 'Zaproś',
    'usersPage.modal.titleSuccess': 'Sukces',
    'usersPage.modal.successDesc': 'Zaproszenie wysłane.',
    'usersPage.continueButton': 'Kontynuuj',
    'usersPage.status.active': 'Aktywny',
    'usersPage.status.created': 'Nieaktywny',
    'usersPage.status.deleted': 'Usunięty',
    'usersPage.status.invited': 'Zaproszony',
    'usersPage.role.maintainer': 'Zarządca',
    'usersPage.role.employee': 'Pracownik',
    'usersPage.modal.confirmInvDelete': 'Czy na pewno chcesz usunąć zaproszenie?',
    'usersPage.modal.confirmUserDelete': 'Czy na pewno chcesz usunąć użytkownika?',

    'reportDetailsPage.backButton': 'Wróć',
    'reportDetailsPage.reporter': 'Sygnalista',
    'reportDetailsPage.anonymous': 'Anonim',
    'reportDetailsPage.related': 'Powiązani',
    'reportDetailsPage.attachments': 'Załączniki',
    'reportDetailsPage.date': 'Data zdarzenia',
    'reportDetailsPage.recurrent': 'Powtarzające się',
    'reportDetailsPage.createMessage': 'Utwórz wiadomość',
    'reportDetailsPage.modal.title': 'Odpowiedź na zgłoszenie',
    'reportDetailsPage.modal.messageLabel': 'Treść wiadomości',
    'reportDetailsPage.modal.validation.emptyMessage': 'Pole jest wymagane.',
    'reportDetailsPage.modal.validation.message.minLength':
        'Pole powinno posiadać conajmniej {chars} znaków.',
    'reportDetailsPage.modal.validation.message.maxLength':
        'Pole akceptuje maksimum {chars} znaków.',
    'reportDetailsPage.modal.sendMessageButton': 'Wyślij',
    'reportDetailsPage.modal.cancelMessageButton': 'Anuluj',
    'reportDetailsPage.modal.messageSentTitle': 'Wiadomość wysłana',
    'reportDetailsPage.modal.messageSentClose': 'Zamknij',
    'reportDetailsPage.closeAlert': 'Zgłoszenie zamknięte, zmiana statusu niemożliwa.',

    'closeCaseModal.closeManyCasesTitle': 'Zamknij zgłoszenia',
    'closeCaseModal.closeOneCaseTitle': 'Zamknij zgłoszenie',
    'closeCaseModal.closeManyCasesHighlight':
        'Po zamknięciu zgłoszenia nie będzie można już zmienić jego statusu. Dalsza możliwość komunikacji w ramach tego zgłoszenia zostanie wyłączona. Pozostanie ono widoczne na liście zgłoszeń zamkniętych.',
    'closeCaseModal.closeOneCaseHighlight':
        'Po zamknięciu zgłoszeń nie będzie można już zmienić ich statusu. Dalsza możliwość komunikacji w ramach tych zgłoszeń zostanie wyłączona. Pozostaną one widoczne na liście zgłoszeń zamkniętych.',
    'closeCaseModal.closeManyCasesParagraphFirstPart':
        'Zamykając zgłoszenie istnieje konieczność poinformowania Sygnalisty o podjętych działaniach wynikających ze zgłoszenia. Opisz je oraz ich efekt w podsumowaniu poniżej.',
    'closeCaseModal.closeManyCasesParagraphSecondPart':
        'Ponieważ zamykasz wiele zgłoszeń w jednej akcji ich Sygnalisci otrzymają to samo podsumowanie.',
    'closeCaseModal.closeManyCasesParagraphThirdPart':
        'Jeśli chcesz przesłać różne podsumowania zamknij każde zgłoszenie osobno.',
    'closeCaseModal.closeOneCaseParagraph':
        'Zamykając zgłoszenie istnieje konieczność poinformowania Sygnalisty o podjętych działaniach wynikających ze zgłoszenia. Opisz je oraz ich efekt w podsumowaniu poniżej. Zostanie ono automatycznie wysłane do Sygnalisty.',
    'closeCaseModal.closeResponseLabel': 'Podsumowanie z podjętych działań',
    'closeCaseModal.validation.emptyCloseResponse': 'Podsumowanie z podjętych działań',
    'closeCaseModal.validation.closeResponseMinLength':
        'Pole powinno posiadać conajmniej {chars} znaków.',
    'closeCaseModal.validation.closeResponseMaxLength': 'Pole akceptuje maksimum {chars} znaków.',
    'closeCaseModal.closeManyCasesButton': 'Zamknij zgłoszenia',
    'closeCaseModal.closeOneCaseButton': 'Zamknij zgłoszenie',
    'closeCaseModal.cancel': 'Anuluj',

    'loginToReportPage.pageTitle': 'Kanał sygnalisty',
    'loginToReportPage.title': 'Logowanie do zgłoszenia',
    'loginToReportPage.highlight':
        'Zaloguj się przy pomocy czteroznakowego numeru swojej sprawy i utworzonego hasła dostępu.',
    'loginToReportPage.reportNumberLabel': 'Numer sprawy',
    'loginToReportPage.validation.emptyReportNumber': 'Numer raportu nie może być pusty',
    'loginToReportPage.passwordLabel': 'Hasło',
    'loginToReportPage.validation.emptyPassword': 'Hasło nie może być puste',
    'loginToReportPage.loginButton': 'Zaloguj',
    'loginToReportPage.validation.loginError':
        'Niepoprawne dane do logowania lub sprawa nie istnieje.',

    'checkReportPage.pageTitle': 'Kanał sygnalisty',
    'checkReportPage.reportClosedTooltip':
        'Sprawa jest zamknięta. Nie mozesz otworzyć jej ponownie. Jesli masz cos do dodania, zalóż nową sprawę!',
    'checkReportPage.reportClosedInfoCardFirstPart': 'Sprawa zamknięta.',
    'checkReportPage.reportClosedInfoCardSecondPart': 'Zapoznaj się z odpowiedzią do zgłoszenia.',
    'checkReportPage.reportInprogressTooltip': 'Sprawa jest w toku.',
    'checkReportPage.reportInprogressInfoCardFirstPart': 'Sprawa w toku.',
    'checkReportPage.reportInprogressInfoCardSecondPart':
        'Otrzymasz odpowiedź do 3 mies. od zgłoszenia.',
    'checkReportPage.refreshButton': 'Odśwież',
    'checkReportPage.sendMessageButton': 'Prześlij wiadomość',
    'checkReportPage.messageButton': 'Wiadomość',
    'checkReportPage.whistleblowerTitle': 'Sygnalista',
    'checkReportPage.relatedTitle': 'Powiązani',
    'checkReportPage.attachmentsTitle': 'Załączniki',
    'checkReportPage.dateTitle': 'Data zdarzenia',
    'checkReportPage.recurring': 'Powtarzające się',
    'checkReportPage.anonymous': 'Anonim',
    'checkReportPage.createMessageButton': 'Utwórz wiadomość',
    'checkReportPage.sendFile': 'Prześlij załącznik',
    'checkReportPage.sendFileShort': 'Załącznik',
    'checkReportPage.uploadFileModal.title': 'Prześlij załącznik',
    'checkReportPage.uploadFileModal.description':
        'Załącznikami mogą być np. zdjęcia, nagrania lub dokumenty.',
    'checkReportPage.uploadFileModal.cancelButton': 'Anuluj',
    'checkReportPage.uploadFileModal.sendButton': 'Prześlij',
    'checkReportPage.uploadFileModal.closeButton': 'Zamknij',
    'checkReportPage.uploadFileModal.addAttachmentButton': 'Dodaj załącznik',
    'checkReportPage.uploadFileModal.successInfo': 'Załącznik został przesłany',
    'checkReportPage.showAttachmentLink': 'Pokaż',

    'reportsSummary.title': 'Raporty',
    'reportsSummary.reportNumber': 'Numer',
    'reportsSummary.createdDate': 'Data zgłoszenia',
    'reportsSummary.status': 'Status',
    'reportsSummary.status.empty': 'Wszystkie',
    'reportsSummary.whistleblower': 'Sygnalista',
    'reportsSummary.whistleblower.empty': 'Wszyscy',
    'reportsSummary.relation': 'Relacja',
    'reportsSummary.relation.empty': 'Każda',
    'reportsSummary.category': 'Kategoria',
    'reportsSummary.category.empty': 'Wszystkie',
    'reportsSummary.createReportButton': 'Utwórz raport',
    'reportsSummary.numberOfReports': 'Raportowane zgłoszenia',
    'reportsSummary.search.placeholder': 'Numer zgłoszenia',
    'reportsSummary.reportNumberInputError': 'Numer raportu musi mieć 4 znaki',
    'reportsSummary.dateInput.from': 'Od',
    'reportsSummary.dateInput.to': 'Do',
    'reportsSummary.list.empty': 'Brak wyników. Spróbuj zmienić filtry.',
    'reportsSummary.list.pagination.of': 'z',
    'reportsSummary.generateReportsModal.title': 'Określ zawartość raportu',
    'reportsSummary.generateReportsModal.numberOfReports': 'Raport tworzony dla wybranych:',
    'reportsSummary.generateReportsModal.numberOfReportsCount': '{count} zgłoszeń',
    'reportsSummary.generateReportsModal.numberOfReportsCountOne': '{count} zgłoszenie',
    'reportsSummary.generateReportsModal.reportsRegistry': 'Rejestr zgłoszeń',
    'reportsSummary.generateReportsModal.finalProtocol': 'Protokół końcowy',
    'reportsSummary.generateReportsModal.cancel': 'Anuluj',
    'reportsSummary.downloadButton': 'Pobierz',

    'finalProtocolPdf.header': 'Protokół końcowy',
    'finalProtocolPdf.creator': 'Twórca dokumentu',
    'finalProtocolPdf.generatedAt': 'Dokument wygenerowany dnia:',
    'finalProtocolPdf.generatedFor': 'Dokument wygenerowany dla podmiotu:',
    'finalProtocolPdf.nameAndSurname': 'Imię i nazwisko',
    'finalProtocolPdf.email': 'Adres e-mail',
    'finalProtocolPdf.reportDetails': 'Szczegóły zgłoszenia',
    'finalProtocolPdf.createdAt': 'Data zgłoszenia',
    'finalProtocolPdf.closedDate': 'Data zamknięcia',
    'finalProtocolPdf.reportNumber': 'Numer zgłoszenia',
    'finalProtocolPdf.whistleblower': 'Sygnalista',
    'finalProtocolPdf.anonymous': 'Anonim',
    'finalProtocolPdf.contactData': 'Dane kontaktowe',
    'finalProtocolPdf.contactData.none': 'Brak',
    'finalProtocolPdf.relation': 'Relacja',
    'finalProtocolPdf.category': 'Kategoria',
    'finalProtocolPdf.closedMessage': 'Podjęte działania',
    'finalProtocolPdf.page': 'Strona {pages}',

    'reportsRegistryPdf.header': 'Rejestr zgłoszeń',
    'reportsRegistryPdf.reportsCount': 'Raportowane zgłoszenia:',
    'reportsRegistryPdf.filters': 'Zastosowany filtr zgłoszeń',
    'reportsRegistryPdf.filters.date': 'Okres',
    'reportsRegistryPdf.filters.status': 'Status',
    'reportsRegistryPdf.filters.whistleblower': 'Sygnalista',
    'reportsRegistryPdf.filters.relation': 'Relacja',
    'reportsRegistryPdf.filters.category': 'Kategoria',
    'reportsRegistryPdf.filters.none': 'Brak',
    'reportsRegistryPdf.filters.whistleblowerRelationsAll': 'Wszystkie typy relacji',
    'reportsRegistryPdf.table.status': 'Status',
    'reportsRegistryPdf.table.contactData': 'Dane kontaktowe',

    'reportSubmittedPdf.header': 'Potwierdzenie nadania zgłoszenia',

    'reportsRegistryPdf.category': 'Kategoria',
    'reportsRegistryPdf.whistleblower': 'Sygnalista',

    'userMenu.myAccount': 'Moje konto',
    'userMenu.changePassword': 'Zmień hasło',
    'userMenu.logout': 'Wyloguj',

    'quickMenu.setStatus': 'Ustaw status',
    'quickMenu.setStatusShort': 'status',
    'quickMenu.sort': 'Sortuj',

    'downloadMenu.download': 'Pobierz',

    'showDetails.hide': 'Ukryj szczegóły',
    'showDetails.show': 'Pokaż szczegóły',

    'person.personNumber': 'Osoba nr',
    'person.delete': 'Usuń',
    'person.nameLabel': 'Imię',
    'person.surnameLabel': 'Nazwisko',
    'person.relationLabel': 'Opisz związek osoby ze sprawą',

    'pickedFile.delete': 'Usuń',

    'reportFooter.firstParagraph':
        'Przy użyciu hasła i numeru sprawy możesz również aktualizować zgłoszenie lub dostarczyć dodatkowych informacji.',
    'reportFooter.secondParagraphStrong':
        'Maksymalny czas przesłania odpowiedzi na Twoje zgłoszenie to 3 miesiące.',
    'reportFooter.secondParagraph':
        'Pomimo to, dokonamy wszelkich starań, aby wydarzyło się to wcześniej. Dlatego zachęcamy do systematycznego logowania i sprawdzania statusu zgłoszenia pod poniższym adresem:',

    'reportNumber.reportNumber': 'Numer sprawy:',

    'validation.unknownError': 'Coś poszło nie tak, spróbuj później.',

    reportingChannel: 'Kanał zgłoszeń',
};
