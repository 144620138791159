import { EnvType } from '@@types/CommonTypes';
import { resolveEnv } from '@utils/helpers';

export const __DEV__ = process.env.NODE_ENV !== EnvType.production;
export const API_URL = resolveEnv('REACT_APP_GRAPHQL');
export const LANDING_PAGE_URL = resolveEnv('REACT_APP_LANDING_PAGE_URL');

export const breakpoints = {
    xs: 480,
    sm: 768,
    md: 992,
    lg: 1200,
};

export const countries = {
    pl: [{ country: 'Polska', value: 'PL' }],
    en: [{ country: 'Poland', value: 'PL' }],
};
