import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Arrow from '@assets/images/icon-icon-arrow-down.svg';
import { UserMenuWrapper, useStyles } from './UserMenu.styled';
import useUserMenu from './useUserMenu';
import { FormattedMessage } from 'react-intl';

export const UserMenu = ({ nameAndSurname }: { nameAndSurname: string }) => {
    const { anchorEl, handleClick, handleClose, handleLogout } = useUserMenu();
    const [name, surname] = nameAndSurname.split(' ');
    const classes = useStyles();
    return (
        <UserMenuWrapper>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <Avatar className={classes.small}>{`${name[0]}${surname[0]}`}</Avatar>
                <img src={Arrow} />
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {/* <MenuItem onClick={handleClose}>
                    <FormattedMessage id="userMenu.myAccount" />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <FormattedMessage id="userMenu.changePassword" />
                </MenuItem> */}
                <MenuItem onClick={handleLogout}>
                    <FormattedMessage id="userMenu.logout" />
                </MenuItem>
            </Menu>
        </UserMenuWrapper>
    );
};

export default UserMenu;
